import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { initApiResult, storedApiResult } from '../../modules/core/bffApiModule';
import * as CONSTANTS from '../../constants/constants';

export const BffApiResultHandler = () => {

  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const apiResult = useSelector(storedApiResult);

  useEffect(() => {
    switch (apiResult.status) {
      case CONSTANTS.API_STATUS_400:
        dispatch(initApiResult());
        history.push(CONSTANTS.PATH_SYSTEMERROR);
        break;
      case CONSTANTS.API_STATUS_401:
        dispatch(initApiResult());
        // シングルサインオン認証をスタートします。
        console.log(location.pathname);
        const host = window.location.host;
        // フロント側のドメインに合わせたBFFのURLを特定
        let baseURL;
        if (host.match("localhost")) {
          baseURL = CONSTANTS.BFF_SERVICE_ENDPOINT_FOR_LOCAL;
        } else if (host === 't2.skillmap-site.kensetsu-site.com') {
          baseURL = CONSTANTS.BFF_SERVICE_ENDPOINT_FOR_T2;
        } else if (host === 's2.skillmap-site.kensetsu-site.com') {
          baseURL = CONSTANTS.BFF_SERVICE_ENDPOINT_FOR_S2;
        } else if (host === 'skillmap-site.kensetsu-site.com') {
          baseURL = CONSTANTS.BFF_SERVICE_ENDPOINT_FOR_P2;
        }
        let authUrl: string = `${baseURL}/auth?next=`;
        authUrl += encodeURIComponent(window.location.href);
        window.location.assign(authUrl);
        break;
      case CONSTANTS.API_STATUS_403:
        dispatch(initApiResult());
        history.push(CONSTANTS.PATH_FORBIDDEN);
        break;
      case CONSTANTS.API_STATUS_500:
        dispatch(initApiResult());
        history.push(CONSTANTS.PATH_SYSTEMERROR);
        break;
    }

  }, [apiResult]);

  return (
    <></>
  );
}
