import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router"
import { Layout, Table, Input, Tooltip } from "antd";
import { Header, ContentEx as Content, FunctionTitle, CommonMessage, Footer } from "../../core/CorePageContents";
import * as Module from "../../../modules/partner/attendance/attendanceModule";
import { CriteriaForm, Checkbox, AuthButton, MonthPicker } from "../../core/CoreForm";
import styles from "./AttendanceSummary.module.css";
import { Store } from "antd/lib/form/interface";
import { initMessages } from "../../../modules/core/commonMessageModule";
import { SortOrder } from "antd/lib/table/interface";
import * as Model from "../../../modules/partner/attendance/attendanceModel";
import { Moment } from "moment";
import * as CONSTANTS from "../../../constants/constants";
import * as messagesUtils from "../../../utils/messagesUtils";
import * as formatUtils from "../../../utils/formatUtils";
import * as Utils from '../../../utils/utils';
import { initApiResult, storedApiResult } from "../../../modules/core/bffApiModule";

interface titleInterface {
  title: string;
}

const EXIST_NOTSUBMITED = "未提出あり"
const EXIST_SUBMITED = "提出済あり"
const EXIST_CONFIRMED = "確認済あり"
const EXIST_APPROVED = "承認済あり"

export const AttendanceSummaryList = (props: titleInterface) => {

  const dispatch = useDispatch();
  let dataList = useSelector(Module.storedDataList);
  let total = useSelector(Module.storedSummaryTotal);
  let criteria = useSelector(Module.storedSummaryCriteria);
  const history = useHistory();
  const apiResult = useSelector(storedApiResult);

  useEffect(() => {
    dispatch(Module.getDataListAsync(criteria));
  },
    [criteria]
  );


  const moveToDetail = (record: Model.WorkRecordPartnerSummaryRow, event: React.MouseEvent<HTMLElement, MouseEvent>, rowIndex: number | undefined) => {
    dispatch(Module.prepareMoveToDetail(record));
    dispatch(Module.prepareMoveToDetailCriteria(criteria));
    history.push(CONSTANTS.PATH_PARTNER_ATTENDANCE_DETAIL);
  }

  const columns = [
    {
      title: "元請会社",
      key: "1",
      width: 300,
      dataIndex: "primeCompanyName",
      sortOrder: (criteria.sortItem !== "1") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      align: "center" as "center",
      sorter: true,
      showSorterTooltip: false,
      render: (text: string, row: Model.WorkRecordPartnerSummaryRow) => {
        return (
          <>
            <div className={styles.columnAlignLeft}>{text}</div>
          </>
        )
      }
    },
    {
      title: "作業所",
      key: "2",
      width: 300,
      dataIndex: "projectName",
      sortOrder: (criteria.sortItem !== "2") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordPartnerSummaryRow) => {
        return (
          <>
            <div className={styles.columnAlignLeft}>{text}</div>
          </>
        )
      }
    },
    {
      title: "一次協力会社",
      key: "3",
      width: 300,
      sortOrder: (criteria.sortItem !== "3") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordPartnerSummaryRow) => {
        return (
          <>
            <div className={styles.columnAlignLeft}>{row.firstPartnerCompanyName}</div>
          </>
        )
      }
    },
    {
      title: "所属会社",
      key: "4",
      dataIndex: "belongCompanyName",
      width: 300,
      sortOrder: (criteria.sortItem !== "4") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
    },
    {
      title: "勤怠年月",
      key: "5",
      width: 200,
      sortOrder: (criteria.sortItem !== "5") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordPartnerSummaryRow) => {
        return (
          <>
            {<div >{formatUtils.formatMonthChar(row.attendanceYearMonth)}</div>}
          </>
        )
      }
    },
    {
      title: "勤怠実績　提出状況",
      width: 300,
      showSorterTooltip: false,
      children: [
        {
          title: "未提出",
          width: 100,
          dataIndex: "notSubmitted",
          align: "center" as "center",
          render: (test: string, row: Model.WorkRecordPartnerSummaryRow) => {
            return (
              {
                props: {
                  className: row.notSubmitted > 0 ? styles.existNotSubmited : {},
                },
                children: (
                  row.notSubmitted > 0 ?
                    <><div className={styles.existNotSubmitedColor}>{test}</div></> :
                    <><div>{test}</div></>
                )
              }
            )
          }
        },
        {
          title: "提出済",
          width: 100,
          dataIndex: "submitted",
          align: "center" as "center",
          render: (test: string, row: Model.WorkRecordPartnerSummaryRow) => {
            return (
              {
                props: {
                  className: row.submitted > 0 ? styles.existSubmitOrConfirmed : {},
                },
                children: (<div>{test}</div>)
              }
            )
          }
        },
        {
          title: "確認済",
          width: 100,
          align: "center" as "center",
          dataIndex: "confirmed",
          render: (test: string, row: Model.WorkRecordPartnerSummaryRow) => {
            return (
              {
                props: {
                  className: row.confirmed > 0 ? styles.existSubmitOrConfirmed : {},
                },
                children: (<div>{test}</div>)
              }
            )
          }
        },
        {
          title: "承認済",
          width: 100,
          align: "center" as "center",
          dataIndex: "approved",
        }]
    },
  ];

  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let newCriteria: Model.WorkRecordSummaryCriteria = {
      primeCompanyName: criteria.primeCompanyName,
      primeCompanyNameKana: criteria.primeCompanyNameKana,
      projectName: criteria.projectName,
      belongCompanyName: criteria.belongCompanyName,
      belongCompanyNameKana: criteria.belongCompanyNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      fromYYYYMMDD: criteria.fromYYYYMMDD,
      toYYYYMMDD: criteria.toYYYYMMDD,
      notSubmitted: criteria.notSubmitted,
      submitted: criteria.submitted,
      confirmed: criteria.confirmed,
      approved: criteria.approved,
      lastName: criteria.workerLastName,
      firstName: criteria.workerFirstName,
      sortItem: criteria.sortItem,
      sortOrder: criteria.sortOrder,
      start: criteria.start,
      count: criteria.count,
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      newCriteria[rec.key] = rec.value;
    })
    dispatch(Module.setSummaryCriteria(newCriteria));
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PARTNER_SUMMARY === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (criteria.start > 0 && total > 0 && (dataList.length === 0 || dataList[0].key === '')) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(total, criteria.count);
      criteriaDataTransfer([
        { key: "start", value: newOffset }
      ]);
    }
  }

  // 検索ボタン押下時アクション
  const pushSerchButton = (value: Store) => {

    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "primeCompanyName", value: value.primeCompanyName }
      , { key: "primeCompanyNameKana", value: value.primeCompanyNameKana }
      , { key: "projectName", value: value.projectName }
      , { key: "belongCompanyName", value: value.belongCompanyName }
      , { key: "belongCompanyNameKana", value: value.belongCompanyNameKana }
      , { key: "firstPartnerCompanyName", value: value.firstPartnerCompanyName }
      , { key: "firstPartnerCompanyNameKana", value: value.firstPartnerCompanyNameKana }
      , { key: "lastName", value: value.lastName }
      , { key: "firstName", value: value.firstName }
      , { key: "fromYYYYMMDD", value: value.fromYYYYMMDD === null ? "" : formatUtils.formatMonthNonSlash(value.fromYYYYMMDD) }
      , { key: "toYYYYMMDD", value: value.toYYYYMMDD === null ? "" : formatUtils.formatMonthNonSlash(value.toYYYYMMDD) }
      , { key: "notSubmitted", value: value.notSubmitted === true ? "1" : "0" }
      , { key: "submitted", value: value.submitted === true ? "1" : "0" }
      , { key: "confirmed", value: value.confirmed === true ? "1" : "0" }
      , { key: "approved", value: value.approved === true ? "1" : "0" }
      , { key: "start", value: 0 }
    ]);
  }

  // Fromの日付を計算
  const setFromDate = () => {
    if (criteria.fromYYYYMMDD === "") {
      return null;
    }

    return formatUtils.getMonthMomentOf(formatUtils.formatMonthSlash(criteria.fromYYYYMMDD));
  }

  // toの日付を計算
  const setToDate = () => {
    if (criteria.toYYYYMMDD === "") {
      return null;
    }

    return formatUtils.getMonthMomentOf(formatUtils.formatMonthSlash(criteria.toYYYYMMDD));
  }

  // 検索フォーム
  const AttendanceCriteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={pushSerchButton}
        initialValues={{
          "primeCompanyName": criteria.primeCompanyName
          , "primeCompanyNameKana": criteria.primeCompanyNameKana
          , "firstPartnerCompanyName": criteria.firstPartnerCompanyName
          , "firstPartnerCompanyNameKana": criteria.firstPartnerCompanyNameKana
          , "projectName": criteria.projectName
          , "fromYYYYMMDD": setFromDate()
          , "toYYYYMMDD": setToDate()
          , "belongCompanyName": criteria.belongCompanyName
          , "belongCompanyNameKana": criteria.belongCompanyNameKana
          , "lastName": criteria.lastName
          , "firstName": criteria.firstName
          , "notSubmitted": criteria.notSubmitted === "1" ? true : false
          , "submitted": criteria.submitted === "1" ? true : false
          , "confirmed": criteria.confirmed === "1" ? true : false
          , "approved": criteria.approved === "1" ? true : false
        }}

      >
        <div className={styles.criteriaSearchFieldRight}>
          <div className={styles.criteriaSearchFieldLeft}>

            <CriteriaForm.Item name="primeCompanyName">
              <Input type="text"
                placeholder="元請会社名"
                maxLength={CONSTANTS.PROJECT_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="projectName">
              <Input type="text"
                placeholder="作業所名"
                maxLength={CONSTANTS.PROJECT_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="firstPartnerCompanyName">
              <Input type="text"
                placeholder="一次協力会社名"
                maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="belongCompanyName">
              <Input type="text"
                placeholder="所属会社名"
                maxLength={CONSTANTS.PROJECT_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="lastName">
              <Input type="text"
                placeholder="氏名（姓）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
            <CriteriaForm.Item name="firstName">
              <Input type="text"
                placeholder="氏名（名）"
                maxLength={CONSTANTS.NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
          </div>
          <div className={styles.duration}>
            <div>
              <CriteriaForm.Item name="fromYYYYMMDD"
                rules={[
                  ({ getFieldValue, validateFields }) => ({
                    validator(rule, value) {
                      if (getFieldValue("toYYYYMMDD") !== null) {
                        validateFields(["toYYYYMMDD"]);
                      }
                      return Promise.resolve();
                    }
                  })
                ]}>
                <MonthPicker placeholder="勤怠年月(From)"
                  style={{ width: "140px" }} />
              </CriteriaForm.Item>
              <div className={styles.durationSpan}>～</div>
              <CriteriaForm.Item name="toYYYYMMDD"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value === null || getFieldValue("fromYYYYMMDD") === null) {
                        return Promise.resolve();
                      }
                      if (getFieldValue("fromYYYYMMDD").isAfter(value, 'month')) {
                        return Promise.reject(messagesUtils.getMessage("ERROR_FROM_TO_DATE_RANGE", ["勤怠年月(From)", "勤怠年月(To)"]));
                      }

                      // To - From が1年以内でなければエラー
                      if ((value as Moment).diff(getFieldValue("fromYYYYMMDD"), "month") > 11) {
                        return Promise.reject(messagesUtils.getMessage("ERROR_FROM_TO_DATE_PERIOD"));
                      }
                      return Promise.resolve();
                    }
                  })

                ]}>
                <MonthPicker placeholder="勤怠年月(To)"
                  style={{ width: "140px" }} />
              </CriteriaForm.Item>
            </div>
            <div >※未入力の場合、過去一年分の検索となります</div>
          </div>
          <CriteriaForm.Item name="notSubmitted" valuePropName="checked">
            <Checkbox>
              {EXIST_NOTSUBMITED}
            </Checkbox>
          </CriteriaForm.Item>
          <CriteriaForm.Item name="submitted" valuePropName="checked">
            <Checkbox>
              {EXIST_SUBMITED}
            </Checkbox>
          </CriteriaForm.Item>
          <CriteriaForm.Item name="confirmed" valuePropName="checked">
            <Checkbox>
              {EXIST_CONFIRMED}
            </Checkbox>
          </CriteriaForm.Item>
          <CriteriaForm.Item name="approved" valuePropName="checked">
            <Checkbox>
              {EXIST_APPROVED}
            </Checkbox>
          </CriteriaForm.Item>
          <AuthButton
            name={"検索"}
            shape={"round"}
            htmlType="submit"
          />
        </div>

      </CriteriaForm>
    )
  }

  /** 一覧部 */
  const AttendanceContent = () => {
    return (
      total > 0 && dataList.length > 0 && dataList[0].key !== '' ?
        <Table
          size="small"
          scroll={{ x: 1000 }}
          columns={columns}
          dataSource={dataList}
          pagination={{
            total: total
            , defaultPageSize: 10
            , current: criteria.start / criteria.count + 1
            , pageSize: criteria.count
            , pageSizeOptions: ["5", "10", "20", "50", "100"]
            , showSizeChanger: true
            , showTotal: (total, range) => `${total}件中、${criteria.start + 1}件目から${total <= criteria.start + criteria.count ? total : criteria.start + criteria.count}件目を表示`
            , itemRender: pageItemRender
            , locale: { items_per_page: "件/ページ" }
          }}
          onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                moveToDetail(record, event, rowIndex)
              }
            };
          }
          }
        >
        </Table>
        : <></>
    );
  }

  /**
 * ページングボタンを描画
 * @param page 
 * @param type 
 * @param originalElement 
 */
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === "prev") {
      return <AuthButton name="＜" size="small" />;
    }
    if (type === "next") {
      return <AuthButton name="＞" size="small" />;
    }
    return originalElement
  }

  /**
 * テーブル検索条件変更時イベント（ページ、表示件数）
 * @param pagination 
 * @param filters 
 */
  const handleTableChange = (pagination: any, sorter: any) => {

    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let offsetReset: boolean = false;

    let order = "";
    if (sorter.order === "ascend") {
      order = "0";
    } else if (sorter.order === "descend") {
      order = "1";
    }
    let sortItem = sorter.columnKey
    if (typeof sorter.column === "undefined") {
      sortItem = "";
    }

    if (criteria.count !== pagination.pageSize ||
      criteria.sortItem !== sortItem ||
      criteria.sortOrder !== order) {
      offsetReset = true;
    }

    criteriaDataTransfer([
      { key: "sortItem", value: sortItem }
      , { key: "sortOrder", value: order }
      , { key: "count", value: pagination.pageSize }
      , { key: "start", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
    ])
  }

  return (
    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <AttendanceCriteria />
        <CommonMessage searchNoDataName="プロジェクト" />
        <AttendanceContent />
      </Content>
      <Footer />
    </Layout>
  );
}
