import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler, putRequest } from "../../core/bffApiModule";
import * as Model from './commonSettingModel';
import * as CONSTANTS from "../../../constants/constants";
import * as constantsUtils from '../../../utils/constantsUtils';


// state初期化
const initialState: Model.CommonSettingState = {
  closingDateList: [],
  workflowFlagList: [],
  calcWorkTimeTypeList: [],
  apportionmentTypeList: [],
  positionAuthorityList: [],
  certificateFlowTypeList: [],
  mailTypeSendFlagToPrimePositionAuthorityApproveUserList: [],
  mailTypeSendFlagToPrimePositionAuthorityConfirmUserList: [],
  mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList: [],
  mailTypeSendFlagToPartnerSubmitUserList: [],
  loadingFlg: false,
};

// スライサー （state更新アクションの実装）
export const commonSettingSlice = createSlice({
  name: 'commonSetting',
  initialState,
  reducers: {
    getDataListOfCommonSettingMaster: (state, action: PayloadAction<Model.PrimeCommonSettingApiData>) => {

      const data: Model.PrimeCommonSettingApiData = action.payload;

      // 締切日設定を取得
      let closingDateList: Array<Model.ClosingDateRow> = new Array<Model.ClosingDateRow>();
      const closingDateKbnList = constantsUtils.getCodeLabelListOf(CONSTANTS.CLOSING_DATE_KBN);
      for (const codelabel of closingDateKbnList) {
        const targetData: Model.ClosingDate | undefined = data.cloingDateList.find((pa) => {
          return pa.closingDateKbn == codelabel.code;
        });

        closingDateList.push({
          closingDateKbn: codelabel.code,
          closingMonth: (targetData && !Number.isNaN(targetData.month)) ? String(targetData.month) : "",
          closingDate: (targetData && !Number.isNaN(targetData.date)) ? String(targetData.date) : "",
        });
      }

      // ワークフローを取得
      const workflowFlagList: Array<Model.WorkflowRow> = new Array<Model.WorkflowRow>();
      workflowFlagList.push({
        label: "「確認」を必要とする",
        flag: data.workflowFlag,
      });

      // 労働時間の自動入力設定を取得
      const calcWorkTimeTypeList: Array<Model.CalcWorkTimeRow> = new Array<Model.CalcWorkTimeRow>();
      calcWorkTimeTypeList.push({
        label: "労働時間の自動入力",
        value: data.calcWorkTimeType,
      })

      // 算出方法
      const apportionmentTypeList: Array<Model.CommonSettingRow> = new Array<Model.CommonSettingRow>();
      apportionmentTypeList.push({
        label: "算出方法",
        value: data.apportionmentType,
      });

      // 役割別権限区分を取得
      // 権限がない（データがない）役割を補完して設定する
      let positionAuthList: Array<Model.PositionAuthority> = new Array<Model.PositionAuthority>();
      const positionList = constantsUtils.getCodeLabelListOf(CONSTANTS.POSITION_TYPE);
      for (const codelabel of positionList) {
        const targetData: Model.PositionAuthority | undefined = data.positionAuthorityList.find((pa) => {
          return pa.positionType == codelabel.code;
        });

        positionAuthList.push({
          positionType: codelabel.code,
          positionAuthority: targetData ? targetData.positionAuthority : CONSTANTS.POSITION_AUTHORITY_TYPE.NONE.code,
        });
      }

      // 資格認定フロー利用設定のスキップ設定の取得
      let certificateFlowTypeList: Array<Model.CertificateFlowTypeRow> = new Array<Model.CertificateFlowTypeRow>();
      certificateFlowTypeList.push({
        label: "利用設定",
        value: data.certificateFlowType,
      });

      const mailSendFlags: Model.MailSendFlags = data.mailSendFlags;

      // メール種別送信設定（元請会社 現場担当者。役割別権限で承認権限）
      let mailSendFlagToPrimePositionAuthorityApproveUserList: Array<Model.MailTypeSendFlagRow> = [];
      mailSendFlagToPrimePositionAuthorityApproveUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.REQUEST_CONFIRM_ATTENCANCE.code,
        flag: mailSendFlags.toPrimePositionAuthorityApproveUserRequestConfirmAttendance,
      });
      mailSendFlagToPrimePositionAuthorityApproveUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.REQUEST_APPROVE_ATTENCANCE.code,
        flag: mailSendFlags.toPrimePositionAuthorityApproveUserRequestApproveAttendance,
      });
      mailSendFlagToPrimePositionAuthorityApproveUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.CANCEL_SUBMIT_ATTENDANCE.code,
        flag: mailSendFlags.toPrimePositionAuthorityApproveUserNoticeCancelSubmitAttendance,
      });
      mailSendFlagToPrimePositionAuthorityApproveUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.CANCEL_APPROVED_ATTENCANCE.code,
        flag: mailSendFlags.toPrimePositionAuthorityApproveUserNoticeCancelApproveAttendance,
      });

      // メール種別送信設定（元請会社 現場担当者。役割別権限で確認権限）
      let mailSendFlagToPrimePositionAuthorityConfirmUserList: Array<Model.MailTypeSendFlagRow> = [];
      mailSendFlagToPrimePositionAuthorityConfirmUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.REQUEST_CONFIRM_ATTENCANCE.code,
        flag: mailSendFlags.toPrimePositionAuthorityConfirmUserRequestConfirmAttendance,
      });
      mailSendFlagToPrimePositionAuthorityConfirmUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.CANCEL_SUBMIT_ATTENDANCE.code,
        flag: mailSendFlags.toPrimePositionAuthorityConfirmUserNoticeCancelSubmitAttendance,
      });
      mailSendFlagToPrimePositionAuthorityConfirmUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.CANCEL_APPROVED_ATTENCANCE.code,
        flag: mailSendFlags.toPrimePositionAuthorityConfirmUserNoticeCancelApproveAttendance,
      });

      // メール種別送信設定（元請企業の本社/支店担当者）
      let mailSendFlagToPrimeSkillmapAuthorityApproveUserList: Array<Model.MailTypeSendFlagRow> = [];
      mailSendFlagToPrimeSkillmapAuthorityApproveUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.REQUEST_CONFIRM_ATTENCANCE.code,
        flag: mailSendFlags.toPrimeSkillmapAuthorityApproveUserRequestConfirmAttendance,
      });
      mailSendFlagToPrimeSkillmapAuthorityApproveUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.REQUEST_APPROVE_ATTENCANCE.code,
        flag: mailSendFlags.toPrimeSkillmapAuthorityApproveUserRequestApproveAttendance,
      });
      mailSendFlagToPrimeSkillmapAuthorityApproveUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.CANCEL_SUBMIT_ATTENDANCE.code,
        flag: mailSendFlags.toPrimeSkillmapAuthorityApproveUserNoticeCancelSubmitAttendance,
      });
      mailSendFlagToPrimeSkillmapAuthorityApproveUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.CANCEL_APPROVED_ATTENCANCE.code,
        flag: mailSendFlags.toPrimeSkillmapAuthorityApproveUserNoticeCancelApproveAttendance,
      });


      // メール種別送信設定（協力会社の勤怠提出者）
      let mailSendFlagToPartnerSubmitUserList: Array<Model.MailTypeSendFlagRow> = [];
      mailSendFlagToPartnerSubmitUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.REMAND_ATTENCANCE.code,
        flag: mailSendFlags.toPartnerSubmitUserNoticeRemandAttendance,
      });
      mailSendFlagToPartnerSubmitUserList.push({
        mailTypeCode: CONSTANTS.MAIL_TYPE.APPROVED_ATTENCANCE.code,
        flag: mailSendFlags.toPartnerSubmitUserNoticeApproveAttendance,
      });


      return Object.assign({}, state, {
        closingDateList: closingDateList,
        workflowFlagList: workflowFlagList,
        calcWorkTimeTypeList: calcWorkTimeTypeList,
        apportionmentTypeList: apportionmentTypeList,
        positionAuthorityList: positionAuthList,
        certificateFlowTypeList: certificateFlowTypeList,
        mailTypeSendFlagToPrimePositionAuthorityApproveUserList: mailSendFlagToPrimePositionAuthorityApproveUserList,
        mailTypeSendFlagToPrimePositionAuthorityConfirmUserList: mailSendFlagToPrimePositionAuthorityConfirmUserList,
        mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList: mailSendFlagToPrimeSkillmapAuthorityApproveUserList,
        mailTypeSendFlagToPartnerSubmitUserList: mailSendFlagToPartnerSubmitUserList,
      });
    },
    savePositionAuthorityList: (state, action: PayloadAction<Array<Model.PositionAuthority>>) => {
      state.positionAuthorityList = action.payload;
    },
    saveClosingDateList: (state, action: PayloadAction<Array<Model.ClosingDateRow>>) => {
      state.closingDateList = action.payload;
    },
    saveWorkflowFlagList: (state, action: PayloadAction<Array<Model.WorkflowRow>>) => {
      state.workflowFlagList = action.payload;
    },
    saveCalcWorkTimeTypeList: (state, action: PayloadAction<Array<Model.CalcWorkTimeRow>>) => {
      state.calcWorkTimeTypeList = action.payload;
    },
    saveApportionmentTypeList: (state, action: PayloadAction<Array<Model.CommonSettingRow>>) => {
      state.apportionmentTypeList = action.payload;
    },
    saveCertificateFlowTypeList: (state, action: PayloadAction<Array<Model.CertificateFlowTypeRow>>) => {
      state.certificateFlowTypeList = action.payload;
    },
    saveMailTypeSendFlagToPrimePositionAuthorityApproveUserList: (state, action: PayloadAction<Array<Model.MailTypeSendFlagRow>>) => {
      state.mailTypeSendFlagToPrimePositionAuthorityApproveUserList = action.payload;
    },
    saveMailTypeSendFlagToPrimePositionAuthorityConfirmUserList: (state, action: PayloadAction<Array<Model.MailTypeSendFlagRow>>) => {
      state.mailTypeSendFlagToPrimePositionAuthorityConfirmUserList = action.payload;
    },
    saveMailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList: (state, action: PayloadAction<Array<Model.MailTypeSendFlagRow>>) => {
      state.mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList = action.payload;
    },
    saveMailTypeSendFlagToPartnerSubmitUserList: (state, action: PayloadAction<Array<Model.MailTypeSendFlagRow>>) => {
      state.mailTypeSendFlagToPartnerSubmitUserList = action.payload;
    },
    setLoadingFlg: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { loadingFlg: action.payload })
    },
  }
});


export const {
  getDataListOfCommonSettingMaster,
  savePositionAuthorityList,
  saveClosingDateList,
  saveWorkflowFlagList,
  saveCalcWorkTimeTypeList,
  saveApportionmentTypeList,
  saveCertificateFlowTypeList,
  saveMailTypeSendFlagToPrimePositionAuthorityApproveUserList,
  saveMailTypeSendFlagToPrimePositionAuthorityConfirmUserList,
  saveMailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList,
  saveMailTypeSendFlagToPartnerSubmitUserList,
  setLoadingFlg,
} = commonSettingSlice.actions;



export const getPrimeCommonSettingAsync = (): AppThunk => async (dispatch) => {
  try {
    const response = await getRequest(CONSTANTS.URL_COMMON_SETTING_PRIME_LIST);

    dispatch(getDataListOfCommonSettingMaster(response.data.data));

    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
}

export const putPrimeCommonSettingAsync = (putData: Model.putCommonSetting.Request): AppThunk => async (dispatch) => {
  try {
    const response = await putRequest(CONSTANTS.URL_COMMON_SETTING_PRIME_LIST, putData);

    // 共通成功時処理を呼び出し
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_PUT));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
}



export const storedClosingClosingDateList = (state: RootState) => state.primeCommonSetting.closingDateList;
export const storedWorkflowFlagList = (state: RootState) => state.primeCommonSetting.workflowFlagList;
export const storedCalcWorkTimeTypeList = (state: RootState) => state.primeCommonSetting.calcWorkTimeTypeList;
export const storedApportionmentTypeList = (state: RootState) => state.primeCommonSetting.apportionmentTypeList;
export const storedPositionAuthorityList = (state: RootState) => state.primeCommonSetting.positionAuthorityList;
export const storedCertificateFlowTypeList = (state: RootState) => state.primeCommonSetting.certificateFlowTypeList;
export const storedMailTypeSendFlagToPrimePositionAuthorityApproveUserList = (state: RootState) => state.primeCommonSetting.mailTypeSendFlagToPrimePositionAuthorityApproveUserList;
export const storedMailTypeSendFlagToPrimePositionAuthorityConfirmUserList = (state: RootState) => state.primeCommonSetting.mailTypeSendFlagToPrimePositionAuthorityConfirmUserList;
export const storedMailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList = (state: RootState) => state.primeCommonSetting.mailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList;
export const storedMailTypeSendFlagToPartnerSubmitUserList = (state: RootState) => state.primeCommonSetting.mailTypeSendFlagToPartnerSubmitUserList;
export const storedLoadingFlg = (state: RootState) => state.primeCommonSetting.loadingFlg;

export default commonSettingSlice.reducer;