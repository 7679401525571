import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Typography, Table, Input, Form } from 'antd';
import { CriteriaForm, AuthButton, Radio, Checkbox, DatePicker } from '../../core/CoreForm'
import { ContentEx as Content, CommonMessage } from '../../core/CorePageContents'
import styles from './workerSelect.module.css';
import * as Module from '../../../modules/prime/workerSelect/workerSelectModule';
import * as Model from '../../../modules/prime/workerSelect/workerSelectModel';
import { Store } from 'antd/lib/form/interface';
import * as CONSTANTS from '../../../constants/constants';
import * as constantsUtils from '../../../utils/constantsUtils';
import * as formatUtils from '../../../utils/formatUtils';
import * as messagesUtils from '../../../utils/messagesUtils';
import { initMessages } from '../../../modules/core/commonMessageModule';
import { initApiResult } from '../../../modules/core/bffApiModule';
import { storedBelongCompanyDataRow } from '../../../modules/prime/qualifications-holders/qualificationHoldersModule';
import moment, { Moment } from 'moment';

const { Title } = Typography;

interface titleInterface {
  title: string;
}

export const WorkerSelect = (props: titleInterface) => {

  const dispatch = useDispatch();
  const dataRow = useSelector(Module.storedDataRow);
  const [form] = Form.useForm();
  let criteria = useSelector(Module.storedCriteria);
  let dataList: Array<Model.dataRow> = [];
  dataList.push(dataRow);
  const checked = useSelector(Module.storedChecked);
  const belongCompanyData = useSelector(storedBelongCompanyDataRow);


  // 検索ボタン押下時アクション
  const pushSerchButton = (value: Store) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initApiResult());
    
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    
    // 従業員一覧を初期化します。
    dispatch(Module.initState());

    let transferCriteria: Model.criteria = {
      belongCompanyId: belongCompanyData.belongCompanyId
      , nameType: value.nameType
      , lastName: value.lastName
      , firstName: value.firstName
      , birthday: formatUtils.formatDateHyphen(value.birthday)
    }
    dispatch(Module.setCriteria(transferCriteria));
    dispatch(Module.getWorkerDataAsync(transferCriteria));
  }

  // チェックボックス押下時アクション
  const onChecked = (e: any) => {
    dispatch(Module.setChecked(e.target.checked));
  }

  const columns = [
    {
      title: '氏名',
      dataIndex: 'name',
      render: (text: string, row: Model.dataRow) => {
        return (
          <div className={styles.columnAlignLeft}>{row.lastName + " " + row.firstName}</div>
        )
      },
    },
    {
      title: '氏名カナ',
      dataIndex: 'Kana',
      render: (text: string, row: Model.dataRow) => {
        return (
          <div className={styles.columnAlignLeft}>{row.lastNameKana + " " + row.firstNameKana}</div>
        )
      },
    },
    {
      title: '生年月日',
      dataIndex: 'birthday',
      align: 'center' as 'center'
      , render: (value: string, row: Model.dataRow) => {
        return {
          children: (
            formatUtils.formatDateSlash(row.birthday)
          )
        }
      }
    }
  ]


  // 検索フォーム
  const AttendanceCriteria = () => {
    return (
      <CriteriaForm
        onFinish={pushSerchButton}
        initialValues={{
          "nameType": criteria.nameType
          , "lastName": criteria.lastName
          , "firstName": criteria.firstName
          , "birthday": criteria.birthday !== "" ? moment(criteria.birthday) : null
        }}
      >
        <div className={styles.criteriaSearchField}>
          <CriteriaForm.Item
            name="nameType"
            validateFirst={true}
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: messagesUtils.getMessage("ERROR_REQUIRED_SELECT", ["氏名区分"])
              }
            ]}
          >
            <Radio.Group defaultValue={form.getFieldValue('nameType')}>
              {constantsUtils.getCodeLabelListOf(CONSTANTS.NAME_TYPE).map((rec: any) => (
                <Radio value={rec.code} >{rec.label}</Radio>
              ))}
            </Radio.Group>
          </CriteriaForm.Item>
          <CriteriaForm.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.nameType !== currentValues.nameType}
          >
            {({ getFieldValue, resetFields }) => {
              resetFields(["lastName"]);
              if (getFieldValue('nameType') === CONSTANTS.NAME_TYPE.NAME.code) {
                return (
                  <CriteriaForm.Item
                    name="lastName"
                    className={styles.formItem}
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["氏名（姓）"])
                      },
                      {
                        max: CONSTANTS.LAST_NAME_MAX_LENGTH,
                        message: messagesUtils.getMessage("ERROR_MAX_LENGTH", ["氏名（姓）", CONSTANTS.LAST_NAME_MAX_LENGTH.toString()])
                      },
                      {
                        type: "string",
                        message: messagesUtils.getMessage("ERROR_REQUIRED_SELECT", ["氏名区分"])
                      }
                    ]}
                  >
                    <Input type="text"
                      placeholder="氏名（姓）"
                      maxLength={CONSTANTS.LAST_NAME_MAX_LENGTH} />
                  </CriteriaForm.Item>
                );
              } else {
                return (
                  <CriteriaForm.Item
                    name="lastName"
                    className={styles.formItem}
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["氏名カナ（姓）"])
                      },
                      {
                        max: CONSTANTS.LAST_NAME_KANA_MAX_LENGTH,
                        message: messagesUtils.getMessage("ERROR_MAX_LENGTH", ["氏名カナ（姓）", CONSTANTS.LAST_NAME_KANA_MAX_LENGTH.toString()])
                      }
                    ]}
                  >
                    <Input type="text"
                      placeholder="氏名カナ（姓）"
                      maxLength={CONSTANTS.LAST_NAME_KANA_MAX_LENGTH} />
                  </CriteriaForm.Item>
                );
              }
            }}
          </CriteriaForm.Item>
          <CriteriaForm.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.nameType !== currentValues.nameType}
          >
            {({ getFieldValue, resetFields }) => {
              resetFields(["firstName"]);
              if (getFieldValue('nameType') === CONSTANTS.NAME_TYPE.NAME.code) {
                return (
                  <CriteriaForm.Item
                    name="firstName"
                    className={styles.formItem}
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["氏名（名）"])
                      },
                      {
                        max: CONSTANTS.FIRST_NAME_MAX_LENGTH,
                        message: messagesUtils.getMessage("ERROR_MAX_LENGTH", ["氏名（名）", CONSTANTS.FIRST_NAME_MAX_LENGTH.toString()])
                      }
                    ]}
                  >
                    <Input
                      placeholder="氏名（名）"
                      maxLength={CONSTANTS.FIRST_NAME_MAX_LENGTH} />
                  </CriteriaForm.Item>
                );
              } else {
                return (
                  <CriteriaForm.Item
                    name="firstName"
                    className={styles.formItem}
                    validateFirst={true}
                    rules={[
                      {
                        required: true,
                        message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["氏名カナ（名）"])
                      },
                      {
                        max: CONSTANTS.FIRST_NAME_KANA_MAX_LENGTH,
                        message: messagesUtils.getMessage("ERROR_MAX_LENGTH", ["氏名カナ（名）", CONSTANTS.FIRST_NAME_KANA_MAX_LENGTH.toString()])
                      }
                    ]}
                  >
                    <Input
                      placeholder="氏名カナ（名）"
                      maxLength={CONSTANTS.FIRST_NAME_KANA_MAX_LENGTH} />
                  </CriteriaForm.Item>
                );
              }
            }}
          </CriteriaForm.Item>
          <CriteriaForm.Item
            name="birthday"
            className={styles.formItem}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["生年月日"])
              }
            ]}
          >
            <DatePicker disabledDate={(date: Moment) => {return false}}/>
          </CriteriaForm.Item>
          <AuthButton
            name={"検索"}
            // className={styles.formItem}
            shape={"round"}
            htmlType="submit" />
        </div>
      </CriteriaForm>
    )
  }

  /** 一覧部 */
  const WorkerSelectContent = () => {
    if (dataRow.lastName !== "") {
      return (
        <div style={{ marginTop: "50px" }}>
          <CommonMessage />
          <Table
            columns={columns}
            size="small"
            dataSource={dataList}
            pagination={false}
            style={{ margin: "0 auto" }}
          />
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            <Checkbox checked={checked} onChange={(e) => { onChecked(e) }}><span>この作業員はプロジェクトに編成されていることを確認しました。</span></Checkbox>
          </div>
        </div >
      )
    }
    return (
      <div style={{ display:"block",marginTop: "10px", marginLeft: "10px" }}>
        <span>生年月日、氏名を入力してください。</span><br />
        <span>以下の項目は必須です。</span><br />
        <div style={{ marginTop: "10px", marginLeft: "10px" }}>
          <span>・氏名（姓＋名）または氏名カナ（姓＋名）</span><br />
          <span>・生年月日</span>
        </div>
        <CommonMessage />
      </div>
    );
  }

  return (

    <Layout>
      <Content hasapicall={"false"} >
        <Title level={4} className={styles.functionTitle}>
          {props.title}
        </Title>
        <AttendanceCriteria />
        <WorkerSelectContent />
      </Content>
    </Layout>
  );
}

