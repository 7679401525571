import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router"
import { Layout, Table, Modal } from "antd";
import { AuthButton, Checkbox, Radio } from "../../core/CoreForm"
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from "../../core/CorePageContents"
import styles from "./AuthorityList.module.css";
import * as AutorityModule from "../../../modules/prime/auth/authorityModule";
import * as Model from "../../../modules/prime/auth/authorityModel";
import * as ManageModule from "../../../modules/prime/managementGroup/multiManagementGroupModule";
import { MultiManagementGroupSelect } from "../managementGroup/MultiManagementGroupSelect";
import * as messagesUtils from "../../../utils/messagesUtils";
import * as CONSTANTS from "../../../constants/constants";
import { storedLoginUserInfo, getLoginUserInfoAsync } from "../../../modules/core/authModule";
import AuthButtonStyles from "../../core/css/AuthButton.module.css";
import { storedApiResult, initApiResult, setApiResult } from "../../../modules/core/bffApiModule";
import { setMessages, message } from "../../../modules/core/commonMessageModule";

// チェックボックス
const CHECKBOX_CHECK = "1";
const CHECKBOX_NONE = "0";

interface titleInterface {
  title: string;
}

export const AuthListEdit = (props: titleInterface) => {

  const dispatch = useDispatch();
  let dataList = useSelector(AutorityModule.storedDataList);
  let modalVisibleState = useSelector(AutorityModule.storedModalVisibleState);
  let loadingFlg = useSelector(AutorityModule.storedLoadingFlg);
  let modalSelectValues = useSelector(ManageModule.storedModalSelectValues);
  let history = useHistory();
  const loginUserInfo = useSelector(storedLoginUserInfo);
  const apiResult = useSelector(storedApiResult);

  const confirmEnabled = () => {
    let disabled: boolean = false;

    dataList.forEach((row: Model.AuthorityRow) => {

      // 管理グループ参照範囲のチェック
      if (CHECKBOX_CHECK === row.primeAvailableAuth && CONSTANTS.REFERENCE_RANGE_ALL !== row.referenceRange && CONSTANTS.REFERENCE_RANGE_POINT !== row.referenceRange) {
        disabled = true;
      }

      // 編集中の管理グループ一覧のチェック
      if (CHECKBOX_CHECK === row.primeAvailableAuth && CONSTANTS.REFERENCE_RANGE_POINT === row.referenceRange && row.managementGroupList.length === 0) {
        disabled = true;
      }

    })

    return disabled;
  }

  // dataListがなくなったら元の一覧画面に戻る
  if (dataList.length === 0) {
    history.push(CONSTANTS.PATH_PRIME_AUTH_LIST);
  }

  // モーダル表示時アクション
  const showModal = (e: any, record: Model.AuthorityRow) => {
    // API処理結果を初期化します。
    dispatch(initApiResult());
    // モーダル内の検索条件初期化
    dispatch(ManageModule.initCriteria());
    // モーダル内の選択値初期化
    dispatch(ManageModule.initModalSelectValue())
    // 選択されたデータ情報取得
    dispatch(AutorityModule.transferDataRow(record));
    // 除外する管理グループIDリストを設定する。
    dispatch(ManageModule.setExclusionList(record.managementGroupList.map((o) => o.managementGroupId)));
    // モーダルを表示
    dispatch(AutorityModule.reflectModalState(true));
  };

  // モーダル選択ボタン押下時アクション
  const handleSelect = () => {
    // 画面に値を反映
    modalSelectValues.forEach(modalSelectValue => {
      dispatch(AutorityModule.reflectManagementGroup(modalSelectValue));
    });

    // モーダル表示を消す
    dispatch(AutorityModule.reflectModalState(false));
  };

  // モーダル戻るボタン押下時アクション
  const handleBack = () => {
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: CONSTANTS.API_STATUS_200, errorCode: "", requestMethod: "", url: "" }));
    // モーダル表示を消す
    dispatch(AutorityModule.reflectModalState(false));
  };

  // 戻るボタン押下時アクション
  const backClicked = () => {
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_BACK"))) {
      return;
    }
    history.push(CONSTANTS.PATH_PRIME_AUTH_LIST);
  }

  // 確定ボタン押下時アクション
  const confirmClicked = () => {
    // メッセージを設定して確認ダイアログを表示します。
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_UPDATE", ["権限"]))) {
      return;
    }

    dispatch(AutorityModule.setLoadingFlg(true));
    dispatch(AutorityModule.putDataLsitAsync(dataList));
  }

  // 更新API実行後、APIエラーメッセージが設定されていない場合、一覧画面に遷移して更新成功メッセージを表示します。
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod) {
    // 成功メッセージを設定します。
    const setMessage: message = {
      message: messagesUtils.getMessage("SUCCESS_UPDATE", ["権限"])
      , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
    }
    dispatch(setMessages([setMessage]));
    dispatch(AutorityModule.setLoadingFlg(false));
    // API処理結果を初期化します。
    dispatch(initApiResult());
    // ログインユーザ情報を再取得します。
    dispatch(getLoginUserInfoAsync());
    history.push(CONSTANTS.PATH_PRIME_AUTH_LIST);

    // メッセージはセッションにも保持
    messagesUtils.setMessageInSession(setMessage.message, setMessage.messageType);
  }

  // 更新API実行後、APIエラーメッセージが設定されている場合、一覧画面に遷移して更新成功メッセージを表示します。
  else if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" !== apiResult.errorCode && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod) {
    dispatch(AutorityModule.setLoadingFlg(false));
  }

  // チェックボックス切替時アクション
  const onChangeCheckbox = (e: any, record: Model.AuthorityRow, dataIndex: string) => {
    const keyValueArray = [];
    keyValueArray.push({ propertyName: dataIndex, value: (e.target.checked) ? CHECKBOX_CHECK : CHECKBOX_NONE });
    changeValueTransfer(keyValueArray, record);
  }

  // ラジオボタン切替時アクション
  const onChangeRadio = (e: any, record: Model.AuthorityRow, dataIndex: string) => {
    const keyValueArray = [];
    keyValueArray.push({ propertyName: dataIndex, value: e.target.value });
    changeValueTransfer(keyValueArray, record);
  }

  // 変更値をstateに反映する
  const changeValueTransfer = (keyValueArray: any, record: Model.AuthorityRow) => {
    let transfer: Model.changeValueTransfer = {
      targetDataRow: record,
      changeKeyValueArray: keyValueArray
    }
    dispatch(AutorityModule.reflectAuthorityMasetrPrimeValue(transfer));
  }

  // 削除ボタン押下時アクション
  const onClickDeleteButton = (e: any, record: Model.AuthorityRow, target: Model.ManagementGroupListRow) => {
    let transfer: Model.deleteValueTransfer = {
      targetDataRow: record,
      managementGroup: target
    }
    dispatch(AutorityModule.deleteManagementGroupListValue(transfer));
  }

  // 参照範囲と利用者権限による活性・非活性の制御
  const disabledByReferenceRange = (referenceRange: string, row: Model.AuthorityRow) => {
    // ”グループ指定”のとき活性
    if (CONSTANTS.REFERENCE_RANGE_POINT === referenceRange && CHECKBOX_CHECK === row.primeAvailableAuth) {
      return false;
    } else {
      return true;
    }
  }

  // 参照範囲と利用者権限による文字色の制御
  const coloredByReferenceRange = (referenceRange: string, row: Model.AuthorityRow) => {
    // ”グループ指定”以外のときグレー
    if (CONSTANTS.REFERENCE_RANGE_POINT !== referenceRange || CHECKBOX_CHECK !== row.primeAvailableAuth) {
      return styles.grayText
    }
  }

  // 利用者権限による活性・非活性の制御
  const disabledByAvailableAuth = (row: Model.AuthorityRow) => {
    if (CHECKBOX_CHECK === row.primeAvailableAuth) {
      return false;
    } else {
      return true;
    }
  }

  // エラーメッセージ
  const errorMessage = [
    < div className={styles.errorMessage}> {messagesUtils.getMessage("ERROR_SET_MANAGEMENT_GROUP_")}</div>
  ]


  // 管理グループ項目表示制御
  const renderManagementGroupName = (text: string, row: Model.AuthorityRow, dataIndex: number) => {

    let managementGroupLabels: Array<any> = [];

    managementGroupLabels.push(
      <div className={styles.referenceRange}>
        <Radio.Group
          onChange={(e) => onChangeRadio(e, row, "referenceRange")}
          defaultValue={text}
          disabled={disabledByAvailableAuth(row)}
          className={styles.radioGroup}>
          <Radio value={CONSTANTS.REFERENCE_RANGE_ALL}>全グループ</Radio>
          <Radio value={CONSTANTS.REFERENCE_RANGE_POINT}>グループ指定</Radio>
        </Radio.Group>
        <AuthButton
          name={"追加"}
          shape={"round"}
          onClick={(e) => showModal(e, row)}
          disabled={disabledByReferenceRange(text, row)}
        />
      </div>
    )

    // 利用者権限のチェック
    if (CHECKBOX_CHECK === row.primeAvailableAuth) {
      // 管理グループ参照範囲のチェック
      if (CONSTANTS.REFERENCE_RANGE_ALL !== row.referenceRange && CONSTANTS.REFERENCE_RANGE_POINT !== row.referenceRange) {
        managementGroupLabels.push(errorMessage)
      } else if (CONSTANTS.REFERENCE_RANGE_POINT === row.referenceRange) {
        // 編集中の管理グループ一覧のチェック
        if (row.managementGroupList.length === 0) {
          // エラー表示処理
          managementGroupLabels.push(errorMessage)
        }
      }
    }



    row.managementGroupList.forEach((groupListRow: Model.ManagementGroupListRow) => {
      managementGroupLabels.push(
        <div className={styles.referenceRange}>
          <span
            className={coloredByReferenceRange(text, row)}
          >
            {groupListRow.managementGroupName}
          </span>
          <AuthButton
            name={"削除"}
            shape={"round"}
            className={styles.managementGroupLabelButton}
            onClick={(e) => onClickDeleteButton(e, row, groupListRow)}
            disabled={disabledByReferenceRange(text, row)}
          />
        </div>
      )
    });

    return (
      <div className={styles.managementGroupColumn}>{managementGroupLabels}</div>
    )
  }

  // モーダル内選択ボタンの活性・非活性の切替制御
  const switchDisabled = () => {
    // 管理グループが選択されているとき
    if (modalSelectValues.length !== 0) {
      return false;
    }
    return true;
  }

  // 利用者以外のチェックボックス活性・非活性の制御
  const disabledCheckBox = (record: Model.AuthorityRow) => {
    if (CHECKBOX_CHECK === record.primeAvailableAuth) {
      return false;
    } else {
      return true;
    }
  }

  // 利用者本人のデータの場合は非活性にする
  const myDataAuthorityDisabled = (record: Model.AuthorityRow) => {
    if (loginUserInfo.mcdpId === record.mcdpId) {
      return true;
    } else {
      return false;
    }
  }

  // テーブル列を設定します。
  const columns = [
    {
      title: "MCDP-ID",
      dataIndex: "mcdpId",
      align: "center" as "center",
      width: 150,
    },
    {
      title: "ユーザ名",
      dataIndex: "userName",
      align: "center" as "center",
      width: 250,
    },
    {
      title: <span>本部/支店<br />{CONSTANTS.AUTHORITY_AVAILABLE_AUTH}</span>,
      dataIndex: "primeAvailableAuth",
      align: "center" as "center",
      width: 120,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          onChange={(e) => onChangeCheckbox(e, record, "primeAvailableAuth")}
          disabled={myDataAuthorityDisabled(record)}
          className={styles.primeCheckbox}
        />
    },
    {
      title: <span>本部/支店<br />{CONSTANTS.AUTHORITY_ATTENDANCE}</span>,
      dataIndex: "primeAttendanceApprovalAuth",
      align: "center" as "center",
      width: 120,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          onChange={(e) => onChangeCheckbox(e, record, "primeAttendanceApprovalAuth")}
          disabled={disabledCheckBox(record)}
          className={styles.primeCheckbox}
        />
    },
    {
      title: CONSTANTS.AUTHORITY_QUALIFICATION,
      dataIndex: "primeQualificationMngAuth",
      align: "center" as "center",
      width: 120,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          onChange={(e) => onChangeCheckbox(e, record, "primeQualificationMngAuth")}
          disabled={disabledCheckBox(record)}
          className={styles.primeCheckbox}
        />
    },
    {
      title: CONSTANTS.AUTHORITY_SYSTEM,
      dataIndex: "primeSystemMngAuth",
      align: "center" as "center",
      width: 120,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          onChange={(e) => onChangeCheckbox(e, record, "primeSystemMngAuth")}
          disabled={disabledCheckBox(record) || myDataAuthorityDisabled(record)}
          className={styles.primeCheckbox}
        />
    },
    {
      title: <span>資格承認フロー<br />{CONSTANTS.AUTHORITY_CERTIFICATE_FLOW_REQUEST}</span>,
      dataIndex: "certificateFlowRequestAuth",
      align: "center" as "center",
      width: 120,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          onChange={(e) => onChangeCheckbox(e, record, "certificateFlowRequestAuth")}
          disabled={disabledCheckBox(record)}
          className={styles.primeCheckbox}
        />
    },
    {
      title: <span>資格承認フロー<br />{CONSTANTS.AUTHORITY_CERTIFICATE_FLOW_CONFIRM}</span>,
      dataIndex: "certificateFlowConfirmAuth",
      align: "center" as "center",
      width: 120,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          onChange={(e) => onChangeCheckbox(e, record, "certificateFlowConfirmAuth")}
          disabled={disabledCheckBox(record)}
          className={styles.primeCheckbox}
        />
    },
    {
      title: <span>資格承認フロー<br />{CONSTANTS.AUTHORITY_CERTIFICATE_FLOW_APPROVE}</span>,
      dataIndex: "certificateFlowApproveAuth",
      align: "center" as "center",
      width: 120,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          onChange={(e) => onChangeCheckbox(e, record, "certificateFlowApproveAuth")}
          disabled={disabledCheckBox(record)}
          className={styles.primeCheckbox}
        />
    },
    {
      title: "管理グループ",
      dataIndex: "referenceRange",
      align: "center" as "center",
      width: 200,
      render: renderManagementGroupName
    }
  ];


  // フッターボタンエリアを描画します。
  const ButtonArea = () => {
    return (
      <FooterButtonArea hasapicall={loadingFlg ? "true" : "false"}>
        <AuthButton
          name="戻る"
          size={"large"}
          shape={"round"}
          className={AuthButtonStyles.backButtonFixed}
          onClick={backClicked}
        />
        <AuthButton
          name="確定"
          size={"large"}
          shape={"round"}
          onClick={confirmClicked}
          className={AuthButtonStyles.authButtonFixed}
          disabled={confirmEnabled()}
        />
      </FooterButtonArea>
    )
  }

  // メッセージを描画します。
  // モーダル表示時にメッセージを表示しないように切り替えます。
  const CommonMessages = () => {
    if (modalVisibleState === true) {
      return (<></>);
    }
    return (<CommonMessage />);
  }

  return (
    <Layout>
      <Header />
      <Content hasapicall={loadingFlg ? "true" : "false"}>
        <FunctionTitle title={props.title} />
        <CommonMessages />
        <>
          <div style={{ width: "70%" }}>
            <span style={{ float: "left" }}>スキルマップサイトを利用するプロジェクトに招待されているユーザーは、自動的に利用権限が付与されており、スキルマップサイトをご利用いただけます。</span><br />
            <span style={{ float: "left" }}>※プロジェクト招待時に設定された役割によって、利用可能な操作が変わります。</span>
          </div>
          <Table
            columns={columns}
            size={"small"}
            scroll={{ x: 1050 }}
            dataSource={dataList}
            rowClassName={styles.tableRow}
            pagination={false}
          />
        </>
      </Content>
      <ButtonArea />
      <Footer />
      <Modal
        visible={modalVisibleState}
        bodyStyle={{ height: "410px" }}
        footer={
          <>
            <AuthButton
              name={"戻る"}
              size={"large"}
              shape={"round"}
              onClick={handleBack}
              className={AuthButtonStyles.backButtonFixed}
              style={{ margin: "10px" }}
            />
            <AuthButton
              name={"選択"}
              size={"large"}
              shape={"round"}
              onClick={handleSelect}
              disabled={switchDisabled()}
              className={AuthButtonStyles.authButtonFixed}
              style={{ margin: "10px" }}
            />
          </>
        }
        destroyOnClose
        closable={false}
        width={1000}
      >
        <MultiManagementGroupSelect title="管理グループ：グリーンサイト上での支店" />
      </Modal>
    </Layout>
  );
}