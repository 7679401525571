import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
import * as Model from './qualificationSelectModel';


// state初期化
const initialState: Model.State = {
  dataList: []
  , dataRow: {
    key: ""
    , originalQualificationId: ""
    , originalQualificationNo: ""
    , originalQualificationName: ""
    , deadlineExist: ""
    , deadlineStartDate: ""
    , deadlineEndDate: ""
  }
  , total: 0
  , criteria: {
    originalQualificationNo: ""
    , originalQualificationName: ""
    , originalQualificationId: ""
    , sortItem: ""
    , sortOrder: ""
    , offset: 0
    , limit: 10
  }
};

// スライサー （state更新アクションの実装）
export const qualificationSelectSlice = createSlice({
  name: 'qualificationSelect',
  initialState,
  reducers: {
    initCriteria: (state) => {
      let criteria = {
        originalQualificationNo: ""
        , originalQualificationName: ""
        , originalQualificationId: ""
        , sortItem: ""
        , sortOrder: ""
        , offset: 0
        , limit: 10
      }
      let dataRow = {
        key: ""
        , originalQualificationId: ""
        , originalQualificationNo: ""
        , originalQualificationName: ""
        , deadlineExist: ""
        , deadlineStartDate: ""
        , deadlineEndDate: ""
      };
      state.criteria = criteria;
      state.dataRow = dataRow;
    }
    /** 検索条件のstate更新 */
    , setQualificationCriteria: (state, action: PayloadAction<Model.Criteria>) => {
      let qualificationCriteria: Model.Criteria = {
        originalQualificationNo: action.payload.originalQualificationNo,
        originalQualificationName: action.payload.originalQualificationName,
        originalQualificationId: action.payload.originalQualificationId,
        sortItem: action.payload.sortItem,
        sortOrder: action.payload.sortOrder,
        offset: action.payload.offset,
        limit: action.payload.limit
      }
      return Object.assign({}, state, { criteria: qualificationCriteria })
    },
    /** 一覧画面描画時のstate更新 */
    setDataListOfQualificationMaster: (state, action: PayloadAction<Model.getDataList.Response>) => {

      let qualificationList: Array<Model.dataRow> = [];

      action.payload.originalQualificationList.forEach((apiDataRow: Model.dataRow) => {

        const rec: Model.dataRow = {
          key: apiDataRow.originalQualificationId
          , originalQualificationId: apiDataRow.originalQualificationId
          , originalQualificationNo: apiDataRow.originalQualificationNo
          , originalQualificationName: apiDataRow.originalQualificationName
          , deadlineExist: apiDataRow.deadlineExist
          , deadlineStartDate: apiDataRow.deadlineStartDate
          , deadlineEndDate: apiDataRow.deadlineEndDate
        };
        qualificationList.push(rec);
      });

      return Object.assign({}, state, { dataList: qualificationList }, { total: action.payload.total })
    },
    reflectModalSelectValue: (state, action: PayloadAction<Model.dataRow>) => {
      state.dataRow = action.payload;
    }
  },
});

export const {
  initCriteria
  , setQualificationCriteria
  , setDataListOfQualificationMaster
  , reflectModalSelectValue
} = qualificationSelectSlice.actions;


//　一覧検索
export const getDataListAsync = (QualificationCriteria: Model.getDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。TODO:一件のみ取得したいためURLを変えています。
    const response = await getRequest(CONSTANTS.URL_QUALIFICATIONS_PRIME_LIST, {
      params: QualificationCriteria
    });
    // 結果をstateに設定します。
    dispatch(setDataListOfQualificationMaster(response.data.data));
    // // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.qualificationSelect.dataList;
export const storedDataRow = (state: RootState) => state.qualificationSelect.dataRow;
export const storedCriteria = (state: RootState) => state.qualificationSelect.criteria;
export const storedTotal = (state: RootState) => state.qualificationSelect.total;

export default qualificationSelectSlice.reducer;
