import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router"
import { Layout, Table, Input, Typography, Modal, Select, } from "antd";
import { Header, ContentEx as Content, CommonMessage, Footer } from "../../core/CorePageContents";
import * as Module from "../../../modules/partner/attendance-mobile/attendanceMobileModule";
import { CriteriaForm, Checkbox, AuthButton, MonthPicker } from "../../core/CoreForm";
import styles from "./AttendanceMobileSummary.module.css";
import { Store } from "antd/lib/form/interface";
import { initMessages } from "../../../modules/core/commonMessageModule";
import * as Model from "../../../modules/partner/attendance-mobile/attendanceMobileModel";
import * as CONSTANTS from "../../../constants/constants";
import * as messagesUtils from "../../../utils/messagesUtils";
import * as formatUtils from "../../../utils/formatUtils";
import { initApiResult } from "../../../modules/core/bffApiModule";
import { RightSquareFilled, LeftSquareFilled } from '@ant-design/icons';
import AuthButtonStyles from "../../core/css/AuthButton.module.css";

const { Option } = Select;
const { Title } = Typography;

interface titleInterface {
  title: string;
}

const EXIST_NOTSUBMITED = "未提出"
const EXIST_SUBMITED = "提出済"
const EXIST_CONFIRMED = "確認済"
const EXIST_APPROVED = "承認済"

export const AttendanceMobileSummaryList = (props: titleInterface) => {

  const dispatch = useDispatch();
  let dataList = useSelector(Module.storedDataList);
  let total = useSelector(Module.storedSummaryTotal);
  let criteria = useSelector(Module.storedSummaryCriteria);
  let searchModalVisibleState = useSelector(Module.storedSearchModalVisibleState);
  let workerNameList = useSelector(Module.storedWorkerNameListState);

  const history = useHistory();

  useEffect(() => {
    dispatch(Module.getDataListAsync(criteria));
  },
    [criteria]
  );

  // 一次協力会社のアラート表示
  const alertFirstPartnerCompanyList = (firstPartnerCompanyList: Array<string>) => {
    let text: string = "";
    firstPartnerCompanyList.forEach((firstPartnerCompany: string) => {
      text = text + firstPartnerCompany;
    })
    alert(text);
  }

  // 検索項目の氏名の選択肢を作成
  const criteriaWorkerNameList = () => {
    let list = [];
    list.push(<Option value={""}>(未指定)</Option>);
    workerNameList.forEach((workerName: string) => {
      list.push(<Option value={workerName}>{workerName}</Option>);
    })
    return list;
  }

  /** 検索モーダルを開く */
  const modalOpen = () => {
    dispatch(Module.reflectSearchModalState(true));
  }

  /** 検索モーダルを閉じる */
  const modalClose = () => {
    dispatch(Module.reflectSearchModalState(false));
  }

  // 参照リンク押下時
  const moveToDetail = (record: Model.WorkRecordPartnerSummaryRow, event: React.MouseEvent<HTMLElement, MouseEvent>, rowIndex: number | undefined) => {
    dispatch(Module.prepareMoveToDetail(record));
    dispatch(Module.prepareMoveToDetailCriteria(criteria));
    history.push(CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_DETAIL);
  }

  // 参照リンク
  const detailLink = (text: string, row: Model.WorkRecordPartnerSummaryRow, rowIndex: number) => {
    return {
      children: (
        <>
          <label>＞</label>
        </>
      )
    }
  }

  const columns = [
    {
      title: <>
        <div className={styles.primeCompanyAndProjectCell}>元請会社 / 作業所
        <br />所属企業 / 一次協力会社</div>
      </>,
      className: styles.tableCell,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordPartnerSummaryRow) => {
        return (
          <>
            <div className={styles.primeCompanyAndProjectCell} style={{ textAlign: "left" }}><div className={styles.longText}>{row.primeCompanyName} / {row.projectName}</div>
            <div className={styles.longText}>{row.belongCompanyName} / {row.firstPartnerCompanyName}</div></div>
          </>
        )
      },
    },
    {
      width: 50,
      className: styles.tableCell,
      align: "center" as "center",
      rowspan: 2,
      render: detailLink
    },
  ];

  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let newCriteria: Model.WorkRecordSummaryCriteria = {
      primeCompanyName: criteria.primeCompanyName,
      primeCompanyNameKana: criteria.primeCompanyNameKana,
      projectName: criteria.projectName,
      belongCompanyName: criteria.belongCompanyName,
      belongCompanyNameKana: criteria.belongCompanyNameKana,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      fromYYYYMMDD: criteria.fromYYYYMMDD,
      toYYYYMMDD: criteria.toYYYYMMDD,
      notSubmitted: criteria.notSubmitted,
      submitted: criteria.submitted,
      confirmed: criteria.confirmed,
      approved: criteria.approved,
      lastName: criteria.lastName,
      firstName: criteria.firstName,
      sortItem: criteria.sortItem,
      sortOrder: criteria.sortOrder,
      start: criteria.start,
      count: criteria.count,
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      newCriteria[rec.key] = rec.value;
    })
    dispatch(Module.setSummaryCriteria(newCriteria));
  }


  // モーダルの検索ボタン押下時アクション
  const pushSerchButton = (value: Store) => {

    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let lastName = "";
    let firstName = "";

    if (value.name !== "") {
      let nameAry = value.name.split("　");
      lastName = nameAry[0];
      firstName = nameAry[1];
    }

    criteriaDataTransfer([
      { key: "primeCompanyName", value: value.primeCompanyName }
      , { key: "projectName", value: value.projectName }
      , { key: "firstPartnerCompanyName", value: value.firstPartnerCompanyName }
      , { key: "lastName", value: lastName }
      , { key: "firstName", value: firstName }
      , { key: "fromYYYYMMDD", value: value.YYYYMMDD === null ? "" : formatUtils.formatMonthNonSlash(value.YYYYMMDD) }
      , { key: "toYYYYMMDD", value: value.YYYYMMDD === null ? "" : formatUtils.formatMonthNonSlash(value.YYYYMMDD) }
      , { key: "notSubmitted", value: value.notSubmitted === true ? "1" : "0" }
      , { key: "submitted", value: value.submitted === true ? "1" : "0" }
      , { key: "confirmed", value: value.confirmed === true ? "1" : "0" }
      , { key: "approved", value: value.approved === true ? "1" : "0" }
      , { key: "start", value: 0 }
    ]);

    dispatch(Module.reflectSearchModalState(false));
  }

  // toの日付を計算
  const setToDate = () => {
    if (criteria.toYYYYMMDD === "") {
      return null;
    }
    return formatUtils.getMonthMomentOf(formatUtils.formatMonthSlash(criteria.toYYYYMMDD));
  }

  /**
   * 勤怠年月変更イベント
   * @param addValue 
   */
  const onChangeMonth = (addValue: number) => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let year = parseInt(criteria.toYYYYMMDD.substring(0, 4));
    let month = parseInt(criteria.toYYYYMMDD.substring(4, 6));

    // 変更後の日付インスタンスの作成
    let newDate = new Date(year, (month - 1) + addValue, 1);

    year = newDate.getFullYear();
    month = newDate.getMonth() + 1;

    criteriaDataTransfer([
      { key: "fromYYYYMMDD", value: year.toString() + ("0" + month).slice(-2) }
      , { key: "toYYYYMMDD", value: year.toString() + ("0" + month).slice(-2) }
    ]);
  }

  // 検索フォーム
  const AttendanceCriteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        className={styles.modalSearchItem}
        onFinish={pushSerchButton}
        initialValues={{
          "primeCompanyName": criteria.primeCompanyName
          , "firstPartnerCompanyName": criteria.firstPartnerCompanyName
          , "projectName": criteria.projectName
          , "YYYYMMDD": setToDate()
          , "name": criteria.lastName === "" || criteria.firstName === "" ? "" : criteria.lastName + "　" + criteria.firstName
          , "notSubmitted": criteria.notSubmitted === "1" ? true : false
          , "submitted": criteria.submitted === "1" ? true : false
          , "confirmed": criteria.confirmed === "1" ? true : false
          , "approved": criteria.approved === "1" ? true : false
        }}

      >
        <div className={styles.modalSearchItemGroup}>
          <div className={styles.modalSearchItem}>
            <div>勤怠年月</div>
            <CriteriaForm.Item
              name="YYYYMMDD"
              className={styles.modalSearchItemInput}
              rules={[
                () => ({
                  validator(rule, value) {
                    // 必須チェック
                    if (value === null) {
                      return Promise.reject(messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["勤怠年月"]));
                    }
                    return Promise.resolve();
                  }
                })
              ]}>
              <MonthPicker placeholder="勤怠年月"
                style={{ width: "140px" }}
                inputReadOnly
              />
            </CriteriaForm.Item>
          </div>
          <div className={styles.modalSearchItem}>
            <div>元請会社名</div>
            <CriteriaForm.Item name="primeCompanyName" className={styles.modalSearchItemInput}>
              <Input type="text"
                placeholder="元請会社名"
                maxLength={CONSTANTS.PROJECT_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
          </div>
          <div className={styles.modalSearchItem}>
            <div>作業所名</div>
            <CriteriaForm.Item name="projectName" className={styles.modalSearchItemInput}>
              <Input type="text"
                placeholder="作業所名"
                maxLength={CONSTANTS.PROJECT_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
          </div>
          <div className={styles.modalSearchItem}>
            <div>一次協力会社</div>
            <CriteriaForm.Item name="firstPartnerCompanyName" className={styles.modalSearchItemInput}>
              <Input type="text"
                placeholder="一次協力会社名"
                maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
                style={{ width: "140px" }} />
            </CriteriaForm.Item>
          </div>
          <div className={styles.modalSearchItem}>
            <div>氏名</div>
            <CriteriaForm.Item name="name" className={styles.modalSearchItemInput}>
              <Select>
                {criteriaWorkerNameList()}
              </Select>
            </CriteriaForm.Item>
          </div>
          <div className={styles.modalSearchItem}>
            <div>状態</div>
            <CriteriaForm.Item name="notSubmitted" valuePropName="checked" >
              <Checkbox>
                {EXIST_NOTSUBMITED}
              </Checkbox>
            </CriteriaForm.Item>
            <CriteriaForm.Item name="submitted" valuePropName="checked">
              <Checkbox>
                {EXIST_SUBMITED}
              </Checkbox>
            </CriteriaForm.Item>
            <CriteriaForm.Item name="confirmed" valuePropName="checked">
              <Checkbox>
                {EXIST_CONFIRMED}
              </Checkbox>
            </CriteriaForm.Item>
            <CriteriaForm.Item name="approved" valuePropName="checked">
              <Checkbox>
                {EXIST_APPROVED}
              </Checkbox>
            </CriteriaForm.Item>
          </div>
        </div>
        <div className={styles.modalInternalButton}>
          <AuthButton
            name={"キャンセル"}
            shape={"round"}
            onClick={modalClose}
            className={AuthButtonStyles.backButtonFixed}
            style={{ margin: "10px" }}
          />
          <AuthButton
            name={"検索"}
            shape={"round"}
            htmlType="submit"
            style={{ margin: "10px" }}
          />
        </div>
      </CriteriaForm>
    )
  }

  /** 一覧ヘッダー部 */
  const AttendanceContentHeader = () => {
    let statusStr = [];
    if (criteria.notSubmitted == "1") {
      statusStr.push(EXIST_NOTSUBMITED);
    }
    if (criteria.submitted == "1") {
      statusStr.push(EXIST_SUBMITED);
    }
    if (criteria.confirmed == "1") {
      statusStr.push(EXIST_CONFIRMED);
    }
    if (criteria.approved == "1") {
      statusStr.push(EXIST_APPROVED);
    }

    return (
      <div style={{ whiteSpace: "nowrap" }} className={styles.contentHeader}>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle}>勤怠年月：</div>
          <div>
            <label className={styles.changeMonthButton} ><LeftSquareFilled onClick={(e) => onChangeMonth(-1)} /></label>
            <span>{criteria.toYYYYMMDD.substring(0, 4)}年{parseInt(criteria.toYYYYMMDD.substring(4, 6))}月</span>
            <label className={styles.changeMonthButton} ><RightSquareFilled onClick={(e) => onChangeMonth(1)} /></label>
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.headerTitle}>氏名：</div>
          <div>{(criteria.lastName === "" && criteria.firstName === "") ? "(未指定)" : criteria.lastName + "　" + criteria.firstName}</div>
        </div>
      </div>
    )
  }

  /** 一覧部 */
  const AttendanceContent = () => {
    return (
      total > 0 ?
        <Table
          columns={columns}
          dataSource={dataList}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                moveToDetail(record, event, rowIndex)
              }
            };
          }
          }
        >
        </Table>
        : <></>
    );
  }

  return (
    <Layout>
      <Header />
      <Content className={styles.content}>
        <Title level={4} className={styles.partnerfunctionTitle}>
          {props.title}
        </Title>
        <CommonMessage searchNoDataName="プロジェクト" />
        <AttendanceContentHeader />
        <div className={styles.searchButton}>
          <AuthButton
            name={"絞込み"}
            shape={"round"}
            onClick={modalOpen}
          />
        </div>
        <AttendanceContent />
      </Content>
      <Footer />
      <Modal
        visible={searchModalVisibleState}
        footer={null}
        closable={false}
        width={300}
      >
        <AttendanceCriteria />
      </Modal>
    </Layout>
  );
}
