// import { Rule } from 'rc-field-form/lib/interface';

// // Validation定義の型定義
// interface IRule {
//     [RuleName:string]: Rule[]
// }

// export const qualificationEditRules:IRule = {
//     originalQualificationNo: [
//         {
//           required: true,
//           message: '独自資格名は必須入力です。',
//         },
//         {
//           len: 10,
//           message: '独自資格名は10文字以内で入力してください。',
//         },
//         {
//           type: 'string',
//           message: '独自資格名は文字列で入力してください',
//         },
//       ]
// }

export const seisu = RegExp("^[0-9]*$");
export const alphanumeric = RegExp("^[A-Za-z0-9]*$");