import React from 'react';
import styles from '../css/Footer.module.css';
import * as CONSTANTS from '../../../constants/constants';

export const Footer = () => {

  return (
    <div className={styles.FooterTable}>
      <div className={styles.FooterCell}>
        <span>{CONSTANTS.COPY_RIGHT}</span>
      </div>
    </div>
  );
}
