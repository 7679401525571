import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { Layout, Typography, Input, Table } from 'antd';
import { CriteriaForm, AuthButton, Checkbox } from '../../core/CoreForm';
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from '../../core/CorePageContents';
import styles from './QualificationHoldersCompanies.module.css';
import * as Module from '../../../modules/prime/qualifications-holders/qualificationHoldersModule';
import * as Model from '../../../modules/prime/qualifications-holders/qualificationHoldersModel';
import { initMessages } from '../../../modules/core/commonMessageModule';
import * as CONSTANTS from '../../../constants/constants';
import * as formatUtils from '../../../utils/formatUtils';
import * as Utils from '../../../utils/utils';
import { SortOrder } from 'antd/lib/table/interface';
import { initApiResult, storedApiResult } from '../../../modules/core/bffApiModule';

// const { Title } = Typography;

interface titleInterface {
  title: string;
}

export const QualificationHoldersCompanies = (props: titleInterface) => {
  //　hoocs
  const dispatch = useDispatch();
  const history = useHistory();
  // ステート取得
  const apiResult = useSelector(storedApiResult);
  let dataList = useSelector(Module.storedBelongCompanyDataList);
  let dataCriteria = useSelector(Module.storedBelongCompanyCriteria);
  const totalCnt = useSelector(Module.storedBelongCompanyTotal);

  useEffect(() => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    dispatch(Module.getBelongCompaniesDataListAsync(dataCriteria));
  },
    [dataCriteria]
  );

  /**
   * 画面アクション
   * 
   */
  //　行選択時アクション
  const moveToDetail = (record: Model.belongCompanyDataRow) => {
    // 選択した所属企業IDを独自資格保有者検索条件に追加します。
    dispatch(Module.prepareMoveToQualificationHolder(record));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // 詳細画面へ遷移します。
    history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_LIST);
  }

  // 検索ボタン押下時イベント
  const searchDataList = (value: Store) => {
    criteriaDataTransfer([
      { key: "belongCompanyName", value: value.belongCompanyName }
      , { key: "belongCompanyNameKana", value: value.belongCompanyNameKana }
      , { key: "offset", value: 0 }
      , { key: "onlyEnableQualification", value: value.onlyEnableQualification === true ? "1" : "0" }
    ]);
  }

  // テーブル検索条件変更時イベント（ページ、表示件数、ソート）
  const handleTableChange = (pagination: any, sort: any) => {
    let order = "";
    if (sort.order === "ascend") {
      order = "0";
    } else if (sort.order === "descend") {
      order = "1";
    }
    let sortItem = sort.columnKey
    if (typeof sort.column === "undefined") {
      sortItem = "";
    }

    let offsetReset: boolean = false;

    if (dataCriteria.limit !== pagination.pageSize ||
      dataCriteria.sortItem !== sortItem ||
      dataCriteria.sortOrder !== order) {
      offsetReset = true;
    }

    criteriaDataTransfer([
      { key: "sortItem", value: sortItem }
      , { key: "sortOrder", value: order }
      , { key: "limit", value: pagination.pageSize }
      , { key: "offset", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
    ]);
  }

  // CriteriaをStoreに保持
  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let criteria: Model.belongCompanyCriteria = {
      belongCompanyName: dataCriteria.belongCompanyName,
      belongCompanyNameKana: dataCriteria.belongCompanyNameKana,
      sortItem: dataCriteria.sortItem,
      sortOrder: dataCriteria.sortOrder,
      offset: dataCriteria.offset,
      limit: dataCriteria.limit,
      onlyEnableQualification: dataCriteria.onlyEnableQualification
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      criteria[rec.key] = rec.value;
    })
    dispatch(Module.setBelongCompanyCriteria(criteria));
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_COMPANIES === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (dataCriteria.offset > 0 && totalCnt > 0 && dataList.length === 0) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(totalCnt, dataCriteria.limit);
      criteriaDataTransfer([
        { key: "offset", value: newOffset }
      ]);
    }
  }

  /**
   * 描画用メソッド
   * 
   */

  // 数値型にフォーマットします。 
  const renderFormattedNumber = (value: number, row: Model.belongCompanyDataRow) => {
    return {
      children: (
        <div className={styles.columnAlignRight}>{formatUtils.formatNumberComma(value)}</div>
      )
    }
  }

  // 検索条件エリアを描画します。
  const QualificationHoldersCriteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={searchDataList}
        // 初期値はFormに設定する
        initialValues={{
          'belongCompanyName': dataCriteria.belongCompanyName
          , 'belongCompanyNameKana': dataCriteria.belongCompanyNameKana
          , 'onlyEnableQualification': dataCriteria.onlyEnableQualification === "1" ? true : false
        }}
      >
        <div className={styles.criteriaSearchField}>
          <CriteriaForm.Item name="belongCompanyName" id="belongCompanyName">
            <Input
              type="text"
              placeholder="所属会社名"
              maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="belongCompanyNameKana">
            <Input
              type="text"
              placeholder="所属会社名カナ"
              maxLength={CONSTANTS.COMPANY_NAME_KANA_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="onlyEnableQualification" valuePropName="checked">
            <Checkbox>
              有効資格のみ
            </Checkbox>
          </CriteriaForm.Item>
          <AuthButton
            name={"検索"}
            htmlType="submit"
            shape="round"
          />
        </div>
      </CriteriaForm>
    )
  }

  // テーブル列を設定します。
  const columns = [
    {
      title: '所属会社'
      , dataIndex: 'belongCompanyName'
      , key: '1'
      , width: 300
      , sortOrder: dataCriteria.sortItem !== '1' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , sorter: true
      , showSorterTooltip: false
      , align: 'center' as 'center'
      , render: (value: string, row: Model.belongCompanyDataRow) => {
        return {
          children: (
            <div className={styles.columnAlignLeft}>{value}</div>
          )
        }
      }
    },
    {
      title: '郵便番号'
      , dataIndex: 'zipcode'
      , key: '2'
      , width: 100
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '2' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
      , render: (value: string, row: Model.belongCompanyDataRow) => {
        return {
          children: (
            <div>{value}</div>
          )
        }
      }
    },
    {
      title: '所在地'
      , dataIndex: 'address'
      , key: '3'
      , width: 300
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '3' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
      , render: (value: string, row: Model.belongCompanyDataRow) => {
        return {
          children: (
            <div className={styles.columnAlignLeft}>{value}</div>
          )
        }
      }
    },
    {
      title: (
        <>
          <span>有効独自資格</span><br />
          <span> 保有人数</span>
        </>
      )
      , dataIndex: 'numberOfPeople'
      , key: '4'
      , width: 100
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '4' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: (
        <>
          <span>独自資格</span><br />
          <span> 確認済人数</span>
        </>
      )
      , dataIndex: 'numberOfComfirmedPeople'
      , key: '5'
      , width: 100
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '5' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: (
        <>
          <span>独自資格</span><br />
          <span> 確認依頼中人数</span>
        </>
      )
      , dataIndex: 'numberOfRequestedPeople'
      , key: '6'
      , width: 100
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '6' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: (
        <>
          <span>独自資格</span><br />
          <span> 申請人数</span>
        </>
      )
      , dataIndex: 'numberOfRequestPeople'
      , key: '7'
      , width: 100
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '7' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    }
  ];

  // ページングボタンを描画します。
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <AuthButton name='＜' size='small' />;
    }
    if (type === 'next') {
      return <AuthButton name='＞' size='small' />;
    }
    return originalElement
  }

  // テーブルエリアを描画します。
  const QualificationHoldersTable = () => {
    if (totalCnt > 0 && dataList.length > 0) {
      return (
        <Table
          columns={columns}
          sortDirections={['ascend', 'descend']}
          size='small'
          dataSource={dataList}
          scroll={{ x: 1000, y: 400 }}
          pagination={{
            total: totalCnt  //総件数
            , defaultCurrent: 1 //
            , current: dataCriteria.offset / dataCriteria.limit + 1
            , pageSize: dataCriteria.limit //ページサイズを入れる
            , pageSizeOptions: ['5', '10', '20', '50', '100']
            , showSizeChanger: true
            , showTotal: (total, range) => `${total}件中、${dataCriteria.offset + 1}件目から${total <= dataCriteria.offset + dataCriteria.limit ? total : dataCriteria.offset + dataCriteria.limit}件目を表示`
            , itemRender: pageItemRender
            , locale: { items_per_page: '件/ページ' }
          }}
          onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => { moveToDetail(record) }
            };
          }}
        />
      )
    }
    return (
      <></>
    )
  }

  return (
    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <QualificationHoldersCriteria />
        <CommonMessage searchNoDataName="協力会社" />
        <QualificationHoldersTable />
      </Content>
      <FooterButtonArea />
      <Footer />
    </Layout >
  );
}

