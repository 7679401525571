import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Store } from 'antd/lib/form/interface';
import { Layout, Typography, Input, Table, Modal, Form } from 'antd';
import { CriteriaForm, AuthButton, AuthButtonAuthorities, Checkbox } from '../../core/CoreForm';
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from '../../core/CorePageContents';
import styles from './PartnerCompanies.module.css';
import * as Module from '../../../modules/prime/partner-companies/partner-companiesModule';
import * as Model from '../../../modules/prime/partner-companies/partner-companiesModel';
import { initMessages, setMessages, message } from '../../../modules/core/commonMessageModule';
import { initApiResult, storedApiResult, setApiResult } from '../../../modules/core/bffApiModule';
import { SortOrder } from 'antd/lib/table/interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { initState as initProjectSelect } from '../../../modules/prime/projectSelect/projectSelectModule';
import { ProjectSelect } from '../projectSelect/ProjectSelect';
import { PartnerCompanySelect } from '../partnerCompanySelect/partnerCompanySelect';
import { storedSelectValue, initState as initPartnerCompanySelect, initState, storedDataTotal } from '../../../modules/prime/partnerCompanySelect/partnerCompanySelectModule';
import * as CONSTANTS from '../../../constants/constants';
import * as messagesUtils from '../../../utils/messagesUtils';
import * as Utils from '../../../utils/utils';
import AuthButtonStyles from "../../core/css/AuthButton.module.css";

//const { Content } = Layout;
const { Title } = Typography;

interface titleInterface {
    title: string;
}

export const PartnerCompanies = (props: titleInterface) => {
    //　hoocs
    const dispatch = useDispatch();
    // ステート取得
    const dataList = useSelector(Module.storedDataList);
    const dataCriteria = useSelector(Module.storedCriteria);
    const totalCnt = useSelector(Module.storedTotal);
    const allSelect: boolean = useSelector(Module.storedAllSelect);
    const existChecked = useSelector(Module.storedExistChecked);
    const projectModalVisibleState = useSelector(Module.storedProjectModalVisibleState);
    const partnerCompanyModalVisibleState = useSelector(Module.storedPartnerCompanyModalVisibleState);
    const partnerCompanyModalTotalCnt = useSelector(storedDataTotal);
    const selectValue = useSelector(storedSelectValue);
    const apiResult = useSelector(storedApiResult);

    useEffect(() => {
        dispatch(Module.getDataListAsync(dataCriteria));
    },
        [dataCriteria]
    );

    // 再描画処理
    const redraw = () => {
        // API結果情報を初期化します。
        dispatch(initApiResult());

        let criteria: Model.Criteria = {
            partnerCompanyName: "",
            sortItem: "",
            sortOrder: "",
            offset: 0,
            limit: 10
        }
        dispatch(Module.setPartnerCompaniesCriteria(criteria));
    };

    // 更新API実行後、APIエラーメッセージが設定されていなければ画面遷移します。
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod) {
        // 協力会社検索モーダル表示を消す
        dispatch(Module.reflectPartnerCompanyModalState(false));
        // 成功メッセージを設定します。
        const setMessage: message = {
            message: messagesUtils.getMessage("SUCCESS_ADD", ["協力会社"])
            , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
        }
        dispatch(setMessages([setMessage]));
        redraw();
    }

    // 削除API実行後、APIエラーメッセージが設定されていなければ画面遷移します。
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_DELETE === apiResult.requestMethod) {
        // 成功メッセージを設定します。
        const setMessage: message = {
            message: messagesUtils.getMessage("SUCCESS_DELETE", ["協力会社"])
            , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
        }
        dispatch(setMessages([setMessage]));
        redraw();
    }

    /**
     * 画面アクション
     * 
     */

    // 検索ボタン押下時イベント
    const searchDataList = (value: Store) => {
        // API結果情報を初期化します。
        dispatch(initApiResult());
        // 共通メッセージ情報を初期化します。
        dispatch(initMessages());

        criteriaDataTransfer([
            { key: "partnerCompanyName", value: value.partnerCompanyName }
            , { key: "offset", value: 0 }
        ]);
    }

    // テーブル検索条件変更時イベント（ページ、表示件数、ソート）
    const handleTableChange = (pagination: any, sort: any) => {
        // API結果情報を初期化します。
        dispatch(initApiResult());
        // 共通メッセージ情報を初期化します。
        dispatch(initMessages());

        let order = "";
        if (sort.order === "ascend") {
            order = "0";
        } else if (sort.order === "descend") {
            order = "1";
        }
        let sortItem = sort.columnKey
        if (typeof sort.column === "undefined") {
            sortItem = "";
        }

        let offsetReset: boolean = false;

        if (dataCriteria.limit !== pagination.pageSize ||
            dataCriteria.sortItem !== sortItem ||
            dataCriteria.sortOrder !== order) {
            offsetReset = true;
        }

        criteriaDataTransfer([
            { key: "sortItem", value: sortItem }
            , { key: "sortOrder", value: order }
            , { key: "limit", value: pagination.pageSize }
            , { key: "offset", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
        ]);
    }

    // CriteriaをStoreに保持
    const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
        let criteria: Model.Criteria = {
            partnerCompanyName: dataCriteria.partnerCompanyName,
            sortItem: dataCriteria.sortItem,
            sortOrder: dataCriteria.sortOrder,
            offset: dataCriteria.offset,
            limit: dataCriteria.limit
        }
        keyValueList.forEach((rec: { key: string, value: any }) => {
            criteria[rec.key] = rec.value;
        })
        dispatch(Module.setPartnerCompaniesCriteria(criteria));
    }

    // チェックボックス変更時イベント
    const onChangeCheck = (e: CheckboxChangeEvent, row: Model.DataRow) => {
        let transfer: Model.changeCheckTransfer = {
            targetDataRow: row,
            value: e.target.checked
        }
        dispatch(Module.onChangeRowSelect(transfer));
    }

    //　削除アクション
    const deleteClicked = () => {
        if (!window.confirm(messagesUtils.getMessage("CONFIRM_DELETE", ["選択した協力会社"]))) {
            return;
        }

        // 共通メッセージ情報を初期化します。
        dispatch(initMessages());

        // API結果情報を初期化します。
        dispatch(initApiResult());

        // リクエストデータの作成
        let destList: Array<Model.deleteData.partnerCompany> = [];
        dataList.forEach((data: Model.DataRow) => {
            if (data.selectFlag) {
                let dest: Model.deleteData.partnerCompany = {
                    companyId: data.companyId,
                }
                destList.push(dest);
            }
        });
        // 利用可能協力会社一覧削除APIを呼び出す。
        let deleteData: Model.deleteData.Request = {
            partnerCompanyList: destList
        }
        dispatch(Module.deleteDataLsitAsync(deleteData));
    }

    //　追加ボタン押下時アクション
    const addClicked = () => {
        // 共通メッセージ情報を初期化します。
        dispatch(initMessages());
        // プロジェクト検索モーダル情報を初期化
        dispatch(initProjectSelect());
        // 協力会社検索モーダル情報を初期化
        dispatch(initPartnerCompanySelect());
        // プロジェクト検索モーダルを表示
        dispatch(Module.reflectProjectModalState(true));
    }

    // プロジェクト検索モーダル戻るボタン押下時アクション
    const handleBack = () => {
        // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
        dispatch(setApiResult({ status: CONSTANTS.API_STATUS_200, errorCode: "", requestMethod: "", url: "" }));
        // 共通メッセージ情報を初期化します。
        dispatch(initMessages());
        // プロジェクト検索モーダル表示を消す
        dispatch(Module.reflectProjectModalState(false));
    };

    // 協力会社検索モーダル戻るボタン押下時アクション
    const backToProjectModal = () => {
        // 共通メッセージ情報を初期化します。
        dispatch(initMessages());
        // 協力会社検索モーダル表示を消す。
        dispatch(Module.reflectPartnerCompanyModalState(false));
        // プロジェクト検索モーダルを表示する
        dispatch(Module.reflectProjectModalState(true));
    };


    // 協力会社検索モーダル確定ボタン押下時アクション
    const selectPartnerCompany = () => {

        // API結果情報を初期化します。
        dispatch(initApiResult());

        // 利用可能協力会社一覧編集APIを呼び出す。
        let request: Model.putDataList.Request = {
            companyId: selectValue
        }
        dispatch(Module.putDataRowAsync(request));
    };

    // 検索が正常終了した時の処理
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
        && "" === apiResult.errorCode
        && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
        && CONSTANTS.URL_CMN_PRIME_AVAILABLE_PARTNER_COMPANIES === apiResult.url) {

        // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
        if (dataCriteria.offset > 0 && totalCnt > 0 && dataList.length === 0) {

            // API結果情報を初期化します。
            dispatch(initApiResult());

            // 最終ページを取得する（基本的には一つ前のページ）
            const newOffset = Utils.calcLastPageOffset(totalCnt, dataCriteria.limit);
            criteriaDataTransfer([
                { key: "offset", value: newOffset }
            ]);
        }
    }

    /**
     * 描画用メソッド
     * 
     */

    // 検索条件エリアを描画します。
    const PartnerCompanyCriteria = () => {
        return (
            <CriteriaForm
                layout="inline"
                onFinish={searchDataList}
                // 初期値はFormに設定する
                initialValues={{
                    'partnerCompanyName': dataCriteria.partnerCompanyName
                }}
            >
                <div className={styles.criteriaSearchField}>
                    <CriteriaForm.Item name="partnerCompanyName" id="partnerCompanyName">
                        <Input
                            type="text"
                            placeholder="協力会社名"
                            maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
                        />
                    </CriteriaForm.Item>
                    <AuthButton
                        name={"検索"}
                        htmlType="submit"
                        shape="round"
                    />
                </div>
            </CriteriaForm>
        )
    }

    // テーブル列を設定します。
    const columns = [
        {
            title: (
                <>
                    <span>選択</span><br />
                    <Checkbox
                        className={styles.checkBox}
                        checked={allSelect}
                        onChange={(e) => dispatch(Module.onChangeAllSelect(e.target.checked))}
                    />
                </>
            ),
            align: 'center' as 'center',
            width: 50,
            render: (text: string, row: Model.DataRow) => {
                return (
                    <Checkbox
                        checked={row.selectFlag}
                        onChange={(e) => onChangeCheck(e, row)}
                    />
                )
            }
        },
        {
            title: '協力会社'
            , dataIndex: 'companyName'
            , key: '1'
            , width: 350
            , sorter: true
            , sortOrder: (dataCriteria.sortItem !== '1') ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
            , showSorterTooltip: false
            , align: 'center' as 'center'
            , render: (value: string) => {
                return {
                    children: (
                        <div className={styles.columnAlignLeft}>{value}</div>
                    )
                }
            }
        },
        {
            title: '郵便番号'
            , dataIndex: 'zipcode'
            , key: '2'
            , width: 100
            , sorter: true
            , sortOrder: (dataCriteria.sortItem !== '2') ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
            , showSorterTooltip: false
            , align: 'center' as 'center'
        },
        {
            title: '所在地'
            , dataIndex: 'address'
            , key: '3'
            , width: 500
            , sorter: true
            , sortOrder: (dataCriteria.sortItem !== '3') ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
            , showSorterTooltip: false
            , align: 'center' as 'center'
            , render: (value: string) => {
                return {
                    children: (
                        <div className={styles.columnAlignLeft}>{value}</div>
                    )
                }
            }
        }
    ];


    // ページングボタンを描画します。
    const pageItemRender = (page: any, type: string, originalElement: any) => {
        if (type === 'prev') {
            return <AuthButton name='＜' size='small' />;
        }
        if (type === 'next') {
            return <AuthButton name='＞' size='small' />;
        }
        return originalElement
    }

    // 協力会社一覧画面の削除ボタンの表示を切り替えます。
    const deleteDisabled = () => {
        if (existChecked !== true) {
            return true;
        }
        return false;
    }

    // 協力会社検索モーダルの確定ボタンの表示を切り替えます。
    let confirmDisabled = () => {
        if (selectValue === "") {
            return true;
        }
        return false;
    }

    // メッセージを描画します。
    // モーダル表示時にメッセージを表示しないように切り替えます。
    const CommonMessages = () => {
        if (projectModalVisibleState === true || partnerCompanyModalVisibleState === true) {
            return (<></>);
        }
        return (<CommonMessage searchNoDataName="協力会社" />);
    }

    // フッターボタンエリアを描画します。
    const ButtonArea = () => {
        if (totalCnt > 0) {
            return (
                <FooterButtonArea>
                    <AuthButton
                        name={"削除"}
                        size={"large"}
                        shape={"round"}
                        onClick={deleteClicked}
                        disabled={deleteDisabled()}
                    />
                    <AuthButton
                        name={"追加"}
                        size={"large"}
                        shape={"round"}
                        onClick={addClicked}
                    />
                </FooterButtonArea>
            );
        }
        return (
            <FooterButtonArea>
                <AuthButton
                    name={"追加"}
                    size={"large"}
                    shape={"round"}
                    onClick={addClicked}
                />
            </FooterButtonArea>
        );
    }


    // 協力会社検索ダイアログのボタンエリアを描画します。
    const PartnerCompanyModalButton = () => {
        if (partnerCompanyModalTotalCnt > 0) {
            return (
                <>
                    <AuthButton
                        key={"PartnerCompanySelectBack"}
                        name={"戻る"}
                        shape={"round"}
                        className={AuthButtonStyles.backButtonFixed}
                        onClick={backToProjectModal}
                    />
                    <AuthButton
                        key={"PartnerCompanySelectConfirm"}
                        name={"確定"}
                        shape={"round"}
                        onClick={selectPartnerCompany}
                        disabled={confirmDisabled()}
                    />
                </>
            );
        }
        return (
            <>
                <AuthButton
                    key={"PartnerCompanySelectBack"}
                    name={"戻る"}
                    shape={"round"}
                    className={AuthButtonStyles.backButtonFixed}
                    onClick={backToProjectModal}
                />
            </>
        );
    }

    return (
        <Layout>
            <Header />
            <Content>
                <FunctionTitle title={props.title} />
                <PartnerCompanyCriteria />
                <CommonMessages />
                {
                    totalCnt > 0 && dataList.length > 0 ?
                        <Table
                            columns={columns}
                            size='small'
                            dataSource={dataList}
                            scroll={{ x: 1000 }}
                            pagination={{
                                total: totalCnt  //総件数
                                , defaultCurrent: 1 //
                                , current: dataCriteria.offset / dataCriteria.limit + 1
                                , pageSize: dataCriteria.limit //ページサイズを入れる
                                , pageSizeOptions: ['5', '10', '20', '50', '100']
                                , showSizeChanger: true
                                , showTotal: (total, range) => `${total}件中、${range[0]}件目から${range[1]}件目を表示`
                                , itemRender: pageItemRender
                                , locale: { items_per_page: '件/ページ' }
                            }}
                            onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
                        />
                        : <></>
                }
            </Content>
            <ButtonArea />
            <Modal
                visible={projectModalVisibleState}
                bodyStyle={{ height: "470px" }}
                footer={[
                    <AuthButton
                        key={"ProjectSelectBack"}
                        name={"戻る"}
                        shape={"round"}
                        className={AuthButtonStyles.backButtonFixed}
                        onClick={handleBack}
                    />
                ]}
                destroyOnClose
                closable={false}
                width={(window.innerWidth > 1111) ? 1000 : "90%"}
            >
                <ProjectSelect title="協力会社の追加" />
            </Modal>
            <Modal
                visible={partnerCompanyModalVisibleState}
                bodyStyle={{ height: "470px" }}
                footer={[
                    <PartnerCompanyModalButton />
                ]}
                destroyOnClose
                closable={false}
                width={(window.innerWidth > 1111) ? 1000 : "90%"}
            >
                <PartnerCompanySelect title="協力会社の追加" />
            </Modal>
            <Footer />
        </Layout >
    );
}

