import React from 'react';
import styles from './css/UseEnd.module.css';
import { Row, Col, Layout, Menu as AntdMenu } from 'antd';

const { Content } = Layout;

export const UseEnd = () => {

  return (
    <Layout>
      <Row className={styles.HeaderArea}>
        <Col span={6} className={styles.HeaderlogoArea}>
          <img
            className={styles.LogoStyle}
            src={process.env.PUBLIC_URL + '/header_logo_green.png'}
            alt="スキルマップサイト" />
        </Col>
      </Row>

      <AntdMenu className={styles.HeaderMenu}>
      </AntdMenu>

      <Content>
        <div className={styles.mainMessage} >ご利用ありがとうございました。</div>
      </Content>
    </Layout >
  );
}
