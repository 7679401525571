import moment from 'moment';
import * as Utils from './utils';

/**
 * 日付フォーマットUTILS
 */
// 日付文字列からmomentオブジェクトを生成します。
export const getMomentOf = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return;
  }
  return moment(dateString, ['YYYYMMDD', 'YYYY-MM-DD', 'YYYY/MM/DD', moment.ISO_8601]);
}

// 日付文字列をYYYY/MM/DD形式にフォーマットします。
export const formatDateSlash = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  const momentInstance = moment(dateString, ['YYYYMMDD', 'YYYY-MM-DD', 'YYYY/MM/DD', moment.ISO_8601]);
  return momentInstance.format("YYYY/MM/DD");
}

// 日付文字列をYYYY-MM-DD形式にフォーマットします。
export const formatDateHyphen = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  const momentInstance = moment(dateString, ['YYYYMMDD', 'YYYY-MM-DD', 'YYYY/MM/DD', moment.ISO_8601]);
  return momentInstance.format("YYYY-MM-DD");
}

// 日付文字列をYYYYMMDD形式にフォーマットします。
export const formatDateNonSlash = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  const momentInstance = moment(dateString, ['YYYYMMDD', 'YYYY-MM-DD', 'YYYY/MM/DD', moment.ISO_8601]);
  return momentInstance.format("YYYYMMDD");
}

// 日付文字列からmomentオブジェクトを生成します。
export const getMonthMomentOf = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return;
  }
  return moment(dateString, ['YYYYMM', 'YYYY-MM', 'YYYY/MM', moment.ISO_8601]);
}

// 日付文字列をYYYY/MM形式にフォーマットします。
export const formatMonthSlash = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  const momentInstance = moment(dateString, ['YYYYMM', 'YYYY-MM', 'YYYY/MM', moment.ISO_8601]);
  return momentInstance.format("YYYY/MM");
}

// 日付文字列をYYYY-MM形式にフォーマットします。
export const formatMonthHyphen = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  const momentInstance = moment(dateString, ['YYYYMM', 'YYYY-MM', 'YYYY/MM', moment.ISO_8601]);
  return momentInstance.format("YYYY-MM");
}

// 日付文字列をYYYY年MM月形式にフォーマットします。
export const formatMonthChar = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  const momentInstance = moment(dateString, ['YYYYMM', 'YYYY-MM', 'YYYY/MM', moment.ISO_8601]);
  return momentInstance.format("YYYY年MM月");
}

// 日付文字列をYYYYMM形式にフォーマットします。
export const formatMonthNonSlash = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  const momentInstance = moment(dateString, ['YYYYMM', 'YYYY-MM', 'YYYY/MM', moment.ISO_8601]);
  return momentInstance.format("YYYYMM");
}

// 日時文字列をYYYY/MM/DD HH:mm:SS形式にフォーマットします。
export const formatDateTimeSlash = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  const momentInstance = moment(dateString, ['YYYY-MM-DDTHH:mm:ssZ', 'YYYY/MM/DDTHH:mm:ssZ', moment.ISO_8601]);
  return momentInstance.format("YYYY/MM/DD HH:mm:SS");
}


// 開始日付文字列と終了日付文字列を「YYYY/MM/DD ～ YYYY/MM/DD」形式に変換します。
export const formatDuration = (startDateString: string, endDateString: string) => {

  if (Utils.isNullOrEmpty(startDateString) || Utils.isNullOrEmpty(endDateString)) {
    return "なし";
  }

  return formatDateSlash(startDateString) + " ～ " + formatDateSlash(endDateString);
}

/**
 * 日付文字列を年月に変換
 * @param dateString 
 */
export const formatDateToYearMonth = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  const momentInstance = moment(dateString, ['YYYYMMDD', 'YYYY-MM-DD', 'YYYY/MM/DD', moment.ISO_8601]);
  return momentInstance.format("YYYYMM");
}

/**
 * 日付文字列を日に変換
 * @param dateString 
 */
export const formatDateToDay = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  const momentInstance = moment(dateString, ['YYYYMMDD', 'YYYY-MM-DD', 'YYYY/MM/DD', moment.ISO_8601]);
  return momentInstance.format("DD");
}

/**
 * 日付文字列をM月D日(dddd)形式に変換する
 * @param dateString 
 */
export const formatDateToYearsAndDayWeek = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }
  moment.updateLocale("ja", { weekdays: ["日", "月", "火", "水", "木", "金", "土"] });
  const momentInstance = moment(dateString, ['YYYY年MM月DD(d)', moment.ISO_8601]);
  return momentInstance.format("M月D日(dddd)")
}

/**
 * 日付文字列をH:mm形式に変換する
 * @param dateString 
 */
export const formatDateToTime = (dateString: string) => {
  if (Utils.isNullOrEmpty(dateString)) {
    return "";
  }

  const momentInstance = moment(dateString, ['YYYY-MM-DDTHH:mm:ssZ', 'YYYY/MM/DDTHH:mm:ssZ', moment.ISO_8601]);
  return momentInstance.format("H:mm");
}

/**
 * 時間文字列をH:mm形式に変換する
 * @param timeString HHmm
 */
export const formatTime = (timeString: string) => {
  if (Utils.isNullOrEmpty(timeString)) {
    return "";
  }

  const momentInstance = moment(timeString, ['HH:mm', moment.ISO_8601]);
  return momentInstance.format("H:mm");
}

/**
 * 数値フォーマットUTILS
 */

const numberFormatter = new Intl.NumberFormat('ja');

// 数値をカンマ区切りにフォーマットします。
export const formatNumberComma = (value: number) => {
  return numberFormatter.format(value);
}