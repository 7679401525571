import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../css/FooterButtonArea.module.css';
import { storedApiResult } from '../../../modules/core/bffApiModule';
import { storedLoginUserInfo } from '../../../modules/core/authModule'
import { isPrimeMenu } from '../../../modules/core/menuModule'
import * as CONSTANTS from '../../../constants/constants';
import { BasicProps } from 'antd/lib/layout/layout';
import { Spin } from 'antd';

const TRUE = "true";
const FALSE = "false";
// 指定可能なパラメータ定義（拡張）
interface FooterButtonAreaProps extends BasicProps {
  hasapicall?: string
}

export const FooterButtonArea = (props: FooterButtonAreaProps) => {

  const apiResult = useSelector(storedApiResult);
  const loginUserInfo = useSelector(storedLoginUserInfo);
  const isPrime = useSelector(isPrimeMenu);

  // 利用者権限を保持していない場合、何も表示しない。
  if (window.location.pathname === CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY || window.location.pathname === CONSTANTS.PATH_PRIME_ATTENDANCE_DETAIL) {
    if (isPrime
      && "0" === loginUserInfo.authorityInfo.primeAttendanceApprovalRoleAuth
      && "0" === loginUserInfo.authorityInfo.primeAttendanceReffenceRoleAuth
      && "0" === loginUserInfo.authorityInfo.primeAvailableAuth) {
      return (
        <>
        </>
      )
    }
  } else {
    if ((isPrime && "0" === loginUserInfo.authorityInfo.primeAvailableAuth)
      || (!isPrime && "0" === loginUserInfo.authorityInfo.partnerAvailableAuth)) {

      return (
        <>
        </>
      )
    }
  }

  // 初期表示時にAPIコールを含む画面で APIステータスが初期値の場合、何も表示しない。
  if ((typeof props.hasapicall === "undefined" || props.hasapicall === TRUE) && CONSTANTS.API_STATUS_INITIAL === apiResult.status) {

    return (
      <Spin indicator={<></>}>
        <div className={styles.buttonArea} {...props} >
        </div>
      </Spin>
    )
  }
  return (
    <div className={styles.buttonArea} {...props} >
    </div>
  );
}
