import * as MESSAGES from '../constants/messages';

const SESSION_STORAGE_KEY_MESSAGE_TEXT = "message_text";
const SESSION_STORAGE_KEY_MESSAGE_TYPE = "message_type";

/**
 * コードからメッセージを取得する。
 * また置換文字列が指定されている場合、指定した文字列で置換して返却する。
 * @param code 
 * @param replaceStrings 
 */
export const getMessage = (code: string, replaceStrings?: Array<string>) => {
    let message = MESSAGES.MESSAGE_LIST[code];
    if (typeof replaceStrings !== "undefined") {
        replaceStrings.forEach((replaceString: string) => {
            message = message.replace("%s", replaceString);
        })
    }
    if (typeof message === "undefined") {
        message = "";
    }

    return message !== "" ? message : "エラー";
}

/**
 * セッションからメッセージ本文を取得
 */
export const getMessageTextFromSession = (): string | null => {
    return sessionStorage.getItem(SESSION_STORAGE_KEY_MESSAGE_TEXT);
}

/**
 * セッションからメッセージタイプを取得
 */
export const getMessageTypeFromSession = (): string | null => {
    return sessionStorage.getItem(SESSION_STORAGE_KEY_MESSAGE_TYPE);
}

/**
 * セッションにメッセージを保存
 * @param text メッセージ本文
 * @param type メッセージ種類
 */
export const setMessageInSession = (text: string, type: string) => {
    sessionStorage.setItem(SESSION_STORAGE_KEY_MESSAGE_TEXT, text);
    sessionStorage.setItem(SESSION_STORAGE_KEY_MESSAGE_TYPE, type);
}

/**
 * セッションに保存されているメッセージをクリア
 */
export const clearSessionMessage = () => {
    sessionStorage.removeItem(SESSION_STORAGE_KEY_MESSAGE_TEXT);
    sessionStorage.removeItem(SESSION_STORAGE_KEY_MESSAGE_TYPE);
}