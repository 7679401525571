import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, putRequest, deleteRequest, apiSuccessHandler, apiErrorHandler, setApiResult } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
import * as Model from './qualificationHoldersModel';
import { dataRow as selectWorker } from '../workerSelect/workerSelectModel';
import * as formatUtils from '../../../utils/formatUtils';
import * as messagesUtils from '../../../utils/messagesUtils';

export interface CreateDataRowInfo {
  originalQualificationList: Array<Model.originalQualificationRow>
  initRegistrationStatus: string
  initStatus: string
}

// state初期化
const initialState: Model.State = {
  belongCompanyDataList: []
  , belongCompanyCriteria: {
    belongCompanyName: ""
    , belongCompanyNameKana: ""
    , sortItem: ""
    , sortOrder: ""
    , offset: 0
    , limit: 10
    , onlyEnableQualification: "0"
  }
  , belongCompanyTotal: 0
  , belongCompanyDataRow: {
    key: ""
    , belongCompanyId: ""
    , belongCompanyName: ""
    , zipcode: ""
    , address: ""
    , numberOfPeople: 0
    , numberOfComfirmedPeople: 0
    , numberOfRequestedPeople: 0
    , numberOfRequestPeople: 0
  }
  , dataList: []
  , dataRow: {
    key: ""
    , managementGroupId: ""
    , managementGroupName: ""
    , workerId: 0
    , firstName: ""
    , lastName: ""
    , birthday: ""
    , primeCertificationNo: ""
    , remarks: ""
    , updateDatetime: ""
    , originalQualificationList: []
    , numberOfQualifications: 0
    , numberOfConfirmedQualifications: 0
    , numberOfRequestedQualifications: 0
    , numberOfRequestQualifications: 0
  }
  , total: 0
  , criteria: {
    belongCompanyId: ""
    , managementGroupId: ""
    , originalQualificationName: ""
    , workerId: 0
    , firstName: ""
    , lastName: ""
    , firstNameKana: ""
    , lastNameKana: ""
    , primeCertificationNo: ""
    , sortItem: ""
    , sortOrder: ""
    , offset: 0
    , limit: 10
    , onlyEnableQualification: "0"
  }
  , managementGroupList: []
  , count: 0
  , managementModalVisibleState: false
  , workerSelectModalVisibleState: false
  , qualificationSelectModalVisibleState: false
  , qualificationSelectTableKey: ""
  , commentVisibleState: false
  , loadingFlg: false
};

// スライサー （state更新アクションの実装）
export const qualificationHoldersSlice = createSlice({
  name: 'primeQualificationHolders',
  initialState,
  reducers: {
    initCriteria: (state) => {
      let criteria = {
        belongCompanyId: ""
        , managementGroupId: ""
        , originalQualificationName: ""
        , workerId: 0
        , firstName: ""
        , lastName: ""
        , firstNameKana: ""
        , lastNameKana: ""
        , primeCertificationNo: ""
        , sortItem: ""
        , sortOrder: ""
        , offset: 0
        , limit: 10
        , onlyEnableQualification: "0"
      }
      let belongCompanyCriteria = {
        belongCompanyName: ""
        , belongCompanyNameKana: ""
        , sortItem: ""
        , sortOrder: ""
        , offset: 0
        , limit: 10
        , onlyEnableQualification: "0"
      }
      state.criteria = criteria;
      state.belongCompanyCriteria = belongCompanyCriteria;
    }
    /** 一覧画面描画時のstate更新 */
    , setBelongCompaniesDataList: (state, action: PayloadAction<Model.getBelongCompaniesDataList.Response>) => {

      let belongCompaniesList: Array<Model.belongCompanyDataRow> = [];

      action.payload.qualificationOwnerPartnerList.forEach((apiDataRow: Model.belongCompanyDataRow) => {

        const rec: Model.belongCompanyDataRow = {
          key: apiDataRow.belongCompanyId
          , belongCompanyId: apiDataRow.belongCompanyId
          , belongCompanyName: apiDataRow.belongCompanyName
          , zipcode: apiDataRow.zipcode
          , address: apiDataRow.address
          , numberOfPeople: apiDataRow.numberOfPeople
          , numberOfComfirmedPeople: apiDataRow.numberOfComfirmedPeople
          , numberOfRequestedPeople: apiDataRow.numberOfRequestedPeople
          , numberOfRequestPeople: apiDataRow.numberOfRequestPeople
        };
        belongCompaniesList.push(rec);
      });

      return Object.assign({}, state, { belongCompanyDataList: belongCompaniesList }, { belongCompanyTotal: action.payload.total })
    }
    /** 所属会社一覧検索条件のstate更新 */
    , setBelongCompanyCriteria: (state, action: PayloadAction<Model.belongCompanyCriteria>) => {
      let belongCompanyCriteria: Model.belongCompanyCriteria = {
        belongCompanyName: action.payload.belongCompanyName,
        belongCompanyNameKana: action.payload.belongCompanyNameKana,
        sortItem: action.payload.sortItem,
        sortOrder: action.payload.sortOrder,
        offset: action.payload.offset,
        limit: action.payload.limit,
        onlyEnableQualification: action.payload.onlyEnableQualification
      }
      return Object.assign({}, state, { belongCompanyCriteria: belongCompanyCriteria })
    }
    /** 独自資格保有者検索条件のstate更新 */
    , setQualificationHolderCriteria: (state, action: PayloadAction<Model.QualificationHolderCriteria>) => {
      let qualificationHolderCriteria: Model.QualificationHolderCriteria = {
        belongCompanyId: action.payload.belongCompanyId,
        managementGroupId: action.payload.managementGroupId,
        originalQualificationName: action.payload.originalQualificationName,
        workerId: action.payload.workerId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        firstNameKana: action.payload.firstNameKana,
        lastNameKana: action.payload.lastNameKana,
        primeCertificationNo: action.payload.primeCertificationNo,
        sortItem: action.payload.sortItem,
        sortOrder: action.payload.sortOrder,
        offset: action.payload.offset,
        limit: action.payload.limit,
        onlyEnableQualification: action.payload.onlyEnableQualification
      }
      return Object.assign({}, state, { criteria: qualificationHolderCriteria })
    },
    /** 所属企業一覧画面行選択時のstate更新 */
    prepareMoveToQualificationHolder: (state, action: PayloadAction<Model.belongCompanyDataRow>) => {
      let criteria: Model.QualificationHolderCriteria = {
        belongCompanyId: action.payload.belongCompanyId,
        managementGroupId: initialState.criteria.managementGroupId,
        originalQualificationName: initialState.criteria.originalQualificationName,
        workerId: initialState.criteria.workerId,
        firstName: initialState.criteria.firstName,
        lastName: initialState.criteria.lastName,
        firstNameKana: initialState.criteria.firstNameKana,
        lastNameKana: initialState.criteria.lastNameKana,
        primeCertificationNo: initialState.criteria.primeCertificationNo,
        sortItem: initialState.criteria.sortItem,
        sortOrder: initialState.criteria.sortOrder,
        offset: initialState.criteria.offset,
        limit: initialState.criteria.limit,
        onlyEnableQualification: state.belongCompanyCriteria.onlyEnableQualification,
      }
      state.belongCompanyDataRow = action.payload;
      state.criteria = criteria;
    }
    /** 独自資格保有者一覧画面描画時のstate更新 */
    , setQualificationHolderDataList: (state, action: PayloadAction<Model.getQualificationHolderDataList.Response>) => {

      let qualificationHoldersList: Array<Model.QualificationHolderDataRow> = [];

      action.payload.qualificationOwnerList.forEach((apiDataRow: Model.QualificationHolderDataRow) => {

        const qualificationHolder: Model.QualificationHolderDataRow = {
          key: apiDataRow.workerId.toString()
          , managementGroupId: apiDataRow.managementGroupId
          , managementGroupName: apiDataRow.managementGroupName
          , workerId: apiDataRow.workerId
          , firstName: apiDataRow.firstName
          , lastName: apiDataRow.lastName
          , birthday: apiDataRow.birthday
          , primeCertificationNo: apiDataRow.primeCertificationNo
          , remarks: apiDataRow.remarks
          , updateDatetime: apiDataRow.updateDatetime
          , originalQualificationList: apiDataRow.originalQualificationList
          , numberOfQualifications: apiDataRow.numberOfQualifications
          , numberOfConfirmedQualifications: apiDataRow.numberOfConfirmedQualifications
          , numberOfRequestedQualifications: apiDataRow.numberOfRequestedQualifications
          , numberOfRequestQualifications: apiDataRow.numberOfRequestQualifications
        };
        qualificationHoldersList.push(qualificationHolder);
      });

      let managementGroupList: Array<Model.managementGroupRow> = [];
      action.payload.managementGroupList.forEach((apiDataRow: Model.managementGroupRow) => {
        const managementGroup: Model.managementGroupRow = {
          key: apiDataRow.managementGroupId
          , managementGroupId: apiDataRow.managementGroupId
          , managementGroupName: apiDataRow.managementGroupName
        };
        managementGroupList.push(managementGroup);
      });
      state.belongCompanyDataRow.belongCompanyName = action.payload.belongCompanyName;
      state.dataList = qualificationHoldersList;
      state.managementGroupList = managementGroupList;
      state.total = action.payload.total;
    },
    /** 一覧画面から詳細画面遷移時のstate更新 */
    prepareMoveToDetail: (state, action: PayloadAction<number>) => {
      let qualificationHolderDataRow: Model.QualificationHolderDataRow = {
        key: "",
        managementGroupId: "",
        managementGroupName: "",
        workerId: action.payload,
        firstName: "",
        lastName: "",
        firstNameKana: "",
        lastNameKana: "",
        birthday: "",
        primeCertificationNo: "",
        remarks: "",
        updateDatetime: "",
        originalQualificationList: [],
        numberOfQualifications: 0, 
        numberOfConfirmedQualifications: 0, 
        numberOfRequestedQualifications: 0, 
        numberOfRequestQualifications: 0
      }
      state.dataRow = qualificationHolderDataRow;
    }
    , setQualificationHolderDataRow: (state, action: PayloadAction<Model.getQualificationHolderDataList.Response>) => {

      let qualificationHolderDataRow: Model.QualificationHolderDataRow = {
        key: action.payload.qualificationOwnerList[0].workerId.toString()
        , managementGroupId: action.payload.qualificationOwnerList[0].managementGroupId
        , managementGroupName: action.payload.qualificationOwnerList[0].managementGroupName
        , workerId: action.payload.qualificationOwnerList[0].workerId
        , firstName: action.payload.qualificationOwnerList[0].firstName
        , lastName: action.payload.qualificationOwnerList[0].lastName
        , birthday: action.payload.qualificationOwnerList[0].birthday
        , primeCertificationNo: action.payload.qualificationOwnerList[0].primeCertificationNo
        , remarks: action.payload.qualificationOwnerList[0].remarks
        , updateDatetime: action.payload.qualificationOwnerList[0].updateDatetime
        , originalQualificationList: action.payload.qualificationOwnerList[0].originalQualificationList
        , numberOfQualifications: action.payload.qualificationOwnerList[0].numberOfQualifications
        , numberOfConfirmedQualifications: action.payload.qualificationOwnerList[0].numberOfConfirmedQualifications
        , numberOfRequestedQualifications: action.payload.qualificationOwnerList[0].numberOfRequestedQualifications
        , numberOfRequestQualifications: action.payload.qualificationOwnerList[0].numberOfRequestQualifications
      };
      let originalQualificationList: Array<Model.originalQualificationRow> = [];
      qualificationHolderDataRow.originalQualificationList.forEach((row: Model.originalQualificationRow) => {
        const originalQualificationRow: Model.originalQualificationRow = {
          key: row.certificationId
          , certificationId: row.certificationId
          , registrationStatus: row.registrationStatus
          , status: row.status
          , originalQualificationId: row.originalQualificationId
          , originalQualificationNo: row.originalQualificationNo
          , originalQualificationName: row.originalQualificationName
          , certificationDate: row.certificationDate
          , certificationCancelDate: row.certificationCancelDate
          , deadlineExist: row.deadlineExist
          , deadlineStartDate: formatUtils.formatDateSlash(row.deadlineStartDate)
          , deadlineEndDate: formatUtils.formatDateSlash(row.deadlineEndDate)
          , newFlg: false
          , deleteFlag: false
          , updateDatetime: row.updateDatetime
        };
        originalQualificationList.push(originalQualificationRow);
      });
      qualificationHolderDataRow.originalQualificationList = originalQualificationList;
      state.dataRow = qualificationHolderDataRow;
      state.belongCompanyDataRow.belongCompanyName = action.payload.belongCompanyName;
    }
    , createDataRow: (state, action: PayloadAction<CreateDataRowInfo>) => {
      let count = state.count + 1;

      let originalQualificationList: Array<Model.originalQualificationRow> = [];
      action.payload.originalQualificationList.forEach((dataRow: Model.originalQualificationRow) => {
        originalQualificationList.push(dataRow);
      });

      const originalQualificationRow: Model.originalQualificationRow = {
        key: (count).toString()
        , certificationId: ""
        , registrationStatus: action.payload.initRegistrationStatus
        , status: action.payload.initStatus
        , originalQualificationId: ""
        , originalQualificationNo: ""
        , originalQualificationName: ""
        , certificationDate: ""
        , certificationCancelDate: ""
        , deadlineExist: ""
        , deadlineStartDate: ""
        , deadlineEndDate: ""
        , newFlg: true
        , deleteFlag: false
        , updateDatetime: ""
      };
      originalQualificationList.push(originalQualificationRow);

      state.dataRow.originalQualificationList = originalQualificationList;
      state.count = count;
    }
    , saveEditedDataList: (state, action: PayloadAction<Array<Model.originalQualificationRow>>) => {
      state.dataRow.originalQualificationList = action.payload;
    }
    , reflectManagementModalState: (state, action: PayloadAction<boolean>) => {
      state.managementModalVisibleState = action.payload;
    }
    , reflectManagementGroup: (state, action: PayloadAction<Model.managementGroupRow>) => {
      const qualificationHolderDataRow: Model.QualificationHolderDataRow = {
        key: state.dataRow.workerId.toString()
        , managementGroupId: action.payload.managementGroupId
        , managementGroupName: action.payload.managementGroupName
        , workerId: state.dataRow.workerId
        , firstName: state.dataRow.firstName
        , lastName: state.dataRow.lastName
        , birthday: state.dataRow.birthday
        , primeCertificationNo: state.dataRow.primeCertificationNo
        , remarks: state.dataRow.remarks
        , updateDatetime: state.dataRow.updateDatetime
        , originalQualificationList: state.dataRow.originalQualificationList
        , numberOfQualifications: state.dataRow.numberOfQualifications
        , numberOfConfirmedQualifications: state.dataRow.numberOfConfirmedQualifications
        , numberOfRequestedQualifications: state.dataRow.numberOfRequestedQualifications
        , numberOfRequestQualifications: state.dataRow.numberOfRequestQualifications
      };
      state.dataRow = qualificationHolderDataRow;
    }
    , reflectWorkerSelectModalState: (state, action: PayloadAction<boolean>) => {
      state.workerSelectModalVisibleState = action.payload;
    }
    , reflectSelectWorker: (state, action: PayloadAction<selectWorker>) => {
      const qualificationHolderDataRow: Model.QualificationHolderDataRow = {
        key: state.dataRow.workerId.toString()
        , managementGroupId: state.dataRow.managementGroupId
        , managementGroupName: state.dataRow.managementGroupName
        , workerId: action.payload.workerId
        , firstName: action.payload.firstName
        , lastName: action.payload.lastName
        , birthday: action.payload.birthday
        , primeCertificationNo: state.dataRow.primeCertificationNo
        , remarks: state.dataRow.remarks
        , updateDatetime: state.dataRow.updateDatetime
        , originalQualificationList: state.dataRow.originalQualificationList
        , numberOfQualifications: state.dataRow.numberOfQualifications
        , numberOfConfirmedQualifications: state.dataRow.numberOfConfirmedQualifications
        , numberOfRequestedQualifications: state.dataRow.numberOfRequestedQualifications
        , numberOfRequestQualifications: state.dataRow.numberOfRequestQualifications
      };
      state.dataRow = qualificationHolderDataRow;
    }
    , reflectQualificationSelectModalState: (state, action: PayloadAction<boolean>) => {
      state.qualificationSelectModalVisibleState = action.payload;
    }
    , reflectQualificationSelectTableKey: (state, action: PayloadAction<string>) => {
      state.qualificationSelectTableKey = action.payload;
    },
    initDataRow: (state) => {
      state.dataRow = initialState.dataRow;
    },
    reflectCommentVisibleState: (state, action: PayloadAction<boolean>) => {
      state.commentVisibleState = action.payload;
    },
    setLoadingFlg: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { loadingFlg: action.payload })
    },
  }
});

export const {
  initCriteria
  , setBelongCompaniesDataList
  , setBelongCompanyCriteria
  , setQualificationHolderCriteria
  , prepareMoveToQualificationHolder
  , setQualificationHolderDataList
  , prepareMoveToDetail
  , setQualificationHolderDataRow
  , createDataRow
  , saveEditedDataList
  , reflectManagementModalState
  , reflectManagementGroup
  , reflectWorkerSelectModalState
  , reflectSelectWorker
  , reflectQualificationSelectModalState
  , reflectQualificationSelectTableKey
  , initDataRow
  , reflectCommentVisibleState
  , setLoadingFlg
} = qualificationHoldersSlice.actions;


//　所属会社一覧検索
export const getBelongCompaniesDataListAsync = (belongCompanyCriteria: Model.getBelongCompaniesDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_COMPANIES, {
      params: belongCompanyCriteria
    });
    // 結果をstateに設定します。
    dispatch(setBelongCompaniesDataList(response.data.data));
    // // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　独自資格保有者一覧検索
export const getQualificationHolderDataListAsync = (criteria: Model.getQualificationHolderDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_LIST, {
      params: criteria
    });
    // 結果をstateに設定します。
    dispatch(setQualificationHolderDataList(response.data.data));
    // // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　独自資格保有者詳細取得
export const getQualificationHolderDataRowAsync = (criteria: Model.getQualificationHolderDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_LIST, {
      params: criteria
    });
    if (response.data.data.total > 1) {
      dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
      return;
    }
    if (response.data.data.total === 0) {
      dispatch(setApiResult({ status: response.status, errorCode: "EA9999000", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
      return;
    }
    // 結果をstateに設定します。
    dispatch(setQualificationHolderDataRow(response.data.data));
    // // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　独自資格保有者詳細取得で選択した従業員が既に登録済みかチェックを行う。
export const checkQualificationHolderDataRowAsync = (criteria: Model.getQualificationHolderDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_LIST, {
      params: criteria
    });
    dispatch(setApiResult({ status: response.status, errorCode: response.data.cmn.errorCode, requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　独自資格保有者編集
export const putDataRowAsync = (putData: Model.putDataList.Request): AppThunk => async dispatch => {
  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_INFO, putData);
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_PUT));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　独自資格保有者削除
export const deleteDataRowAsync = (deleteData: Model.deleteData.Request): AppThunk => async dispatch => {
  try {
    // DELETE-APIを呼び出します。
    const response = await deleteRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_INFO, { data: deleteData });
    if (response.data.cmn.errorCode === "EA9999409") {
      const message = messagesUtils.getMessage("EA1003409");
      messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
      // 共通成功時処理を呼び出します。（メッセージはセッションに入れたので、エラーコードは空で設定）
      dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_DELETE, url: response.config.url }));
      return;
    }
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_DELETE));
    dispatch(initDataRow());
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　独自資格認定状態変更
export const putCertificationStatusAsync = (putData: Model.putCertificationStatusData.Request): AppThunk => async dispatch => {
  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_CERTIFICATION, putData);
    if (response.data.cmn.errorCode !== "") {
      let message = "";
      if (response.data.cmn.errorCode === "EA9999409") {
        message = messagesUtils.getMessage("EA1003409");
      } else {
        message = messagesUtils.getMessage(response.data.cmn.errorCode);
      }
      messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
      // 共通成功時処理を呼び出します。（メッセージはセッションに入れたので、エラーコードは空で設定）
      dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_PUT, url: response.config.url }));
      return;
    }
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_PUT));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedBelongCompanyDataList = (state: RootState) => state.primeQualificationHolders.belongCompanyDataList;
export const storedBelongCompanyDataRow = (state: RootState) => state.primeQualificationHolders.belongCompanyDataRow;
export const storedBelongCompanyCriteria = (state: RootState) => state.primeQualificationHolders.belongCompanyCriteria;
export const storedBelongCompanyTotal = (state: RootState) => state.primeQualificationHolders.belongCompanyTotal;
export const storedDataList = (state: RootState) => state.primeQualificationHolders.dataList;
export const storedDataRow = (state: RootState) => state.primeQualificationHolders.dataRow;
export const storedCriteria = (state: RootState) => state.primeQualificationHolders.criteria;
export const storedTotal = (state: RootState) => state.primeQualificationHolders.total;
export const storedManagementGroupList = (state: RootState) => state.primeQualificationHolders.managementGroupList;
export const storedManagementModalVisibleState = (state: RootState) => state.primeQualificationHolders.managementModalVisibleState;
export const storedWorkerSelectModalVisibleState = (state: RootState) => state.primeQualificationHolders.workerSelectModalVisibleState;
export const storedQualificationSelectModalVisibleState = (state: RootState) => state.primeQualificationHolders.qualificationSelectModalVisibleState;
export const storedQualificationSelectTableKey = (state: RootState) => state.primeQualificationHolders.qualificationSelectTableKey;
export const storedCommentVisibleState = (state: RootState) => state.primeQualificationHolders.commentVisibleState;
export const storedLoadingFlg = (state: RootState) => state.primeQualificationHolders.loadingFlg;


export default qualificationHoldersSlice.reducer;
