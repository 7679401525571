import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router'
import { Layout } from 'antd';
import { DetailForm, AuthButton, AuthButtonAuthorities } from '../../core/CoreForm';
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from '../../core/CorePageContents';
import styles from './QualificationDetail.module.css';
import * as Module from '../../../modules/prime/qualification/qualificationModule';
import * as Model from '../../../modules/prime/qualification/qualificationModel';
import * as CONSTANTS from '../../../constants/constants';
import * as constantsUtils from '../../../utils/constantsUtils';
import * as messagesUtils from '../../../utils/messagesUtils';
import * as formatUtils from '../../../utils/formatUtils';
import { storedApiResult, initApiResult } from '../../../modules/core/bffApiModule';
import { initMessages, message } from '../../../modules/core/commonMessageModule';
import { setMessages } from '../../../modules/core/commonMessageModule';
import AuthButtonStyles from "../../core/css/AuthButton.module.css";

interface titleInterface {
  title: string;
}

export const QualificationDetail = (props: titleInterface) => {

  const dispatch = useDispatch();
  const dataRow = useSelector(Module.storedDataRow);
  const apiResult = useSelector(storedApiResult);
  let history = useHistory();

  useEffect(() => {
    // APIステータスが初期値の場合、詳細情報取得APIを実行します。
    if (CONSTANTS.API_STATUS_INITIAL === apiResult.status && "" === apiResult.requestMethod) {
      let initParam: Model.Criteria = {
        originalQualificationNo: "",
        originalQualificationName: "",
        originalQualificationId: dataRow.originalQualificationId,
        sortItem: "",
        sortOrder: "",
        offset: 0,
        limit: 10,
      }
      dispatch(Module.getDataDetailAsync(initParam));
    }

    // 詳細情報取得API実行後、APIエラーメッセージが設定されていたら一覧画面へ遷移します。
    if (dataRow.key === "" && CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" !== apiResult.errorCode && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod) {
      // API結果メッセージを遷移先画面に表示するために共通メッセージに設定します。
      const setMessage: message = {
        message: messagesUtils.getMessage(apiResult.errorCode)
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setMessages([setMessage]));
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_LIST);
    }

    // 詳細情報取得API実行後、daraRow設定されていない場合一覧画面へ遷移します。
    if (dataRow.key === "" && CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod) {
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_LIST);
    }

    // 独自資格削除API実行後、APIエラーメッセージが設定されていない場合、一覧画面に遷移して削除成功メッセージを表示します。
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_DELETE === apiResult.requestMethod) {
      // 削除失敗メッセージを設定します。
      const setMessage: message = {
        message: messagesUtils.getMessage("SUCCESS_DELETE", ["独自資格"])
        , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
      }
      dispatch(setMessages([setMessage]));
      dispatch(Module.initDataRow());
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_LIST);
    }
  },
    [apiResult]
  );

  const backClicked = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_LIST);
  }

  const editClicked = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_EDIT);
  }

  const deleteClicked = () => {
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_DELETE", ["独自資格"]))) {
      return;
    }
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // API結果情報を初期化します。
    dispatch(initApiResult());

    let initParam: Model.deleteData.Request = {
      originalQualificationId: dataRow.originalQualificationId
      , updateDatetime: dataRow.updateDatetime
    }
    dispatch(Module.deleteDataLsitAsync(initParam));
  }




  // 月手当必要日数部品
  const AllowanceInspection = () => {
    if (dataRow.allowanceInspectionType === CONSTANTS.ALLOWANCE_INSPECTION_TYPE.NONE.code ) {
      if (dataRow.allowanceType === CONSTANTS.ALLOWANCE_TYPE.DAILY.code) {
        return (
          <DetailForm.Item label="集計条件">
            <span>1分以上の労働時間の入力がある作業日を集計対象とする</span>
          </DetailForm.Item>
        )
      } else if (dataRow.allowanceType === CONSTANTS.ALLOWANCE_TYPE.MONTHLY.code) {
        return (
          <DetailForm.Item label="集計条件">
            <span>1分以上の労働時間の入力がある作業日があれば集計対象とする</span>
          </DetailForm.Item>
        )
      }
    } else {
      if (dataRow.allowanceType === CONSTANTS.ALLOWANCE_TYPE.DAILY.code) {
        return (
          <DetailForm.Item label="集計条件">
            <span>{dataRow.allowanceInspectionTime}時間以上の作業日のみ集計対象とする</span>
          </DetailForm.Item>
        )
      } else if (dataRow.allowanceType === CONSTANTS.ALLOWANCE_TYPE.MONTHLY.code) {
        return (
          <DetailForm.Item label="集計条件">
            <span>{dataRow.allowanceInspectionTime}時間以上の作業日が月{dataRow.monthlyAllowanceInspectionDays}日以上ある場合のみ集計対象とする </span>
          </DetailForm.Item>
        )
      }
    }
    return (<></>);
  }


  // 手当金額部品
  const AllowanceAmount = () => {
    if (dataRow.allowanceType !== CONSTANTS.ALLOWANCE_TYPE.NONE.code) {

      let cellingDailyAllowance = <></>;
      if (dataRow.allowanceType === CONSTANTS.ALLOWANCE_TYPE.DAILY.code) {
        cellingDailyAllowance = (
          <DetailForm.Item label="月手当への切り替え">
            {(dataRow.ceilingDailyAllowanceInspectionDays != null
              && dataRow.ceilingDailyAllowanceAmount != null)
              ? <span>労働日数が{ formatUtils.formatNumberComma(dataRow.ceilingDailyAllowanceInspectionDays) } 日以上の場合は定額 {formatUtils.formatNumberComma(dataRow.ceilingDailyAllowanceAmount)} 円となります</span>
              : <span>なし</span>
            }
          </DetailForm.Item>
        );
      }

      return (
        <>
          <DetailForm.Item label="手当金額">
            <span>{formatUtils.formatNumberComma(dataRow.allowanceAmount)}</span>
          </DetailForm.Item>
          { cellingDailyAllowance }
        </>
      );
    }
    return (<></>);
  }


  // 独自資格詳細Formを描画します。
  const DetailFormArea = () => {
    return (
      <>
        <DetailForm className={styles.detailform} >
          <DetailForm.Item label="独自資格番号">
            <span>{dataRow.originalQualificationNo}</span>
          </DetailForm.Item>

          <DetailForm.Item label="独自資格名">
            <span>{dataRow.originalQualificationName}</span>
          </DetailForm.Item>

          <DetailForm.Item label="手当区分">
            <span>{constantsUtils.getLabelOf(CONSTANTS.ALLOWANCE_TYPE, dataRow.allowanceType)}</span>
          </DetailForm.Item>

          <AllowanceInspection />

          <AllowanceAmount />

          <DetailForm.Item label="有効期限">
            <span>{formatUtils.formatDuration(dataRow.deadlineStartDate, dataRow.deadlineEndDate)}</span>
          </DetailForm.Item>

          <DetailForm.Item label="備考１">
            <span className={styles.remarks}>{dataRow.remarks1}</span>
          </DetailForm.Item>

          <DetailForm.Item label="備考２">
            <span className={styles.remarks}>{dataRow.remarks2}</span>
          </DetailForm.Item>
        </DetailForm>
        <br /><br /><br />
      </>
    )
  }

  // フッターボタンエリアを描画します。
  const ButtonArea = () => {
    return (
      <FooterButtonArea>
        <AuthButton
          name={"戻る"}
          size={"large"}
          shape={"round"}
          className={AuthButtonStyles.backButtonFixed}
          onClick={backClicked}
        />
        <AuthButton
          name={"編集"}
          authorities={[
            AuthButtonAuthorities.primeQualificationMngAuth
          ]}
          size={"large"}
          shape={"round"}
          onClick={editClicked}
        />
        <AuthButton
          name={"削除"}
          className={styles.customButtonColor}
          authorities={[
            AuthButtonAuthorities.primeQualificationMngAuth
          ]}
          size={"large"}
          shape={"round"}
          onClick={deleteClicked}
        />
      </FooterButtonArea>
    )
  }

  return (

    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <CommonMessage />
        <DetailFormArea />
      </Content>
      <ButtonArea />
      <Footer />
    </Layout>
  );
}

