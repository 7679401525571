import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Store } from 'antd/lib/form/interface';
import { Layout, Typography, Input, Table } from 'antd';
import { CriteriaForm, AuthButton, AuthButtonAuthorities, Radio } from '../../core/CoreForm';
import { ContentEx as Content, FunctionTitle, CommonMessage } from '../../core/CorePageContents';
import styles from './QualificationSelect.module.css';
import * as Module from '../../../modules/prime/qualificationSelect/qualificationSelectModule';
import * as Model from '../../../modules/prime/qualificationSelect/qualificationSelectModel';
import { initMessages } from '../../../modules/core/commonMessageModule';
import * as formatUtils from '../../../utils/formatUtils';
import * as Utils from '../../../utils/utils';
import * as CONSTANTS from '../../../constants/constants';
import { initApiResult, storedApiResult } from '../../../modules/core/bffApiModule';

const { Title } = Typography;

interface titleInterface {
  title: string;
}

export const QualificationSelect = (props: titleInterface) => {
  //　hoocs
  const dispatch = useDispatch();
  // ステート取得
  const apiResult = useSelector(storedApiResult);
  let dataList = useSelector(Module.storedDataList);
  let dataRow = useSelector(Module.storedDataRow);
  let dataCriteria = useSelector(Module.storedCriteria);
  const totalCnt = useSelector(Module.storedTotal);

  useEffect(() => {
    dispatch(Module.getDataListAsync(dataCriteria));
  },
    [dataCriteria]
  );

  /**
   * 画面アクション
   * 
   */

  // 検索ボタン押下時イベント
  const searchDataList = (value: Store) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "originalQualificationNo", value: value.originalQualificationNo }
      , { key: "originalQualificationName", value: value.originalQualificationName }
      , { key: "offset", value: 0 }
    ]);
  }

  // テーブル検索条件変更時イベント（ページ、表示件数、ソート）
  const handleTableChange = (pagination: any) => {

    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "limit", value: pagination.pageSize }
      , { key: "offset", value: dataCriteria.limit !== pagination.pageSize ? 0 : (pagination.current - 1) * pagination.pageSize }
    ]);
  }

  // CriteriaをStoreに保持
  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let criteria: Model.Criteria = {
      originalQualificationNo: dataCriteria.originalQualificationNo,
      originalQualificationName: dataCriteria.originalQualificationName,
      originalQualificationId: dataCriteria.originalQualificationId,
      sortItem: dataCriteria.sortItem,
      sortOrder: dataCriteria.sortOrder,
      offset: dataCriteria.offset,
      limit: dataCriteria.limit
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      criteria[rec.key] = rec.value;
    })
    dispatch(Module.setQualificationCriteria(criteria));
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_QUALIFICATIONS_PRIME_LIST === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (dataCriteria.offset > 0 && totalCnt > 0 && dataList.length === 0) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(totalCnt, dataCriteria.limit);
      criteriaDataTransfer([
        { key: "offset", value: newOffset }
      ]);
    }
  }

  const reflectSelectValue = (dataRow: Model.dataRow) => {
    // 選択値を保持
    dispatch(Module.reflectModalSelectValue(dataRow));
  }

  /**
   * 描画用メソッド
   * 
   */
  // 有効期限を描画します。
  const renderDeadlineTerm = (value: string, row: Model.dataRow) => {
    return {
      children: (
        formatUtils.formatDuration(row.deadlineStartDate, row.deadlineEndDate)
      )
    }
  };

  // 検索条件エリアを描画します。
  const QualificationCriteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={searchDataList}
        // 初期値はFormに設定する
        initialValues={{
          'originalQualificationNo': dataCriteria.originalQualificationNo
          , 'originalQualificationName': dataCriteria.originalQualificationName
        }}
      >
        <div className={styles.criteriaSearchField}>
          <CriteriaForm.Item name="originalQualificationNo" id="originalQualificationNo">
            <Input
              type="text"
              placeholder="独自資格番号"
              maxLength={CONSTANTS.QUALIFICATION_NO_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="originalQualificationName">
            <Input
              type="text"
              placeholder="独自資格名"
              maxLength={CONSTANTS.QUALIFICATION_NAME_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <AuthButton
            name={"検索"}
            htmlType="submit"
            shape="round"
          />
        </div>
      </CriteriaForm>
    )
  }

  // ラジオボタンのフォーマット（カスタムラジオボタンを使用） 
  const radioFormatter = (value: string, row: Model.dataRow) => {
    return (
      <Radio
        checked={row === dataRow ? true : false}
        value={row.originalQualificationId}
        onChange={() => reflectSelectValue(row)}
      />
    )
  }

  const columns = [
    {
      title: '選択',
      align: 'center' as 'center',
      width: 50,
      render: radioFormatter,
    },
    {
      title: '独自資格番号'
      , dataIndex: 'originalQualificationNo'
      , width: 150
      , align: 'center' as 'center'
      , render: (value: string, row: Model.dataRow) => {
        return {
          children: (
            <div className={styles.columnAlignLeft}>{value}</div>
          )
        }
      }
    },
    {
      title: '独自資格名'
      , dataIndex: 'originalQualificationName'
      , width: 300
      , render: (value: string, row: Model.dataRow) => {
        return {
          children: (
            <div className={styles.columnAlignLeft}>{value}</div>
          )
        }
      }
    },
    {
      title: '有効期限'
      , dataIndex: 'deadlineTerm'
      , width: 200
      , align: 'center' as 'center'
      , render: renderDeadlineTerm
    }
  ];

  // ページングボタンを描画します。
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <AuthButton name='＜' size='small' />;
    }
    if (type === 'next') {
      return <AuthButton name='＞' size='small' />;
    }
    return originalElement
  }

  return (
    <Layout>
      <Content>
        <FunctionTitle title={props.title} />
        <QualificationCriteria />
        <CommonMessage />
        {totalCnt > 0 && dataList.length > 0 ?
          <Table
            columns={columns}
            size='small'
            dataSource={dataList}
            scroll={{ x: 700, y: 250 }}
            pagination={{
              total: totalCnt  //総件数
              , defaultCurrent: 1 //
              , current: dataCriteria.offset / dataCriteria.limit + 1
              , pageSize: dataCriteria.limit //ページサイズを入れる
              , pageSizeOptions: ['5', '10', '20', '50', '100']
              , showSizeChanger: true
              , showTotal: (total, range) => `${total}件中、${dataCriteria.offset + 1}件目から${total <= dataCriteria.offset + dataCriteria.limit ? total : dataCriteria.offset + dataCriteria.limit}件目を表示`
              , itemRender: pageItemRender
              , locale: { items_per_page: '件/ページ' }
            }}
            onChange={(pagination) => handleTableChange(pagination)}
          /> : <></>
        }
      </Content>
    </Layout >
  );
}

