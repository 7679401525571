import React from 'react';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import './App.css';
import './style/customized-antd.css';
import './components/core/css/core.css'
// core
import { AuthRoute } from './components/core/AuthRoute';
import { UseEnd } from './components/core/UseEnd';
import { SystemError } from './components/core/SystemError';
import { Forbidden } from './components/core/Forbidden';
// prime
import { QualificationHoldersCompanies } from './components/prime/qualifications-holders/QualificationHoldersCompanies';
import { QualificationHoldersList } from './components/prime/qualifications-holders/QualificationHoldersList';
import { QualificationHoldersDetail } from './components/prime/qualifications-holders/QualificationHoldersDetail';
import { QualificationHoldersEdit } from './components/prime/qualifications-holders/QualificationHoldersEdit';
import { QualificationList } from './components/prime/qualifications/QualificationList';
import { QualificationDetail } from './components/prime/qualifications/QualificationDetail';
import { QualificationEdit } from './components/prime/qualifications/QualificationEdit';
import { AttendanceDetail as PrimeAttendanceDetail } from './components/prime/attendance/AttendanceDetail';
import { AttendanceDetail as PartnerAttendanceDetail } from './components/partner/attendance/AttendanceDetail';
import { AuthList } from './components/prime/auth/AuthorityList';
import { AuthListEdit } from './components/prime/auth/AuthorityListEdit';
import { PartnerCompanies } from './components/prime/partner-companies/PartnerCompanies';
import { ReportDownload as PrimeReportDownload } from './components/prime/report/ReportDownload';
import { ReportDownload as PartnerReportDownload } from './components/partner/report/ReportDownload';
import { CommonSettingList } from './components/prime/commonSetting/CommonSettingList';
import { CommonSettingEdit } from './components/prime/commonSetting/CommonSettingEdit';

// partner
import { Counter } from './components/partner/counter/Counter';
import { PartnerAuthorityList } from './components/partner/auth/PartnerAuthorityList';
import { PartnerAuthorityListEdit } from './components/partner/auth/PartnerAuthorityListEdit';
import { AttendanceSummaryList } from './components/prime/attendance/AttendanceSummary';
import { AttendanceSummaryList as PartnerAttendanceSummaryList } from './components/partner/attendance/AttendanceSummary';
import { AttendanceMobileSummaryList as PartnerAttendanceMobileSummaryList } from './components/partner/attendance-mobile/AttendanceMobileSummary';
import { AttendanceMobileDetail as PartnerAttendanceMobileDetail } from './components/partner/attendance-mobile/AttendanceMobileDetail';
import { WorkersAttendanceSummaryList } from './components/prime/workerAttendance/WorkerAttendanceSummary';
import { WorkerAttendanceDetail } from './components/prime/workerAttendance/WorkerAttendanceDetail';

import * as CONSTANTS from './constants/constants';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {/* core */}
          <AuthRoute exact path={CONSTANTS.PATH_TOP} />

          <Route exact path={CONSTANTS.PATH_USE_END} >
            <UseEnd />
          </Route>

          <Route exact path={CONSTANTS.PATH_SYSTEMERROR} >
            <SystemError />
          </Route>

          <Route exact path={CONSTANTS.PATH_FORBIDDEN} >
            <Forbidden />
          </Route>

          {/* prime　勤怠実績管理 */}
          <AuthRoute exact path={CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY} >
            <AttendanceSummaryList title="勤怠実績報告 一覧" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PRIME_ATTENDANCE_DETAIL} >
            <PrimeAttendanceDetail title="勤怠実績報告 作業員別明細" />
          </AuthRoute>

          {/* prime　勤怠実績管理(作業者別) */}
          <AuthRoute exact path={CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_SUMMARY} >
            <WorkersAttendanceSummaryList title="勤怠実績報告(作業員別) 一覧" />
          </AuthRoute>

          {<AuthRoute exact path={CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_DETAIL} >
            <WorkerAttendanceDetail title="勤怠実績報告(作業員別) 明細" />
          </AuthRoute>}

          {/* prime　独自資格保有者管理 */}
          <AuthRoute exact path={CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_COMPANIES} >
            <QualificationHoldersCompanies title="独自資格保有者 所属会社一覧" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_LIST} >
            <QualificationHoldersList title="独自資格保有者 一覧" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_ADD} >
            <QualificationHoldersEdit title="独自資格保有者 登録" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_DETAIL} >
            <QualificationHoldersDetail title="独自資格保有者 詳細" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_EDIT} >
            <QualificationHoldersEdit title="独自資格保有者 編集" />
          </AuthRoute>

          {/* prime　独自資格管理 */}
          <AuthRoute exact path={CONSTANTS.PATH_PRIME_QUALIFICATIONS_LIST} >
            <QualificationList title="独自資格 一覧" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PRIME_QUALIFICATIONS_ADD}>
            <QualificationEdit title="独自資格 登録" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PRIME_QUALIFICATIONS_DETAIL}>
            <QualificationDetail title="独自資格 詳細" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PRIME_QUALIFICATIONS_EDIT}>
            <QualificationEdit title="独自資格 編集" />
          </AuthRoute>

          {/* prime　権限管理 */}
          <AuthRoute exact path={CONSTANTS.PATH_PRIME_AUTH_LIST}>
            <AuthList title="ユーザ権限管理 一覧" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PRIME_AUTH_EDIT}>
            <AuthListEdit title="ユーザ権限管理 一覧編集" />
          </AuthRoute>

          {/* prime 協力会社管理*/}
          <AuthRoute exact path={CONSTANTS.PATH_PRIME_PARTNER_COMPANIES}>
            <PartnerCompanies title="協力会社一覧" />
          </AuthRoute>

          {/* prime 帳票出力*/}
          <AuthRoute exact path={CONSTANTS.PATH_PRIME_REPORT_DOWNLOAD}>
            <PrimeReportDownload title="帳票出力" />
          </AuthRoute>

          {/* prime 基本設定 */}
          <AuthRoute exact path={CONSTANTS.PATH_PRIME_SETTING_LIST}>
            <CommonSettingList title="基本設定 一覧" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PRIME_SETTING_EDIT}>
            <CommonSettingEdit title="基本設定 編集" />
          </AuthRoute>


          {/* partner 勤怠管理*/}
          <AuthRoute exact path={CONSTANTS.PATH_PARTNER_ATTENDANCE_SUMMARY}>
            <PartnerAttendanceSummaryList title="勤怠実績報告 一覧" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PARTNER_ATTENDANCE_DETAIL}>
            <PartnerAttendanceDetail title="勤怠実績報告 作業員別明細" />
          </AuthRoute>

          {/* partner 勤怠管理(スマホ用)*/}
          <AuthRoute exact path={CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_SUMMARY}>
            <PartnerAttendanceMobileSummaryList title="勤怠実績報告 一覧" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_DETAIL}>
            <PartnerAttendanceMobileDetail title="勤怠実績報告 作業員別明細" />
          </AuthRoute>

          {/* partner 勤怠帳票出力*/}
          <AuthRoute exact path={CONSTANTS.PATH_PARTNER_REPORT_DOWNLOAD}>
            <PartnerReportDownload title="帳票出力" />
          </AuthRoute>

          {/* partner 権限管理 */}
          <AuthRoute exact path={CONSTANTS.PATH_PARTNER_AUTH_LIST}>
            <PartnerAuthorityList title="ユーザ権限管理 一覧" />
          </AuthRoute>

          <AuthRoute exact path={CONSTANTS.PATH_PARTNER_AUTH_EDIT}>
            <PartnerAuthorityListEdit title="ユーザ権限管理 一覧編集" />
          </AuthRoute>

          {/* <Route path='/'>
            <>
              <div>URLがまちがっています。</div>
              <div>メモリが壊れた人用にリンクを用意します。</div>
              <br />
              <br />
              <br />
              <div>◇◇core</div>
              <Link to={CONSTANTS.URL_CMN_TERMS}>サービス利用約款　同意</Link><br />
              <br />
              <br />
              <div>◆◇prime 勤怠実績管理（プロジェクト別）</div>
              <Link to={CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY}>勤怠実績報告（プロジェクト別） 一覧</Link><br />
              <Link to={CONSTANTS.PATH_PRIME_ATTENDANCE_DETAIL}>勤怠実績報告（プロジェクト別） 明細</Link><br />
              <br />
              <br />
              <div>◆◇prime 勤怠実績管理（作業者別）</div>
              <Link to={CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_SUMMARY}>勤怠実績報告（作業者別） 一覧</Link><br />
              <Link to={CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_DETAIL}>勤怠実績報告（作業者別） 明細</Link><br />
              <br />
              <br />
              <div>◆◇prime　独自資格保有者管理</div>
              <Link to={CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_COMPANIES}>独自資格保有者 協力会社一覧</Link><br />
              <Link to={CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_LIST}>独自資格保有者 一覧</Link><br />
              <Link to={CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_ADD}>独自資格保有者 登録</Link><br />
              <Link to={CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_DETAIL}>独自資格保有者 詳細</Link><br />
              <Link to={CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_EDIT}>独自資格保有者 編集</Link><br />
              <br />
              <br />
              <div>◆◇prime　独自資格管理</div>
              <Link to={CONSTANTS.PATH_PRIME_QUALIFICATIONS_LIST}>独自資格 一覧</Link><br />
              <Link to={CONSTANTS.PATH_PRIME_QUALIFICATIONS_ADD}>独自資格 登録</Link><br />
              <Link to={CONSTANTS.PATH_PRIME_QUALIFICATIONS_DETAIL}>独自資格 詳細</Link><br />
              <Link to={CONSTANTS.PATH_PRIME_QUALIFICATIONS_EDIT}>独自資格 編集</Link><br />
              <br />
              <br />
              <div>◆◇prime　権限管理</div>
              <Link to={CONSTANTS.PATH_PRIME_AUTH_LIST}>ユーザ権限管理 一覧</Link><br />
              <Link to={CONSTANTS.PATH_PRIME_AUTH_EDIT}>ユーザ権限管理 一覧編集</Link><br />
              <br />
              <br />
              <div>◆◇prime　帳票出力</div>
              <Link to={CONSTANTS.PATH_PRIME_REPORT_DOWNLOAD}>帳票出力</Link><br />
              <br />
              <br />
              <div>◆◆partner　勤怠管理</div>
              <Link to={CONSTANTS.PATH_PARTNER_ATTENDANCE_SUMMARY}>勤怠実績報告 支店一覧</Link><br />
              <Link to={CONSTANTS.PATH_PARTNER_ATTENDANCE_DETAIL}>勤怠実績報告 作業者別明細</Link><br />
              <br />
              <br />
              <div>◆◆partner　勤怠帳票出力</div>
              <Link to={CONSTANTS.PATH_PARTNER_REPORT_DOWNLOAD}>勤怠帳票出力</Link><br />
              <br />
              <br />
              <div>◆◆partner　権限管理</div>
              <Link to={CONSTANTS.PATH_PARTNER_AUTH_LIST}>権限 一覧</Link><br />
              <Link to={CONSTANTS.PATH_PARTNER_AUTH_EDIT}>権限 一覧編集</Link><br />
              <br />
              <br />
              <br />
              <br />
            </>
          </Route> */}

        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
