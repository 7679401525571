import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router"
import { Layout, Table, Input, Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import { Header, ContentEx as Content, FunctionTitle, CommonMessage, FooterButtonArea, Footer } from "../../core/CorePageContents";
import * as Module from "../../../modules/prime/attendance/attendanceModule";
import { CriteriaForm, Checkbox, AuthButton, DatePicker, MonthPicker, AuthButtonAuthorities } from "../../core/CoreForm";
import styles from "./AttendanceSummary.module.css";
import { Store } from "antd/lib/form/interface";
import { initMessages, setMessages, message } from "../../../modules/core/commonMessageModule";
import { SortOrder } from "antd/lib/table/interface";
import * as Model from "../../../modules/prime/attendance/attendanceModel";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment, { Moment } from "moment";
import * as CONSTANTS from "../../../constants/constants";
import * as messagesUtils from "../../../utils/messagesUtils";
import * as formatUtils from "../../../utils/formatUtils";
import * as Utils from '../../../utils/utils';
import { setApiResult, storedApiResult, initApiResult } from "../../../modules/core/bffApiModule";

interface titleInterface {
  title: string;
}

const EXIST_SUBMITED = "提出済あり"
const EXIST_CONFIRMED = "確認済あり"
const EXIST_APPROVED = "承認済あり"

export const AttendanceSummaryList = (props: titleInterface) => {

  const dispatch = useDispatch();
  const apiResult = useSelector(storedApiResult);
  let dataList = useSelector(Module.storedDataList);
  let allSelect: boolean = useSelector(Module.storedSummaryAllSelect);
  let existChecked = useSelector(Module.storedExsitCheckedSummary);
  let existAvailableRow = useSelector(Module.storedExsitAvailabletRowSummary);
  let total = useSelector(Module.storedSummaryTotal);
  let criteria = useSelector(Module.storedSummaryCriteria);
  let history = useHistory();

  useEffect(() => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    dispatch(Module.getDataListAsync(criteria));
  },
    [criteria]
  );


  // 承認依頼メール送信API実行後、APIエラーメッセージが設定されていない場合、送信完了メッセージを表示し、一覧の再建策を行います。
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_POST === apiResult.requestMethod) {

    // API結果メッセージを遷移先画面に表示するために共通メッセージに設定します。
    const setMessage: message = {
      message: messagesUtils.getMessage("SUCCESS_ATTENDANCE_SEND_MAIL_PROCESS")
      , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
    }
    dispatch(setMessages([setMessage]));
    // API結果情報を初期化します。
    dispatch(initApiResult());
    dispatch(Module.getDataListAsync(criteria));
  }

  const moveToDetail = (record: Model.WorkRecordSummaryRow) => {

    dispatch(Module.prepareMoveToDetail(record));
    dispatch(Module.prepareMoveToDetailCriteria(criteria));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    history.push(CONSTANTS.PATH_PRIME_ATTENDANCE_DETAIL);
  }

  //画面遷移時のパラメータにメッセージが設定されている場合、そのメッセージを表示
  const location = useLocation<message>();
  if (location.state !== undefined) {
    const receivedMessage : message = {
      message: location.state.message
      , messageType: location.state.messageType
    }
    dispatch(setMessages([receivedMessage]));
  }

  /**
  * 一次協力会社名の一覧の整形
  * @param text
  * @param row
  */
  const renderfirstPartnerCompanyName = (text: string, row: Model.WorkRecordSummaryRow) => {

    let firstPartnerCompanyNames: Array<any> = [];
    let promptTextList: Array<any> = [];

    let index = 0;
    row.firstPartnerCompanyList.forEach((partnerRow: Model.FirstPartnerCompanyRow) => {
      if (row.firstPartnerCompanyList.length >= 4 && index === 2) {
        // 一次協力会社が4つ以上ある場合、3件目以降は「...」で表示する
        firstPartnerCompanyNames.push(<div key={row.key + index} className={styles.columnAlignLeft}>...</div>);
      } else if (row.firstPartnerCompanyList.length < 4 || index < 3) {
        firstPartnerCompanyNames.push(
          <div key={row.key + index} className={styles.columnAlignLeft}>{partnerRow.firstPartnerCompanyName}</div>
        );
      }

      // Tooltipに表示するメッセージの作成
      promptTextList.push(<div>{partnerRow.firstPartnerCompanyName}</div>);
      index++;
    });

    if (row.firstPartnerCompanyList.length >= 4) {
      return {
        children: (<Tooltip placement="topLeft" title={promptTextList}><div>{firstPartnerCompanyNames}</div></Tooltip>)
      }
    } else {
      return {
        children: firstPartnerCompanyNames
      }
    }
  };

  /**
 * チェックボックス変更時イベント
 * @param e 
 * @param row 
 */
  const onChangeCheck = (e: CheckboxChangeEvent, row: Model.WorkRecordSummaryRow) => {
    let transfer: Module.changeCheckTransferSummary = {
      targetDataRow: row,
      value: e.target.checked
    }

    dispatch(Module.onChangeRowSelectSummary(transfer));
  }

  const columns = [
    {
      title: (
        <>
          <span>承認依頼</span><br />
          <span>メール</span><br />
          <Checkbox
            checked={allSelect}
            onChange={(e) => dispatch(Module.onChangeAllSelectSummary(e.target.checked))}
            style={{ display: `${existAvailableRow ? "inline" : "none"}` }}
          >
          </Checkbox>
        </>
      ),
      onHeaderCell: () => ({
        onClick() {
          if (existAvailableRow) {
            dispatch(Module.onChangeAllSelectSummary(!allSelect));
          }
        },
      }),
      width: 100,
      align: "center" as "center",
      sorter: false,
      onCell: (row: Model.WorkRecordSummaryRow) => ({
        onClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
          e.stopPropagation();
          if ((row.submitted > 0 || row.confirmed > 0) && row.editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_AVAILABLE) {
            let transfer: Module.changeCheckTransferSummary = {
              targetDataRow: row,
              value: !row.selectFlag
            }
            dispatch(Module.onChangeRowSelectSummary(transfer));
          }
        },
      }),
      render: (text: string, row: Model.WorkRecordSummaryRow) => {
        if ((row.submitted > 0 || row.confirmed > 0) && row.editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_AVAILABLE) {
          return (
            {
              props: {

              },
              children: (
                <Checkbox
                  checked={row.selectFlag}
                  onChange={(e) => onChangeCheck(e, row)}
                >
                </Checkbox >
              )
            }
          )
        } else {
          return (<></>)
        }
      }
    },
    {
      title: "プロジェクト",
      key: "1",
      width: 400,
      dataIndex: "projectName",
      sortOrder: (criteria.sortItem !== "1") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordSummaryRow) => {
        return (
          <>
            <div className={styles.columnAlignLeft}>{text}</div>
          </>
        )
      },
    },
    {
      title: "一次協力会社",
      key: "2",
      width: 400,
      sortOrder: (criteria.sortItem !== "2") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
      render: renderfirstPartnerCompanyName
    },
    {
      title: "勤怠年月",
      key: "3",
      width: 200,
      sortOrder: (criteria.sortItem !== "3") ? null : criteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder,
      sorter: true,
      showSorterTooltip: false,
      align: "center" as "center",
      render: (text: string, row: Model.WorkRecordSummaryRow) => {
        return (
          <>
            {<div >{formatUtils.formatMonthChar(row.attendanceYearMonth)}</div>}
          </>
        )
      }
    },
    {
      title: "勤怠実績　提出状況",
      width: 300,
      showSorterTooltip: false,
      children: [{
        title: "提出済",
        width: 100,
        dataIndex: "submitted",
        align: "center" as "center",
        render: (test: string, row: Model.WorkRecordSummaryRow) => {
          return (
            {
              props: {
                className: row.submitted > 0 ? styles.existSubmitOrConfirmed : {},
              },
              children: (<div>{test}</div>)
            }
          )
        }
      },
      {
        title: "確認済",
        width: 100,
        align: "center" as "center",
        dataIndex: "confirmed",
        render: (test: string, row: Model.WorkRecordSummaryRow) => {
          return (
            {
              props: {
                className: row.confirmed > 0 ? styles.existSubmitOrConfirmed : {},
              },
              children: (<div>{test}</div>)
            }
          )
        }
      },
      {
        title: "承認済",
        width: 100,
        align: "center" as "center",
        dataIndex: "approved",
      }]
    },
  ];

  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let newCriteria: Model.WorkRecordSummaryCriteria = {
      projectName: criteria.projectName,
      firstPartnerCompanyName: criteria.firstPartnerCompanyName,
      firstPartnerCompanyNameKana: criteria.firstPartnerCompanyNameKana,
      fromYYYYMM: criteria.fromYYYYMM,
      toYYYYMM: criteria.toYYYYMM,
      notSubmitted: "0",
      submitted: criteria.submitted,
      confirmed: criteria.confirmed,
      approved: criteria.approved,
      sortItem: criteria.sortItem,
      sortOrder: criteria.sortOrder,
      offset: criteria.offset,
      limit: criteria.limit,
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      newCriteria[rec.key] = rec.value;
    })
    dispatch(Module.setSummaryCriteria(newCriteria));
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PRIME_SUMMARY === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (criteria.offset > 0 && total > 0 && (dataList.length === 0 || dataList[0].key === '')) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(total, criteria.limit);
      criteriaDataTransfer([
        { key: "offset", value: newOffset }
      ]);
    }
  }


  // 検索ボタン押下時アクション
  const pushSerchButton = (value: Store) => {

    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "projectName", value: value.projectName }
      , { key: "firstPartnerCompanyName", value: value.firstPartnerCompanyName }
      , { key: "firstPartnerCompanyNameKana", value: value.firstPartnerCompanyNameKana }
      , { key: "fromYYYYMM", value: value.fromYYYYMM === null ? "" : formatUtils.formatMonthNonSlash(value.fromYYYYMM) }
      , { key: "toYYYYMM", value: value.toYYYYMM === null ? "" : formatUtils.formatMonthNonSlash(value.toYYYYMM) }
      , { key: "submitted", value: value.submitted === true ? "1" : "0" }
      , { key: "confirmed", value: value.confirmed === true ? "1" : "0" }
      , { key: "approved", value: value.approved === true ? "1" : "0" }
      , { key: "offset", value: 0 }
    ]);
  }

  // Fromの日付を計算
  const setFromDate = () => {
    if (criteria.fromYYYYMM === "") {
      return null;
    }

    return formatUtils.getMonthMomentOf(formatUtils.formatMonthSlash(criteria.fromYYYYMM));
  }

  // toの日付を計算
  const setToDate = () => {
    if (criteria.toYYYYMM === "") {
      return null;
    }

    return formatUtils.getMonthMomentOf(formatUtils.formatMonthSlash(criteria.toYYYYMM));
  }

  // 検索フォーム
  const AttendanceCriteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={pushSerchButton}
        initialValues={{
          "projectName": criteria.projectName
          , "firstPartnerCompanyName": criteria.firstPartnerCompanyName
          , "firstPartnerCompanyNameKana": criteria.firstPartnerCompanyNameKana
          , "fromYYYYMM": setFromDate()
          , "toYYYYMM": setToDate()
          , "submitted": criteria.submitted === "1" ? true : false
          , "confirmed": criteria.confirmed === "1" ? true : false
          , "approved": criteria.approved === "1" ? true : false
        }}
      >
        <div className={styles.criteriaSearchFieldRight}>
          <CriteriaForm.Item name="projectName">
            <Input type="text"
              placeholder="プロジェクト名"
              maxLength={CONSTANTS.PROJECT_NAME_MAX_LENGTH}
              style={{ width: "140px" }} />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="firstPartnerCompanyName">
            <Input type="text"
              placeholder="一次協力会社名"
              maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
              style={{ width: "140px" }} />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="firstPartnerCompanyNameKana">
            <Input type="text"
              placeholder="一次協力会社名カナ"
              maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
              style={{ width: "140px" }} />
          </CriteriaForm.Item>
          <div className={styles.duration}>
            <div >
              <CriteriaForm.Item name="fromYYYYMM"
                rules={[
                  ({ getFieldValue, validateFields }) => ({
                    validator(rule, value) {
                      if (getFieldValue("toYYYYMM") !== null) {
                        validateFields(["toYYYYMM"]);
                      }
                      return Promise.resolve();
                    }
                  })
                ]}>
                <MonthPicker placeholder="勤怠年月(From)" format="YYYY/MM"
                  style={{ width: "140px" }} />
              </CriteriaForm.Item>
              <div className={styles.durationSpan}>～</div>
              <CriteriaForm.Item name="toYYYYMM"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value === null || getFieldValue("fromYYYYMM") === null) {
                        return Promise.resolve();
                      }
                      if (getFieldValue("fromYYYYMM").isAfter(value, 'month')) {
                        return Promise.reject(messagesUtils.getMessage("ERROR_FROM_TO_DATE_RANGE", ["勤怠年月(From)", "勤怠年月(To)"]));
                      }

                      // To - From が1年以内でなければエラー
                      if ((value as Moment).diff(getFieldValue("fromYYYYMM"), "month") > 11) {
                        return Promise.reject(messagesUtils.getMessage("ERROR_FROM_TO_DATE_PERIOD"));
                      }
                      return Promise.resolve();
                    }
                  })

                ]}>
                <MonthPicker placeholder="勤怠年月(To)" format="YYYY/MM"
                  style={{ width: "140px" }} />
              </CriteriaForm.Item>
            </div>
            <div >※未入力の場合、過去一年分の検索となります</div>
          </div>

          <CriteriaForm.Item name="submitted" valuePropName="checked">
            <Checkbox>
              {EXIST_SUBMITED}
            </Checkbox>
          </CriteriaForm.Item>
          <CriteriaForm.Item name="confirmed" valuePropName="checked">
            <Checkbox>
              {EXIST_CONFIRMED}
            </Checkbox>
          </CriteriaForm.Item>
          <CriteriaForm.Item name="approved" valuePropName="checked">
            <Checkbox>
              {EXIST_APPROVED}
            </Checkbox>
          </CriteriaForm.Item>
          <AuthButton
            name={"検索"}
            shape={"round"}
            htmlType="submit"
          />

        </div>
      </CriteriaForm>
    )
  }

  const onClickProcess = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // 確認ダイアログの表示
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_ATTENDANCE_SEND_MAIL"))) {
      return;
    }
    // API結果情報を初期化します。
    dispatch(initApiResult());

    // リクエストデータの作成
    let destList: Array<Model.postMail.Dest> = [];
    dataList.forEach((data: Model.WorkRecordSummaryRow) => {
      if (data.selectFlag) {
        // 状態が「確認済」または「未承認」 かつ 選択されている
        let dest: Model.postMail.Dest = {
          projectId: data.projectId,
          yearMonth: data.attendanceYearMonth
        }

        destList.push(dest);
      }
    });

    let postData: Model.postMail.Request = {
      projectInfoList: destList
    }

    dispatch(Module.postAttendanceMailAsync(postData));

  }


  /** ボタン部 */
  const AttendanceButton = () => {
    return (
      <FooterButtonArea>
        <AuthButton
          name={"承認依頼メール送信"}
          size={"large"}
          shape={"round"}
          onClick={() => onClickProcess()}
          disabled={existChecked ? false : true}
          style={{ margin: "10px" }}
          authorities={[AuthButtonAuthorities.primeAvailableAuth]}
        />
      </FooterButtonArea>
    );
  }

  /**
 * ページングボタンを描画
 * @param page 
 * @param type 
 * @param originalElement 
 */
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === "prev") {
      return <AuthButton name="＜" size="small" />;
    }
    if (type === "next") {
      return <AuthButton name="＞" size="small" />;
    }
    return originalElement
  }

  /**
 * テーブル検索条件変更時イベント（ページ、表示件数）
 * @param pagination 
 * @param filters 
 */
  const handleTableChange = (pagination: any, sorter: any) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let offsetReset: boolean = false;

    let order = "";
    if (sorter.order === "ascend") {
      order = "0";
    } else if (sorter.order === "descend") {
      order = "1";
    }
    let sortItem = sorter.columnKey
    if (typeof sorter.column === "undefined") {
      sortItem = "";
    }

    if (criteria.limit !== pagination.pageSize ||
      criteria.sortItem !== sortItem ||
      criteria.sortOrder !== order) {
      offsetReset = true;
    }

    criteriaDataTransfer([
      { key: "sortItem", value: sortItem }
      , { key: "sortOrder", value: order }
      , { key: "limit", value: pagination.pageSize }
      , { key: "offset", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
    ])
  }

  return (
    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <AttendanceCriteria />
        <CommonMessage searchNoDataName="プロジェクト" />
        {
          total > 0 && dataList.length > 0 && dataList[0].key !== '' ?
            <Table
              size="small"
              scroll={{ x: 1000 }}
              columns={columns}
              dataSource={dataList}
              pagination={{
                total: total
                , defaultPageSize: 10
                , current: criteria.offset / criteria.limit + 1
                , pageSize: criteria.limit
                , pageSizeOptions: ["5", "10", "20", "50", "100"]
                , showSizeChanger: true
                , showTotal: (total, range) => `${total}件中、${criteria.offset + 1}件目から${total <= criteria.offset + criteria.limit ? total : criteria.offset + criteria.limit}件目を表示`
                , itemRender: pageItemRender
                , locale: { items_per_page: "件/ページ" }
              }}
              onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    moveToDetail(record)
                  }
                };
              }
              }
            >
            </Table>
            : <></>
        }
      </Content>
      <AttendanceButton />
      <Footer />
    </Layout>
  );
}
