import { IUserInfo } from "../../../modules/core/authModule";
import {
    IComponentPermissionSettings
    , componentsPermissionSettings
    , initialPermissionSettings
} from "../../../settings/componentsPermissionSettings";

// パスに対して認可判定を行います。
export const isAllow = (path: any, loginUserInfo: IUserInfo) => {

    // 認可設定を特定します。
    const targetComponentPS = getComponentPS(path);

    // 特定できなければ認可NGとします。
    if (targetComponentPS.menuItemId === "") {
        return false;
    }

    // 元請アクセス権限 認可チェック
    // if (targetComponentPS.primeAccessAuth &&
    //     loginUserInfo.authorityInfo.primeAccessAuth === "1") {
    //     return true;
    // }
    // 元請利用者権限 認可チェック
    if (targetComponentPS.primeAvailableAuth &&
        loginUserInfo.authorityInfo.primeAvailableAuth === "1") {
        return true;
    }
    // 元請_勤怠参照役割保持者 認可チェック
    if (targetComponentPS.primeAttendanceReffenceRoleAuth &&
        loginUserInfo.authorityInfo.primeAttendanceReffenceRoleAuth === "1") {
        return true;
    }
    // 元請_勤怠承認役割保持者 認可チェック
    if (targetComponentPS.primeAttendanceApprovalRoleAuth &&
        loginUserInfo.authorityInfo.primeAttendanceApprovalRoleAuth === "1") {
        return true;
    }
    // 元請勤怠承認者権限 認可チェック
    if (targetComponentPS.primeAttendanceApprovalAuth &&
        loginUserInfo.authorityInfo.primeAttendanceApprovalAuth === "1") {
        return true;
    }
    // 元請資格管理者権限 認可チェック
    if (targetComponentPS.primeQualificationMngAuth &&
        loginUserInfo.authorityInfo.primeQualificationMngAuth === "1") {
        return true;
    }
    // 元請システム管理者権限 認可チェック
    if (targetComponentPS.primeSystemMngAuth &&
        loginUserInfo.authorityInfo.primeSystemMngAuth === "1") {
        return true;
    }
    // 元請_資格承認フロー_申請権限 認可チェック
    if (targetComponentPS.primeCertificationFlowRequestAuth &&
        loginUserInfo.authorityInfo.primeCertificationFlowRequestAuth === "1") {
        return true;
    }
    // 元請_資格承認フロー_確認権限 認可チェック
    if (targetComponentPS.primeCertificationFlowConfirmAuth &&
        loginUserInfo.authorityInfo.primeCertificationFlowConfirmAuth === "1") {
        return true;
    }
    // 元請_資格承認フロー_承認権限 認可チェック
    if (targetComponentPS.primeCertificationFlowApproveAuth &&
        loginUserInfo.authorityInfo.primeCertificationFlowApproveAuth === "1") {
        return true;
    }

    // // 協力アクセス権限 認可チェック
    // if (targetComponentPS.partnerAccessAuth &&
    //     loginUserInfo.authorityInfo.partnerAccessAuth === "1") {
    //     return true;
    // }
    // 協力利用者権限 認可チェック
    if (targetComponentPS.partnerAvailableAuth &&
        loginUserInfo.authorityInfo.partnerAvailableAuth === "1") {
        return true;
    }
    // 協力勤怠提出者 認可チェック
    if (targetComponentPS.partnerAttendanceSubmitAuth &&
        loginUserInfo.authorityInfo.partnerAttendanceSubmitAuth === "1") {
        return true;
    }
    // 協力システム管理者 認可チェック
    if (targetComponentPS.partnerSystemMngAuth &&
        loginUserInfo.authorityInfo.partnerSystemMngAuth === "1") {
        return true;
    }

    return false;
}

// 指定パスのメニューIDを取得します。
export const getMenuItemId = (path: any) => {
    const componentPS = getComponentPS(path);
    return componentPS.menuItemId;
}

// パスの認可設定を取得します。
const getComponentPS = (path: any) => {

    // TOP-URL以外が指定された場合
    const targetComponentPS = componentsPermissionSettings.find((componentPS: IComponentPermissionSettings) =>
        path === componentPS.url
    );

    // 特定できなければ初期設定を返却します。
    if (typeof targetComponentPS === "undefined") {
        return initialPermissionSettings;
    }

    return targetComponentPS;
}

