import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { Layout, Typography, Input, Table, Select } from 'antd';
import { CriteriaForm, AuthButton, AuthButtonAuthorities, Checkbox } from '../../core/CoreForm';
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from '../../core/CorePageContents';
import styles from './QualificationHoldersList.module.css';
import * as Module from '../../../modules/prime/qualifications-holders/qualificationHoldersModule';
import * as Model from '../../../modules/prime/qualifications-holders/qualificationHoldersModel';
import { initMessages } from '../../../modules/core/commonMessageModule';
import * as CONSTANTS from '../../../constants/constants';
import * as formatUtils from '../../../utils/formatUtils';
import * as Utils from '../../../utils/utils';
import { SortOrder } from 'antd/lib/table/interface';
import AuthButtonStyles from "../../core/css/AuthButton.module.css";
import { initApiResult, storedApiResult } from '../../../modules/core/bffApiModule';
import { storedLoginUserInfo } from '../../../modules/core/authModule'

interface titleInterface {
  title: string;
}

export const QualificationHoldersList = (props: titleInterface) => {
  //　hoocs
  const dispatch = useDispatch();
  const history = useHistory();
  // ステート取得
  const apiResult = useSelector(storedApiResult);
  const loginUserInfo = useSelector(storedLoginUserInfo);
  let dataList = useSelector(Module.storedDataList);
  let dataCriteria = useSelector(Module.storedCriteria);
  let managementGroupList = useSelector(Module.storedManagementGroupList);
  const belongCompanyData = useSelector(Module.storedBelongCompanyDataRow);
  const totalCnt = useSelector(Module.storedTotal);

  useEffect(() => {
    if (dataCriteria.belongCompanyId === "") {
      // 共通メッセージ情報を初期化します。
      dispatch(initMessages());
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_COMPANIES);
      return;
    }

    // API結果情報を初期化します。
    dispatch(initApiResult());
    dispatch(Module.getQualificationHolderDataListAsync(dataCriteria));
  },
    [dataCriteria]
  );

  /**
   * 画面アクション
   * 
   */
  //　行選択時アクション
  const moveToDetail = (record: Model.QualificationHolderDataRow) => {
    // 共通メッセージ情報を初期化します。
    dispatch(Module.prepareMoveToDetail(record.workerId));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // 詳細画面へ遷移します。
    history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_DETAIL);
  }

  //　戻るボタン押下時アクション
  const backClicked = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_COMPANIES);
  }

  //　登録ボタン押下時アクション
  const addClicked = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // 独自資格一覧行定義のstateを初期化します。
    dispatch(Module.initDataRow());
    // 登録画面へ遷移します。
    history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_ADD);
  }

  // 検索ボタン押下時イベント
  const searchDataList = (value: Store) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "managementGroupId", value: value.managementGroupId }
      , { key: "originalQualificationName", value: value.originalQualificationName }
      , { key: "workerId", value: value.workerId }
      , { key: "firstName", value: value.firstName }
      , { key: "lastName", value: value.lastName }
      , { key: "firstNameKana", value: value.firstNameKana }
      , { key: "lastNameKana", value: value.lastNameKana }
      , { key: "primeCertificationNo", value: value.primeCertificationNo }
      , { key: "offset", value: 0 }
      , { key: "onlyEnableQualification", value: value.onlyEnableQualification === true ? "1" : "0" }
    ]);
  }

  // テーブル検索条件変更時イベント（ページ、表示件数、ソート）
  const handleTableChange = (pagination: any, sort: any) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let order = "";
    if (sort.order === "ascend") {
      order = "0";
    } else if (sort.order === "descend") {
      order = "1";
    }
    let sortItem = sort.columnKey
    if (typeof sort.column === "undefined") {
      sortItem = "";
    }

    let offsetReset: boolean = false;

    if (dataCriteria.limit !== pagination.pageSize ||
      dataCriteria.sortItem !== sortItem ||
      dataCriteria.sortOrder !== order) {
      offsetReset = true;
    }

    criteriaDataTransfer([
      { key: "sortItem", value: sortItem }
      , { key: "sortOrder", value: order }
      , { key: "limit", value: pagination.pageSize }
      , { key: "offset", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
    ]);
  }

  // CriteriaをStoreに保持
  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let criteria: Model.QualificationHolderCriteria = {
      belongCompanyId: dataCriteria.belongCompanyId,
      managementGroupId: dataCriteria.managementGroupId,
      originalQualificationName: dataCriteria.originalQualificationName,
      workerId: dataCriteria.workerId,
      firstName: dataCriteria.firstName,
      lastName: dataCriteria.lastName,
      firstNameKana: dataCriteria.firstNameKana,
      lastNameKana: dataCriteria.lastNameKana,
      primeCertificationNo: dataCriteria.primeCertificationNo,
      sortItem: dataCriteria.sortItem,
      sortOrder: dataCriteria.sortOrder,
      offset: dataCriteria.offset,
      limit: dataCriteria.limit,
      onlyEnableQualification: dataCriteria.onlyEnableQualification
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      criteria[rec.key] = rec.value;
    })
    dispatch(Module.setQualificationHolderCriteria(criteria));
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_LIST === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (dataCriteria.offset > 0 && totalCnt > 0 && dataList.length === 0) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(totalCnt, dataCriteria.limit);
      criteriaDataTransfer([
        { key: "offset", value: newOffset }
      ]);
    }
  }

  /**
   * 描画用メソッド
   * 
   */

  // 数値型にフォーマットします。 
  const renderFormattedNumber = (value: number, row: Model.QualificationHolderDataRow) => {
    const formattedValue = formatUtils.formatNumberComma(value);
    return {
      children: (
        <div className={styles.columnAlignRight}>{formattedValue}</div>
      )
    }
  }

  // 登録ボタン表示権限有無を判定します。
  const isDisplayOperationButton = () => {
    if (loginUserInfo.authorityInfo.primeQualificationMngAuth === "0") {
      // 資格管理者権限を持っていない場合、非表示
      return false;
    }
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_ALL.code) {
      // 独自資格付与フロー設定が「資格の登録のみ行う（デフォルト）」の場合、表示
      return true;
    } else if (loginUserInfo.authorityInfo.primeCertificationFlowRequestAuth === "1") {
      // 独自資格付与フロー設定が「資格の登録のみ行う（デフォルト）」以外の場合、申請権限を持っていれば、表示
      return true;
    } else {
      return false;
    }
  }

  // 検索条件エリアを描画します。
  const QualificationCriteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={searchDataList}
        // 初期値はFormに設定する
        initialValues={{
          'managementGroupId': dataCriteria.managementGroupId
          , 'primeCertificationNo': dataCriteria.primeCertificationNo
          , 'lastName': dataCriteria.lastName
          , 'firstName': dataCriteria.firstName
          , 'lastNameKana': dataCriteria.lastNameKana
          , 'firstNameKana': dataCriteria.firstNameKana
          , 'originalQualificationName': dataCriteria.originalQualificationName
          , 'onlyEnableQualification': dataCriteria.onlyEnableQualification === "1" ? true : false
        }}
      >
        <div className={styles.criteriaSearchField}>
          <CriteriaForm.Item name="managementGroupId" id="managementGroupId" label={"管理グループ"}>
            <Select className={styles.formSelect}>
              <Select.Option value={""}>（全て）</Select.Option>
              {managementGroupList.map((rec: Model.managementGroupRow) => (
                <Select.Option key={rec.managementGroupId} value={rec.managementGroupId}>{rec.managementGroupName}</Select.Option>
              ))}
            </Select>
          </CriteriaForm.Item>
          <CriteriaForm.Item name="primeCertificationNo" id="primeCertificationNo">
            <Input
              type="text"
              placeholder="元請認定番号"
              maxLength={CONSTANTS.PRIME_CERTIFICATION_NO_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="lastName">
            <Input
              type="text"
              placeholder="氏名（姓）"
              maxLength={CONSTANTS.LAST_NAME_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="firstName">
            <Input
              type="text"
              placeholder="氏名（名）"
              maxLength={CONSTANTS.FIRST_NAME_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="lastNameKana">
            <Input
              type="text"
              placeholder="氏名カナ（姓）"
              maxLength={CONSTANTS.LAST_NAME_KANA_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="firstNameKana">
            <Input
              type="text"
              placeholder="氏名カナ（名）"
              maxLength={CONSTANTS.FIRST_NAME_KANA_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="originalQualificationName">
            <Input
              type="text"
              placeholder="独自資格名"
              maxLength={CONSTANTS.QUALIFICATION_NAME_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="onlyEnableQualification" valuePropName="checked">
            <Checkbox>
              有効資格のみ
            </Checkbox>
          </CriteriaForm.Item>
          <AuthButton
            name={"検索"}
            htmlType="submit"
            shape="round"
          />
        </div>
      </CriteriaForm>
    )
  }

  // テーブル列を設定します。
  const columns = [
    {
      title: '管理グループ'
      , dataIndex: 'managementGroupName'
      , key: '1'
      , width: 120
      , sortOrder: dataCriteria.sortItem !== '1' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , sorter: true
      , showSorterTooltip: false
      , align: 'center' as 'center'
      , render: (value: string, row: Model.QualificationHolderDataRow) => {
        return {
          children: (
            <div className={styles.columnAlignLeft}>{row.managementGroupName}</div>
          )
        }
      }
    },
    {
      title: '元請認定番号'
      , dataIndex: 'primeCertificationNo'
      , key: '2'
      , width: 300
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '2' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: '氏名'
      , dataIndex: 'name'
      , key: '3'
      , width: 100
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '3' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
      , render: (value: string, row: Model.QualificationHolderDataRow) => {
        return {
          children: (
            <div className={styles.columnAlignLeft}>{row.lastName + ' ' + row.firstName}</div>
          )
        }
      }
    },
    {
      title: '生年月日'
      , dataIndex: 'birthday'
      , key: '4'
      , width: 100
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '4' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
      , render: (value: string, row: Model.QualificationHolderDataRow) => {
        return {
          children: (
            formatUtils.formatDateSlash(row.birthday)
          )
        }
      }
    },
    {
      title: '有効独自資格数'
      , dataIndex: 'numberOfQualifications'
      , key: '5'
      , width: 120
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '5' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: '確認済独自資格数'
      , dataIndex: 'numberOfConfirmedQualifications'
      , key: '6'
      , width: 120
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '6' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: '確認依頼中独自資格数'
      , dataIndex: 'numberOfRequestedQualifications'
      , key: '7'
      , width: 120
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '7' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: '申請独自資格数'
      , dataIndex: 'numberOfRequestQualifications'
      , key: '8'
      , width: 120
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '8' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    }
  ];

  // ページングボタンを描画します。
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <AuthButton name='＜' size='small' />;
    }
    if (type === 'next') {
      return <AuthButton name='＞' size='small' />;
    }
    return originalElement
  }

  // テーブルエリアを描画します。
  const QualificationTable = () => {
    if (totalCnt > 0 && dataList.length > 0) {
      return (
        <Table
          columns={columns}
          sortDirections={['ascend', 'descend']}
          size='small'
          dataSource={dataList}
          scroll={{ x: 1000, y: 400 }}
          pagination={{
            total: totalCnt  //総件数
            , defaultCurrent: 1 //
            , current: dataCriteria.offset / dataCriteria.limit + 1
            , pageSize: dataCriteria.limit //ページサイズを入れる
            , pageSizeOptions: ['5', '10', '20', '50', '100']
            , showSizeChanger: true
            , showTotal: (total, range) => `${total}件中、${dataCriteria.offset + 1}件目から${total <= dataCriteria.offset + dataCriteria.limit ? total : dataCriteria.offset + dataCriteria.limit}件目を表示`
            , itemRender: pageItemRender
            , locale: { items_per_page: '件/ページ' }
          }}
          onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => { moveToDetail(record) }
            };
          }}
        />
      )
    }
    return (
      <></>
    )
  }

  // 所属会社を描画します。
  const BelongCompany = () => {
    return (
      <div className={styles.belongCompany}>
        <span>{"所属会社：" + belongCompanyData.belongCompanyName}</span>
      </div>
    )
  }

  // フッターボタンエリアを描画します。
  const ButtonArea = () => {
    return (
      <FooterButtonArea>
        <AuthButton
          name={"戻る"}
          className={AuthButtonStyles.backButtonFixed}
          size={"large"}
          shape={"round"}
          onClick={backClicked}
        />
        {isDisplayOperationButton() ?
          <AuthButton
            name={"登録"}
            size={"large"}
            shape={"round"}
            onClick={addClicked}
          />
          : <></>
        }
      </FooterButtonArea>
    )
  }

  return (
    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <QualificationCriteria />
        <CommonMessage searchNoDataName="独自資格保有者" />
        <BelongCompany />
        <QualificationTable />
      </Content>
      <ButtonArea />
      <Footer />
    </Layout >
  );
}

