import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './ReportDownload.module.css';
import { Layout, Input, Form, Select, Button } from 'antd';
import { AuthButton, Radio, MonthPicker, Checkbox } from '../../core/CoreForm';
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from '../../core/CorePageContents';
import AuthButtonStyles from '../../core/css/AuthButton.module.css';
import * as Module from '../../../modules/prime/report/reportModule';
import * as Model from '../../../modules/prime/report/reportModel';
import moment, { Moment } from 'moment';
import * as formatUtils from '../../../utils/formatUtils';
import * as messagesUtils from '../../../utils/messagesUtils';
import * as validationRules from '../../../validation/coreRules';
import * as CONSTANTS from '../../../constants/constants';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { storedApiResult, setApiResult, initApiResult } from '../../../modules/core/bffApiModule';

interface titleInterface {
  title: string;
}

const FLG_OFF = "0";
const FLG_ON = "1";

export const ReportDownload = (props: titleInterface) => {
  //　hoocs
  const dispatch = useDispatch();
  const managementGroupList = useSelector(Module.storedManagementGroupList);
  const filterdManagementGroupList = managementGroupList.filter(managementGroup => managementGroup.managementGroupId !== "");
  const selectValue = useSelector(Module.storedSelectRadio);
  const disabled = useSelector(Module.storedDisabled);
  const apiResult = useSelector(storedApiResult);
  let date = new Date();
  let lastYear = date.getFullYear() - 1;
  let year = date.getFullYear();
  let month = date.getMonth() + 1;

  useEffect(() => {
    dispatch(Module.getManagementGroupListAsync());
    dispatch(Module.selectRadio(CONSTANTS.PRIME_REPORT_PAYMENT));
    dispatch(Module.setDisabled(false));
  },
    []
  );


  // 帳票出力API実行後、帳票のダウンロードが完了した時、ボタンを活性します。
  if (disabled && CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && (CONSTANTS.URL_REPORT_PRIME_PAYMENT === apiResult.url || CONSTANTS.URL_REPORT_PRIME_QUALIFICATIONS_HOLDERS === apiResult.url || CONSTANTS.URL_REPORT_PRIME_PROJECT_PAYMENT === apiResult.url)) {
    dispatch(Module.setDisabled(false));
    // ステータスコード以外を初期化します。
    dispatch(setApiResult({ status: apiResult.status, errorCode: "", requestMethod: "", url: "" }));
  }

  const onSubmit = (values: any) => {
    // 通信結果を初期化する
    dispatch(initApiResult());
    if (selectValue === CONSTANTS.PRIME_REPORT_PAYMENT) {
      let criteria: Model.downloadPaymentRecord.Request = {
        managementGroupId: values.managementGroup
        , firstPartnerCompanyName: values.firstPartnerCompanyName
        , firstPartnerCompanyNameKana: values.firstPartnerCompanyNameKana
        , belongCompanyName: values.belongCompanyName_pay
        , belongCompanyNameKana: values.belongCompanyNameKana_pay
        , fromYYYYMM: formatUtils.formatMonthNonSlash(values.fromYYYYMM)
        , toYYYYMM: formatUtils.formatMonthNonSlash(values.toYYYYMM)
        , password: values.password
      }
      dispatch(Module.downloadPaymentRecord(criteria));
    } else if (selectValue === CONSTANTS.PRIME_REPORT_QUALIFICATION_HOLDERS) {
      let criteria: Model.downloadQualificationHolder.Request = {
        belongCompanyName: values.belongCompanyName_qualification
        , belongCompanyNameKana: values.belongCompanyNameKana_qualification
        , includeOutsidePeriodFlg: values.includeOutsidePeriodFlg === true ? FLG_ON : FLG_OFF
        , password: values.password
      }
      dispatch(Module.downloadQualificationHolder(criteria));
    } else {
      let criteria: Model.downloadProjectPaymentRecord.Request = {
        managementGroupId: values.managementGroup_projectPay
        , belongCompanyName: values.belongCompanyName_projectPay
        , belongCompanyNameKana: values.belongCompanyNameKana_projectPay
        , fromYYYYMM: formatUtils.formatMonthNonSlash(values.fromYYYYMM_projectPay)
        , toYYYYMM: formatUtils.formatMonthNonSlash(values.toYYYYMM_projectPay)
        , password: values.password
      }
      dispatch(Module.downloadProjectPaymentRecord(criteria));
    }
    dispatch(Module.setDisabled(true));
  }

  const onChange = (e: any) => {
    dispatch(Module.selectRadio(e.target.value));
  }

  const disabledPayReport = () => {
    if (selectValue !== CONSTANTS.PRIME_REPORT_PAYMENT) {
      return true;
    }
    return false;
  }

  const disableProjectPayReport = () => {
    if (selectValue !== CONSTANTS.PRIME_REPORT_PROJECT_QUALIFICATION_HOLDERS) {
      return true;
    }
    return false;
  }

  const disabledQualificationReport = () => {
    if (selectValue !== CONSTANTS.PRIME_REPORT_QUALIFICATION_HOLDERS) {
      return true;
    }
    return false;
  }

  const PasswordContent = () => {
    return (
      <div className={styles.passwordArea}>
        <Form.Item
          name="password"
          id="password"
          label={"zipファイルの暗号化パスワード(1文字以上の任意の文字列)"}
          rules={[
            {
              required: true,
              message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["パスワード"]),
            },
            {
              pattern: validationRules.alphanumeric,
              message: messagesUtils.getMessage("ERROR_ALPHANUMERIC", ["パスワード"]),
            },
            {
              max: CONSTANTS.PASSWORD_MAX_LENGTH,
              message: messagesUtils.getMessage("ERROR_MAX_LENGTH", ["パスワード", CONSTANTS.PASSWORD_MAX_LENGTH.toString()]),
            }
          ]}
        >
          <Input.Password
            placeholder={"パスワード"}
            maxLength={CONSTANTS.PASSWORD_MAX_LENGTH}
            iconRender={(visible: any) => (visible ? <EyeInvisibleFilled /> : <EyeFilled />)}
          />
        </Form.Item>
      </div>
    );
  }

  const ReportDownloadContent = () => {
    return (
      <>
        <div className={styles.amountNotion}>
          出力する帳票を選択してください。帳票は暗号化されたzipファイルに圧縮されて出力されます。
        </div>
        <div className={styles.content}>
          <div className={styles.radioArea}>
            <Radio value={CONSTANTS.PRIME_REPORT_PAYMENT} onChange={onChange} checked={selectValue === CONSTANTS.PRIME_REPORT_PAYMENT ? true : false}>
              <span className={styles.subTitle}>勤怠実績一覧</span>
            </Radio>
          </div>
          <div className={styles.selectedArea} style={{ display: `${disabledPayReport() ? "none" : ""}` }}>
            <div className={styles.commentArea}>
              勤怠実績一覧の出力条件を選択してください(各項目は任意入力です)
            </div>
            <div className={styles.criteriaArea}>
              <Form.Item name="managementGroup" id="managementGroup" label={"管理グループ"} className={styles.formInput}>
                <Select className={styles.managementGroup} disabled={disabledPayReport()}>
                  <Select.Option value={""}>全て</Select.Option>
                  {filterdManagementGroupList.map((rec: Model.managementGroup) => (
                    <Select.Option key={rec.managementGroupId} value={rec.managementGroupId}>{rec.managementGroupName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="firstPartnerCompanyName" id="firstPartnerCompanyName" className={styles.formItem}>
                <Input
                  type="text"
                  placeholder="一次協力会社名"
                  disabled={disabledPayReport()}
                  maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
                  className={styles.firstPartnerCompanyName}
                />
              </Form.Item>
              <Form.Item name="firstPartnerCompanyNameKana" id="firstPartnerCompanyNameKana" className={styles.formItem}>
                <Input
                  type="text"
                  placeholder="一次協力会社名カナ"
                  disabled={disabledPayReport()}
                  maxLength={CONSTANTS.COMPANY_NAME_KANA_MAX_LENGTH}
                  className={styles.firstPartnerCompanyNameKana}
                />
              </Form.Item>
              <Form.Item name="belongCompanyName_pay" id="belongCompanyName_pay" className={styles.formItem}>
                <Input
                  type="text"
                  placeholder="所属企業名"
                  disabled={disabledPayReport()}
                  maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
                  className={styles.belongCompanyName}
                />
              </Form.Item>
              <Form.Item name="belongCompanyNameKana_pay" id="belongCompanyNameKana_pay" className={styles.formItem}>
                <Input
                  type="text"
                  placeholder="所属企業名カナ"
                  disabled={disabledPayReport()}
                  maxLength={CONSTANTS.COMPANY_NAME_KANA_MAX_LENGTH}
                  className={styles.belongCompanyNameKana}
                />
              </Form.Item>
              <div className={styles.duration}>
                <div className={styles.formItem}>
                  <Form.Item
                    name="fromYYYYMM"
                    className={styles.fromYYYYMM}
                    rules={[
                      ({ getFieldValue, validateFields }) => ({
                        validator(rule, value) {
                          if (getFieldValue("toYYYYMM") !== null) {
                            validateFields(["toYYYYMM"]);
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                  >
                    <MonthPicker placeholder="勤怠年月(FROM)" disabled={disabledPayReport()} />
                  </Form.Item>
                  <div className={styles.durationSpan}><span>～</span ></div>
                  <Form.Item
                    name="toYYYYMM"
                    className={styles.toYYYYMM}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value === null || getFieldValue("fromYYYYMM") === null) {
                            return Promise.resolve();
                          }
                          if (getFieldValue("fromYYYYMM").isAfter(value, 'month')) {
                            return Promise.reject(messagesUtils.getMessage("ERROR_FROM_TO_DATE_RANGE", ["勤怠年月(From)", "勤怠年月(To)"]));
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                  >
                    <MonthPicker className={styles.monthPicker} placeholder="勤怠年月(TO)" disabled={disabledPayReport()} />
                  </Form.Item >
                </div>
                <div>
                  <span>※未入力の場合、過去1年分の検索となります。</span >
                </div>
              </div>
            </div>
            <PasswordContent />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.radioArea}>
            <Radio value={CONSTANTS.PRIME_REPORT_QUALIFICATION_HOLDERS} onChange={onChange} checked={selectValue === CONSTANTS.PRIME_REPORT_QUALIFICATION_HOLDERS ? true : false}>
              <span className={styles.subTitle}>資格保有者一覧</span>
            </Radio>
          </div>
          <div className={styles.selectedArea} style={{ display: `${disabledQualificationReport() ? "none" : ""}` }}>
            <div className={styles.commentArea}>
              資格保有者一覧の出力条件を選択してください(各項目は任意入力です)
            </div>
            <div className={`${styles.criteriaArea} ${styles.qualificationcriteriaAreaPosition}`}>
              <Form.Item name="belongCompanyName_qualification" className={styles.formItem}>
                <Input
                  type="text"
                  placeholder="所属企業名"
                  disabled={disabledQualificationReport()}
                  maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
                  className={styles.belongCompanyName}
                />
              </Form.Item>
              <Form.Item name="belongCompanyNameKana_qualification" className={styles.formItem}>
                <Input
                  type="text"
                  placeholder="所属企業名カナ"
                  disabled={disabledQualificationReport()}
                  maxLength={CONSTANTS.COMPANY_NAME_KANA_MAX_LENGTH}
                  className={styles.belongCompanyNameKana}
                />
              </Form.Item>
              <Form.Item name="includeOutsidePeriodFlg" id="includeOutsidePeriodFlg" valuePropName={"checked"}>
                <Checkbox disabled={disabledQualificationReport()}>
                  有効ではない資格も表示する
                </ Checkbox>
              </Form.Item>
            </div>
            <PasswordContent />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.radioArea}>
            <Radio value={CONSTANTS.PRIME_REPORT_PROJECT_QUALIFICATION_HOLDERS} onChange={onChange} checked={selectValue === CONSTANTS.PRIME_REPORT_PROJECT_QUALIFICATION_HOLDERS ? true : false}>
              <span className={styles.subTitle}>プロジェクト別手当一覧</span>
            </Radio>
          </div>
          <div className={styles.selectedArea} style={{ display: `${disableProjectPayReport() ? "none" : ""}` }}>
            <div className={styles.commentArea}>
              プロジェクト別手当一覧の出力条件を選択してください(各項目は任意入力です)
            </div>
            <div className={styles.criteriaArea}>
              <Form.Item name="managementGroup_projectPay" id="managementGroup_projectPay" label={"管理グループ"} className={styles.formInput}>
                <Select className={styles.managementGroup} disabled={disableProjectPayReport()}>
                  <Select.Option value={""}>全て</Select.Option>
                  {filterdManagementGroupList.map((rec: Model.managementGroup) => (
                    <Select.Option key={rec.managementGroupId} value={rec.managementGroupId}>{rec.managementGroupName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="belongCompanyName_projectPay" id="belongCompanyName_projectPay" className={styles.formItem}>
                <Input
                  type="text"
                  placeholder="所属企業名"
                  disabled={disableProjectPayReport()}
                  maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH}
                  className={styles.belongCompanyName}
                />
              </Form.Item>
              <Form.Item name="belongCompanyNameKana_projectPay" id="belongCompanyNameKana_projectPay" className={styles.formItem}>
                <Input
                  type="text"
                  placeholder="所属企業名カナ"
                  disabled={disableProjectPayReport()}
                  maxLength={CONSTANTS.COMPANY_NAME_KANA_MAX_LENGTH}
                  className={styles.belongCompanyNameKana}
                />
              </Form.Item>

              <div className={styles.duration}>
                <div className={styles.formItem}>
                  <Form.Item
                    name="fromYYYYMM_projectPay"
                    className={styles.fromYYYYMM}
                    rules={[
                      ({ getFieldValue, validateFields }) => ({
                        validator(rule, value) {
                          if (getFieldValue("toYYYYMM_projectPay") !== null) {
                            validateFields(["toYYYYMM_projectPay"]);
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                  >
                    <MonthPicker placeholder="勤怠年月(FROM)" disabled={disableProjectPayReport()} />
                  </Form.Item>
                  <div className={styles.durationSpan}><span>～</span ></div>
                  <Form.Item
                    name="toYYYYMM_projectPay"
                    className={styles.toYYYYMM}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (value === null || getFieldValue("fromYYYYMM_projectPay") === null) {
                            return Promise.resolve();
                          }
                          if (getFieldValue("fromYYYYMM_projectPay").isAfter(value, 'month')) {
                            return Promise.reject(messagesUtils.getMessage("ERROR_FROM_TO_DATE_RANGE", ["勤怠年月(From)", "勤怠年月(To)"]));
                          }
                          return Promise.resolve();
                        }
                      })

                    ]}
                  >
                    <MonthPicker className={styles.monthPicker} placeholder="勤怠年月(TO)" disabled={disableProjectPayReport()} />
                  </Form.Item >
                </div>
                <div>
                  <span>※未入力の場合、過去1年分の検索となります。</span >
                </div>
              </div>
            </div>
            <PasswordContent />
          </div>
        </div>

        <div className={styles.buttonFixed}>
          <AuthButton
            name={"帳票出力"}
            size={"large"}
            shape={"round"}
            className={AuthButtonStyles.authButtonFixed}
            htmlType="submit"
            disabled={disabled}
          />
          <div style={{ "color": "rgba(0,0,0,0.85)" }}>
            <div>※しばらく時間がかかる場合があります。</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <Layout>
      <Header />
      <Content style={{ marginBottom: "70px" }}>
        <FunctionTitle title={props.title} />
        <CommonMessage />
        <Form
          layout="inline"
          method="POST"
          onFinish={onSubmit}
          className={styles.form}
          initialValues={{
            'managementGroup': ""
            , 'firstPartnerCompanyName': ""
            , 'firstPartnerCompanyNameKana': ""
            , 'belongCompanyName_pay': ""
            , 'belongCompanyNameKana_pay': ""
            , 'belongCompanyName_qualification': ""
            , 'belongCompanyNameKana_qualification': ""
            , 'fromYYYYMM': moment(lastYear + "/" + month, 'YYYY/MM')
            , 'toYYYYMM': moment(year + "/" + month, 'YYYY/MM')
            , 'managementGroup_projectPay': ""
            , 'fromYYYYMM_projectPay': moment(lastYear + "/" + month, 'YYYY/MM')
            , 'toYYYYMM_projectPay': moment(year + "/" + month, 'YYYY/MM')
            , 'password': ""
          }}
        >
          <ReportDownloadContent />
        </Form>
      </Content>
      <FooterButtonArea />
      <Footer />
    </Layout >
  );
}

