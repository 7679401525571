import React from 'react';
import { useSelector } from 'react-redux';
import { isPrimeMenu } from '../../../modules/core/menuModule';
import styles from '../css/Radio.module.css';
import { Radio as AntRadio } from 'antd';
import { RadioProps, RadioGroupProps } from 'antd/lib/radio';

const Radio = (props: RadioProps) => {
    const isPrime = useSelector(isPrimeMenu);

    // prime/partner用CSSを切り替えます。
    const classNameProperty = () => {
        if (typeof props.className === "undefined") {
            return isPrime ? styles.primeRadio : styles.partnerRadio;
        }
        return props.className;
    }
    return (
        <AntRadio {...props}
            className={classNameProperty()} >
        </AntRadio>
    )
}
Radio.Group = (props: RadioGroupProps) => { return <AntRadio.Group {...props}></AntRadio.Group> }

export default Radio;