import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router"
import styles from './CommonSettingList.module.css';
import { Layout, Table } from "antd";
import { AuthButton } from "../../core/CoreForm"
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from "../../core/CorePageContents"
import * as Module from "../../../modules/prime/commonSetting/commonSettingModule";
import * as Model from "../../../modules/prime/commonSetting/commonSettingModel";
import * as CONSTANTS from "../../../constants/constants";
import * as constantsUtils from '../../../utils/constantsUtils';
import { initMessages } from "../../../modules/core/commonMessageModule";
import AuthButtonStyles from "../../core/css/AuthButton.module.css";

interface titleInterface {
  title: string;
}

export const CommonSettingList = (props: titleInterface) => {

  const dispatch = useDispatch();
  let closingDateList = useSelector(Module.storedClosingClosingDateList);
  let workflowFlagList = useSelector(Module.storedWorkflowFlagList);
  let calcWorkTimeTypeList = useSelector(Module.storedCalcWorkTimeTypeList);
  let apportionmentTypeList = useSelector(Module.storedApportionmentTypeList);
  let certificateFlowTypeList = useSelector(Module.storedCertificateFlowTypeList);
  let storedPositionAuthorityList = useSelector(Module.storedPositionAuthorityList);
  let storedMailTypeSendFlagToPrimePositionAuthorityApproveUserList = useSelector(Module.storedMailTypeSendFlagToPrimePositionAuthorityApproveUserList);
  let storedMailTypeSendFlagToPrimePositionAuthorityConfirmUserList = useSelector(Module.storedMailTypeSendFlagToPrimePositionAuthorityConfirmUserList);
  let storedMailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList = useSelector(Module.storedMailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList);
  let storedMailTypeSendFlagToPartnerSubmitUserList = useSelector(Module.storedMailTypeSendFlagToPartnerSubmitUserList);

  let history = useHistory();

  useEffect(() => {
    dispatch(Module.getPrimeCommonSettingAsync());
  }, []);


  /**
   * 編集ボタン押下時アクション
   */
  const editClicked = () => {
    dispatch(initMessages());
    history.push(CONSTANTS.PATH_PRIME_SETTING_EDIT);
  }


  /**
   * 締切設定の表示
   */
  const closingDateTableColumns = [
    {
      title: '締切',
      width: '250px',
      align: 'center' as 'center',
      render: (value: any, row: Model.ClosingDateRow) => {
        return (
          <>
            <span>{constantsUtils.getLabelOf(CONSTANTS.CLOSING_DATE_KBN, row.closingDateKbn)}</span>
          </>
        );
      },
    },
    {
      title: '期限',
      align: 'left' as 'left',
      render: (value: any, row: Model.ClosingDateRow) => {
        return (
          <>
            <span>
              {(row.closingMonth && row.closingDate) ? `${row.closingMonth} ヶ月後の ${row.closingDate} 日まで` : "設定なし"}
            </span>
          </>
        );
      },
    },
  ];

  /**
   * ワークフローの表示
   */
  const workflowColumns = [
    {
      title: '項目',
      width: '250px',
      dataIndex: 'label',
      align: 'center' as 'center',
      render: (value: any, row: Model.WorkflowRow) => {
        return (
          <>
            <span>{row.label}</span>
          </>
        );
      },
    },
    {
      title: '設定値',
      dataIndex: 'flag',
      align: 'left' as 'left',
      render: (value: string, row: Model.WorkflowRow) => {
        return (
          <>
            <span>
              {row.flag == CONSTANTS.WORKFLOW_FLAG.ON.code ? "有効" : "無効"}
            </span>
          </>
        );
      },
    },
  ];

  /**
   * 労働時間自動入力の表示
   */
  const calcWorkTimeColumns = [
    {
      title: '項目',
      width: '250px',
      dataIndex: 'label',
      align: 'center' as 'center',
      render: (value: any, row: Model.CalcWorkTimeRow) => {
        return (
          <>
            <span>{row.label}</span>
          </>
        );
      },
    },
    {
      title: '設定値',
      dataIndex: 'value',
      align: 'left' as 'left',
      render: (value: string, row: Model.CalcWorkTimeRow) => {
        return (
          <>
            <span>
              {constantsUtils.getLabelOf(CONSTANTS.CALC_WORK_TIME_TYPE, row.value)}
            </span>
          </>
        );
      },
    },
  ];


  /**
   * 基本設定の表示
   */
  const apportionmentTypeColumns = [
    {
      title: '項目',
      width: '250px',
      dataIndex: 'label',
      align: 'center' as 'center',
      render: (value: any, row: Model.CommonSettingRow) => {
        return (
          <>
            <span>{row.label}</span>
          </>
        );
      },
    },
    {
      title: '設定値',
      dataIndex: 'flag',
      align: 'left' as 'left',
      render: (value: string, row: Model.CommonSettingRow) => {
        return (
          <>
            <span>
              {constantsUtils.getLabelOf(CONSTANTS.APPORTIONMENT_TYPE, row.value)}
            </span>
          </>
        );
      },
    },
  ];


  /**
   * 役割別権限の表示
   */
  const positionAuthorityTableColumns = [
    {
      title: '役割',
      dataIndex: 'positioin',
      width: '250px',
      align: 'center' as 'center',
      render: (value: string, row: Model.PositionAuthority) => {
        return (
          <>
            <span>{constantsUtils.getLabelOf(CONSTANTS.POSITION_TYPE, row.positionType)}</span>
          </>
        );
      },
    },
    {
      title: '権限',
      dataIndex: 'authority',
      align: 'left' as 'left',
      render: (value: string, row: Model.PositionAuthority) => {
        return (
          <>
            <span>
              {constantsUtils.getLabelOf(CONSTANTS.POSITION_AUTHORITY_TYPE, row.positionAuthority)}
            </span>
          </>
        );
      },
    },
  ];

  /**
   * 資格認定フロー利用設定のスキップ設定
   */
  const certificateFlowTypeTableColums = [
    {
      title: '項目',
      dataIndex: 'positioin',
      width: '250px',
      align: 'center' as 'center',
      render: (value: string, row: Model.CertificateFlowTypeRow) => {
        return (
          <>
            <span>{row.label}</span>
          </>
        );
      },
    },
    {
      title: '設定値',
      dataIndex: 'authority',
      align: 'left' as 'left',
      render: (value: string, row: Model.CertificateFlowTypeRow) => {
        return (
          <>
            <span>
              {constantsUtils.getLabelOf(CONSTANTS.CERTIFICATE_FLOW_TYPE, row.value)}
            </span>
          </>
        );
      },
    },
  ];

  /**
   * メール送信可否設定
   */
  const mailTypeSendFlagTableColumns = [
    {
      title: 'メール通知タイミング',
      dataIndex: 'positioin',
      width: '250px',
      align: 'left' as 'left',
      render: (value: string, row: Model.MailTypeSendFlagRow) => {
        return (
          <>
            <span>{constantsUtils.getLabelOf(CONSTANTS.MAIL_TYPE, row.mailTypeCode)}</span>
          </>
        );
      },
    },
    {
      title: '通知設定',
      dataIndex: 'authority',
      align: 'left' as 'left',
      render: (value: string, row: Model.MailTypeSendFlagRow) => {
        return (
          <>
            <span>
              {constantsUtils.getLabelOf(CONSTANTS.MAIL_SEND_FLAG, row.flag)}
            </span>
          </>
        );
      },
    },
  ];

  /**
   * フッターボタンエリアの描画
   * @returns 
   */
  const ButtonArea = () => {
    return (
      <FooterButtonArea>
        <AuthButton
          name={"編集"}
          size={"large"}
          shape={"round"}
          onClick={editClicked}
          className={AuthButtonStyles.authButtonFixed}
        />
      </FooterButtonArea>
    );
  }

  return (
    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <CommonMessage />
        <div className={styles.settings}>
          <div className={styles.headline}>
            <span>締切設定</span>
          </div>
          <Table
            columns={closingDateTableColumns}
            size='small'
            dataSource={closingDateList}
            pagination={false}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
          />

          <div className={styles.headline}>
            <span>協力会社からの提出勤怠の「確認」要否設定</span>
          </div>
          <div className={styles.description}>
            協力会社から提出された勤怠の「承認」前に「確認」を必要とするかの設定です。設定した場合、「承認」前に「確認」が必要になります。
          </div>
          <Table
            columns={workflowColumns}
            size='small'
            dataSource={workflowFlagList}
            pagination={false}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
          />

          <div className={styles.headline}>
            <span>【協力会社】入退場いずれかの記録のみの場合に労働時間の自動入力の利用設定</span>
          </div>
          <div className={styles.description}>
          「利用する」を選択の場合、協力会社側で認定技能者の勤怠を確認する際、入退場いずれかの記録がある作業日に労働時間８時間が自動入力されます。
          </div>
          <Table
            columns={calcWorkTimeColumns}
            size='small'
            dataSource={calcWorkTimeTypeList}
            pagination={false}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
          />

          <div className={styles.headline}>
            <span>プロジェクト毎の手当金額の算出方法</span>
          </div>
          <Table
            columns={apportionmentTypeColumns}
            size='small'
            dataSource={apportionmentTypeList}
            pagination={false}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
          />

          <div className={styles.headline}>
            <span>役割別権限</span>
          </div>
          <Table
            columns={positionAuthorityTableColumns}
            size='small'
            dataSource={storedPositionAuthorityList}
            pagination={false}
            scroll={{ x: 1000 }}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
          />

          <div className={styles.headline}>
            <span>資格認定フロー利用設定</span>
          </div>
          <div className={styles.description}>
          作業員の資格登録の際、「申請」「確認」「承認」の手続きフローをスキルマップサイト内で処理する際の設定です。
          </div>
          <Table
            columns={certificateFlowTypeTableColums}
            size='small'
            dataSource={certificateFlowTypeList}
            pagination={false}
            scroll={{ x: 1000 }}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
          />

          <div className={styles.headline}>
            <span>【元請会社】勤怠承認権限を持つ本社/支店担当者へのメール通知設定</span>
          </div>
          <div className={styles.description}>
            元請会社の本社/支店担当者で勤怠承認者の権限を持つユーザーを対象に、担当下の協力会社から提出された勤怠が処理された際のメール通知の設定です。
          </div>
          <Table
            columns={mailTypeSendFlagTableColumns}
            size='small'
            dataSource={storedMailTypeSendFlagToPrimeSkillmapAuthorityApproveUserList}
            pagination={false}
            scroll={{ x: 1000 }}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
          />
          <div className={styles.headline}>
            <span>【元請会社】勤怠確認権限を持つ現場担当者へのメール通知設定</span>
          </div>
          <div className={styles.description}>
            元請会社の現場担当者で勤怠の確認権限を持つユーザーを対象に、担当下の協力会社から提出された勤怠が処理された際のメール通知の設定です。
          </div>
          <Table
            columns={mailTypeSendFlagTableColumns}
            size='small'
            dataSource={storedMailTypeSendFlagToPrimePositionAuthorityConfirmUserList}
            pagination={false}
            scroll={{ x: 1000 }}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
          />
          <div className={styles.headline}>
            <span>【元請会社】勤怠承認権限を持つ現場担当者へのメール通知設定</span>
          </div>
          <div className={styles.description}>
            元請会社の現場担当者で勤怠の承認権限を持つユーザーを対象に、担当下の協力会社から提出された勤怠が処理された際のメール通知の設定です。
          </div>
          <Table
            columns={mailTypeSendFlagTableColumns}
            size='small'
            dataSource={storedMailTypeSendFlagToPrimePositionAuthorityApproveUserList}
            pagination={false}
            scroll={{ x: 1000 }}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
          />

          <div className={styles.headline}>
            <span>【協力会社】勤怠を提出したユーザーへのメール通知設定</span>
          </div>
          <div className={styles.description}>
            協力会社の勤怠を提出したユーザーを対象に、元請会社へ提出した勤怠が元請会社側で処理された際のメール通知の設定です。
          </div>
          <Table
            columns={mailTypeSendFlagTableColumns}
            size='small'
            dataSource={storedMailTypeSendFlagToPartnerSubmitUserList}
            pagination={false}
            scroll={{ x: 1000 }}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
          />

        </div>
      </Content>
      <ButtonArea />
      <Footer />
    </Layout >
  );

}