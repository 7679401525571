import React from 'react';
import {
  Layout
} from 'antd';

const { Content } = Layout;

export const NowLoading = () => {

  return (
    <Layout>
      <Content>
        <div>Now Loading</div>
      </Content>
    </Layout>
  );
}
