import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

interface baseInterface {
  [propertyName: string]: any
}

export interface IdateString extends baseInterface {
  id: string
  dateString: string
}


export interface message {
  message: string
  messageType: string
}
// state定義
interface commonMessageState {
  messages: Array<message>
  modalMessages: Array<message>
}

// state初期化
const initialState: commonMessageState = {
  messages: []
  , modalMessages: []
};

// スライサー （state更新アクションの実装）
export const commonMessageSlice = createSlice({
  name: 'commonMessage',
  initialState,
  reducers: {
    initMessages: (state) => {
      state.messages = [];
      state.modalMessages = [];
    }
    , setMessages: (state, action: PayloadAction<Array<message>>) => {
      state.messages = action.payload;
    }
    , setModalMessages: (state, action: PayloadAction<Array<message>>) => {
      state.modalMessages = action.payload;
    }
  },
});

export const { initMessages, setMessages, setModalMessages } = commonMessageSlice.actions;

export const commonMessageState = (state: RootState) => state.commonMessage.messages;
export const modalMessageState = (state: RootState) => state.commonMessage.modalMessages;

export default commonMessageSlice.reducer;
