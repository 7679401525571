import * as CONSTANTS from '../constants/constants';

export interface IComponentPermissionSettings {
    url: string
    // 元請アクセス権限
    primeAccessAuth: boolean
    // 11:元請利用者
    primeAvailableAuth: boolean
    // 12:元請勤怠承認者
    primeAttendanceApprovalAuth: boolean
    // 14:元請資格管理者
    primeQualificationMngAuth: boolean
    // 15:元請勤怠参照役割保持者
    primeAttendanceReffenceRoleAuth: boolean
    // 16:元請勤怠承認役割保持者
    primeAttendanceApprovalRoleAuth: boolean
    // 19:元請システム管理者
    primeSystemMngAuth: boolean
    // 31:元請_資格承認フロー_申請権限
    primeCertificationFlowRequestAuth: boolean
    // 32:元請_資格承認フロー_確認権限
    primeCertificationFlowConfirmAuth: boolean
    // 33:元請_資格承認フロー_承認権限
    primeCertificationFlowApproveAuth: boolean
    // 協力アクセス権限
    partnerAccessAuth: boolean
    // 21:協力利用者
    partnerAvailableAuth: boolean
    // 22:協力勤怠提出者
    partnerAttendanceSubmitAuth: boolean
    // 29:協力システム管理者
    partnerSystemMngAuth: boolean
    // メニューアイテムID
    menuItemId: string
}

export const initialPermissionSettings: IComponentPermissionSettings = {
    url: ""
    , primeAccessAuth: true
    , primeAvailableAuth: false
    , primeAttendanceReffenceRoleAuth: false
    , primeAttendanceApprovalAuth: false
    , primeAttendanceApprovalRoleAuth: false
    , primeQualificationMngAuth: false
    , primeSystemMngAuth: false
    , primeCertificationFlowRequestAuth: false
    , primeCertificationFlowConfirmAuth: false
    , primeCertificationFlowApproveAuth: false
    , partnerAccessAuth: true
    , partnerAvailableAuth: false
    , partnerAttendanceSubmitAuth: false
    , partnerSystemMngAuth: false
    , menuItemId: ""
}

export const componentsPermissionSettings: Array<IComponentPermissionSettings> = [
    {
        // 元請_勤怠実績管理_一覧
        url: CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY
        , primeAccessAuth: true
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: true
        , primeAttendanceApprovalAuth: true
        , primeAttendanceApprovalRoleAuth: true
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeWorkRecord"
    },
    {
        // 元請_勤怠実績管理_作業者別明細 
        url: CONSTANTS.PATH_PRIME_ATTENDANCE_DETAIL
        , primeAccessAuth: true
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: true
        , primeAttendanceApprovalAuth: true
        , primeAttendanceApprovalRoleAuth: true
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeWorkRecord"
    },
    {
        // 元請_勤怠実績管理(作業者別)_一覧
        url: CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_SUMMARY
        , primeAccessAuth: true
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: true
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeWorkersAttendance"
    },
    {
        // 元請_勤怠実績管理(作業者別)_明細 
        url: CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_DETAIL
        , primeAccessAuth: true
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: true
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeWorkersAttendance"
    },
    {
        // 元請_独自資格保有者_協力会社_一覧
        url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_COMPANIES
        , primeAccessAuth: true
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeCertification"
    },
    {
        // 元請_独自資格保有者_一覧
        url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_LIST
        , primeAccessAuth: true
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeCertification"
    },
    {
        // 元請_独自資格保有者_登録
        url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_ADD
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: true
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeCertification"
    },
    {
        // 元請_独自資格保有者_詳細
        url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_DETAIL
        , primeAccessAuth: true
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeCertification"
    },
    {
        // 元請_独自資格保有者_編集
        url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_EDIT
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: true
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeCertification"
    },
    {
        // 元請_独自資格_一覧
        url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_LIST
        , primeAccessAuth: true
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeQualification"
    },
    {
        // 元請_独自資格_登録
        url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_ADD
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: true
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeQualification"
    },
    {
        // 元請_独自資格_詳細
        url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_DETAIL
        , primeAccessAuth: true
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeQualification"
    },
    {
        // 元請_独自資格_編集
        url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_EDIT
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: true
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeQualification"
    },
    {
        // 元請_権限_一覧
        url: CONSTANTS.PATH_PRIME_AUTH_LIST
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: true
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeAuthority"
    },
    {
        // 元請_権限_一覧編集
        url: CONSTANTS.PATH_PRIME_AUTH_EDIT
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: true
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeAuthority"
    },
    {
        // 元請_協力会社一覧
        url: CONSTANTS.PATH_PRIME_PARTNER_COMPANIES
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: true
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primePartner"
    },
    {
        // 元請_帳票出力
        url: CONSTANTS.PATH_PRIME_REPORT_DOWNLOAD
        , primeAccessAuth: true
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeReport"
    },
    {
        // 元請_基本設定_一覧
        url: CONSTANTS.PATH_PRIME_SETTING_LIST
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: true
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeCommonSetting"
    },
    {
        // 元請_基本設定_編集
        url: CONSTANTS.PATH_PRIME_SETTING_EDIT
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: true
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "primeCommonSetting"
    },
    {
        // 協力_勤怠実績報告_支店_一覧
        url: CONSTANTS.PATH_PARTNER_ATTENDANCE_SUMMARY
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: true
        , partnerAvailableAuth: true
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "partnerWorkRecord"
    },
    {
        // 協力_勤怠実績報告_作業者別明細
        url: CONSTANTS.PATH_PARTNER_ATTENDANCE_DETAIL
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: true
        , partnerAvailableAuth: true
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "partnerWorkRecord"
    },
    {
        // 協力_勤怠実績報告_支店_一覧(スマホ用)
        url: CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_SUMMARY
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: true
        , partnerAvailableAuth: true
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "partnerMobileWorkRecord"
    },
    {
        // 協力_勤怠実績報告_作業者別明細(スマホ用)
        url: CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_DETAIL
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: true
        , partnerAvailableAuth: true
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "partnerMobileWorkRecord"
    },
    {
        // 協力_勤怠帳票出力
        url: CONSTANTS.PATH_PARTNER_REPORT_DOWNLOAD
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: true
        , partnerAvailableAuth: true
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "partnerWorkRecordPrint"
    },
    {
        // 協力_権限_一覧
        url: CONSTANTS.PATH_PARTNER_AUTH_LIST
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: true
        , menuItemId: "partnerAuthority"
    },
    {
        // 協力_権限_一覧
        url: CONSTANTS.PATH_PARTNER_AUTH_EDIT
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: true
        , menuItemId: "partnerAuthority"
    },
    {
        // 協力_帳票出力
        url: CONSTANTS.PATH_PARTNER_REPORT_DOWNLOAD
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , primeCertificationFlowRequestAuth: false
        , primeCertificationFlowConfirmAuth: false
        , primeCertificationFlowApproveAuth: false
        , partnerAccessAuth: true
        , partnerAvailableAuth: true
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
        , menuItemId: "partnerReport"
    },

]

