import React from 'react';
import { Layout, Typography } from 'antd';
import styles from './css/Forbidden.module.css'
import { Header, Footer } from './CorePageContents';

const { Title } = Typography;
const { Content } = Layout;

export const Forbidden = () => {

  return (
    <Layout>
      <Header />
      <div className={styles.loadingMessage}>
        表示可能なデータがありません。
        </div>
      <Footer />
    </Layout>
  );
}
