import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store/store';
import { getRequest, putRequest, apiSuccessHandler, apiErrorHandler } from './bffApiModule'
import * as CONSTANTS from '../../constants/constants';

interface baseInterface {
  [propertyName: string]: any
}

/** 
 *  約款情報定義
 */
interface ITermOfService extends baseInterface {
  // サービス利用約款バージョン
  serviceTermsVersion: number
  // サービス利用約款HTML
  serviceTermsHtml: string
}

/** 
 *  約款同意APIリクエスト定義
 */
export interface putRequestData extends baseInterface {
  // 契約種別
  contractType: string
  // サービス利用約款バージョン
  serviceTermsVersion: number
}

/** 
 *  約款情報画面状態定義
 */
interface ITermOfServiceCheck extends baseInterface {
  // サービス利用約款チェック
  serviceTermsCheck: string
}

// state定義
interface TermOfServiceState {
  serviceTermsVersion: number
  serviceTermsHtml: string
  serviceTermsCheck: string
}

// state初期化
const initialState: TermOfServiceState = {
  serviceTermsVersion: 0
  , serviceTermsHtml: ""
  , serviceTermsCheck: "0"
}

// スライサー
export const termOfServiceSlice = createSlice({
  name: 'termOfService',
  initialState,
  reducers: {
    setTermOfService: (state, action: PayloadAction<ITermOfService>) => {
      state.serviceTermsVersion = action.payload.serviceTermsVersion;
      state.serviceTermsHtml = action.payload.serviceTermsHtml;
    },
    setTermOfServiceCheck: (state, action: PayloadAction<string>) => {
      state.serviceTermsCheck = action.payload;
    },
    initTermOfService: (state) => {
      state.serviceTermsCheck = "0";
      state.serviceTermsHtml = "";
    }
  },
});

// state更新アクションを公開
export const { setTermOfService, setTermOfServiceCheck, initTermOfService } = termOfServiceSlice.actions;

// 約款取得APIを呼び出します。
export const getTermOfServiceAsync = (param: any): AppThunk => async dispatch => {
  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_CMN_TERMS, { params: param });
    // ステートを更新します。
    dispatch(setTermOfService(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

// 約款同意APIを呼び出します。
export const putTermOfServiceAsync = (param: putRequestData): AppThunk => async dispatch => {
  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_CMN_TERMS, param);
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_PUT));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
};

// state参照
export const serviceTermsVersionState = (state: RootState) => state.termOfService.serviceTermsVersion;
export const serviceTermsHtmlState = (state: RootState) => state.termOfService.serviceTermsHtml
export const serviceTermsCheckState = (state: RootState) => state.termOfService.serviceTermsCheck

export default termOfServiceSlice.reducer;
