import * as CONSTANTS from '../constants/constants';

export interface IMenuItemPermissionSettings {
    id: string
    title: string
    url: string
    // 元請アクセス権限
    primeAccessAuth: boolean
    // 11:元請利用者
    primeAvailableAuth: boolean
    // 12:元請勤怠承認者
    primeAttendanceApprovalAuth: boolean
    // 14:元請資格管理者
    primeQualificationMngAuth: boolean
    // 15:元請勤怠参照役割保持者
    primeAttendanceReffenceRoleAuth: boolean
    // 16:元請勤怠承認役割保持者
    primeAttendanceApprovalRoleAuth: boolean
    // 19:元請システム管理者
    primeSystemMngAuth: boolean
    // 協力アクセス権限
    partnerAccessAuth: boolean
    // 21:協力利用者
    partnerAvailableAuth: boolean
    // 22:協力勤怠提出者
    partnerAttendanceSubmitAuth: boolean
    // 29:協力システム管理者
    partnerSystemMngAuth: boolean
}

export const initialRole: IMenuItemPermissionSettings = {
    id: ""
    , title: ""
    , url: ""
    , primeAccessAuth: true
    , primeAvailableAuth: false
    , primeAttendanceReffenceRoleAuth: false
    , primeAttendanceApprovalAuth: false
    , primeAttendanceApprovalRoleAuth: false
    , primeQualificationMngAuth: false
    , primeSystemMngAuth: false
    , partnerAccessAuth: true
    , partnerAvailableAuth: false
    , partnerAttendanceSubmitAuth: false
    , partnerSystemMngAuth: false
}

export const primeMenuPermissionSettings: Array<IMenuItemPermissionSettings> = [
    {
        id: "primeWorkRecord"
        , title: "勤怠実績管理（プロジェクト別）"
        , url: CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY
        , primeAccessAuth: false
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: true
        , primeAttendanceApprovalAuth: true
        , primeAttendanceApprovalRoleAuth: true
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
    {
        id: "primeWorkersAttendance"
        , title: "勤怠実績管理（作業員別）"
        , url: CONSTANTS.PATH_PRIME_WORKERS_ATTENDANCE_SUMMARY
        , primeAccessAuth: false
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
    {
        id: "primeCertification"
        , title: "独自資格保有者管理"
        , url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_COMPANIES
        , primeAccessAuth: false
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
    {
        id: "primeQualification"
        , title: "独自資格管理"
        , url: CONSTANTS.PATH_PRIME_QUALIFICATIONS_LIST
        , primeAccessAuth: false
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
    {
        id: "primeReport"
        , title: "帳票出力"
        , url: CONSTANTS.PATH_PRIME_REPORT_DOWNLOAD
        , primeAccessAuth: false
        , primeAvailableAuth: true
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
    {
        id: "primeAuthority"
        , title: "権限管理"
        , url: CONSTANTS.PATH_PRIME_AUTH_LIST
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: true
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
    {
        id: "primePartner"
        , title: "協力会社管理"
        , url: CONSTANTS.PATH_PRIME_PARTNER_COMPANIES
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: true
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
    {
        id: "primeCommonSetting"
        , title: "基本設定"
        , url: CONSTANTS.PATH_PRIME_SETTING_LIST
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: true
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
]

export const partnerMenuPermissionSettings: Array<IMenuItemPermissionSettings> = [
    {
        id: "partnerWorkRecord"
        , title: "勤怠管理"
        , url: CONSTANTS.PATH_PARTNER_ATTENDANCE_SUMMARY
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: true
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
    {
        id: "partnerMobileWorkRecord"
        , title: "勤怠管理(スマホ用)"
        , url: CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_SUMMARY
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: true
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
    {
        id: "partnerReport"
        , title: "帳票出力"
        , url: CONSTANTS.PATH_PARTNER_REPORT_DOWNLOAD
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: true
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: false
    },
    {
        id: "partnerAuthority"
        , title: "権限管理"
        , url: CONSTANTS.PATH_PARTNER_AUTH_LIST
        , primeAccessAuth: false
        , primeAvailableAuth: false
        , primeAttendanceReffenceRoleAuth: false
        , primeAttendanceApprovalAuth: false
        , primeAttendanceApprovalRoleAuth: false
        , primeQualificationMngAuth: false
        , primeSystemMngAuth: false
        , partnerAccessAuth: false
        , partnerAvailableAuth: false
        , partnerAttendanceSubmitAuth: false
        , partnerSystemMngAuth: true
    },

]
