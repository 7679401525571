import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "moment/locale/ja";
import { Moment } from "moment";
import styles from "../css/DatePicker.module.css";
import { DatePicker as AntdDatePicker } from "antd";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import locale from "antd/lib/date-picker/locale/ja_JP";
import * as formatUtils from "../../../utils/formatUtils";
import * as Module from "../../../modules/core/monthPickerModule";
import moment from "moment";
import * as CONSTANTS from '../../../constants/constants';

// 指定可能なパラメータ定義を拡張
interface DatePickerProps extends PickerDateProps<Moment> {
  dateString?: string;
}

// 拡張DatePicker
// フォーカスアウト時、YYYY/MM表示します。
// フォーカス時YYYYMM表示とし、YYYYMMおよびYYYY/MM形式の入力を許可します。
export const MonthPicker = (props: DatePickerProps) => {
  const { MonthPicker } = AntdDatePicker;

  const dispatch = useDispatch();
  const dateFocusState = useSelector(Module.dateFocus);
  const dateStringsState = useSelector(Module.dateStrings);

  //フォーカスステートによって表示＆入力許可フォーマットを切り替えます。
  const dateFormat: Array<string> = dateFocusState
    ? ["YYYY/MM", "YYYYMM"]
    : ["YYYY/MM"];

  // 操作するdatePickerのIDを取得します。
  const targetId = typeof props.id !== "undefined" ? props.id : "defaultId";

  // targetId別にステート管理された入力値を取得します。
  const targetDateStringState = dateStringsState.find((dateString: Module.IdateString) => dateString.id === targetId);

  let dateStringCurrent = props.dateString;
  // ステート管理された入力値を優先して表示します。なければパラメータ日付を表示します。
  if (typeof targetDateStringState != "undefined") {
    dateStringCurrent = targetDateStringState.dateString;
  }

  // フォーカス時処理
  const onFocusDate = (e: any) => {
    //YYYYMM形式の入力のみ受け付ける
    dispatch(Module.toggleDateFocus(true));
  };

  // フォーカスアウト時処理
  const onBlurDate = (e: any) => {
    dispatch(Module.toggleDateFocus(false));
    // スラッシュ形式での入力はスラッシュ無しに変換することで許容する。
    dispatch(Module.setDateString({ id: targetId, dateString: formatUtils.formatMonthNonSlash(e.target.value), }));
  };

  // 選択または入力できる日付の範囲を指定します。
  const disabledDate = (date: Moment) => {
    const rangeDateMin = moment(CONSTANTS.DATE_PICKER_RANGE_MIN);
    const rangeDateMax = moment(CONSTANTS.DATE_PICKER_RANGE_MAX);
    return date && (date.isBefore(rangeDateMin, "year") || date.isAfter(rangeDateMax, "year"));
  };

  if (dateStringCurrent !== "" && typeof dateStringCurrent !== "undefined" && dateStringCurrent !== "Invalid date") {
    return (
      <MonthPicker
        {...props}
        disabledDate={props.disabledDate ? props.disabledDate : (date) => disabledDate(date)}
        className={styles.floatLeft}
        locale={locale}
        defaultValue={formatUtils.getMonthMomentOf(dateStringCurrent)}
        format={dateFormat}
        onFocus={onFocusDate}
        onBlur={onBlurDate}
        autoComplete={"off"}
      />
    );
  }

  return (
    <MonthPicker
      {...props}
      disabledDate={props.disabledDate ? props.disabledDate : (date) => disabledDate(date)}
      className={styles.floatLeft}
      locale={locale}
      format={dateFormat}
      onFocus={onFocusDate}
      onBlur={onBlurDate}
      autoComplete={"off"}
    />
  );
};
