import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, putRequest, deleteRequest, apiSuccessHandler, apiErrorHandler } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
import * as Model from './partner-companiesModel';

// state初期化
const initialState: Model.PartnerCompaniesState = {
  dataList: []
  , dataRow: {
    key: ""
    , companyId: ""
    , companyName: ""
    , companyNameKana: ""
    , zipcode: ""
    , address: ""
    , selectFlag: false
  }
  , total: 0
  , criteria: {
    partnerCompanyName: ""
    , sortItem: ""
    , sortOrder: ""
    , offset: 0
    , limit: 10
  }
  , allSelect: false
  , existChecked: false
  , projectModalVisibleState: false
  , partnerCompanyModalVisibleState: false
};

// スライサー （state更新アクションの実装）
export const partnerCompaniesSlice = createSlice({
  name: 'primePartnerCompanies',
  initialState,
  reducers: {
    /** メニュー押下時のstate更新 */
    initCriteria: (state) => {
      let criteria = {
        partnerCompanyName: ""
        , sortItem: ""
        , sortOrder: ""
        , offset: 0
        , limit: 10
      }
      state.criteria = criteria;
    },
    /** 検索条件のstate更新 */
    setPartnerCompaniesCriteria: (state, action: PayloadAction<Model.Criteria>) => {
      let criteria: Model.Criteria = {
        partnerCompanyName: action.payload.partnerCompanyName,
        sortItem: action.payload.sortItem,
        sortOrder: action.payload.sortOrder,
        offset: action.payload.offset,
        limit: action.payload.limit
      }
      state.criteria = criteria;
    },
    /** 一覧画面描画時のstate更新 */
    setDataListOfPartnerCompaniesMaster: (state, action: PayloadAction<Model.getDataList.Response>) => {

      // APIのQualificationRow配列をstateに設定します。
      state.dataList = initialState.dataList;

      let partnerCompaniesList: Array<Model.DataRow> = [];

      action.payload.partnerCompanyList.forEach((apiDataRow: Model.DataRow) => {

        const rec: Model.DataRow = {
          key: apiDataRow.companyId,
          // 協力会社企業ID
          companyId: apiDataRow.companyId,
          // 協力会社企業名称
          companyName: apiDataRow.companyName,
          // 協力会社企業名称カナ
          companyNameKana: apiDataRow.companyNameKana,
          // 郵便番号
          zipcode: apiDataRow.zipcode,
          // 所在地
          address: apiDataRow.address,
          //　選択
          selectFlag: false
        };
        partnerCompaniesList.push(rec);
      });

      // dataListを設定します。
      state.dataList = partnerCompaniesList;

      // totalを設定します。
      state.total = action.payload.total;

      // チェック済みフラグを設定します。
      state.existChecked = false;
      state.allSelect = false;
    },
    /**
     * 全選択チェックボックス変更
     */
    onChangeAllSelect: (state, action: PayloadAction<boolean>) => {
      let partnerCompaniesList: Array<Model.DataRow> = [];

      state.dataList.forEach((dataRow: Model.DataRow) => {
        const rec: Model.DataRow = {
          key: dataRow.key
          , companyId: dataRow.companyId
          , companyName: dataRow.companyName
          , companyNameKana: dataRow.companyNameKana
          , zipcode: dataRow.zipcode
          , address: dataRow.address
          , selectFlag: action.payload
        };

        partnerCompaniesList.push(rec);

      });
      
      state.dataList = partnerCompaniesList;
      state.allSelect = action.payload;
      state.existChecked = action.payload;
    },
    /**
     * 各行のチェックボックス変更
     */
    onChangeRowSelect: (state, action: PayloadAction<Model.changeCheckTransfer>) => {
      let partnerCompaniesList: Array<Model.DataRow> = [];
      let existOffFlag = false;
      let existOnFlag = false;

      state.dataList.forEach((dataRow: Model.DataRow) => {
        const rec: Model.DataRow = {
          key: dataRow.key
          , companyId: dataRow.companyId
          , companyName: dataRow.companyName
          , companyNameKana: dataRow.companyNameKana
          , zipcode: dataRow.zipcode
          , address: dataRow.address
          , selectFlag: action.payload.targetDataRow.key === dataRow.key ? action.payload.value : dataRow.selectFlag
        };

        partnerCompaniesList.push(rec);

        if (rec.selectFlag) {
          // 一つでも選択されている場合existOnFlagをtrueにします。
          existOnFlag = true;
        } else {
          // 一つでも選択されて以内場合existOffFlagをtrueにします。
          existOffFlag = true;
        }
      });
      let allSelect = existOffFlag ? false : true;
      state.dataList = partnerCompaniesList;
      state.allSelect = allSelect;
      state.existChecked = existOnFlag;
    },
    initPartnerCompaniesCriteria: (state) => {
      // 検索条件をクリア
      state.criteria = initialState.criteria;
      state.allSelect = false;
    },
    reflectProjectModalState: (state, action: PayloadAction<boolean>) => {
      // プロジェクト検索モーダル表示,非表示
      state.projectModalVisibleState = action.payload
    },
    reflectPartnerCompanyModalState: (state, action: PayloadAction<boolean>) => {
      // 協力会社検索モーダル表示,非表示
      state.partnerCompanyModalVisibleState = action.payload
    }
  }
});

export const {
  setPartnerCompaniesCriteria
  , setDataListOfPartnerCompaniesMaster
  , initCriteria
  , onChangeAllSelect
  , onChangeRowSelect
  , reflectProjectModalState
  , reflectPartnerCompanyModalState
  , initPartnerCompaniesCriteria
} = partnerCompaniesSlice.actions;

//　一覧検索
export const getDataListAsync = (PartnerCompaniesCriteria: Model.getDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_CMN_PRIME_AVAILABLE_PARTNER_COMPANIES, {
      params: PartnerCompaniesCriteria
    });
    // 結果をstateに設定します。
    dispatch(setDataListOfPartnerCompaniesMaster(response.data.data));
    // // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    console.log(error);
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

export const putDataRowAsync = (putData: Model.putDataList.Request): AppThunk => async dispatch => {

  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_CMN_PRIME_AVAILABLE_PARTNER_COMPANIES, putData);
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_PUT));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
};

export const deleteDataLsitAsync = (deleteData: Model.deleteData.Request): AppThunk => async dispatch => {

  try {
    // DELETE-APIを呼び出します。
    const response = await deleteRequest(CONSTANTS.URL_CMN_PRIME_AVAILABLE_PARTNER_COMPANIES, { data: deleteData });
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_DELETE));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_DELETE));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.primePartnerCompanies.dataList;
export const storedDataRow = (state: RootState) => state.primePartnerCompanies.dataRow;
export const storedCriteria = (state: RootState) => state.primePartnerCompanies.criteria;
export const storedTotal = (state: RootState) => state.primePartnerCompanies.total;
export const storedAllSelect = (state: RootState) => state.primePartnerCompanies.allSelect;
export const storedExistChecked = (state: RootState) => state.primePartnerCompanies.existChecked;
export const storedProjectModalVisibleState = (state: RootState) => state.primePartnerCompanies.projectModalVisibleState;
export const storedPartnerCompanyModalVisibleState = (state: RootState) => state.primePartnerCompanies.partnerCompanyModalVisibleState;

export default partnerCompaniesSlice.reducer;
