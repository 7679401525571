import * as Model from './commentModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { RootState } from '../../../store/store';
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";


const initialState: Model.CommentState = {
  comment: ""
};

export const CommentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    initComment: (state) => {
      state.comment = initialState.comment;
    },
    reflectComment: (state, action: PayloadAction<string>) => {
      // 入力されたコメントをstateに代入
      state.comment = action.payload;
    },
  },
});

export const {
  initComment,
  reflectComment
} = CommentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedComment = (state: RootState) => state.comment.comment;

export default CommentSlice.reducer;
