import React from 'react';
import { Layout } from 'antd';
import styles from './css/Forbidden.module.css'
import { Header, Footer } from './CorePageContents';

export const NoPartnerAvailableFlg = () => {

  return (
    <Layout>
      <Header />
        <div className={styles.loadingMessage}>
          グリーンファイル提出先の元請会社から利用対象の協力会社として設定いただく必要があります。
        </div>
      <Footer />
    </Layout>
  );
}
