import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Typography } from "antd";
import { ContentEx as Content } from "../../core/CorePageContents"
import styles from './Comment.module.css';
import * as Module from "../../../modules/prime/comment/commentModule";
import { initMessages } from "../../../modules/core/commonMessageModule";
import TextArea from "antd/lib/input/TextArea";
import * as CONSTANTS from "../../../constants/constants";
import * as messagesUtils from '../../../utils/messagesUtils';

const { Title } = Typography;

interface titleInterface {
  title: string;
  message: string;
}

export const Comment = (props: titleInterface) => {

  const dispatch = useDispatch();
  let comment = useSelector(Module.storedComment);

  useEffect(() => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
  }
  );

  const reflectComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(Module.reflectComment(event.target.value))
  }

  const ErrorMessage = () => {
    return (
      (comment.length) > CONSTANTS.COMMENT_MAX_LENGTH ?
        <span className={styles.errorMessage}>
          {messagesUtils.getMessage("ERROR_MAX_LENGTH", ["コメント", CONSTANTS.COMMENT_MAX_LENGTH.toString()])}
        </span>
        : <></>
    )
  }

  return (

    <Layout className={styles.layout}>
      <Content>
        <Title level={4} className={styles.functionTitle}>
          {props.title}
        </Title>
        <ErrorMessage />
        <h1>{props.message}</h1>
        <TextArea
          placeholder='コメント（任意）'
          rows={6}
          style={{
            width: '100%',
            fontSize: '150%',
            height: '50%',
            resize: 'none'
          }}
          value={comment}
          maxLength={CONSTANTS.COMMENT_MAX_LENGTH}
          onChange={(event) => { reflectComment(event) }}
        />
      </Content>
    </Layout>
  );
}

