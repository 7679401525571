import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router"
import { Store } from "antd/lib/form/interface";
import { Layout, Table, Select, Input } from "antd";
import { CriteriaForm, AuthButton, Checkbox } from "../../core/CoreForm"
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from "../../core/CorePageContents"
import styles from "./AuthorityList.module.css";
import * as Module from "../../../modules/prime/auth/authorityModule";
import * as Model from "../../../modules/prime/auth/authorityModel";
import * as CONSTANTS from "../../../constants/constants";
import * as messagesUtils from '../../../utils/messagesUtils';
import * as Utils from '../../../utils/utils';
import { initMessages, message, setMessages } from "../../../modules/core/commonMessageModule";
import AuthButtonStyles from "../../core/css/AuthButton.module.css";
import { initApiResult, storedApiResult } from "../../../modules/core/bffApiModule";

const { Option } = Select;

// チェックボックス
const CHECKBOX_CHECK = "1";

interface titleInterface {
  title: string;
}

export const AuthList = (props: titleInterface) => {

  const dispatch = useDispatch();
  const apiResult = useSelector(storedApiResult);
  let dataList = useSelector(Module.storedDataList);
  let totalCnt = useSelector(Module.storedTotal);
  let criteria = useSelector(Module.storedCriteriaInfo)
  let history = useHistory();
  let nextPageTokenList = useSelector(Module.storedNextPageTokenList);
  let managementGroupChoicesList = useSelector(Module.storedManagementGroupChoicesList)

  useEffect(() => {
    dispatch(Module.getDataListAsync(criteria));
  },
    [criteria]
  );

  useEffect(() => {

    // ログイン情報取得が正常終了した時の処理
    // 権限更新後、ログイン情報を再取得する→再取得の処理でメッセージがクリアされるので、セッションに保持しておいたメッセージを設定
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
      && "" === apiResult.errorCode
      && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
      && CONSTANTS.URL_CMN_USERS_ === apiResult.url) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // メッセージが設定されている場合、表示します。
      const messageText = messagesUtils.getMessageTextFromSession();
      const messageType = messagesUtils.getMessageTypeFromSession() || CONSTANTS.MESSAGE_TYPE_SUCCESS;
      if (messageText !== null) {
        const setMessage: message = {
          message: messageText
          , messageType: messageType
        }
        dispatch(setMessages([setMessage]));

        messagesUtils.clearSessionMessage();
      }
    }
  }, [apiResult]);

  // 編集ボタン押下時アクション
  const editClicked = () => {

    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    history.push(CONSTANTS.PATH_PRIME_AUTH_EDIT);
  }

  // 管理グループ項目表示制御
  const renderManagementGroupName = (text: string, row: Model.AuthorityRow, dataIndex: number) => {
    let managementGroupLabels: Array<any> = [];
    if (CONSTANTS.REFERENCE_RANGE_POINT === row.referenceRange) {
      row.managementGroupList.forEach((groupListRow: Model.ManagementGroupListRow) => {
        managementGroupLabels.push(
          <div key={groupListRow.managementGroupId} className={styles.managementGroupLabels}>
            {groupListRow.managementGroupName}
          </div>
        )
      });
    } else if (CONSTANTS.REFERENCE_RANGE_ALL === row.referenceRange) {
      managementGroupLabels.push(
        <div key="all">全グループ</div>
      );
    }
    return (
      <div key="result">{managementGroupLabels}</div>
    )
  }

  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let newCriteria: Model.AuthorityCriteria = {
      mcdpId: criteria.mcdpId,
      lastName: criteria.lastName,
      firstName: criteria.firstName,
      lastNameKana: criteria.lastNameKana,
      firstNameKana: criteria.firstNameKana,
      authKbn: criteria.authKbn,
      managementGroupId: criteria.managementGroupId,
      offset: 0,
      limit: criteria.limit,
      nextPageToken: "",
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      newCriteria[rec.key] = rec.value;
    })
    dispatch(Module.setCriteria(newCriteria));
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_CMN_PRIME_AUTH === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (criteria.offset > 0 && totalCnt > 0 && dataList.length === 0) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(totalCnt, criteria.limit);
      criteriaDataTransfer([
        { key: "offset", value: newOffset }
      ]);
    }
  }

  // 検索ボタン押下時アクション
  const pushSerchButton = (value: Store) => {

    // API結果情報を初期化します。
    dispatch(initApiResult());
    
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "mcdpId", value: value.mcdpId }
      , { key: "lastName", value: value.lastName }
      , { key: "firstName", value: value.firstName }
      , { key: "lastNameKana", value: value.lastNameKana }
      , { key: "firstNameKana", value: value.firstNameKana }
      , { key: "authKbn", value: value.authKbn }
      , { key: "managementGroupId", value: value.managementGroupId }
      , { key: "offset", value: 0 }
      , { key: "nextPageToken", value: "" }
    ]);
  }

  // すべての権限保有者の管理グループの重複排除したもの
  const CriteriamanagementGroup = () => {
    let list: Array<Model.ManagementGroupChoicesListRow> = [];
    list.push({
      managementGroupId: "",
      managementGroupName: "すべての管理グループ"
    })
    return list.concat(managementGroupChoicesList);
  }

  // 検索フォーム
  const AuthorityCriteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={pushSerchButton}
        initialValues={{
          "mcdpId": criteria.mcdpId
          , "lastName": criteria.lastName
          , "firstName": criteria.firstName
          , "lastNameKana": criteria.lastNameKana
          , "firstNameKana": criteria.firstNameKana
          , "authKbn": criteria.authKbn
          , "managementGroupId": criteria.managementGroupId
        }}
      >
        <div className={styles.criteriaSearchField}>
          <CriteriaForm.Item name="mcdpId">
            <Input type="text"
              placeholder="MCDP-ID"
              maxLength={CONSTANTS.AUTHORITY_MCDP_ID_MAX_LENGTH} />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="lastName">
            <Input type="text"
              placeholder="ユーザ名(姓)"
              maxLength={CONSTANTS.LAST_NAME_MAX_LENGTH} />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="firstName">
            <Input type="text"
              placeholder="ユーザ名(名)"
              maxLength={CONSTANTS.FIRST_NAME_MAX_LENGTH} />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="lastNameKana">
            <Input type="text"
              placeholder="ユーザ名(姓)カナ"
              maxLength={CONSTANTS.LAST_NAME_KANA_MAX_LENGTH} />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="firstNameKana">
            <Input type="text"
              placeholder="ユーザ名(名)カナ"
              maxLength={CONSTANTS.FIRST_NAME_KANA_MAX_LENGTH} />
          </CriteriaForm.Item>
          <CriteriaForm.Item
            name="authKbn">
            <Select style={{ marginRight: "5px" }}>
              <Option key="" value={""}>{CONSTANTS.AUTHORITY_ALL}</Option>
              <Option key="11" value="11">{CONSTANTS.AUTHORITY_AVAILABLE_AUTH}</Option>
              <Option key="12" value="12">{CONSTANTS.AUTHORITY_ATTENDANCE}</Option>
              <Option key="14" value="14">{CONSTANTS.AUTHORITY_QUALIFICATION}</Option>
              <Option key="19" value="19">{CONSTANTS.AUTHORITY_SYSTEM}</Option>
              <Option key="31" value="31">{CONSTANTS.AUTHORITY_CERTIFICATE_FLOW_REQUEST}</Option>
              <Option key="32" value="32">{CONSTANTS.AUTHORITY_CERTIFICATE_FLOW_CONFIRM}</Option>
              <Option key="33" value="33">{CONSTANTS.AUTHORITY_CERTIFICATE_FLOW_APPROVE}</Option>
            </Select>
          </CriteriaForm.Item>
          <CriteriaForm.Item
            name="managementGroupId"
            className={styles.criteriaForm}>
            <Select style={{ marginRight: "5px" }}>
              {CriteriamanagementGroup().map((rec: Model.ManagementGroupListRow) => (
                <Option key={rec.managementGroupId} value={rec.managementGroupId}> {rec.managementGroupName}</Option>
              ))}
            </Select>
          </CriteriaForm.Item>
          <AuthButton
            name={"検索"}
            shape={"round"}
            htmlType="submit"
          />
        </div>
      </CriteriaForm>
    )
  }

  // テーブル列を設定します。
  const columns = [
    {
      title: "MCDP-ID",

      dataIndex: "mcdpId",
      align: "center" as "center",
      width: 150,
    },
    {
      title: "ユーザ名",
      dataIndex: "userName",
      align: "center" as "center",
      width: 250,
    },
    {
      title: <span>本部/支店<br />{CONSTANTS.AUTHORITY_AVAILABLE_AUTH}</span>,
      dataIndex: "primeAvailableAuth",
      align: "center" as "center",
      width: 100,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          style={CHECKBOX_CHECK === text ? {} : { display: "none" }}
          disabled
        >
        </Checkbox>
    },
    {
      title: <span>本部/支店<br />{CONSTANTS.AUTHORITY_ATTENDANCE}</span>,
      dataIndex: "primeAttendanceApprovalAuth",
      align: "center" as "center",
      width: 100,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          style={CHECKBOX_CHECK === text ? {} : { display: "none" }}
          disabled
        >
        </Checkbox>
    },
    {
      title: CONSTANTS.AUTHORITY_QUALIFICATION,
      dataIndex: "primeQualificationMngAuth",
      align: "center" as "center",
      width: 100,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          style={CHECKBOX_CHECK === text ? {} : { display: "none" }}
          disabled
        >
        </Checkbox>
    },
    {
      title: CONSTANTS.AUTHORITY_SYSTEM,
      dataIndex: "primeSystemMngAuth",
      align: "center" as "center",
      width: 100,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          style={CHECKBOX_CHECK === text ? {} : { display: "none" }}
          disabled
        >
        </Checkbox>
    },
    {
      title: <span>資格承認フロー<br />{CONSTANTS.AUTHORITY_CERTIFICATE_FLOW_REQUEST}</span>,
      dataIndex: "certificateFlowRequestAuth",
      align: "center" as "center",
      width: 100,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          style={CHECKBOX_CHECK === text ? {} : { display: "none" }}
          disabled
        >
        </Checkbox>
    },
    {
      title: <span>資格承認フロー<br />{CONSTANTS.AUTHORITY_CERTIFICATE_FLOW_CONFIRM}</span>,
      dataIndex: "certificateFlowConfirmAuth",
      align: "center" as "center",
      width: 100,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          style={CHECKBOX_CHECK === text ? {} : { display: "none" }}
          disabled
        >
        </Checkbox>
    },
    {
      title: <span>資格承認フロー<br />{CONSTANTS.AUTHORITY_CERTIFICATE_FLOW_APPROVE}</span>,
      dataIndex: "certificateFlowApproveAuth",
      align: "center" as "center",
      width: 100,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          style={CHECKBOX_CHECK === text ? {} : { display: "none" }}
          disabled
        >
        </Checkbox>
    },
    {
      title: "管理グループ",
      dataIndex: "referenceRange",
      align: "center" as "center",
      width: 200,
      render: renderManagementGroupName,
    }
  ];

  // ページングボタンを描画します。
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === "prev") {
      return <AuthButton name="＜" size="small" />;
    }
    if (type === "next") {
      return <AuthButton name="＞" size="small" />;
    }
    return originalElement
  }

  //　ページング選択時イベント
  const handleTableChange = (pagination: any) => {

    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let offsetReset: boolean = false;

    if (criteria.limit !== pagination.pageSize) {
      offsetReset = true;
    }

    criteriaDataTransfer([
      { key: "limit", value: pagination.pageSize }
      , { key: "offset", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
      , { key: "nextPageToken", value: typeof nextPageTokenList[pagination.current] === "undefined" ? "" : nextPageTokenList[pagination.current] }
    ])
  }

  /** 一覧部 */
  const AuthorityContent = () => {
    return (
      totalCnt > 0 && dataList.length > 0 ?
        <>
          <span style={{ float: "left" }}>スキルマップサイトを利用するプロジェクトに招待されているユーザーは、自動的に利用権限が付与されており、スキルマップサイトをご利用いただけます。</span><br />
          <span style={{ float: "left" }}>※プロジェクト招待時に設定された役割によって、利用可能な操作が変わります。</span>
          <Table
            columns={columns}
            size={"small"}
            scroll={{ x: 1000 }}
            dataSource={dataList}
            pagination={{
              total: totalCnt  //総件数
              , defaultCurrent: 1 //
              , current: criteria.offset / criteria.limit + 1
              , pageSize: criteria.limit //ページサイズを入れる
              , pageSizeOptions: ["5", "10", "20", "50", "100"]
              , showSizeChanger: true
              , showTotal: (total, range) => `${total}件中、${criteria.offset + 1}件目から${total <= criteria.offset + criteria.limit ? total : criteria.offset + criteria.limit}件目を表示`
              , itemRender: pageItemRender
              , locale: { items_per_page: "件/ページ" }
            }}
            onChange={(pagination, filters) => handleTableChange(pagination)}
          />
        </>
        : <></>
    );
  }

  // フッターボタンエリアを描画します。
  const ButtonArea = () => {
    return (
      totalCnt > 0 ?
        <FooterButtonArea>
          <AuthButton
            name={"編集"}
            size={"large"}
            shape={"round"}
            onClick={editClicked}
            className={AuthButtonStyles.authButtonFixed}
          />
        </FooterButtonArea>
        : <></>
    )
  }

  return (
    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <AuthorityCriteria />
        <CommonMessage searchNoDataName="ユーザー" />
        <AuthorityContent />
      </Content>
      <ButtonArea />
      <Footer />
    </Layout >
  );
}
