import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, putRequest, deleteRequest, apiSuccessHandler, apiErrorHandler, setApiResult } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
import * as Model from './qualificationModel';


// state初期化
const initialState: Model.State = {
  dataList: []
  , dataRow: {
    key: ""
    , processType: ""
    , originalQualificationId: ""
    , originalQualificationNo: ""
    , originalQualificationName: ""
    , allowanceType: CONSTANTS.ALLOWANCE_TYPE.DAILY.code
    , allowanceInspectionType: CONSTANTS.ALLOWANCE_INSPECTION_TYPE.EXIST.code
    , allowanceInspectionTime: 1
    , monthlyAllowanceInspectionDays: 0
    , allowanceAmount: 0
    , ceilingDailyAllowanceInspectionDays: NaN
    , ceilingDailyAllowanceAmount: NaN
    , deadlineExist: CONSTANTS.EXIST_DEADLINE.EXIST.code
    , deadlineStartDate: ""
    , deadlineEndDate: ""
    , remarks1: ""
    , remarks2: ""
    , numberOfCompanies: 0
    , numberOfPeople: 0
    , updateDatetime: ""
  }
  , total: 0
  , criteria: {
    originalQualificationNo: ""
    , originalQualificationName: ""
    , originalQualificationId: ""
    , sortItem: ""
    , sortOrder: ""
    , offset: 0
    , limit: 10
  }
};

// スライサー （state更新アクションの実装）
export const qualificationSlice = createSlice({
  name: 'primeQualification',
  initialState,
  reducers: {
    initCriteria: (state) => {
      let criteria = {
        originalQualificationNo: ""
        , originalQualificationName: ""
        , originalQualificationId: ""
        , sortItem: ""
        , sortOrder: ""
        , offset: 0
        , limit: 10
      }
      state.criteria = criteria;
    }
    /** 検索条件のstate更新 */
    , setQualificationCriteria: (state, action: PayloadAction<Model.Criteria>) => {
      let qualificationCriteria: Model.Criteria = {
        originalQualificationNo: action.payload.originalQualificationNo,
        originalQualificationName: action.payload.originalQualificationName,
        originalQualificationId: action.payload.originalQualificationId,
        sortItem: action.payload.sortItem,
        sortOrder: action.payload.sortOrder,
        offset: action.payload.offset,
        limit: action.payload.limit
      }
      return Object.assign({}, state, { criteria: qualificationCriteria })
    },
    /** 一覧画面から詳細画面遷移時のstate更新 */
    prepareMoveToDetail: (state, action: PayloadAction<Model.dataRow>) => {
      const rec: Model.dataRow = {
        key: ""
        , processType: ""
        , originalQualificationId: action.payload.originalQualificationId
        , originalQualificationNo: ""
        , originalQualificationName: ""
        , allowanceType: CONSTANTS.ALLOWANCE_TYPE.DAILY.code
        , allowanceInspectionType: CONSTANTS.ALLOWANCE_INSPECTION_TYPE.EXIST.code
        , allowanceInspectionTime: 1
        , monthlyAllowanceInspectionDays: 0
        , allowanceAmount: 0
        , ceilingDailyAllowanceInspectionDays: NaN
        , ceilingDailyAllowanceAmount: NaN
        , deadlineExist: ""
        , deadlineStartDate: ""
        , deadlineEndDate: ""
        , remarks1: ""
        , remarks2: ""
        , numberOfCompanies: 0
        , numberOfPeople: 0
        , updateDatetime: ""
      };
      return Object.assign({}, state, { dataRow: rec })
    },
    /** 一覧画面描画時のstate更新 */
    setDataListOfQualificationMaster: (state, action: PayloadAction<Model.getDataList.Response>) => {

      let qualificationList: Array<Model.dataRow> = [];

      action.payload.originalQualificationList.forEach((apiDataRow: Model.dataRow) => {

        const rec: Model.dataRow = {
          key: apiDataRow.originalQualificationId
          , processType: ""
          , originalQualificationId: apiDataRow.originalQualificationId
          , originalQualificationNo: apiDataRow.originalQualificationNo
          , originalQualificationName: apiDataRow.originalQualificationName
          , allowanceType: apiDataRow.allowanceType
          , allowanceInspectionType: apiDataRow.allowanceInspectionType
          , allowanceInspectionTime: apiDataRow.allowanceInspectionTime
          , monthlyAllowanceInspectionDays: apiDataRow.monthlyAllowanceInspectionDays
          , allowanceAmount: apiDataRow.allowanceAmount
          , ceilingDailyAllowanceInspectionDays: apiDataRow.ceilingDailyAllowanceInspectionDays
          , ceilingDailyAllowanceAmount: apiDataRow.ceilingDailyAllowanceAmount
          , deadlineExist: apiDataRow.deadlineExist
          , deadlineStartDate: apiDataRow.deadlineStartDate
          , deadlineEndDate: apiDataRow.deadlineEndDate
          , remarks1: apiDataRow.remarks1
          , remarks2: apiDataRow.remarks2
          , numberOfCompanies: apiDataRow.numberOfCompanies
          , numberOfPeople: apiDataRow.numberOfPeople
          , updateDatetime: apiDataRow.updateDatetime
        };
        qualificationList.push(rec);
      });

      return Object.assign({}, state, { dataList: qualificationList }, { total: action.payload.total })
    },
    /** 詳細画面描画時のstate更新 */
    setDataRowOfQualificationMaster: (state, action: PayloadAction<Model.getDataList.Response>) => {
      const rec: Model.dataRow = {
        key: action.payload.originalQualificationList[0].originalQualificationId
        , processType: ""
        , originalQualificationId: action.payload.originalQualificationList[0].originalQualificationId
        , originalQualificationNo: action.payload.originalQualificationList[0].originalQualificationNo
        , originalQualificationName: action.payload.originalQualificationList[0].originalQualificationName
        , allowanceType: action.payload.originalQualificationList[0].allowanceType
        , allowanceInspectionType: action.payload.originalQualificationList[0].allowanceInspectionType
        , allowanceInspectionTime: action.payload.originalQualificationList[0].allowanceInspectionTime
        , monthlyAllowanceInspectionDays: action.payload.originalQualificationList[0].monthlyAllowanceInspectionDays
        , allowanceAmount: action.payload.originalQualificationList[0].allowanceAmount
        , ceilingDailyAllowanceInspectionDays: action.payload.originalQualificationList[0].ceilingDailyAllowanceInspectionDays
        , ceilingDailyAllowanceAmount: action.payload.originalQualificationList[0].ceilingDailyAllowanceAmount
        , deadlineExist: action.payload.originalQualificationList[0].deadlineExist
        , deadlineStartDate: action.payload.originalQualificationList[0].deadlineStartDate
        , deadlineEndDate: action.payload.originalQualificationList[0].deadlineEndDate
        , remarks1: action.payload.originalQualificationList[0].remarks1
        , remarks2: action.payload.originalQualificationList[0].remarks2
        , numberOfCompanies: action.payload.originalQualificationList[0].numberOfCompanies
        , numberOfPeople: action.payload.originalQualificationList[0].numberOfPeople
        , updateDatetime: action.payload.originalQualificationList[0].updateDatetime
      };

      return Object.assign({}, state, { dataRow: rec })
    },
    /** 登録画面描画時のstate更新 */
    initDataRow: (state) => {
      return Object.assign({}, state, { dataRow: initialState.dataRow })
    }
  },
});

export const {
  initCriteria
  , setQualificationCriteria
  , setDataListOfQualificationMaster
  , prepareMoveToDetail
  , setDataRowOfQualificationMaster
  , initDataRow
} = qualificationSlice.actions;


//　一覧検索
export const getDataListAsync = (QualificationCriteria: Model.getDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_QUALIFICATIONS_PRIME_LIST, {
      params: QualificationCriteria
    });
    // 結果をstateに設定します。
    dispatch(setDataListOfQualificationMaster(response.data.data));
    // // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　詳細画面遷移時
export const getDataDetailAsync = (QualificationCriteria: Model.getDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。 TODO モックを使っているためURLが違う
    const response = await getRequest(CONSTANTS.URL_QUALIFICATIONS_PRIME_LIST, {
      params: QualificationCriteria
    });
    if (response.data.data.total > 1) {
      dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
      return;
    }
    if (response.data.data.total === 0) {
      dispatch(setApiResult({ status: response.status, errorCode: "EA9999000", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
      return;
    }
    // 結果をstateに設定します。
    dispatch(setDataRowOfQualificationMaster(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

export const putDataRowAsync = (putData: Model.putDataList.Request): AppThunk => async dispatch => {

  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_QUALIFICATIONS_PRIME_INFO, putData);
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_PUT));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
};

export const deleteDataLsitAsync = (deleteData: Model.deleteData.Request): AppThunk => async dispatch => {

  try {
    // DELETE-APIを呼び出します。
    const response = await deleteRequest(CONSTANTS.URL_QUALIFICATIONS_PRIME_INFO, { data: deleteData });
    if (response.data.cmn.errorCode === "EA9999409") {
      dispatch(setApiResult({ status: response.status, errorCode: "EA1003409", requestMethod: CONSTANTS.REQUEST_METHOD_DELETE, url: response.config.url }));
      return;
    }
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_DELETE));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_DELETE));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.primeQualification.dataList;
export const storedDataRow = (state: RootState) => state.primeQualification.dataRow;
export const storedCriteria = (state: RootState) => state.primeQualification.criteria;
export const storedTotal = (state: RootState) => state.primeQualification.total;

export default qualificationSlice.reducer;
