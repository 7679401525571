import React from 'react';
import { Alert } from 'antd';
import { ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import * as Module from '../../../modules/core/commonMessageModule';
import { storedApiResult } from '../../../modules/core/bffApiModule';
import styles from '../css/CommonMessage.module.css';
import { getMessage } from '../../../utils/messagesUtils';
import * as CONSTANTS from '../../../constants/constants';

interface commonErrorMessages {
  messages?: Array<Module.message>;
  isModal?: boolean;
  searchNoDataName?: string;
}

export const CommonMessage = (props?: commonErrorMessages) => {
  const apiResult = useSelector(storedApiResult);
  const commonMessages = useSelector(Module.commonMessageState);
  const modalCommonMessages = useSelector(Module.modalMessageState);

  let workMessages: Array<Module.message> = [];
  let isModal = false;

  // パラメータ指定がある場合、指定されたパラメータから値を設定します。
  if (typeof props !== "undefined") {
    if (typeof props.messages !== "undefined" && props.messages.length > 0) {
      workMessages = props.messages;
    }
    if (typeof props.isModal !== "undefined") {
      isModal = props.isModal;
    }
  }

  // api呼び出し結果が取得できた場合、
  // ステータスコード200でエラーコードが設定されている場合、エラーコードに対応するメッセージを表示します。
  if (workMessages.length === 0 && apiResult.status === 200 && apiResult.errorCode !== "") {

    // エラーコードからメッセージを取得
    const params: string[] = [];
    params.push(props?.searchNoDataName || "データ");
    const setMessage: Module.message = {
      message: getMessage(apiResult.errorCode, params)
      , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
    }
    workMessages.push(setMessage);
  }

  // 共通メッセージに設定されたメッセージを表示します。
  if (isModal) {
    modalCommonMessages.forEach((message: Module.message) => {
      workMessages.push(message);
    });
  } else {
    commonMessages.forEach((message: Module.message) => {
      workMessages.push(message);
    });
  }

  const generateJtx = () => {

    if (workMessages.length > 1) {
      return (
        workMessages.map((messages: Module.message, index: number) => (
          <Alert
            key={index}
            message={messages.message}
            type="error"
            showIcon
            className={messages.messageType === CONSTANTS.MESSAGE_TYPE_ERROR ? styles.commonMessage : styles.commonSuccessMessage}
            icon={messages.messageType === CONSTANTS.MESSAGE_TYPE_ERROR ? <CheckOutlined className={styles.iconSize} /> : <></>}
          />
        ))
      );
    }

    return (
      workMessages.map((messages: Module.message, index: number) => (
        <Alert
          key={index}
          message={messages.message}
          type="error"
          showIcon
          className={messages.messageType === CONSTANTS.MESSAGE_TYPE_ERROR ? styles.commonMessage : styles.commonSuccessMessage}
          icon={messages.messageType === CONSTANTS.MESSAGE_TYPE_ERROR ? <ExclamationCircleOutlined className={styles.iconSize} /> : <></>}
        />
      ))
    );

  }

  return (
    <div>
      {generateJtx()}
    </div>
  );
}
