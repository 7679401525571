import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
import * as Model from './partnerCompanySelectModel';
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

// state初期化
const initialState: Model.State = {
  dataList: []
  , dataRow: {
    key: ""
    , companyId: ""
    , companyName: ""
    , companyNameKana: ""
    , companyType: ""
    , zipcode: ""
    , address: ""
  }
  , criteria: {
    projectId: ""
    , companyName: ""
    , nextPageToken: ""
    , limit: 10
  }
  , total: 0
  , nextPageTokenList: []
  , currentPage: 1
  , selectValue: ""
};

export const PartnerCompanySlice = createSlice({
  name: 'partnerCompanySelect',
  initialState,
  reducers: {
    initState: (state) => {
      state.dataList = initialState.dataList;
      state.dataRow = initialState.dataRow;
      state.criteria = initialState.criteria;
      state.total = initialState.total;
      state.nextPageTokenList = initialState.nextPageTokenList;
      state.currentPage = initialState.currentPage;
    },
    getDataListOfProjectSelect: (state, action: PayloadAction<Model.getDataList.Response>) => {

      let partnerCompanyList: Array<Model.DataRow> = [];

      action.payload.partnerCompanyList.forEach((apiDataRow: Model.DataRow) => {

        const rec: Model.DataRow = {
          key: apiDataRow.companyId,
          // 企業ID
          companyId: apiDataRow.companyId,
          // 企業名称
          companyName: apiDataRow.companyName,
          // 企業名称カナ
          companyNameKana: apiDataRow.companyNameKana,
          // 企業区分
          companyType: apiDataRow.companyType,
          // 郵便番号
          zipcode: apiDataRow.zipcode,
          // 住所
          address: apiDataRow.address
        };
        partnerCompanyList.push(rec);
      });
      state.dataList = partnerCompanyList;
      state.nextPageTokenList[state.currentPage + 1] = action.payload.nextPageToken;
      state.total = action.payload.total;
    },
    setCriteria: (state, action: PayloadAction<Model.Criteria>) =>{
      let criteria: Model.Criteria = {
        projectId: action.payload.projectId,
        companyName: action.payload.companyName,
        nextPageToken: action.payload.nextPageToken,
        limit: action.payload.limit
      }
      state.criteria = criteria;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    reflectModalSelectValue: (state, action: PayloadAction<string>) => {
      state.selectValue = action.payload;
    }
  }
});

export const {
  initState
  , getDataListOfProjectSelect
  , setCriteria
  , setCurrentPage
  , reflectModalSelectValue
} = PartnerCompanySlice.actions;

export const getDataListAsync = (partnerCompanyCriteria: Model.getDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_CMN_PRIME_PARTNER_COMPANIES,{
      params: partnerCompanyCriteria
    });
    // 結果をstateに設定します。
    dispatch(getDataListOfProjectSelect(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.partnerCompanySelect.dataList;
export const storedDataRow = (state: RootState) => state.partnerCompanySelect.dataRow;
export const storedDataCriteria = (state: RootState) => state.partnerCompanySelect.criteria;
export const storedDataTotal = (state: RootState) => state.partnerCompanySelect.total;
export const storedDataCurrentPage = (state: RootState) => state.partnerCompanySelect.currentPage;
export const storedNextPageTokenList = (state: RootState) => state.partnerCompanySelect.nextPageTokenList;
export const storedSelectValue = (state: RootState) => state.partnerCompanySelect.selectValue;

export default PartnerCompanySlice.reducer;
