import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { primeMenuPermissionSettings } from '../../settings/menuPermissionSettings';
import { getRequest } from './bffApiModule'
import * as CONSTANTS from '../../constants/constants';
interface baseInterface {
  [propertyName: string]: any
}

// メニューアイテム定義
export interface IMenuItem extends baseInterface {
  // メニュー ID
  id: string
  // タイトル
  title: string
  // 押下時遷移先URL
  url: string
}

// state定義
interface MenuState {
  // 元請メニュー
  primeMenu: Array<IMenuItem>
  // 協力会社メニュー
  partnerMenu: Array<IMenuItem>
  // カレントメニューアイテムID
  currentMenuItemId: string
}

// メニューstate初期化
const initialState: MenuState = {
  // 元請メニュー
  primeMenu: []
  // 協力会社メニュー
  , partnerMenu: []
  // カレントメニュー
  , currentMenuItemId: ""
}

// スライサー （state更新アクションの実装）
export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setPrimeMenu: (state, action: PayloadAction<IMenuItem[]>) => {
      state.primeMenu = action.payload;
    },
    setPartnerMenu: (state, action: PayloadAction<IMenuItem[]>) => {
      state.partnerMenu = action.payload;
    },
    setCurrnetMenuItemId: (state, action: PayloadAction<string>) => {
      state.currentMenuItemId = action.payload;
    },
  },
});

// state更新アクションを公開
export const { setPrimeMenu, setPartnerMenu, setCurrnetMenuItemId } = menuSlice.actions;

// state参照
export const storedPrimeMenu = (state: RootState) => state.menu.primeMenu;
export const storedPartnerMenu = (state: RootState) => state.menu.partnerMenu;
export const storedCurrentMenuItemId = (state: RootState) => state.menu.currentMenuItemId;
// 現在表示中のメニューがプライムメニューの場合true、それ以外の場合falseを返却します。
export const isPrimeMenu = (state: RootState) => {
  const primeMenuItem = primeMenuPermissionSettings.find((menuItem: IMenuItem) => {
    return (menuItem.id === state.menu.currentMenuItemId)
  })

  if (typeof primeMenuItem === "undefined") {
    return false;
  }

  return (primeMenuItem.id !== "") ? true : false;
}

/**
 * ログアウト処理
 */
export const logout = async () => {

  try {
    // bffのログアウト処理を呼び出す  
    await getRequest(CONSTANTS.URL_LOGOUT);
  } catch (error) {
    return Promise.reject(error);
  }
}

export default menuSlice.reducer;
