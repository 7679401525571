import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRequest, apiSuccessHandler, apiErrorHandler, putRequest, setApiResult } from '../../core/bffApiModule'
import { setMessages, message } from '../../core/commonMessageModule';
import * as messagesUtils from '../../../utils/messagesUtils';
import { AppThunk, RootState } from '../../../store/store';
import * as CONSTANTS from '../../../constants/constants';
import * as formatUtils from '../../../utils/formatUtils';
import * as Utils from '../../../utils/utils';
import * as Model from './attendanceMobileModel';
import { Moment } from 'moment';
import moment from 'moment';

export interface changeCheckTransfer {
  targetDataRow: Model.WorkRecordDetailRow
  value: boolean
}

// 初期設定の日付を計算
const setDate = (num: number) => {
  const nowMoment: Moment | undefined = moment(new Date(), moment.ISO_8601);
  return nowMoment === undefined ? "" : nowMoment.add(num, "month").format("YYYYMM");
}

// 元請勤怠実績データ初期化
const initialState: Model.WorkRecordPartnerState = {
  dataList: [],
  summaryTotal: 0
  , workRecordSummaryCriteria: {
    primeCompanyName: "",
    primeCompanyNameKana: "",
    projectName: "",
    belongCompanyName: "",
    belongCompanyNameKana: "",
    firstPartnerCompanyName: "",
    firstPartnerCompanyNameKana: "",
    fromYYYYMMDD: setDate(0),
    toYYYYMMDD: setDate(0),
    notSubmitted: "1",
    submitted: "1",
    confirmed: "1",
    approved: "1",
    lastName: "",
    firstName: "",
    sortItem: "",
    sortOrder: "",
    start: 0,
    count: 100
  }
  , primeCompanyId: ""
  , primeCompanyName: ""
  , projectId: ""
  , projectName: ""
  , belongCompanyId: ""
  , belongCompanyName: ""
  , firstPartnerCompanyId: ""
  , detailDataList: []
  , detailTotal: 0
  , workRecordDetailCriteria: {
    attendanceYearMonth: ""
    , primeCertificationNo: ""
    , workerLastName: ""
    , workerLastNameKana: ""
    , workerFirstName: ""
    , workerFirstNameKana: ""
    , firstPartnerCompanyName: ""
    , firstPartnerCompanyNameKana: ""
    , offset: 0
    , limit: 1
    , approved: true
    , submitted: true
    , remand: true
    , noSubmitted: true
  }
  , allSelect: false
  , existSubmittedChecked: false
  , existNotSubmittedChecked: false
  , existNotSubmitted: false
  , modalVisibleState: false
  , selectedAttendanceInfo: {
    workRecordSubmissionHistoryId: ""
    , primeCompanyId: ""
    , belongCompanyId: ""
    , workerId: ""
    , projectId: ""
    , firstPartnerCompanyId: ""
    , dailyWorkStatus: {
      date: ""
      , firstInTime: ""
      , lastOutTime: ""
      , workingTime: ""
      , meetQualificationRequirementType: ""
      , updateDatetime: ""
    }
  }
  , editAvailableFlag: 1
  , existAvailableRow: false
  , isLoading: false
  , searchModalVisibleState: false
  , workerNameList: []
  , showDetailDataIndex: 0
  , worktimeConfirmModalVisibleState: false
  , worktimeConfirmText: ""
  , isContainInvalidInOutTime: false
};

export const partnerAttendanceSlice = createSlice({
  name: 'partnerAttendanceSlice',
  initialState,
  reducers: {
    // -------------
    // 勤怠一覧
    // -------------

    prepareMoveToDetail: (state, action: PayloadAction<Model.WorkRecordPartnerSummaryRow>) => {
      state.primeCompanyId = action.payload.primeCompanyId;
      state.projectId = action.payload.projectId;
      state.belongCompanyId = action.payload.belongCompanyId;
      state.workRecordDetailCriteria.attendanceYearMonth = formatUtils.formatMonthNonSlash(action.payload.attendanceYearMonth)
      state.modalVisibleState = false;
      state.isLoading = false;
      state.showDetailDataIndex = 0;
      state.worktimeConfirmModalVisibleState = false;
      state.worktimeConfirmText = "";
    },

    prepareMoveToDetailCriteria: (state, action: PayloadAction<Model.WorkRecordSummaryCriteria>) => {
      let workRecordDetailCriteria = {
        attendanceYearMonth: state.workRecordDetailCriteria.attendanceYearMonth
        , primeCertificationNo: ""
        , workerLastName: action.payload.lastName
        , workerLastNameKana: ""
        , workerFirstName: action.payload.firstName
        , workerFirstNameKana: ""
        , firstPartnerCompanyName: action.payload.firstPartnerCompanyName
        , firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana
        , offset: 0
        , limit: 1
        , approved: action.payload.approved === "1" ? true : false
        , submitted: action.payload.submitted === "1" || action.payload.confirmed === "1" ? true : false
        , remand: action.payload.notSubmitted === "1" ? true : false
        , noSubmitted: action.payload.notSubmitted === "1" ? true : false
      }

      state.workRecordDetailCriteria = workRecordDetailCriteria;
    },

    initDataList: (state) => {
      state.dataList = initialState.dataList;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setDataListOfWorkRecordPartner: (state, action: PayloadAction<Model.AttendanceRecordApiData>) => {

      state.dataList = initialState.dataList;

      const responseAttendanceRecordList: Model.WorkRecordPartnerSummaryRow[] = action.payload.attendanceRecordList || [];
      let attendanceRecordList: Array<Model.WorkRecordPartnerSummaryRow> = [];
      responseAttendanceRecordList.forEach((attnendanceRecord: Model.WorkRecordPartnerSummaryRow) => {

        const rec: Model.WorkRecordPartnerSummaryRow = {
          key: attnendanceRecord.projectId + attnendanceRecord.attendanceYearMonth
          , primeCompanyId: attnendanceRecord.primeCompanyId
          , primeCompanyName: attnendanceRecord.primeCompanyName
          , projectId: attnendanceRecord.projectId
          , projectName: attnendanceRecord.projectName
          , firstPartnerCompanyId: attnendanceRecord.firstPartnerCompanyId
          , firstPartnerCompanyName: attnendanceRecord.firstPartnerCompanyName
          , belongCompanyId: attnendanceRecord.belongCompanyId
          , belongCompanyName: attnendanceRecord.belongCompanyName
          , notSubmitted: attnendanceRecord.notSubmitted
          , submitted: attnendanceRecord.submitted
          , confirmed: attnendanceRecord.confirmed
          , approved: attnendanceRecord.approved
          , attendanceYearMonth: attnendanceRecord.attendanceYearMonth
        };
        attendanceRecordList.push(rec);
      });

      state.dataList = attendanceRecordList;
      state.summaryTotal = action.payload.total
    },

    /**
     * 検索条件のstate更新
     */
    setSummaryCriteria: (state, action: PayloadAction<Model.WorkRecordSummaryCriteria>) => {
      let criteria: Model.WorkRecordSummaryCriteria = {
        primeCompanyName: action.payload.primeCompanyName,
        primeCompanyNameKana: action.payload.primeCompanyNameKana,
        projectName: action.payload.projectName,
        belongCompanyName: action.payload.belongCompanyName,
        belongCompanyNameKana: action.payload.belongCompanyNameKana,
        firstPartnerCompanyName: action.payload.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana,
        fromYYYYMMDD: action.payload.fromYYYYMMDD,
        toYYYYMMDD: action.payload.toYYYYMMDD,
        notSubmitted: action.payload.notSubmitted,
        submitted: action.payload.submitted,
        confirmed: action.payload.confirmed,
        approved: action.payload.approved,
        lastName: action.payload.lastName,
        firstName: action.payload.firstName,
        sortItem: action.payload.sortItem,
        sortOrder: action.payload.sortOrder,
        start: action.payload.start,
        count: action.payload.count
      }
      return Object.assign({}, state, { workRecordSummaryCriteria: criteria })
    },

    // -------------
    // 勤怠明細一覧
    // -------------

    /**
     * URLに設定されているパラメータをstateに反映
     */
    setUrlParameterValue: (state, action: PayloadAction<Model.UrlParameter>) => {
      let criteria: Model.WorkRecordDetailCriteria = {
        attendanceYearMonth: action.payload.attendanceYearMonth,
        primeCertificationNo: state.workRecordDetailCriteria.primeCertificationNo,
        workerLastName: state.workRecordDetailCriteria.workerLastName,
        workerFirstName: state.workRecordDetailCriteria.workerFirstName,
        workerLastNameKana: state.workRecordDetailCriteria.workerLastNameKana,
        workerFirstNameKana: state.workRecordDetailCriteria.workerFirstNameKana,
        firstPartnerCompanyName: state.workRecordDetailCriteria.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: state.workRecordDetailCriteria.firstPartnerCompanyNameKana,
        offset: state.workRecordDetailCriteria.offset,
        limit: state.workRecordDetailCriteria.limit,
        approved: state.workRecordDetailCriteria.approved,
        submitted: state.workRecordDetailCriteria.submitted,
        remand: state.workRecordDetailCriteria.remand,
        noSubmitted: state.workRecordDetailCriteria.noSubmitted
      }

      return Object.assign(
        {}, state,
        { workRecordDetailCriteria: criteria },
        { projectId: action.payload.projectId },
        { belongCompanyId: action.payload.belongCompanyId }
      );
    },

    /**
     * 検索条件の初期化
     */
    initCriteria: (state) => {
      let workRecordDetailCriteria = {
        attendanceYearMonth: state.workRecordDetailCriteria.attendanceYearMonth
        , primeCertificationNo: ""
        , workerLastName: ""
        , workerLastNameKana: ""
        , workerFirstName: ""
        , workerFirstNameKana: ""
        , firstPartnerCompanyName: ""
        , firstPartnerCompanyNameKana: ""
        , offset: 0
        , limit: 1
        , approved: true
        , submitted: true
        , remand: true
        , noSubmitted: true
      }

      let workRecordSummaryCriteria = {
        primeCompanyName: ""
        , primeCompanyNameKana: ""
        , projectName: ""
        , belongCompanyName: ""
        , belongCompanyNameKana: ""
        , firstPartnerCompanyName: ""
        , firstPartnerCompanyNameKana: ""
        , lastName: ""
        , firstName: ""
        , fromYYYYMMDD: setDate(0)
        , toYYYYMMDD: setDate(0)
        , notSubmitted: "1"
        , submitted: "1"
        , confirmed: "1"
        , approved: "1"
        , sortItem: ""
        , sortOrder: ""
        , start: 0
        , count: 100
      }

      return Object.assign({}, state, { workRecordDetailCriteria: workRecordDetailCriteria }, { workRecordSummaryCriteria: workRecordSummaryCriteria });
    },

    /**
     * 検索条件のstate更新
     */
    setCriteria: (state, action: PayloadAction<Model.WorkRecordDetailCriteria>) => {
      let criteria: Model.WorkRecordDetailCriteria = {
        attendanceYearMonth: action.payload.attendanceYearMonth,
        primeCertificationNo: action.payload.primeCertificationNo,
        workerLastName: action.payload.workerLastName,
        workerFirstName: action.payload.workerFirstName,
        workerLastNameKana: action.payload.workerLastNameKana,
        workerFirstNameKana: action.payload.workerFirstNameKana,
        firstPartnerCompanyName: action.payload.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana,
        offset: action.payload.offset,
        limit: action.payload.limit,
        approved: action.payload.approved,
        submitted: action.payload.submitted,
        remand: action.payload.remand,
        noSubmitted: action.payload.noSubmitted
      }
      return Object.assign({}, state, { workRecordDetailCriteria: criteria })
    },

    /**
     * 一覧の初期化
     */
    initDetailDataList: (state) => {
      return Object.assign({}, state, { detailDataList: initialState.detailDataList })
    },

    /**
     * 一覧レコードのセット
     */
    setDetailDataListOfWorkRecordMobilePartner: (state, action: PayloadAction<Model.AttendanceAndQualificationInfo>) => {
      let userWorkStatusList: Array<Model.WorkRecordDetailRow> = [];

      let total = action.payload.attendanceData.total;
      let primeCompanyId = action.payload.attendanceData.primeCompanyId;
      let primeCompanyName = action.payload.attendanceData.primeCompanyName;
      let projectId = action.payload.attendanceData.projectId;
      let projectName = action.payload.attendanceData.projectName;
      let belongCompanyId = action.payload.attendanceData.belongCompanyId;
      let belongCompanyName = action.payload.attendanceData.belongCompanyName;
      let editAvailableFlag = action.payload.attendanceData.editAvailableFlag;
      let existAvailableRow: boolean = false;
      let existNotSubmitted: boolean = false;
      let qualificationNameList = action.payload.qualificationNameList;

      action.payload.attendanceData.userWorkStatusList.forEach((userWorkStatus: Model.getDetailDataList.UserWorkStatusList) => {
        // 労働日数、労働時間の算出
        let days = 0;
        let totalWorkingTime = 0;
        let totalWorkingTimeStr = "";

        userWorkStatus.dailyWorkStatusList.forEach(dailyWorkStatus => {
          let workingTime = formatUtils.formatTime(dailyWorkStatus.workingTime);

          if (workingTime === "" || workingTime === "0:00") {
            // 労働時間が設定されていない場合は処理しない
            return;
          }

          if (dailyWorkStatus.meetQualificationRequirementType === CONSTANTS.QUALIFICATION_REQUIREMENT_TYPE.OUTSIDE_VALID_PERIOD.code) {
            // 資格要件に該当していない日付は処理しない
            return;
          }

          // 労働日数を加算
          days++;

          // 労働時間を加算
          let hour = workingTime.split(":")[0];
          let minutes = workingTime.split(":")[1];

          totalWorkingTime += ((parseInt(hour) * 60 * 60) + (parseInt(minutes) * 60));
        });

        // 時
        let hourStr = Math.floor(totalWorkingTime / 3600);
        // 分
        let minutesStr = Math.ceil((totalWorkingTime - hourStr * 3600) / 60);

        totalWorkingTimeStr = hourStr + ":" + ("0" + minutesStr).slice(-2);

        const rec: Model.WorkRecordDetailRow = {
          key: userWorkStatus.workRecordSubmissionHistoryId + '_' + userWorkStatus.workerId
          , workRecordSubmissionHistoryId: userWorkStatus.workRecordSubmissionHistoryId
          , selectFlag: false
          , approvalStatus: userWorkStatus.approvalStatus
          , forceApprovalFlag: userWorkStatus.forceApprovalFlag
          , primeCertificationNo: userWorkStatus.primeCertificationNo
          , workerId: userWorkStatus.workerId
          , name: userWorkStatus.name
          , firstPartnerCompanyId: userWorkStatus.firstPartnerCompanyId
          , firstPartnerCompanyName: userWorkStatus.firstPartnerCompanyName
          , topHierarchyLevel: userWorkStatus.topHierarchyLevel
          , qualificationList: qualificationNameList[userWorkStatus.workerId] // 資格一覧情報
          , dailyWorkStatusList: userWorkStatus.dailyWorkStatusList
          , updateDatetime: userWorkStatus.updateDatetime
          , days: days
          , totalWorkingTime: totalWorkingTimeStr
          , abolishCompanyFlag: userWorkStatus.abolishCompanyFlag
        };

        userWorkStatusList.push(rec);

        if (isAvailableRowDetail(rec, editAvailableFlag)) {
          // 処理可能な行があるので、全選択チェックボックスを表示する
          existAvailableRow = true;
        }

        if (isNotSubmitted(rec) || isRemand(rec)) {
          // 未提出の行がある
          existNotSubmitted = true;
        }
      });

      // 表示している中に入退場時間が逆転して労働時間が未設定の勤怠が存在するかチェック
      const isContainInvalidInOutTime = action.payload.attendanceData.userWorkStatusList.some((ws) => {
        return ws.dailyWorkStatusList.some((s) => {
          return Utils.isInversionInOutTime(s.firstInTime, s.lastOutTime);
        });
      });

      return Object.assign(
        {}, state,
        { detailTotal: total },
        { primeCompanyId: primeCompanyId },
        { primeCompanyName: primeCompanyName },
        { projectId: projectId },
        { projectName: projectName },
        { belongCompanyId: belongCompanyId },
        { belongCompanyName: belongCompanyName },
        { detailDataList: userWorkStatusList },
        { allSelect: false },
        { existSubmittedChecked: false },
        { existNotSubmittedChecked: false },
        { existNotSubmitted: existNotSubmitted },
        { editAvailableFlag: editAvailableFlag },
        { existAvailableRow: existAvailableRow },
        { isContainInvalidInOutTime: isContainInvalidInOutTime},
      )
    },

    /**
     * 全選択チェックボックス変更
     */
    onChangeAllSelect: (state, e: any) => {
      let userWorkStatusList: Array<Model.WorkRecordDetailRow> = [];

      let existSubmittedChecked = false;
      let existNotSubmittedChecked = false;

      // 一覧のチェック状態を全て変更
      state.detailDataList.forEach((detailData: Model.WorkRecordDetailRow) => {
        const rec: Model.WorkRecordDetailRow = {
          key: detailData.workRecordSubmissionHistoryId + '_' + detailData.workerId
          , workRecordSubmissionHistoryId: detailData.workRecordSubmissionHistoryId
          , selectFlag: e.payload.target.checked
          , approvalStatus: detailData.approvalStatus
          , forceApprovalFlag: detailData.forceApprovalFlag
          , primeCertificationNo: detailData.primeCertificationNo
          , workerId: detailData.workerId
          , name: detailData.name
          , firstPartnerCompanyId: detailData.firstPartnerCompanyId
          , firstPartnerCompanyName: detailData.firstPartnerCompanyName
          , topHierarchyLevel: detailData.topHierarchyLevel
          , qualificationList: detailData.qualificationList
          , dailyWorkStatusList: detailData.dailyWorkStatusList
          , updateDatetime: detailData.updateDatetime
          , days: detailData.days
          , totalWorkingTime: detailData.totalWorkingTime
          , abolishCompanyFlag: detailData.abolishCompanyFlag
        };

        if (!isApproved(rec) && !isForceApproved(rec)) {
          if (rec.selectFlag) {
            // 「承認済」「承認済＊」以外 　かつ　 選択されている
            if (isUnApproved(rec) || isConfirmed(rec)) {
              // 「提出済」または「確認済」
              existSubmittedChecked = true;
            } else if (isNotSubmitted(rec) || isRemand(rec)) {
              // 「未提出」または「差戻」
              existNotSubmittedChecked = true;
            }
          }
        }

        userWorkStatusList.push(rec);
      });

      return Object.assign(
        {}, state,
        { allSelect: e.payload.target.checked ? true : false },
        { detailDataList: userWorkStatusList },
        { existSubmittedChecked: existSubmittedChecked },
        { existNotSubmittedChecked: existNotSubmittedChecked },
      );
    },

    /**
     * 各行のチェックボックス変更
     */
    onChangeRowSelect: (state, action: PayloadAction<changeCheckTransfer>) => {
      let userWorkStatusList: Array<Model.WorkRecordDetailRow> = [];
      let existOffFlag = false;
      let existSubmittedOnFlag = false;
      let existNotSubmittedOnFlag = false;

      state.detailDataList.forEach((detailData: Model.WorkRecordDetailRow) => {
        const rec: Model.WorkRecordDetailRow = {
          key: detailData.workRecordSubmissionHistoryId + '_' + detailData.workerId
          , workRecordSubmissionHistoryId: detailData.workRecordSubmissionHistoryId
          , selectFlag: action.payload.targetDataRow.key === detailData.key ? action.payload.value : detailData.selectFlag
          , approvalStatus: detailData.approvalStatus
          , forceApprovalFlag: detailData.forceApprovalFlag
          , primeCertificationNo: detailData.primeCertificationNo
          , workerId: detailData.workerId
          , name: detailData.name
          , firstPartnerCompanyId: detailData.firstPartnerCompanyId
          , firstPartnerCompanyName: detailData.firstPartnerCompanyName
          , topHierarchyLevel: detailData.topHierarchyLevel
          , qualificationList: detailData.qualificationList
          , dailyWorkStatusList: detailData.dailyWorkStatusList
          , updateDatetime: detailData.updateDatetime
          , days: detailData.days
          , totalWorkingTime: detailData.totalWorkingTime
          , abolishCompanyFlag: detailData.abolishCompanyFlag
        };

        userWorkStatusList.push(rec);

        if (!isApproved(rec) && !isForceApproved(rec)) {
          if (rec.selectFlag) {
            // 「承認済」「承認済＊」以外 　かつ　 選択されている
            if (isUnApproved(rec) || isConfirmed(rec)) {
              // 「提出済」または「確認済」
              existSubmittedOnFlag = true;
            } else if (isNotSubmitted(rec) || isRemand(rec)) {
              // 「未提出」または「差戻」
              existNotSubmittedOnFlag = true;
            }
          } else {
            // 「承認済」「承認済＊」以外 　かつ　 選択されていない
            existOffFlag = true;
          }
        }
      });

      let allSelect = existOffFlag ? false : true;

      return Object.assign(
        {}, state,
        { allSelect: allSelect },
        { detailDataList: userWorkStatusList },
        { existSubmittedChecked: existSubmittedOnFlag },
        { existNotSubmittedChecked: existNotSubmittedOnFlag },
      );
    },

    /**
     * モーダルの表示/非表示切り替え
     */
    reflectModalState: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { modalVisibleState: action.payload });
    },

    /**
     * モーダルで使用するstateの更新
     */
    setModalState: (state, action: PayloadAction<Model.SelectedAttendanceInfo>) => {
      return Object.assign({}, state, { selectedAttendanceInfo: action.payload });
    },

    /**
     * 検索モーダルの表示/非表示切り替え
     */
    reflectSearchModalState: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { searchModalVisibleState: action.payload });
    },

    /**
     * ローディンを表示するかどうかを設定
     */
    setLoading: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { isLoading: action.payload });
    },

    /**
     * 勤怠情報の切替
     */
    changeDetailDataIndex: (state, action: PayloadAction<number>) => {
      return Object.assign({}, state, { showDetailDataIndex: action.payload });
    },

    /**
     * 検索可能な氏名一覧を取得
     */
    setWorkerNameList: (state, action: PayloadAction<Model.getWorkerName.Response>) => {
      let nameList: Array<string> = [];
      action.payload.workerNameList.forEach((workerName: Model.WorkerName) => {
        nameList.push(workerName.lastName + "　" + workerName.firstName);
      })
      return Object.assign({}, state, { workerNameList: nameList });
    },

    reflectWorktimeConfirmModalVisibleState: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { worktimeConfirmModalVisibleState: action.payload });
    },

    setWorktimeConfirmText: (state, action: PayloadAction<string>) => {
      return Object.assign({}, state, { worktimeConfirmText: action.payload });
    },
  },
});

export const {
  prepareMoveToDetail
  , initDataList
  , setDataListOfWorkRecordPartner
  , setUrlParameterValue
  , initCriteria
  , setCriteria
  , initDetailDataList
  , setDetailDataListOfWorkRecordMobilePartner
  , onChangeAllSelect
  , onChangeRowSelect
  , reflectModalState
  , setModalState
  , prepareMoveToDetailCriteria
  , setSummaryCriteria
  , reflectSearchModalState
  , setLoading
  , changeDetailDataIndex
  , setWorkerNameList
  , reflectWorktimeConfirmModalVisibleState
  , setWorktimeConfirmText
} = partnerAttendanceSlice.actions;

/**
 * 承認済＊かどうかチェック
 */
function isForceApproved(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_ON;
}

/**
 * 承認済かどうかチェック
 */
function isApproved(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_APPROVED;
}

/**
 * 確認済かどうかチェック
 */
function isConfirmed(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_CONFIRMED;
}

/**
 * 提出済かどうかチェック
 */
function isUnApproved(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED;
}

/**
 * 差戻かどうかチェック
 */
function isRemand(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_REMAND;
}

/**
 * 未提出かどうかチェック
 */
function isNotSubmitted(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED;
}

/**
 * 提出か提出取消可能な行か判定する（勤怠明細画面用）
 * @param row
 * @returns
 */
function isAvailableRowDetail(row: Model.WorkRecordDetailRow, editAvailableFlag: number): boolean {
  // 提出期限内、かつ、「未提出」「差戻」「未承認」「確認」のいずれかの場合、提出か提出取消可能
  if (editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_AVAILABLE) {
    if (isNotSubmitted(row) || isRemand(row) || isUnApproved(row) || isConfirmed(row)) {
      return true;
    }
  }

  // 上記以外の場合、可能な処理はない
  return false;
}

/**
 * 作業員氏名一覧取得API、勤怠明細取得_協力API呼び出し
 * @param params 
 * @returns 
 */
export const getDataListAsync = (params: Model.WorkRecordSummaryCriteria): AppThunk => async dispatch => {
  try {
    // GET-APIを呼び出します。
    const workerNameListResponse = await getRequest(CONSTANTS.URL_ATTENDANCE_CMN_WORKER_NAME_LIST);
    const summaryResponse = await getRequest(CONSTANTS.URL_ATTENDANCE_PARTNER_SUMMARY, {
      params: params
    });
    // 結果をstateに設定します。
    dispatch(setWorkerNameList(workerNameListResponse.data.data));
    dispatch(setDataListOfWorkRecordPartner(summaryResponse.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(workerNameListResponse, CONSTANTS.REQUEST_METHOD_GET));
    dispatch(apiSuccessHandler(summaryResponse, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

/**
 * 勤怠明細取得_協力API呼び出し
 * @param attendanceParams 
 * @param qualificationParams 
 */
export const getDetailDataListAsync = (attendanceParams: Model.getDetailDataList.Request, qualificationParams: Model.getOriginalQualificationList.Request): AppThunk => async dispatch => {
  try {
    // 勤怠明細取得　GET-APIを呼び出します。
    const attendanceResponse = await getRequest(CONSTANTS.URL_ATTENDANCE_PARTNER_DETAIL, {
      params: attendanceParams
    });

    // ユーザーに紐づく資格一覧も取得する
    let qualificationNameList:{[key:string]:Array<string>} = {}; // key:従業員ID、value:資格一覧
    let qualificationResponse = undefined;
    for (let i = 0; i < attendanceResponse.data.data.userWorkStatusList.length; i++) {
      // 引数のworkerIdを設定
      let workerId = attendanceResponse.data.data.userWorkStatusList[i].workerId;
      qualificationParams.workerId = workerId; // workerIdを設定。そのほかは設定済み。

      // 個人の有効独自資格取得　GET-APIを呼び出します。
      qualificationResponse = await getRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PARTNER_QUALIFICATION_LIST, {
          params: qualificationParams
      });

      // 結果を確認
      // 権限なし
      if (qualificationResponse.data.cmn.errorCode === "EA2006001") {
        // 取得できないので終了
        break;
      }

      // 取得結果から資格名だけの一覧にする（workerIdをキーに設定する）
      let nameList : Array<string> = [];
      let qualiList:Array<Model.getOriginalQualificationList.OriginalQualificationList> = qualificationResponse.data.data.qualificationList;
      qualiList.forEach(quali => {
        nameList.push(quali.originalQualificationName);
      });
      qualificationNameList[workerId] = nameList;
    }

    // 次の処理に渡す引数を作成
    let displayInfo: Model.AttendanceAndQualificationInfo = {
      attendanceData: attendanceResponse.data.data,
      qualificationNameList: qualificationNameList
    }

    // 結果をstateに設定します。
    dispatch(setDetailDataListOfWorkRecordMobilePartner(displayInfo));

    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(attendanceResponse, CONSTANTS.REQUEST_METHOD_GET));
    if (qualificationResponse !== undefined) {
      dispatch(apiSuccessHandler(qualificationResponse, CONSTANTS.REQUEST_METHOD_GET));
    }
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
}

/**
 * 勤怠実績ステータス変更API呼び出し
 * TODO 元請側にも同じ実装があるため、元請側と協力側で共通化したい。
 * @param putData 
 */
export const putAttendanceStatusAsync = (putData: Model.putStatus.Request): AppThunk => async dispatch => {
  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_ATTENDANCE_CMN_STATUS, putData);

    // APIが返却したエラーコードがいずれかの場合、セッションストレージに保管されている処理成功メッセージを書き換える
    // ・認可チェック失敗
    // ・勤怠締め切りによる失敗
    // ・メール送信失敗
    // ・更新処理0件
    let message = "";
    let processName = "";
    switch (response.data.cmn.errorCode) {
      case "EA5000001":
          processName = getAeendanceProcessType(putData.processType);
          message = messagesUtils.getMessage(response.data.cmn.errorCode, [processName]);
          messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
          break;
      case "EA5000002":
          processName = getAeendanceProcessType(putData.processType);
          message = messagesUtils.getMessage("EA5000002_PARTNER", [processName, processName]);
          messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
          break;
      case "EA5000004":
          message = messagesUtils.getMessage(response.data.cmn.errorCode);
          messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
          break;
      case "EA5000003":
          // メール送信失敗の場合はエラーメッセージは表示させない
          break;
    }
    // 共通成功時処理を呼び出します
    dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_PUT, url: response.config.url }));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
}

/**
 * 勤怠編集API呼び出し
 * @param putData 
 */
export const putEditAttendanceAsync = (putData: Model.editWorkingTime.Request): AppThunk => async dispatch => {
  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_ATTENDANCE_PARTNER_DAILY, putData);
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_PUT));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
}

/**
 * 実行メッセージ取得
 * @param processType 
 */
 const getAeendanceProcessType = (processType: string) : string => {
  // 処理タイプ毎にメッセージを取得
  let args = "";
  switch (processType) {
    case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT:
      args = "提出";
      break;
    case CONSTANTS.ATTENDANCE_PROCESS_TYPE.SUBMIT_CANCEL:
      args = "提出取消";
      break;
  }
  return args;
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.workRecordMobilePartner.dataList;
export const storedDetailDataList = (state: RootState) => state.workRecordMobilePartner.detailDataList;
export const storedAllSelect = (state: RootState) => state.workRecordMobilePartner.allSelect;
export const storedCriteria = (state: RootState) => state.workRecordMobilePartner.workRecordDetailCriteria;
export const storedDetailTotal = (state: RootState) => state.workRecordMobilePartner.detailTotal;
export const storedPrimeCompanyId = (state: RootState) => state.workRecordMobilePartner.primeCompanyId;
export const storedPrimeCompanyName = (state: RootState) => state.workRecordMobilePartner.primeCompanyName;
export const storedProjectId = (state: RootState) => state.workRecordMobilePartner.projectId;
export const storedProjectName = (state: RootState) => state.workRecordMobilePartner.projectName;
export const storedBelongCompanyId = (state: RootState) => state.workRecordMobilePartner.belongCompanyId;
export const storedBelongCompanyName = (state: RootState) => state.workRecordMobilePartner.belongCompanyName;
export const storedFirstPartnerCompanyId = (state: RootState) => state.workRecordPartner.firstPartnerCompanyId;
export const storedExistSubmittedChecked = (state: RootState) => state.workRecordMobilePartner.existSubmittedChecked;
export const storedExistNotSubmittedChecked = (state: RootState) => state.workRecordMobilePartner.existNotSubmittedChecked;
export const storedExistNotSubmitted = (state: RootState) => state.workRecordPartner.existNotSubmitted;
export const storedModalVisibleState = (state: RootState) => state.workRecordMobilePartner.modalVisibleState;
export const storedSelectedAttendanceInfo = (state: RootState) => state.workRecordMobilePartner.selectedAttendanceInfo;
export const storedEditAvailableFlag = (state: RootState) => state.workRecordMobilePartner.editAvailableFlag;
export const storedExistAvailableRow = (state: RootState) => state.workRecordMobilePartner.existAvailableRow;
export const storedSummaryTotal = (state: RootState) => state.workRecordMobilePartner.summaryTotal;
export const storedSummaryCriteria = (state: RootState) => state.workRecordMobilePartner.workRecordSummaryCriteria;
export const storedLoading = (state: RootState) => state.workRecordMobilePartner.isLoading;
export const storedSearchModalVisibleState = (state: RootState) => state.workRecordMobilePartner.searchModalVisibleState;
export const storedWorkerNameListState = (state: RootState) => state.workRecordMobilePartner.workerNameList;
export const storedShowDetailDataIndexState = (state: RootState) => state.workRecordMobilePartner.showDetailDataIndex;
export const storedWorktimeConfirmModalVisibleState = (state: RootState) => state.workRecordPartner.worktimeConfirmModalVisibleState;
export const storedWorktimeConfirmText = (state: RootState) => state.workRecordPartner.worktimeConfirmText;
export const storedIsContainInvalidInOutTime = (state: RootState) => state.workRecordPartner.isContainInvalidInOutTime

export default partnerAttendanceSlice.reducer;
