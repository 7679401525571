import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router'
import { Row, Col, Switch } from 'antd';
import styles from '../css/Header.module.css';
import { Menu } from './Menu';
import { getMenuItemId } from "../helpers/authorizationHelper";
import { storedLoginUserInfo } from '../../../modules/core/authModule';
import { isPrimeMenu, setCurrnetMenuItemId } from '../../../modules/core/menuModule';
import { initCriteria as initCriteriaAttendancePrime } from '../../../modules/prime/attendance/attendanceModule'
import { initCriteria as initCriteriaAttendancePartner } from '../../../modules/partner/attendance/attendanceModule'
import * as CONSTANTS from '../../../constants/constants';
import { initMessages } from '../../../modules/core/commonMessageModule';

export const Header = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const loginUserInfo = useSelector(storedLoginUserInfo);
  const isPrime = useSelector(isPrimeMenu);

  // トグル切替押下時、所定画面への遷移処理を行います。
  const switchOnChange = (checked: boolean) => {

    let path = "";
    if (checked) {
      // 利用不可の場合、処理を中断します。
      if ("0" === loginUserInfo.primeServiceAvailableFlg) {
        return;
      }
      // 元請_勤怠一覧に遷移します。
      path = CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY;
    } else {
      if (window.innerWidth <= 850) {
        // 画面幅が850px以下のとき協力会社_勤怠一覧(スマホ用)に遷移します。
        path = CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_SUMMARY;
      } else {
        // 協力会社_勤怠一覧に遷移します。
        path = CONSTANTS.PATH_PARTNER_ATTENDANCE_SUMMARY;
      }
    }

    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // 遷移先勤怠画面のCriteriaを初期化する
    dispatch(initCriteriaAttendancePrime());
    dispatch(initCriteriaAttendancePartner());

    // 機能をまたく遷移の場合、カレントメニューアイテムを書き換えます。
    dispatch(setCurrnetMenuItemId(getMenuItemId(path)));
    history.push(path);
  }

  return (
    <div className={styles.HeaderFixed}>
      <Row className={styles.HeaderArea}>
        <Col span={6} className={styles.HeaderlogoArea}>
          <img
            className={styles.LogoStyle}
            src={isPrime ? CONSTANTS.PRIME_HEADER_ROGO : CONSTANTS.PARTNER_HEADER_ROGO}
            alt="スキルマップサイト" />
        </Col>
        <Col span={14} offset={4} className={styles.HeaderInfoArea}>
          <div>
            <Switch
              checkedChildren="元請会社"
              unCheckedChildren="協力会社"
              checked={isPrime}
              onChange={(checked) => switchOnChange(checked)}
            />
            <div>
              <span className={styles.noWrap}>{loginUserInfo.belongCompanyName}</span>
              <span className={styles.noWrap}>{loginUserInfo.belongBranchName}</span>
              <span className={styles.noWrap}>{loginUserInfo.name ? loginUserInfo.name + " 様" : loginUserInfo.name}</span>
            </div>
          </div>
        </Col>
      </Row>

      <Menu />
    </div>
  );
}
