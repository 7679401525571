import { isNullOrEmpty, isInversionInOutTime } from './utils'
import moment from 'moment';


/**
 * 入退場時間から労働時間を算出。
 * backendと計算方法を同じにする。
 * フォーマットは行わないので注意
 * @param firstInTime 入場時間
 * @param lastOutTime 退場時間
 * @param inversionInOutOutput 入退場時間が逆転している場合の出力
 * @returns 
 */
export const calcWorkTime = (firstInTime: string, lastOutTime: string, inversionInOutOutput: string = "00:00"): string => {
    let time: string = "";
    if (isNullOrEmpty(lastOutTime) || isNullOrEmpty(firstInTime)) {
        return time;
    }
    if (isInversionInOutTime(firstInTime, lastOutTime)) {
        return inversionInOutOutput;
    }
    // 差分（ミリ秒）を算出して分に変換
    let diff: number = moment(lastOutTime).diff(moment(firstInTime));
    diff = Math.floor(diff / (60 * 1000));
    // 差分(分)をHH:mm形式に変換
    const hour: number = Math.floor(diff / 60);
    const minutes: number = diff % 60;
    time = ("0" + String(hour)).slice(-2) + ":" + ("0" + String(minutes)).slice(-2);

    return time;
}