import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './AbolishCompanyListButton.module.css';
import { Layout, Typography, Table, Form, Input, Tooltip, Modal, Button } from 'antd';
import { ButtonProps } from 'antd/lib/button'
import { CriteriaForm, Checkbox, AuthButton, AuthButtonAuthorities } from '../../core/CoreForm'
import * as Module from '../../../modules/core/abolishCompanyListButtonModule';
import * as CONSTANTS from '../../../constants/constants';
import AuthButtonStyles from '../../core/css/AuthButton.module.css';
import { initApiResult } from '../../../modules/core/bffApiModule';



/**
 * 指定可能なパラメータ定義（拡張）
 */
interface AbolishCompanyListButtonProps extends ButtonProps {
  abolishCompanyFlag: string
  historyId: string
}


export const AbolishCompanyListButton = (props: AbolishCompanyListButtonProps) => {

  const dispatch = useDispatch();

  let abolishCompanyModalVisibleState = useSelector(Module.storedAbolishCompanyModalVisibleState);
  let abolishCompanyList = useSelector(Module.storeAbolishCompanyList);


  /** 廃止企業一覧モーダルを開く */
  const showAbolishCompanyModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // 本来の処理は中断する
    e.preventDefault();

    // API結果情報を初期化します。
    dispatch(initApiResult());

    // そのボタンに対応する勤怠実績履歴IDを取得
    const historyId: string = e.currentTarget.getAttribute('data-atid') || "";
    if (historyId == "") {
      return;
    }

    // モーダル表示
    dispatch(Module.showAbolishCompanyAsync(historyId));
  }

  /** 廃止企業一覧モーダルを閉じる */
  const closeAbolishCompanyModal = () => {
    dispatch(Module.reflectAbolishCompanyModalState(false));
  }

  // 廃止企業の一覧を作成
  const criteriaAbolishCompanyList = () => {

    if (abolishCompanyList == null || abolishCompanyList.length == 0) {
      return (
        <div className={styles.abolishCompanyModalError}>
          データが見つかりませんでした。
        </div>
      )
    }


    let list = [];
    list.push(<></>);
    abolishCompanyList.forEach((abolishCompanyInfo: Module.AbolishCompanyListItem) => {
      list.push(<li>所属会社が「{abolishCompanyInfo.abolishedCompanyName}」から変更になりました</li>);
    });

    return (
      <ul className={styles.abolishCompanyList}>
        {list}
      </ul>
    );
  }


  if (props.abolishCompanyFlag == CONSTANTS.ABOLISH_COMPANY_FLAG_OFF) {
    return (
      <></>
    )
  } else {
    return (
      <>
        <a className={styles.abolishCompanyButton} onClick={showAbolishCompanyModal} data-atid={props.historyId}>◎</a>
        <Modal
          visible={abolishCompanyModalVisibleState}
          footer={null}
          closable={false}
          width={500}
        >
          <div>
            <h2 className={styles.abolishCompanyModalTitle}>所属会社の変更履歴</h2>
            <div>
              {criteriaAbolishCompanyList()}
            </div>
            <AuthButton
                name={"閉じる"}
                shape={"round"}
                onClick={closeAbolishCompanyModal}
                className={AuthButtonStyles.backButtonFixed}
                style={{ margin: "10px" }}
            />
          </div>
        </Modal>
      </>
    )
  }

}