import { Form, Input, Layout, Modal, Table } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as CONSTANTS from '../../../constants/constants';
import { initApiResult, setApiResult, storedApiResult } from '../../../modules/core/bffApiModule';
import { initMessages, message, setMessages } from '../../../modules/core/commonMessageModule';
import { initCriteria, initModalSelectValue, setExclusionList, storedModalSelectValue } from '../../../modules/prime/managementGroup/managementGroupModule';
import { storedLoginUserInfo } from '../../../modules/core/authModule'
import * as Model from '../../../modules/prime/qualifications-holders/qualificationHoldersModel';
import * as Module from '../../../modules/prime/qualifications-holders/qualificationHoldersModule';
import { initCriteria as qualificationInitCriteria, storedDataRow as storedQualification } from '../../../modules/prime/qualificationSelect/qualificationSelectModule';
import { initState, storedChecked, storedDataRow } from '../../../modules/prime/workerSelect/workerSelectModule';
import * as formatUtils from '../../../utils/formatUtils';
import * as messagesUtils from '../../../utils/messagesUtils';
import { isNullOrEmpty } from '../../../utils/utils';
import { AuthButton, DatePicker, DetailForm } from '../../core/CoreForm';
import { CommonMessage, ContentEx as Content, Footer, FooterButtonArea, FunctionTitle, Header } from '../../core/CorePageContents';
import AuthButtonStyles from '../../core/css/AuthButton.module.css';
import { ManagementGroupSelect } from '../managementGroup/ManagementGroupSelect';
import { QualificationSelect } from '../qualificationSelect/QualificationSelect';
import { WorkerSelect } from '../workerSelect/workerSelect';
import styles from './QualificationHoldersEdit.module.css';

const { TextArea } = Input;

interface titleInterface {
  title: string;
}

export const QualificationHoldersEdit = (props: titleInterface) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const dataRow = useSelector(Module.storedDataRow);
  const belongCompanyData = useSelector(Module.storedBelongCompanyDataRow);
  let filteredDataList = dataRow.originalQualificationList.filter(function (data) {
    return !data.deleteFlag;
  })
  const apiResult = useSelector(storedApiResult);
  const loginUserInfo = useSelector(storedLoginUserInfo);
  const processType = window.location.pathname === CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_ADD ? CONSTANTS.PROCESS_TYPE_ADD : CONSTANTS.PROCESS_TYPE_EDIT;
  const managementModalVisibleState = useSelector(Module.storedManagementModalVisibleState);
  const managementGroup = useSelector(storedModalSelectValue);
  const workerSelectModalVisibleState = useSelector(Module.storedWorkerSelectModalVisibleState);
  const selectWorker = useSelector(storedDataRow);
  const selectWorkerChecked = useSelector(storedChecked);
  const qualificationSelectModalVisibleState = useSelector(Module.storedQualificationSelectModalVisibleState);
  const qualificationSelectTableKey = useSelector(Module.storedQualificationSelectTableKey);
  const selectQualification = useSelector(storedQualification);
  const commentVisibleState = useSelector(Module.storedCommentVisibleState);
  let loadingFlg = useSelector(Module.storedLoadingFlg);

  useEffect(() => {
    // 表示権限がない場合、権限エラー画面へ遷移します。
    if (!isDisplayScreenAuth()) {
      history.push(CONSTANTS.PATH_FORBIDDEN);
      return;
    }
    // 所属企業が設定されていない場合、独自資格保有者協力会社一覧画面へ遷移します。
    if (belongCompanyData.belongCompanyName === "") {
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_COMPANIES);
      return;
    }

    // 独自資格保有者一覧取得API実行後、APIエラーメッセージが設定されていなければモーダルで選択した値を画面に設定し、モーダルを閉じます。
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode
      && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod && CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_LIST === apiResult.url) {

      form.setFieldsValue({ name: selectWorker.lastName + " " + selectWorker.firstName });
      // 画面に値を反映
      dispatch(Module.reflectSelectWorker(selectWorker));
      // 氏名検索モーダルを閉じます。
      dispatch(Module.reflectWorkerSelectModalState(false));

    }

    // 更新API実行後、APIエラーメッセージが設定されていなければ画面遷移します。
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod) {

      if (processType === CONSTANTS.PROCESS_TYPE_ADD) {
        // 成功メッセージを設定します。
        const setMessage: message = {
          message: messagesUtils.getMessage("SUCCESS_ADD", ["独自資格保有者"])
          , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
        }
        dispatch(setMessages([setMessage]));
        history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_LIST);
      } else {
        // 成功メッセージを設定します。
        const setMessage: message = {
          message: messagesUtils.getMessage("SUCCESS_UPDATE", ["独自資格保有者"])
          , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
        }
        dispatch(setMessages([setMessage]));
        history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_DETAIL);
      }
    }
    dispatch(Module.setLoadingFlg(false));

    // 独自資格一覧が0行の時の処理
    viewEmptyQualificationDetailTable();
  },
    [apiResult]
  );

  // 独自資格の行が削除されたときの処理
  useEffect(() => {
    viewEmptyQualificationDetailTable();
  },
    [dataRow]
  );

  // 画面を表示する権限があるか判定します。
  const isDisplayScreenAuth = () => {
    if (loginUserInfo.authorityInfo.primeQualificationMngAuth === "0") {
      // 資格管理者権限を持っていない場合、非表示
      return false;
    }
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_ALL.code) {
      // 独自資格付与フロー設定が「資格の登録のみ行う（デフォルト）」の場合、表示
      return true;
    } else if (loginUserInfo.authorityInfo.primeCertificationFlowRequestAuth === "1") {
      // 独自資格付与フロー設定が「資格の登録のみ行う（デフォルト）」以外の場合、申請権限を持っていれば、表示
      return true;
    } else {
      return false;
    }
  }
  // 独自資格一覧が0行の時の処理
  const viewEmptyQualificationDetailTable = () => {
    // 0行になったとき、ant designのテーブルが作成する「No Data」行を非表示にする（0行になったとき"ant-table-placeholder"が存在する）
    const tableElem: HTMLElement | null = document.getElementById("qualificationDetailTable");
    if (tableElem != null) {
      const phRow: HTMLElement | null = tableElem.querySelector(".ant-table-placeholder");
      if (phRow != null) {
        phRow.style.display = "none";
      }
    }
  }

  // 独自資格の行の編集可否
  const enableChangeRow = (row: Model.originalQualificationRow): boolean => {
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.NO_SKIP.code) {
      // 資格認定フロー利用設定：資格を登録する際に申請・確認・承認を必要とする　の場合、状態が選定の場合、編集可
      if (row.registrationStatus === CONSTANTS.CERTIFICATION_STATUS_CODE.REQUEST) {
        return true;
      }
    } else if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_CONFIRM.code) {
      // 資格認定フロー利用設定：資格を登録する際に申請・承認を必要とする　の場合、状態が認定済以外の場合、編集可
      if (row.registrationStatus !== CONSTANTS.CERTIFICATION_STATUS_CODE.CERTIFIED) {
        return true;
      }
    } else {
      // 資格認定フロー利用設定：資格の登録のみ行う（デフォルト）　の場合、常に編集可
      return true;
    }
    return false;
  }

  /**
   * 画面アクション
   * 
   */

  // 確定ボタン押下時アクション（入力エラー無）
  const submitTabelForm = (values: any) => {
    dispatch(initMessages());
    form.submit();
  }

  // 確定ボタン押下時アクション（入力エラー有）
  const submitTabelFormFailed = (errorInfo: any) => {
    dispatch(initMessages());
    scrollToTop();
    if (errorInfo.errorFields.length > 0) {
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_VALIDATION")
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setMessages([setMessage]));
    }
    form.validateFields();
  }

  // 確定ボタン押下時アクション（入力エラー有）
  const submitFormFailed = (errorInfo: any) => {
    scrollToTop();
    if (errorInfo.errorFields.length > 0) {
      const setMessage: message = {
        message: messagesUtils.getMessage("ERROR_VALIDATION")
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setMessages([setMessage]));
    }
  }

  // 確定ボタン押下時アクション（入力エラー無）
  const submitForm = (values: any) => {
    // 入力チェックを行います。
    let checked = validation();
    if (!checked) {
      return scrollToTop();
    }

    // メッセージを設定して確認ダイアログを表示します。
    let messageCode = processType === CONSTANTS.PROCESS_TYPE_ADD ? "CONFIRM_ADD" : "CONFIRM_UPDATE";
    if (!window.confirm(messagesUtils.getMessage(messageCode, ["独自資格保有者"]))) {
      return;
    }
    dispatch(initApiResult());
    dispatch(Module.setLoadingFlg(true));
    let originalQualificationList: Array<Model.putDataList.originalQualification> = [];
    dataRow.originalQualificationList.forEach((row: Model.originalQualificationRow) => {
      const originalQualificationRow: Model.putDataList.originalQualification = {
        certificationId: row.certificationId
        , originalQualificationId: row.originalQualificationId
        , registrationStatus: row.registrationStatus
        , certificationDate: formatUtils.formatDateHyphen(row.certificationDate)
        , certificationCancelDate: formatUtils.formatDateHyphen(row.certificationCancelDate)
        , deleteFlag: row.deleteFlag ? '1' : '0'
        , updateDatetime: row.updateDatetime
      };
      originalQualificationList.push(originalQualificationRow);
    });

    let sendRequest: Model.putDataList.Request = {
      processType: processType
      , belongCompanyId: belongCompanyData.belongCompanyId
      , workerId: dataRow.workerId
      , primeCertificationNo: values.primeCertificationNo
      , managementGroupId: dataRow.managementGroupId
      , remarks: values.remarks
      , originalQualificationList: originalQualificationList
      , updateDatetime: dataRow.updateDatetime
    }

    dispatch(Module.putDataRowAsync(sendRequest))
  }

  // 入力チェック（相関チェック）
  const validation = () => {
    let checked = true;
    // 同じ資格の認定日範囲チェック
    let dataList: Array<Model.originalQualificationRow> = [];
    for (const data of dataRow.originalQualificationList) {
      let flg = true;
      if (data.deleteFlag) {
        continue;
      }
      for (const row of dataList) {
        if (row.deleteFlag) {
          continue;
        }
        if (row.originalQualificationId === data.originalQualificationId) {
          // 同一独自資格の場合
          if (moment(data.certificationDate).isBefore(row.certificationCancelDate || '9999-12-31')
            && moment(data.certificationCancelDate || '9999-12-31').isAfter(row.certificationDate)) {
            flg = false;
            break;
          }
        }
      }

      if (flg) {
        dataList.push(data);
      } else {
        checked = false;
        const setMessage: message = {
          message: messagesUtils.getMessage("ERROR_DUPLICATE_QUALIFICATION")
          , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
        }
        dispatch(setMessages([setMessage]));
        break;
      }
    }
    return checked;
  }

  // ページトップへスクロールする
  const scrollToTop = () => {
    const target = document.getElementById("belongCompany");
    if (target !== null) {
      target.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  // 戻るボタン押下時アクション
  const backClicked = () => {
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_BACK"))) {
      return;
    }
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    dispatch(Module.reflectCommentVisibleState(false));
    if (processType === CONSTANTS.PROCESS_TYPE_ADD) {
      // 一覧画面へ遷移します。
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_LIST);
    } else {
      // 詳細画面へ遷移します。
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_DETAIL);
    }
  }

  // 削除ボタン押下時アクション
  const deleteRow = (key: string) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    let dataList: Array<Model.originalQualificationRow> = [];
    dataRow.originalQualificationList.forEach((dataRow: Model.originalQualificationRow) => {
      if (dataRow.key === key && dataRow.newFlg) {
        return;
      }
      const originalQualificationRow: Model.originalQualificationRow = {
        key: dataRow.key
        , certificationId: dataRow.certificationId
        , registrationStatus: dataRow.registrationStatus
        , status: dataRow.status
        , originalQualificationId: dataRow.originalQualificationId
        , originalQualificationNo: dataRow.originalQualificationNo
        , originalQualificationName: dataRow.originalQualificationName
        , certificationDate: dataRow.certificationDate
        , certificationCancelDate: dataRow.certificationCancelDate
        , deadlineExist: dataRow.deadlineExist
        , deadlineStartDate: dataRow.deadlineStartDate
        , deadlineEndDate: dataRow.deadlineEndDate
        , newFlg: dataRow.newFlg
        , deleteFlag: dataRow.key === key ? true : dataRow.deleteFlag
        , updateDatetime: dataRow.updateDatetime
      };
      dataList.push(originalQualificationRow);
    });
    // 編集したリストをステートに保存します。
    dispatch(Module.saveEditedDataList(dataList));

    dispatch(Module.reflectCommentVisibleState(true));

  }

  // 追加ボタン押下時アクション
  const createRow = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    dispatch(Module.reflectCommentVisibleState(true));

    // dataListに空のデータを追加します。
    const param: Module.CreateDataRowInfo = {
      originalQualificationList: dataRow.originalQualificationList,
      initRegistrationStatus: CONSTANTS.CERTIFICATION_STATUS_CODE.REQUEST,
      initStatus: CONSTANTS.CERTIFICATION_STATUS.REQUEST,
    };
    // 資格認定フロー利用設定に応じて、初期状態を切り替える。
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_CONFIRM.code) {
      param.initRegistrationStatus = CONSTANTS.CERTIFICATION_STATUS_CODE.CONFIRMED;
      param.initStatus = CONSTANTS.CERTIFICATION_STATUS.CONFIRMED;
    } else if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_ALL.code) {
      param.initRegistrationStatus = CONSTANTS.CERTIFICATION_STATUS_CODE.CERTIFIED;
      param.initStatus = CONSTANTS.CERTIFICATION_STATUS.CERTIFIED;
    }
    dispatch(Module.createDataRow(param));
  }

  // 管理グループ検索モーダルを表示します。
  const openManagementModal = () => {
    // API処理結果を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // モーダル内の検索条件初期化
    dispatch(initCriteria());
    // 除外する管理グループIDリストを設定する。
    dispatch(setExclusionList([dataRow.managementGroupId]));

    dispatch(Module.reflectManagementModalState(true));
  }

  // 管理グループ検索モーダルを閉じます。
  const closeManagementModal = () => {
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: CONSTANTS.API_STATUS_200, errorCode: "", requestMethod: "", url: "" }));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // 管理グループ検索モーダル内の選択値初期化
    dispatch(initModalSelectValue());
    // 管理グループ検索モーダルを閉じます。
    dispatch(Module.reflectManagementModalState(false));
  }

  // 管理グループ検索モーダル選択ボタン押下時アクション
  const handleManagementGroupSelect = () => {
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: CONSTANTS.API_STATUS_200, errorCode: "", requestMethod: "", url: "" }));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // 画面に値を反映
    dispatch(Module.reflectManagementGroup(managementGroup));
    // 管理グループ検索モーダルを閉じます。
    dispatch(Module.reflectManagementModalState(false));
    // 管理グループ検索モーダル内の選択値初期化
    dispatch(initModalSelectValue());
    form.setFieldsValue({ managementGroupName: managementGroup.managementGroupName });
  };

  // 氏名検索モーダルを表示します。
  const openWorkerModal = () => {
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: CONSTANTS.API_STATUS_200, errorCode: "", requestMethod: "", url: "" }));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    dispatch(initState());
    dispatch(Module.reflectWorkerSelectModalState(true));
  }

  // 氏名検索モーダルを閉じます。
  const closeWorkerSelectModal = () => {
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: CONSTANTS.API_STATUS_200, errorCode: "", requestMethod: "", url: "" }));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    dispatch(Module.reflectWorkerSelectModalState(false));
  }

  // 選択した従業員が既に登録済みではないかチェックを行います。
  const checkRegistered = () => {
    let reqest: Model.getQualificationHolderDataList.Request = {
      belongCompanyId: belongCompanyData.belongCompanyId,
      managementGroupId: "",
      originalQualificationName: "",
      workerId: selectWorker.workerId,
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
      primeCertificationNo: "",
      sortItem: "",
      sortOrder: "",
      offset: 0,
      limit: 10
    }
    dispatch(Module.checkQualificationHolderDataRowAsync(reqest));
  }

  // 独自資格検索モーダルを表示します。
  const openQualificationModal = (key: string) => {
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: CONSTANTS.API_STATUS_200, errorCode: "", requestMethod: "", url: "" }));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // モーダル内の検索条件初期化
    dispatch(qualificationInitCriteria());
    dispatch(Module.reflectQualificationSelectModalState(true));
    dispatch(Module.reflectQualificationSelectTableKey(key));
  }

  // 独自資格検索モーダルを閉じます。
  const closeQualificationModal = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: CONSTANTS.API_STATUS_200, errorCode: "", requestMethod: "", url: "" }));
    dispatch(Module.reflectQualificationSelectModalState(false));
    dispatch(Module.reflectQualificationSelectTableKey(""));
  }

  // 独自資格検索モーダル選択ボタン押下時アクション
  const handleQualificationSelect = () => {
    // ステータスを初期化すると元の画面が「Now Loading」になってしまうので、ステータス以外を初期化
    dispatch(setApiResult({ status: CONSTANTS.API_STATUS_200, errorCode: "", requestMethod: "", url: "" }));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    // 画面に値を反映
    let dataList: Array<Model.originalQualificationRow> = [];
    dataRow.originalQualificationList.forEach((dataRow: Model.originalQualificationRow) => {
      const originalQualificationRow: Model.originalQualificationRow = {
        key: dataRow.key
        , certificationId: dataRow.certificationId
        , registrationStatus: dataRow.registrationStatus
        , status: dataRow.status
        , originalQualificationId: dataRow.key === qualificationSelectTableKey ? selectQualification.originalQualificationId : dataRow.originalQualificationId
        , originalQualificationNo: dataRow.key === qualificationSelectTableKey ? selectQualification.originalQualificationNo : dataRow.originalQualificationNo
        , originalQualificationName: dataRow.key === qualificationSelectTableKey ? selectQualification.originalQualificationName : dataRow.originalQualificationName
        , certificationDate: dataRow.key === qualificationSelectTableKey ? "" : dataRow.certificationDate
        , certificationCancelDate: dataRow.key === qualificationSelectTableKey ? "" : dataRow.certificationCancelDate
        , deadlineExist: dataRow.key === qualificationSelectTableKey ? selectQualification.deadlineExist : dataRow.deadlineExist
        , deadlineStartDate: dataRow.key === qualificationSelectTableKey ? formatUtils.formatDateSlash(selectQualification.deadlineStartDate) : dataRow.deadlineStartDate
        , deadlineEndDate: dataRow.key === qualificationSelectTableKey ? formatUtils.formatDateSlash(selectQualification.deadlineEndDate) : dataRow.deadlineEndDate
        , newFlg: dataRow.newFlg
        , deleteFlag: dataRow.deleteFlag
        , updateDatetime: dataRow.updateDatetime
      };
      dataList.push(originalQualificationRow);
    });
    // 編集したリストをステートに保存します。
    dispatch(Module.saveEditedDataList(dataList));
    //  独自資格検索モーダルを閉じます。
    dispatch(Module.reflectQualificationSelectModalState(false));
    dispatch(Module.reflectQualificationSelectTableKey(""));
    tableForm.setFieldsValue({
      ["originalQualificationName" + qualificationSelectTableKey]: selectQualification.originalQualificationName
      , ["certificationDate" + qualificationSelectTableKey]: null
      , ["certificationCancelDate" + qualificationSelectTableKey]: null
    });
  };


  // 削除ボタン押下時アクション
  const changeCertificationDate = (value: any, key: string) => {
    let certificationDate = value === null ? "" : formatUtils.formatDateHyphen(value);
    let dataList: Array<Model.originalQualificationRow> = [];
    dataRow.originalQualificationList.forEach((dataRow: Model.originalQualificationRow) => {
      const originalQualificationRow: Model.originalQualificationRow = {
        key: dataRow.key
        , certificationId: dataRow.certificationId
        , registrationStatus: dataRow.registrationStatus
        , status: dataRow.status
        , originalQualificationId: dataRow.originalQualificationId
        , originalQualificationNo: dataRow.originalQualificationNo
        , originalQualificationName: dataRow.originalQualificationName
        , certificationDate: dataRow.key === key ? certificationDate : dataRow.certificationDate
        , certificationCancelDate: dataRow.certificationCancelDate
        , deadlineExist: dataRow.deadlineExist
        , deadlineStartDate: dataRow.deadlineStartDate
        , deadlineEndDate: dataRow.deadlineEndDate
        , newFlg: dataRow.newFlg
        , deleteFlag: dataRow.deleteFlag
        , updateDatetime: dataRow.updateDatetime
      };
      dataList.push(originalQualificationRow);
    });
    // 編集したリストをステートに保存します。
    dispatch(Module.saveEditedDataList(dataList));
  }
  const changeCertificationCancelDate = (value: any, key: string) => {
    let certificationCancelDate = value === null ? "" : formatUtils.formatDateHyphen(value);
    let dataList: Array<Model.originalQualificationRow> = [];
    dataRow.originalQualificationList.forEach((dataRow: Model.originalQualificationRow) => {
      const originalQualificationRow: Model.originalQualificationRow = {
        key: dataRow.key
        , certificationId: dataRow.certificationId
        , registrationStatus: dataRow.registrationStatus
        , status: dataRow.status
        , originalQualificationId: dataRow.originalQualificationId
        , originalQualificationNo: dataRow.originalQualificationNo
        , originalQualificationName: dataRow.originalQualificationName
        , certificationDate: dataRow.certificationDate
        , certificationCancelDate: dataRow.key === key ? certificationCancelDate : dataRow.certificationCancelDate
        , deadlineExist: dataRow.deadlineExist
        , deadlineStartDate: dataRow.deadlineStartDate
        , deadlineEndDate: dataRow.deadlineEndDate
        , newFlg: dataRow.newFlg
        , deleteFlag: dataRow.deleteFlag
        , updateDatetime: dataRow.updateDatetime
      };
      dataList.push(originalQualificationRow);
    });
    // 編集したリストをステートに保存します。
    dispatch(Module.saveEditedDataList(dataList));
  }

  /**
   * 描画用メソッド
   * 
   */

  // テーブル列を設定します。
  const columns = [
    {
      title: ''
      , dataIndex: 'deleteButton'
      , align: 'center' as 'center'
      , width: '70px'
      , render: (value: string, row: Model.originalQualificationRow, index: Number) => {
        if (enableChangeRow(row)) {
          return (
            <AuthButton
              name={"削除"}
              shape={"round"}
              onClick={() => deleteRow(row.key)}
              className={styles.customButtonColor}
            />
          )
        }
      }
    },
    {
      title: '状態'
      , dataIndex: 'certificationStatus'
      , key: '1'
      , align: 'center' as 'center'
      , width: '70px'
      , render: (value: string, row: Model.originalQualificationRow) => {
        return (
          row.status
        )
      }
    },
    {
      title: '独自資格名'
      , dataIndex: 'originalQualificationName'
      , key: '2'
      , align: 'center' as 'center'
      , width: '350px'
      , render: (value: string, row: Model.originalQualificationRow) => {
        if (!enableChangeRow(row)) {
          // 編集不可の状態
          return (
            <>
              <div className={styles.columnAlignLeft}>
                <Form.Item
                  name={"originalQualificationName" + row.key}
                >
                  <span>{value}</span>
                </Form.Item>
              </div>
            </>
          )
        }
        if (row.certificationId !== "") {
          // 認定IDがある場合、既存の行の変更なので、資格名は編集不可
          return (
            <>
              <div className={styles.columnAlignLeft}>
                <Form.Item
                  name={"originalQualificationName" + row.key}
                >
                  <span>{value}</span>
                </Form.Item>
              </div>
            </>
          )
        }
        return (
          <>
            <div className={styles.duration}>
              <Form.Item
                name={"originalQualificationName" + row.key}
                validateFirst={true}
                style={{ display: "inline", marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["独自資格名"])
                  }
                ]}
              >
                <Input className={styles.originalQualificationName} value={value} placeholder="独自資格名を選択してください。" readOnly />
              </Form.Item>
              <div>
                <AuthButton
                  name={"検索"}
                  shape={"round"}
                  onClick={() => openQualificationModal(row.key)}
                />
              </div>
            </div>
          </>
        )
      }
    },
    {
      title: '認定日'
      , dataIndex: 'certificationDate'
      , key: '3'
      , align: 'center' as 'center'
      , width: '200px'
      , render: (value: string, row: Model.originalQualificationRow, index: number) => {
        if (!enableChangeRow(row)) {
          // 編集不可の状態
          return (
            formatUtils.formatDateSlash(row.certificationDate)
          )
        }
        return (
          <div className={styles.duration}>
            <Form.Item
              name={"certificationDate" + row.key}
              initialValue={isNullOrEmpty(value) ? null : moment(value)}
              validateFirst={true}
              style={{ display: "inline", marginBottom: 0 }}
              rules={[
                ({ getFieldValue, validateFields, isFieldValidating }) => ({
                  validator(rule, value) {
                    // 必須チェック
                    if (value === null) {
                      return Promise.reject(messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["認定日"]));
                    }
                    // 有効範囲チェック
                    if (row.deadlineExist !== CONSTANTS.EXIST_DEADLINE.NONE.code && row.deadlineExist !== "") {
                      if (!value.isBetween(row.deadlineStartDate, row.deadlineEndDate, 'day', '[]')) {
                        return Promise.reject(messagesUtils.getMessage("ERROR_CERTIFICATION_DATE", ["認定日"]));
                      }
                    }
                    // 認定取消日のバリデーション
                    validateFields(["certificationCancelDate" + row.key]);
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <DatePicker disabled={row.originalQualificationId === ""} onChange={(value) => changeCertificationDate(value, row.key)} />
            </Form.Item>
          </div>
        )
      }
    },
    {
      title: '認定取消日'
      , dataIndex: 'certificationCancelDate'
      , key: '4'
      , align: 'center' as 'center'
      , width: '200px'
      , render: (value: string, row: Model.originalQualificationRow) => {
        if (!enableChangeRow(row)) {
          // 編集不可の状態
          return (
            formatUtils.formatDateSlash(row.certificationCancelDate)
          )
        }
        return (
          <div className={styles.duration}>
            <Form.Item
              name={"certificationCancelDate" + row.key}
              initialValue={isNullOrEmpty(value) ? null : moment(value)}
              validateFirst={true}
              style={{ display: "inline", marginBottom: 0 }}
              rules={[
                ({ getFieldValue, validateFields, isFieldValidating }) => ({
                  validator(rule, value) {
                    const certificationDate = getFieldValue(["certificationDate" + row.key]);
                    // 未入力の場合、スルー
                    if (null === value) {
                      return Promise.resolve();
                    }
                    // 認定日 < 認定取消日 チェック
                    if (value.isSameOrBefore(certificationDate, 'day')) {
                      return Promise.reject(messagesUtils.getMessage("ERROR_CERTIFICATION_DATE_RANGE", ["認定取消日", "認定日"]));
                    }
                    // 有効範囲チェック
                    if (row.deadlineExist !== CONSTANTS.EXIST_DEADLINE.NONE.code && row.deadlineExist !== "") {
                      // 有効期限終了日の1日後にする
                      const endDate = new Date(row.deadlineEndDate);
                      endDate.setDate(endDate.getDate() + 1);
                      if (!value.isBetween(row.deadlineStartDate, endDate, 'day', '[]')) {
                        return Promise.reject(messagesUtils.getMessage("ERROR_CERTIFICATION_DATE", ["認定取消日"]));
                      }
                    }
                    // 認定日のチェック
                    if (certificationDate === null) {
                      // 認定日がnullの場合（必須チェックエラーを出すため）
                      validateFields(["certificationDate" + row.key]);
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <DatePicker disabled={row.originalQualificationId === ""} onChange={(value) => changeCertificationCancelDate(value, row.key)} />
            </Form.Item>
          </div>
        )
      }
    },
    {
      title: '有効期限'
      , dataIndex: 'numberOfQualifications'
      , key: '5'
      , align: 'center' as 'center'
      , width: '200px'
      , render: (value: string, row: Model.originalQualificationRow) => {
        if (row.originalQualificationId === "") {
          return (
            ""
          )
        }
        return (
          formatUtils.formatDuration(row.deadlineStartDate, row.deadlineEndDate)
        )
      }
    }
  ];

  // メッセージを描画します。
  // モーダル表示時にメッセージを表示しないように切り替えます。
  const CommonMessages = () => {
    if (managementModalVisibleState === true || workerSelectModalVisibleState === true) {
      return (<></>);
    }
    return (<CommonMessage />);
  }

  // モーダル表示時にメッセージを表示しないように切り替えます。
  const Comment = () => {
    if (commentVisibleState === true) {
      return (<div className={styles.comment}>{"※追加・削除した資格は、決定するまで確定されません。"}</div>);
    }
    return (<></>);
  }

  // 所属企業を描画します。
  const BelongCompany = () => {
    return (
      <div id={"belongCompany"} className={styles.belongCompany}>
        <span>{"所属会社：" + belongCompanyData.belongCompanyName}</span>
      </div>
    )
  }

  // 所属企業を描画します。
  const FormItemName = () => {
    if (processType === CONSTANTS.PROCESS_TYPE_ADD) {
      return (
        <DetailForm.Item
          label="氏名"
          required
        >
          <div className={styles.duration}>
            <DetailForm.Item
              name="name"
              validateFirst={true}
              style={{ display: "inline" }}
              rules={[
                {
                  required: true,
                  message: messagesUtils.getMessage("ERROR_REQUIRED_SELECT", ["氏名"])
                }
              ]}
            >
              <Input className={styles.fullName} style={{ margin: 0 }} placeholder="氏名を選択してください。" readOnly />
            </DetailForm.Item>
            <div>
              <AuthButton
                name={"検索"}
                shape={"round"}
                onClick={openWorkerModal}
              />
            </div>
          </div>
        </DetailForm.Item>
      )
    }
    return (
      <DetailForm.Item label="氏名" >
        <span>{dataRow.lastName + " " + dataRow.firstName}</span>
      </DetailForm.Item>
    )
  }

  // 編集Formを描画します。
  const EditForm = () => {
    return (
      <div className={styles.contentArea} >
        <div className={styles.headline}>
          <span>基本情報</span>
        </div>
        <DetailForm
          form={form}
          initialValues={{
            'primeCertificationNo': dataRow.primeCertificationNo
            , 'managementGroupName': dataRow.managementGroupName
            , 'name': dataRow.lastName + " " + dataRow.firstName !== " " ? dataRow.lastName + " " + dataRow.firstName : ""
            , 'remarks': dataRow.remarks
          }}
          style={{ marginBottom: "50px" }}
          onFinish={submitForm}
          onFinishFailed={submitFormFailed}
        >

          <DetailForm.Item
            name="primeCertificationNo"
            label="元請認定番号"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["元請認定番号"])
              },
              {
                max: CONSTANTS.QUALIFICATION_NAME_MAX_LENGTH,
                message: messagesUtils.getMessage("ERROR_MAX_LENGTH", ["元請認定番号", CONSTANTS.PRIME_CERTIFICATION_NO_MAX_LENGTH.toString()])
              }
            ]}
          >
            <Input className={styles.originalQualificationName} placeholder="元請認定番号を入力してください。" maxLength={CONSTANTS.PRIME_CERTIFICATION_NO_MAX_LENGTH} />
          </DetailForm.Item>

          <DetailForm.Item
            label="管理グループ"
            required
          >
            <div className={styles.duration}>
              <DetailForm.Item
                name="managementGroupName"
                validateFirst={true}
                style={{ display: "inline" }}
                rules={[
                  {
                    required: true,
                    message: messagesUtils.getMessage("ERROR_REQUIRED_SELECT", ["管理グループ"])
                  }
                ]}
              >
                <Input className={styles.managementGroupName} style={{ margin: 0 }} placeholder="管理グループを選択してください。" readOnly />
              </DetailForm.Item>
              <div>
                <AuthButton
                  name={"検索"}
                  shape={"round"}
                  onClick={openManagementModal}
                />
              </div>
            </div>
          </DetailForm.Item>

          <FormItemName />

          <DetailForm.Item label="生年月日">
            <span>{dataRow.birthday === "" ? <>&nbsp;</> : formatUtils.formatDateSlash(dataRow.birthday)}</span>
          </DetailForm.Item>

          <DetailForm.Item
            name="remarks"
            label="備考"
            validateFirst={true}
            className={styles.editFormItem}
            rules={[
              {
                max: CONSTANTS.REMARKS_MAX_LENGTH,
                message: messagesUtils.getMessage("ERROR_MAX_LENGTH", ["備考", CONSTANTS.REMARKS_MAX_LENGTH.toString()])
              }
            ]}
          >
            <TextArea rows={4} />
          </DetailForm.Item>
        </DetailForm>
      </div>
    )
  }
  const bounce = {
    animation: 'bounce 2s infinite',
    color: '#fff'
  }

  return (
    <Layout>
      <Header />
      <Content hasapicall={loadingFlg ? "true" : "false"}>
        <FunctionTitle title={props.title} />
        <BelongCompany />
        <CommonMessages />
        <EditForm />
        <Form
          form={tableForm}
          onFinish={submitTabelForm}
          onFinishFailed={submitTabelFormFailed}
          className={styles.contentArea}
        >
          <div className={styles.headline}>
            <span>独自資格</span>
          </div>
          <Table
            id="qualificationDetailTable"
            columns={columns}
            size='small'
            dataSource={filteredDataList}
            pagination={false}
            scroll={{ x: 1000 }}
            className="hoverNotBackground"
            style={{ marginLeft: "20px" }}
            bordered
            footer={() => {
              return (
                <div style={{ textAlign: "left" }}>
                  <AuthButton
                    name={"追加"}
                    shape={"round"}
                    onClick={() => createRow()}
                  />
                  <Comment />
                </div>
              )
            }}
          />
          <div className={styles.fixed}>
            <div className={styles.float}>
              <FooterButtonArea hasapicall={loadingFlg ? "true" : "false"}>
                <AuthButton
                  name={"戻る"}
                  size={"large"}
                  shape={"round"}
                  onClick={backClicked}
                  className={AuthButtonStyles.backButtonFixed}
                />
                <AuthButton
                  name={"確定"}
                  htmlType="submit"
                  size={"large"}
                  shape={"round"}
                  className={AuthButtonStyles.authButtonFixed}
                />
              </FooterButtonArea>
            </div>
          </div>
        </Form>
      </Content>
      <Modal
        visible={managementModalVisibleState}
        bodyStyle={{ height: "440px" }}
        footer={[
          <AuthButton
            name={"戻る"}
            shape={"round"}
            key={"ManagementGroupSelectBack"}
            className={AuthButtonStyles.backButtonFixed}
            onClick={closeManagementModal}
          />,
          <AuthButton
            name={"選択"}
            shape={"round"}
            key={"ManagementGroupSelectConfirm"}
            onClick={handleManagementGroupSelect}
            disabled={"" === managementGroup.managementGroupId}
          />
        ]}
        destroyOnClose
        closable={false}
        width={1000}
      >
        <ManagementGroupSelect key={3} title="管理グループ：グリーンサイト上での支店" />
      </Modal>
      <Modal
        visible={workerSelectModalVisibleState}
        bodyStyle={{ height: "440px" }}
        footer={[
          <>
            <AuthButton
              name={"戻る"}
              shape={"round"}
              key={"WorkerSelectBack"}
              className={AuthButtonStyles.backButtonFixed}
              onClick={closeWorkerSelectModal}
            />
            <AuthButton
              name={"選択"}
              shape={"round"}
              key={"WorkerSelectConfirm"}
              onClick={checkRegistered}
              disabled={!selectWorkerChecked}
            />
          </>
        ]}
        destroyOnClose
        closable={false}
        width={1000}
      >
        <WorkerSelect key={2} title="作業員の追加" />
      </Modal>
      <Modal
        visible={qualificationSelectModalVisibleState}
        bodyStyle={{ height: "440px" }}
        footer={[
          <>
            <AuthButton
              name={"戻る"}
              shape={"round"}
              key={"QualificationSelectBack"}
              className={AuthButtonStyles.backButtonFixed}
              onClick={closeQualificationModal}
            />
            <AuthButton
              name={"選択"}
              shape={"round"}
              key={"QualificationSelectConfirm"}
              onClick={handleQualificationSelect}
              disabled={"" === selectQualification.originalQualificationId}
            />
          </>
        ]}
        destroyOnClose
        closable={false}
        width={1000}
      >
        <QualificationSelect key={1} title="独自資格選択" />
      </Modal>
      <FooterButtonArea hasapicall={"false"} />
      <Footer />
    </Layout >
  );
}

