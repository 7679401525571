
// コード定義名とコードからラベルを特定して返却します。
export const getLabelOf = (constants: any, code: string) => {

    for (let key in constants) {
        if (constants[key].code === code) {
            return constants[key].label;
        }
    }
    return "";
}

// 指定されたコード定義名のコードとラベルのリストを返却します。
export const getCodeLabelListOf = (constants: any) => {
    let list: Array<{ code: string, label: string }> = [];

    for (let key in constants) {
        list.push({code: constants[key].code,label:constants[key].label});
    }
    return list;
}
