import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getRequest, apiSuccessHandler, apiErrorHandler, putRequest, postRequest, setApiResult } from '../../core/bffApiModule'
import { AppThunk, RootState } from '../../../store/store';
import * as CONSTANTS from '../../../constants/constants';
import * as formatUtils from '../../../utils/formatUtils';
import * as Model from './attendanceModel';
import moment, { Moment } from 'moment';
import { setMessages, message } from '../../core/commonMessageModule';
import * as messagesUtils from '../../../utils/messagesUtils';

export interface changeCheckTransfer {
  targetDataRow: Model.WorkRecordDetailRow
  value: boolean
}

export interface changeCheckTransferSummary {
  targetDataRow: Model.WorkRecordSummaryRow
  value: boolean
}

// 初期設定の日付を計算
const setDate = (num: number) => {
  const nowMoment: Moment | undefined = moment(new Date(), moment.ISO_8601);
  return nowMoment === undefined ? "" : nowMoment.add(num, "month").format("YYYYMM");
}

// 元請勤怠実績データ初期化
const initialState: Model.WorkRecordPrimeState = {
  dataList: []
  , projectId: ""
  , projectName: ""
  , detailDataList: []
  , detailTotal: 0
  , workRecordDetailCriteria: {
    attendanceYearMonth: ""
    , belongCompanyName: ""
    , belongCompanyNameKana: ""
    , firstPartnerCompanyName: ""
    , firstPartnerCompanyNameKana: ""
    , primeCertificationNo: ""
    , workerLastName: ""
    , workerLastNameKana: ""
    , workerFirstName: ""
    , workerFirstNameKana: ""
    , fromTopHierarchyLevel : ""
    , toTopHierarchyLevel : ""
    , offset: 0
    , limit: 10
    , approved: false
    , confirmed: true
    , unApproved: true
    , remand: false
    , noSubmitted: false
  }
  , allSelect: false
  , existAvailableRow: false
  , existApprovalRow: false
  , existUnapprovedRow: false
  , existConfirmedRow: false
  , existNotSubmittedRow: false
  , editAvailableFlag: 1
  , approveAvailableFlag: 1
  , approvalRoleFlag: 0
  , refferenceRoleFlag: 0
  , approvalFlag: 0
  , workRecordSummaryCriteria: {
    projectName: ""
    , firstPartnerCompanyName: ""
    , firstPartnerCompanyNameKana: ""
    , fromYYYYMM: setDate(-11)
    , toYYYYMM: setDate(0)
    , notSubmitted: "0"
    , submitted: "1"
    , confirmed: "1"
    , approved: "0"
    , sortItem: ""
    , sortOrder: ""
    , offset: 0
    , limit: 10
  }
  , summaryTotal: 0
  , summaryAllSelect: false
  , existCheckedSummary: false
  , existEditAvailableRowSummary: false
  , modalVisibleState: false
  , modalRemandExpiredVisibleState: false
  , modalForceApprovalVisibleState: false
  , checkedAttendancesCount: {
    approved: 0
    , confirmed: 0
    , unapproved: 0
    , remand: 0
    , notSubmitted: 0
    , forceApproved: 0
  }
  , forceApprovalChecked : false
};

export const attendanceSlice = createSlice({
  name: 'attendanceSlice',
  initialState,
  reducers: {
    // -------------
    // 勤怠一覧
    // -------------

    prepareMoveToDetail: (state, action: PayloadAction<Model.WorkRecordSummaryRow>) => {
      state.projectId = action.payload.projectId;
      state.workRecordDetailCriteria.attendanceYearMonth = formatUtils.formatMonthNonSlash(action.payload.attendanceYearMonth)
    },

    prepareMoveToDetailCriteria: (state, action: PayloadAction<Model.WorkRecordSummaryCriteria>) => {

      let criteria: Model.WorkRecordDetailCriteria = {
        attendanceYearMonth: state.workRecordDetailCriteria.attendanceYearMonth,
        belongCompanyName: "",
        belongCompanyNameKana: "",
        firstPartnerCompanyName: action.payload.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana,
        primeCertificationNo: "",
        workerLastName: "",
        workerFirstName: "",
        workerLastNameKana: "",
        workerFirstNameKana: "",
        fromTopHierarchyLevel: "",
        toTopHierarchyLevel: "",
        offset: 0,
        limit: 10,
        approved: action.payload.approved === "1" ? true : false,
        confirmed: action.payload.confirmed === "1" ? true : false,
        unApproved: action.payload.submitted === "1" ? true : false,
        remand: false,
        noSubmitted: false
      }

      state.workRecordDetailCriteria = criteria;
    },
    initDataList: (state) => {
      state.dataList = initialState.dataList;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setDataListOfWorkRecordSummary: (state, action: PayloadAction<Model.AttendanceRecordApiData>) => {

      state.dataList = initialState.dataList;

      const responseAttendanceRecordList: Model.WorkRecordSummaryRow[] = action.payload.attendanceRecordList || [];
      let existEditAvailabletRowSummary: boolean = false
      let attendanceRecordList: Array<Model.WorkRecordSummaryRow> = [];
      responseAttendanceRecordList.forEach((attnendanceRecord: Model.WorkRecordSummaryRow) => {

        const rec: Model.WorkRecordSummaryRow = {
          key: attnendanceRecord.projectId + attnendanceRecord.attendanceYearMonth
          , projectId: attnendanceRecord.projectId
          , projectName: attnendanceRecord.projectName
          , firstPartnerCompanyList: attnendanceRecord.firstPartnerCompanyList
          , attendanceYearMonth: attnendanceRecord.attendanceYearMonth
          , editAvailableFlag: attnendanceRecord.editAvailableFlag
          , notSubmitted: attnendanceRecord.submitted
          , submitted: attnendanceRecord.submitted
          , confirmed: attnendanceRecord.confirmed
          , approved: attnendanceRecord.approved
          , selectFlag: false
        };
        attendanceRecordList.push(rec);

        // 一つでも承認可能なデータがあった場合、全選択チェックボックスを表示する
        if ((rec.submitted > 0 || rec.confirmed > 0) && rec.editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_AVAILABLE) {
          existEditAvailabletRowSummary = true;
        }
      });

      state.dataList = attendanceRecordList;
      state.summaryTotal = action.payload.total;
      state.summaryAllSelect = false;
      state.existCheckedSummary = false;
      state.existEditAvailableRowSummary = existEditAvailabletRowSummary;
    },

    /**
     * 検索条件のstate更新
     */
    setSummaryCriteria: (state, action: PayloadAction<Model.WorkRecordSummaryCriteria>) => {
      let criteria: Model.WorkRecordSummaryCriteria = {
        projectName: action.payload.projectName,
        firstPartnerCompanyName: action.payload.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana,
        fromYYYYMM: action.payload.fromYYYYMM,
        toYYYYMM: action.payload.toYYYYMM,
        notSubmitted: action.payload.notSubmitted,
        submitted: action.payload.submitted,
        confirmed: action.payload.confirmed,
        approved: action.payload.approved,
        sortItem: action.payload.sortItem,
        sortOrder: action.payload.sortOrder,
        offset: action.payload.offset,
        limit: action.payload.limit
      }
      return Object.assign({}, state, { workRecordSummaryCriteria: criteria })
    },

    /**
     * 各行のチェックボックス変更
     */
    onChangeRowSelectSummary: (state, action: PayloadAction<changeCheckTransferSummary>) => {
      let userWorkStatusList: Array<Model.WorkRecordSummaryRow> = [];
      let existOffFlag = false;
      let existOnFlag = false;

      state.dataList.forEach((summaryData: Model.WorkRecordSummaryRow) => {
        const rec: Model.WorkRecordSummaryRow = {
          key: summaryData.key
          , projectId: summaryData.projectId
          , projectName: summaryData.projectName
          , firstPartnerCompanyList: summaryData.firstPartnerCompanyList
          , attendanceYearMonth: summaryData.attendanceYearMonth
          , editAvailableFlag: summaryData.editAvailableFlag
          , notSubmitted: summaryData.notSubmitted
          , submitted: summaryData.submitted
          , confirmed: summaryData.confirmed
          , approved: summaryData.approved
          , selectFlag: action.payload.targetDataRow.key === summaryData.key ? action.payload.value : summaryData.selectFlag
        };

        userWorkStatusList.push(rec);

        if ((rec.submitted > 0 || rec.confirmed > 0) && summaryData.editAvailableFlag === 1) {
          if (rec.selectFlag) {
            // 選択されている
            existOnFlag = true;
          } else {
            // 選択されていない
            existOffFlag = true;
          }
        }
      });

      let summaryAllSelect = existOffFlag ? false : true;

      return Object.assign({}, state, { summaryAllSelect: summaryAllSelect }, { dataList: userWorkStatusList }, { existCheckedSummary: existOnFlag });
    },

    /**
     * 全選択チェックボックス変更
     */
    onChangeAllSelectSummary: (state, action: PayloadAction<boolean>) => {
      let userWorkStatusList: Array<Model.WorkRecordSummaryRow> = [];

      let checkDataExist = false;
      // 一覧のチェック状態を全て変更
      state.dataList.forEach((summaryData: Model.WorkRecordSummaryRow) => {
        const rec: Model.WorkRecordSummaryRow = {
          selectFlag: (summaryData.submitted > 0 || summaryData.confirmed > 0) && summaryData.editAvailableFlag === 1 ? action.payload : false
          , key: summaryData.key
          , projectId: summaryData.projectId
          , projectName: summaryData.projectName
          , firstPartnerCompanyList: summaryData.firstPartnerCompanyList
          , attendanceYearMonth: summaryData.attendanceYearMonth
          , editAvailableFlag: summaryData.editAvailableFlag
          , notSubmitted: summaryData.notSubmitted
          , submitted: summaryData.submitted
          , confirmed: summaryData.confirmed
          , approved: summaryData.approved
        };

        userWorkStatusList.push(rec);
        checkDataExist = action.payload;
      });

      return Object.assign({}, state, { summaryAllSelect: action.payload }, { dataList: userWorkStatusList }, { existCheckedSummary: checkDataExist });
    },

    // -------------
    // 勤怠明細一覧
    // -------------

    /**
     * URLに設定されているパラメータをstateに反映
     */
    setUrlParameterValue: (state, action: PayloadAction<Model.UrlParameter>) => {
      let criteria: Model.WorkRecordDetailCriteria = {
        attendanceYearMonth: action.payload.attendanceYearMonth,
        belongCompanyName: state.workRecordDetailCriteria.belongCompanyName,
        belongCompanyNameKana: state.workRecordDetailCriteria.belongCompanyNameKana,
        firstPartnerCompanyName: state.workRecordDetailCriteria.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: state.workRecordDetailCriteria.firstPartnerCompanyNameKana,
        primeCertificationNo: state.workRecordDetailCriteria.primeCertificationNo,
        workerLastName: state.workRecordDetailCriteria.workerLastName,
        workerFirstName: state.workRecordDetailCriteria.workerFirstName,
        workerLastNameKana: state.workRecordDetailCriteria.workerLastNameKana,
        workerFirstNameKana: state.workRecordDetailCriteria.workerFirstNameKana,
        fromTopHierarchyLevel: state.workRecordDetailCriteria.fromTopHierarchyLevel,
        toTopHierarchyLevel: state.workRecordDetailCriteria.toTopHierarchyLevel,
        offset: state.workRecordDetailCriteria.offset,
        limit: state.workRecordDetailCriteria.limit,
        approved: state.workRecordDetailCriteria.approved,
        confirmed: state.workRecordDetailCriteria.confirmed,
        unApproved: state.workRecordDetailCriteria.unApproved,
        remand: state.workRecordDetailCriteria.remand,
        noSubmitted: state.workRecordDetailCriteria.noSubmitted
      }

      return Object.assign(
        {}, state,
        { workRecordDetailCriteria: criteria },
        { projectId: action.payload.projectId }
      );
    },

    /**
     * 検索条件の初期化
     */
    initCriteria: (state) => {
      let workRecordDetailCriteria = {
        attendanceYearMonth: state.workRecordDetailCriteria.attendanceYearMonth
        , belongCompanyName: ""
        , belongCompanyNameKana: ""
        , firstPartnerCompanyName: ""
        , firstPartnerCompanyNameKana: ""
        , primeCertificationNo: ""
        , workerLastName: ""
        , workerLastNameKana: ""
        , workerFirstName: ""
        , workerFirstNameKana: ""
        , offset: 0
        , limit: 10
        , approved: false
        , confirmed: true
        , unApproved: true
        , remand: false
        , noSubmitted: false
      }

      let workRecordSummaryCriteria = {
        projectName: ""
        , firstPartnerCompanyName: ""
        , firstPartnerCompanyNameKana: ""
        , fromYYYYMM: setDate(-11)
        , toYYYYMM: setDate(0)
        , notSubmitted: "0"
        , submitted: "1"
        , confirmed: "1"
        , approved: "0"
        , sortItem: ""
        , sortOrder: ""
        , offset: 0
        , limit: 10
      }

      return Object.assign({}, state, { workRecordDetailCriteria: workRecordDetailCriteria }, { workRecordSummaryCriteria: workRecordSummaryCriteria });
    },

    /**
     * 検索条件のstate更新
     */
    setCriteria: (state, action: PayloadAction<Model.WorkRecordDetailCriteria>) => {
      let criteria: Model.WorkRecordDetailCriteria = {
        attendanceYearMonth: action.payload.attendanceYearMonth,
        belongCompanyName: action.payload.belongCompanyName,
        belongCompanyNameKana: action.payload.belongCompanyNameKana,
        firstPartnerCompanyName: action.payload.firstPartnerCompanyName,
        firstPartnerCompanyNameKana: action.payload.firstPartnerCompanyNameKana,
        primeCertificationNo: action.payload.primeCertificationNo,
        workerLastName: action.payload.workerLastName,
        workerFirstName: action.payload.workerFirstName,
        workerLastNameKana: action.payload.workerLastNameKana,
        workerFirstNameKana: action.payload.workerFirstNameKana,
        fromTopHierarchyLevel: action.payload.fromTopHierarchyLevel,
        toTopHierarchyLevel: action.payload.toTopHierarchyLevel,
        offset: action.payload.offset,
        limit: action.payload.limit,
        approved: action.payload.approved,
        confirmed: action.payload.confirmed,
        unApproved: action.payload.unApproved,
        remand: action.payload.remand,
        noSubmitted: action.payload.noSubmitted
      }
      return Object.assign({}, state, { workRecordDetailCriteria: criteria })
    },

    /**
     * 一覧の初期化
     */
    initDetailDataList: (state) => {
      return Object.assign({}, state, { detailDataList: initialState.detailDataList })
    },

    /**
     * 一覧レコードのセット
     */
    setDetailDataListOfWorkRecordPrime: (state, action: PayloadAction<Model.getDetailDataList.Response>) => {
      let userWorkStatusList: Array<Model.WorkRecordDetailRow> = [];
      let total = action.payload.total;
      let projectId = action.payload.projectId;
      let projectName = action.payload.projectName;
      let editAvailableFlag = action.payload.editAvailableFlag;
      let approveAvailableFlag = action.payload.approveAvailableFlag;
      let refferenceRoleFlag = action.payload.refferenceRoleFlag;
      let approvalRoleFlag = action.payload.approvalRoleFlag;
      let approvalFlag = action.payload.approvalFlag;
      let existAvailableRow: boolean = false
      let existApprovalRow: boolean = false
      let existUnapprovedRow: boolean = false
      let existConfirmedRow: boolean = false
      let existNotSubmittedRow: boolean = false

      action.payload.userWorkStatusList.forEach((userWorkStatus: Model.getDetailDataList.UserWorkStatusList) => {
        userWorkStatus.projectWorkStatusList.forEach((projectWorkStatus: Model.getDetailDataList.ProjectWorkStatusList) => {
          // 労働日数、労働時間の算出
          let days = 0;
          let totalWorkingTime = 0;
          let totalWorkingTimeStr = "";

          projectWorkStatus.dailyWorkStatusList.forEach(dailyWorkStatus => {
            let workingTime = formatUtils.formatTime(dailyWorkStatus.workingTime);

            if (workingTime === "" || workingTime === "0:00") {
              // 労働時間が設定されていない場合は処理しない
              return;
            }

            if (dailyWorkStatus.meetQualificationRequirementType === CONSTANTS.QUALIFICATION_REQUIREMENT_TYPE.OUTSIDE_VALID_PERIOD.code) {
              // 資格要件に該当していない日付は処理しない
              return;
            }

            // 労働日数を加算
            days++;

            // 労働時間を加算
            let hour = workingTime.split(":")[0];
            let minutes = workingTime.split(":")[1];

            totalWorkingTime += ((parseInt(hour) * 60 * 60) + (parseInt(minutes) * 60));
          });

          // 時
          let hourStr = Math.floor(totalWorkingTime / 3600);
          // 分
          let minutesStr = Math.ceil((totalWorkingTime - hourStr * 3600) / 60);

          totalWorkingTimeStr = hourStr + ":" + ("0" + minutesStr).slice(-2);

          const rec: Model.WorkRecordDetailRow = {
            key: projectWorkStatus.workRecordSubmissionHistoryId
            , workRecordSubmissionHistoryId: projectWorkStatus.workRecordSubmissionHistoryId
            , selectFlag: false
            , approvalStatus: projectWorkStatus.approvalStatus
            , forceApprovalFlag: projectWorkStatus.forceApprovalFlag
            , submissionCompanyName: projectWorkStatus.submissionCompanyName
            , submissionMcdpId: projectWorkStatus.submissionMcdpId
            , submissionName: projectWorkStatus.submissionName
            , confirmDate: projectWorkStatus.confirmDate
            , confirmMcdpId: projectWorkStatus.confirmMcdpId
            , confirmName: projectWorkStatus.confirmName
            , approvalDate: projectWorkStatus.approvalDate
            , approvalMcdpId: projectWorkStatus.approvalMcdpId
            , approvalName: projectWorkStatus.approvalName
            , belongCompanyId: projectWorkStatus.belongCompanyId
            , belongCompanyName: projectWorkStatus.belongCompanyName
            , primeCertificationNo: userWorkStatus.primeCertificationNo
            , workerId: userWorkStatus.workerId
            , name: userWorkStatus.name
            , firstPartnerCompanyList: projectWorkStatus.firstPartnerCompanyList
            , dailyWorkStatusList: projectWorkStatus.dailyWorkStatusList
            , updateDatetime: projectWorkStatus.updateDatetime
            , days: days
            , totalWorkingTime: totalWorkingTimeStr
            , companyMergedAttendanceFlag: projectWorkStatus.companyMergedAttendanceFlag
            , topHierarchyLevel: projectWorkStatus.topHierarchyLevel
          };

          userWorkStatusList.push(rec);

          if (isAvailableRowDetail(rec, editAvailableFlag, approveAvailableFlag)) {
            // 処理可能な行があるので、全選択チェックボックスを表示する
            existAvailableRow = true;
          }
          if (isApproved(rec)) {
            existApprovalRow = true;
          }
          if (isUnApproved(rec)) {
            existUnapprovedRow = true;
          }
          if (isConfirmed(rec)) {
            existConfirmedRow = true;
          }
          if (isNotSubmitted(rec) || isRemand(rec)) {
            existNotSubmittedRow = true;
          }
        });
      });


      let checkedAttendancesCount: Model.CheckedAttendancesCount = {
        approved: 0,
        confirmed: 0,
        unapproved: 0,
        remand: 0,
        notSubmitted: 0,
        forceApproved: 0,
      }


      return Object.assign(
        {}, state,
        { detailTotal: total },
        { detailDataList: userWorkStatusList },
        { allSelect: false },
        { existChecked: false },
        { projectId: projectId },
        { projectName: projectName },
        { editAvailableFlag: editAvailableFlag },
        { approveAvailableFlag: approveAvailableFlag },
        { refferenceRoleFlag: refferenceRoleFlag },
        { approvalRoleFlag: approvalRoleFlag },
        { approvalFlag: approvalFlag },
        { existAvailableRow: existAvailableRow },
        { existApprovalRow: existApprovalRow },
        { existUnapprovedRow: existUnapprovedRow },
        { existConfirmedRow: existConfirmedRow },
        { existNotSubmittedRow: existNotSubmittedRow },
        { checkedAttendancesCount: checkedAttendancesCount },
      )
    },

    /**
     * 全選択チェックボックス変更
     */
    onChangeAllSelect: (state, e: any) => {
      let userWorkStatusList: Array<Model.WorkRecordDetailRow> = [];

      // 一覧のチェック状態を全て変更
      state.detailDataList.forEach((detailData: Model.WorkRecordDetailRow) => {
        const rec: Model.WorkRecordDetailRow = {
          key: detailData.workRecordSubmissionHistoryId
          , workRecordSubmissionHistoryId: detailData.workRecordSubmissionHistoryId
          , selectFlag: isAvailableRowDetail(detailData, state.editAvailableFlag, state.approveAvailableFlag) ? e.payload.target.checked : false
          , approvalStatus: detailData.approvalStatus
          , forceApprovalFlag: detailData.forceApprovalFlag
          , submissionCompanyName: detailData.submissionCompanyName
          , submissionMcdpId: detailData.submissionMcdpId
          , submissionName: detailData.submissionName
          , confirmDate: detailData.confirmDate
          , confirmMcdpId: detailData.confirmMcdpId
          , confirmName: detailData.confirmName
          , approvalDate: detailData.approvalDate
          , approvalMcdpId: detailData.approvalMcdpId
          , approvalName: detailData.approvalName
          , belongCompanyId: detailData.belongCompanyId
          , belongCompanyName: detailData.belongCompanyName
          , primeCertificationNo: detailData.primeCertificationNo
          , workerId: detailData.workerId
          , name: detailData.name
          , firstPartnerCompanyList: detailData.firstPartnerCompanyList
          , dailyWorkStatusList: detailData.dailyWorkStatusList
          , updateDatetime: detailData.updateDatetime
          , days: detailData.days
          , totalWorkingTime: detailData.totalWorkingTime
          , companyMergedAttendanceFlag: detailData.companyMergedAttendanceFlag
          , topHierarchyLevel: detailData.topHierarchyLevel
        };

        userWorkStatusList.push(rec);
      });

      let checkedAttendancesCount: Model.CheckedAttendancesCount = getSelectedAttendanceCount(userWorkStatusList);

      return Object.assign(
        {}, state,
        { allSelect: e.payload.target.checked },
        { detailDataList: userWorkStatusList },
        { checkedAttendancesCount: checkedAttendancesCount },
      );
    },

    /**
     * 各行のチェックボックス変更
     */
    onChangeRowSelect: (state, action: PayloadAction<changeCheckTransfer>) => {
      let userWorkStatusList: Array<Model.WorkRecordDetailRow> = [];
      let existOffFlag = false;

      state.detailDataList.forEach((detailData: Model.WorkRecordDetailRow) => {
        const rec: Model.WorkRecordDetailRow = {
          key: detailData.workRecordSubmissionHistoryId
          , workRecordSubmissionHistoryId: detailData.workRecordSubmissionHistoryId
          , selectFlag: action.payload.targetDataRow.key === detailData.key ? action.payload.value : detailData.selectFlag
          , approvalStatus: detailData.approvalStatus
          , forceApprovalFlag: detailData.forceApprovalFlag
          , submissionCompanyName: detailData.submissionCompanyName
          , submissionMcdpId: detailData.submissionMcdpId
          , submissionName: detailData.submissionName
          , confirmDate: detailData.confirmDate
          , confirmMcdpId: detailData.confirmMcdpId
          , confirmName: detailData.confirmName
          , approvalDate: detailData.approvalDate
          , approvalMcdpId: detailData.approvalMcdpId
          , approvalName: detailData.approvalName
          , belongCompanyId: detailData.belongCompanyId
          , belongCompanyName: detailData.belongCompanyName
          , primeCertificationNo: detailData.primeCertificationNo
          , workerId: detailData.workerId
          , name: detailData.name
          , firstPartnerCompanyList: detailData.firstPartnerCompanyList
          , dailyWorkStatusList: detailData.dailyWorkStatusList
          , updateDatetime: detailData.updateDatetime
          , days: detailData.days
          , totalWorkingTime: detailData.totalWorkingTime
          , companyMergedAttendanceFlag: detailData.companyMergedAttendanceFlag
          , topHierarchyLevel: detailData.topHierarchyLevel
        };

        userWorkStatusList.push(rec);

        if (isAvailableRowDetail(rec, state.editAvailableFlag, state.approveAvailableFlag)) {
          if (!rec.selectFlag) {
            // 処理可能行　かつ　 選択されていない
            existOffFlag = true;
          }
        }
      });

      let allSelect = existOffFlag ? false : true;
      let checkedAttendancesCount: Model.CheckedAttendancesCount = getSelectedAttendanceCount(userWorkStatusList);

      return Object.assign(
        {}, state,
        { allSelect: allSelect },
        { detailDataList: userWorkStatusList },
        { checkedAttendancesCount: checkedAttendancesCount },
      );
    },

    /**
     * コメントモーダルの表示切替
     */
    reflectModalState: (state, action: PayloadAction<boolean>) => {
      // モーダル表示切替
      state.modalVisibleState = action.payload
    },

    /**
     * 差戻期限切れモーダルの表示切替
     */
    reflectModalRemandExpiredState: (state, action: PayloadAction<boolean>) => {
      // モーダル表示切替
      state.modalRemandExpiredVisibleState = action.payload
    },

    /**
     * 条件付き承認確認モーダルの表示切替
     */
    reflectModalForceApprovalState: (state, action: PayloadAction<boolean>) => {
      // モーダル表示切替
      state.modalForceApprovalVisibleState = action.payload
    },

    /**
     * 条件付き承認確認モーダルのチェックボックス変更
     */
    setForceApprovalChecked: (state, action: PayloadAction<boolean>) => {
      state.forceApprovalChecked = action.payload;
    },
  },
});

export const {
  prepareMoveToDetail
  , prepareMoveToDetailCriteria
  , initDataList
  , setDataListOfWorkRecordSummary
  , setSummaryCriteria
  , setUrlParameterValue
  , initCriteria
  , setCriteria
  , initDetailDataList
  , setDetailDataListOfWorkRecordPrime
  , onChangeAllSelect
  , onChangeAllSelectSummary
  , onChangeRowSelect
  , onChangeRowSelectSummary
  , reflectModalState
  , reflectModalRemandExpiredState
  , reflectModalForceApprovalState
  , setForceApprovalChecked: setForceApprovalChecked
} = attendanceSlice.actions;

/**
 * 承認済＊かどうかチェック
 */
function isForceApproved(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_ON;
}

/**
 * 承認済かどうかチェック
 */
function isApproved(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_APPROVED;
}

/**
 * 確認済かどうかチェック
 */
function isConfirmed(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_CONFIRMED;
}

/**
 * 提出済かどうかチェック
 */
function isUnApproved(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED;
}

/**
 * 差戻かどうかチェック
 */
function isRemand(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_REMAND;
}

/**
 * 未提出かどうかチェック
 */
function isNotSubmitted(row: Model.WorkRecordDetailRow): boolean {
  return row.forceApprovalFlag === CONSTANTS.FORCE_APPROVAL_FLAG_OFF && row.approvalStatus === CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED;
}

/**
 * 処理（承認など）可能な行か判定する（勤怠明細画面用）
 * @param row 行データ
 * @param editAvailableFlag 編集可能フラグ
 * @param approveAvailableFlag 承認可能フラグ
 * @returns 
 */
function isAvailableRowDetail(row: Model.WorkRecordDetailRow, editAvailableFlag: number, approveAvailableFlag: number): boolean {
  // 未提出、差戻は、提出期限内の場合、可能な処理はない
  if (isNotSubmitted(row) || isRemand(row)) {
    if (editAvailableFlag === CONSTANTS.EDIT_AVAILABLE_FLAG_AVAILABLE) {
      // 未提出、差戻には可能な処理はない
      return false;
    }
  }

  // 承認済は、承認期限が過ぎている場合、可能な処理はない
  if (isApproved(row)) {
    if (approveAvailableFlag === CONSTANTS.APPROVE_AVAILABLE_FLAG_NOT_AVAILABLE) {
      return false;
    }
  }

  // 上記以外の場合、何らかの処理（承認など）が可能
  return true;
}


function getSelectedAttendanceCount(rows: Model.WorkRecordDetailRow[]): Model.CheckedAttendancesCount {
  let selectedAttendanceCount: Model.CheckedAttendancesCount = {
    approved: 0,
    confirmed: 0,
    unapproved: 0,
    remand: 0,
    notSubmitted: 0,
    forceApproved: 0,
  }

  for (const r of rows) {
    if (r.selectFlag) {
      if (isForceApproved(r)) {
        selectedAttendanceCount.forceApproved += 1;
      } else {
        switch (r.approvalStatus) {
          case CONSTANTS.ATTENDANCE_STATUS_APPROVED:
            selectedAttendanceCount.approved += 1;
            break;
          case CONSTANTS.ATTENDANCE_STATUS_CONFIRMED:
            selectedAttendanceCount.confirmed += 1;
            break;
          case CONSTANTS.ATTENDANCE_STATUS_UNAPPROVED:
            selectedAttendanceCount.unapproved += 1;
            break;
          case CONSTANTS.ATTENDANCE_STATUS_REMAND:
            selectedAttendanceCount.remand += 1;
            break;
          case CONSTANTS.ATTENDANCE_STATUS_NOT_SUBMITTED:
            selectedAttendanceCount.notSubmitted += 1;
            break;
        }
      }
    }
  }

  return selectedAttendanceCount;
}

/**
 * 勤怠実績集計取得_元請API呼び出し
 * @param params 
 * @returns 
 */
export const getDataListAsync = (params: Model.getSummaryDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_PRIME_SUMMARY, {
      params: params
    });
    // 結果をstateに設定します。
    dispatch(setDataListOfWorkRecordSummary(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

/**
 * 勤怠明細取得_元請API呼び出し
 * @param params 
 */
export const getDetailDataListAsync = (params: Model.getDetailDataList.Request): AppThunk => async dispatch => {
  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_PRIME_DETAIL, {
      params: params
    });
    // 結果をstateに設定します。
    dispatch(setDetailDataListOfWorkRecordPrime(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
}

/**
 * 勤怠実績ステータス変更API呼び出し
 * TODO 協力側にも同じ実装があるため、元請側と協力側で共通化したい。
 * @param putData 
 */
export const putAttendanceStatusAsync = (putData: Model.putStatus.Request): AppThunk => async dispatch => {
  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_ATTENDANCE_CMN_STATUS, putData);

    // APIが返却したエラーコードがいずれかの場合、セッションストレージに保管されている処理成功メッセージを書き換える
    // ・認可チェック失敗
    // ・勤怠締め切りによる失敗
    // ・メール送信失敗
    // ・更新処理0件
    let message = "";
    let processName = "";
    switch (response.data.cmn.errorCode) {
      case "EA5000001":
          processName = getAeendanceProcessType(putData.processType);
          message = messagesUtils.getMessage(response.data.cmn.errorCode, [processName]);
          messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
          break;
      case "EA5000002":
        processName = getAeendanceProcessType(putData.processType);
        if (putData.processType === CONSTANTS.ATTENDANCE_PROCESS_TYPE.FORCE_APPROVAL) {
          message = messagesUtils.getMessage("EA5000002_PRIME_FORCE_APPROVAL", [processName, processName]);
        } else {
          message = messagesUtils.getMessage("EA5000002_PRIME", [processName, processName]);
        }
        messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
        break;
      case "EA5000004":
          message = messagesUtils.getMessage(response.data.cmn.errorCode);
          messagesUtils.setMessageInSession(message, CONSTANTS.MESSAGE_TYPE_ERROR);
          break;
      case "EA5000003":
          // メール送信失敗の場合はエラーメッセージは表示させない
          break;
    }
    // 共通成功時処理を呼び出します。
    dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_PUT, url: response.config.url }));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
}

/**
 * 承認依頼メール送信_元請API呼び出し
 * @param postData
 */
export const postAttendanceMailAsync = (postData: Model.postMail.Request): AppThunk => async dispatch => {
  try {
    // POST-APIを呼び出します。
    const response = await postRequest(CONSTANTS.URL_ATTENDANCE_PRIME_SENDAPPROVEREQUESTMAIL, postData);
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_POST));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_POST));
  }
}

/**
 * 実行メッセージ取得
 * @param processType 
 */
const getAeendanceProcessType = (processType: string): string => {
  // 処理タイプ毎にメッセージを取得
  let args = "";
  switch (processType) {
    case CONSTANTS.ATTENDANCE_PROCESS_TYPE.APPROVAL:
      args = "承認";
      break;
    case CONSTANTS.ATTENDANCE_PROCESS_TYPE.CONFIRM:
      args = "確認";
      break;
    case CONSTANTS.ATTENDANCE_PROCESS_TYPE.REMAND:
      args = "差戻";
      break;
    case CONSTANTS.ATTENDANCE_PROCESS_TYPE.APPROVAL_CANCEL:
      args = "承認取消";
      break;
    case CONSTANTS.ATTENDANCE_PROCESS_TYPE.FORCE_APPROVAL:
      args = "条件付き承認";
      break;
  }
  return args;
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.workRecordPrime.dataList;
export const storedDetailDataList = (state: RootState) => state.workRecordPrime.detailDataList;
export const storedAllSelect = (state: RootState) => state.workRecordPrime.allSelect;
export const storedCriteria = (state: RootState) => state.workRecordPrime.workRecordDetailCriteria;
export const storedDetailTotal = (state: RootState) => state.workRecordPrime.detailTotal;
export const storedProjectId = (state: RootState) => state.workRecordPrime.projectId;
export const storedProjectName = (state: RootState) => state.workRecordPrime.projectName;
export const storedEditAvailableFlag = (state: RootState) => state.workRecordPrime.editAvailableFlag;
export const storedApproveAvailableFlag = (state: RootState) => state.workRecordPrime.approveAvailableFlag;
export const storedRefferenceRoleFlag = (state: RootState) => state.workRecordPrime.refferenceRoleFlag;
export const storedApprovalRoleFlag = (state: RootState) => state.workRecordPrime.approvalRoleFlag;
export const storedApprovalFlag = (state: RootState) => state.workRecordPrime.approvalFlag;
export const storedExsitAvailabletRow = (state: RootState) => state.workRecordPrime.existAvailableRow;
export const storedExsitApprovalRow = (state: RootState) => state.workRecordPrime.existApprovalRow;
export const storedExsitUnapprovedRow = (state: RootState) => state.workRecordPrime.existUnapprovedRow;
export const storedConfirmedRow = (state: RootState) => state.workRecordPrime.existConfirmedRow;
export const storedNotSubmittedRow = (state: RootState) => state.workRecordPrime.existNotSubmittedRow;
export const storedSummaryCriteria = (state: RootState) => state.workRecordPrime.workRecordSummaryCriteria;
export const storedSummaryTotal = (state: RootState) => state.workRecordPrime.summaryTotal;
export const storedSummaryAllSelect = (state: RootState) => state.workRecordPrime.summaryAllSelect;
export const storedExsitCheckedSummary = (state: RootState) => state.workRecordPrime.existCheckedSummary;
export const storedExsitAvailabletRowSummary = (state: RootState) => state.workRecordPrime.existEditAvailableRowSummary;
export const storedModalVisibleState = (state: RootState) => state.workRecordPrime.modalVisibleState;
export const storedModalRemandExpiredVisibleState = (state: RootState) => state.workRecordPrime.modalRemandExpiredVisibleState;
export const storedModalForceApprovalVisibleState = (state: RootState) => state.workRecordPrime.modalForceApprovalVisibleState;
export const storedCheckedAttendancesCount = (state: RootState) => state.workRecordPrime.checkedAttendancesCount;
export const storedForceApprovalChecked = (state: RootState) => state.workRecordPrime.forceApprovalChecked;

export default attendanceSlice.reducer;
