import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
import * as Model from './projectSelectModel';
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";


// state初期化
const initialState: Model.State = {
  dataList: []
  , dataRow: {
    key: ""
    , projectId: ""
    , projectName: ""
  }
  , criteria: {
    projectName: ""
    , nextPageToken: ""
    , limit: 10
  }
  , total: 0
  , nextPageTokenList: []
  , currentPage: 1
};

export const ProjectSelectSlice = createSlice({
  name: 'projectSelect',
  initialState,
  reducers: {
    initState: (State) => {
      State.criteria = initialState.criteria;
      State.nextPageTokenList = initialState.nextPageTokenList;
      State.currentPage = initialState.currentPage;
    },
    getDataListOfProjectSelect: (state, action: PayloadAction<Model.getDataList.Response>) => {

      let projectList: Array<Model.DataRow> = [];

      action.payload.projectList.forEach((apiDataRow: Model.DataRow) => {

        const rec: Model.DataRow = {
          key: apiDataRow.projectId,
          // プロジェクトID
          projectId: apiDataRow.projectId,
          // プロジェクト名称
          projectName: apiDataRow.projectName,
        };
        projectList.push(rec);
      });
      state.dataList = projectList;
      state.total = action.payload.total;
      state.nextPageTokenList[state.currentPage + 1] = action.payload.nextPageToken;
    },
    setCriteria: (state, action: PayloadAction<Model.Criteria>) => {
      let criteria: Model.Criteria = {
        projectName: action.payload.projectName,
        nextPageToken: action.payload.nextPageToken,
        limit: action.payload.limit
      }
      state.criteria = criteria;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    }
  }
});

export const {
  initState
  , getDataListOfProjectSelect
  , setCriteria
  , setCurrentPage
} = ProjectSelectSlice.actions;

export const getDataListAsync = (ProjectCriteria: Model.getDataList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_CMN_PRIME_PROJECTS, {
      params: ProjectCriteria
    });
    // 結果をstateに設定します。
    dispatch(getDataListOfProjectSelect(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.projectSelect.dataList;
export const storedDataRow = (state: RootState) => state.projectSelect.dataRow;
export const storedDataCriteria = (state: RootState) => state.projectSelect.criteria;
export const storedDataTotal = (state: RootState) => state.projectSelect.total;
export const storedDataCurrentPage = (state: RootState) => state.projectSelect.currentPage;
export const storedNextPageTokenList = (state: RootState) => state.projectSelect.nextPageTokenList;

export default ProjectSelectSlice.reducer;
