import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { useHistory } from 'react-router';
import { isAllow, getMenuItemId } from "./helpers/authorizationHelper";
import { BffApiResultHandler } from './BffApiResultHandler';
import { TermsOfService } from "./TermsOfService"
import { NowLoading } from "./NowLoading"
import { Forbidden } from './Forbidden';
import { getLoginUserInfoAsync, storedLoginUserInfo } from "../../modules/core/authModule";
import { setCurrnetMenuItemId, isPrimeMenu } from "../../modules/core/menuModule";
import { initApiResult } from '../../modules/core/bffApiModule';
import { initDateString } from '../../modules/core/datePickerModule';
import { initDateString as initMonthString } from '../../modules/core/monthPickerModule';
import { initMessages } from '../../modules/core/commonMessageModule';
import * as CONSTANTS from '../../constants/constants';
import { NotContractPrimeService } from './NotContractPrimeService';
import { NoUsableAuthorized } from './NoUsableAuthorized';
import { NoPartnerAvailableFlg } from './NoPartnerAvailableFlg';


export const AuthRoute = (props: RouteProps) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const loginUserInfo = useSelector(storedLoginUserInfo);
  const isPrime = useSelector(isPrimeMenu);

  let targetPath = props.path;

  // TOP-URLが指定された場合、遷移先URLをログインユーザー情報から決定します。
  if (CONSTANTS.PATH_TOP === props.path && "" !== loginUserInfo.mcdpId) {

    // PRIME契約サービス利用可能フラグが利用可能
    if ("1" === loginUserInfo.primeServiceAvailableFlg) {

      // 元請_権限設定済みフラグが未設定かつシステム管理者権限の場合
      if ("1" !== loginUserInfo.primeAuthSettingFinishedFlg
        && "1" === loginUserInfo.authorityInfo.primeSystemMngAuth) {
        // prime ユーザ権限管理 一覧
        targetPath = CONSTANTS.PATH_PRIME_AUTH_LIST;
      } else {
        // prime 勤怠実績報告 一覧
        targetPath = CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY;
      }

    } else {
      // 協力_権限設定済みフラグが未設定かつシステム管理者権限の場合
      if ("1" !== loginUserInfo.partnerAuthSettingFinishedFlg
        && "1" === loginUserInfo.authorityInfo.partnerSystemMngAuth) {
        // partner ユーザ権限管理 一覧
        targetPath = CONSTANTS.PATH_PARTNER_AUTH_LIST;
      } else {
        // partner 勤怠実績報告 一覧
        if (window.innerWidth <= 850) {
          // 画面幅が850px以下の場合スマホ用の画面に遷移
          targetPath = CONSTANTS.PATH_PARTNER_ATTENDANCE_MOBILE_SUMMARY;
        } else {
          targetPath = CONSTANTS.PATH_PARTNER_ATTENDANCE_SUMMARY;
        }
      }
    }
  }

  // 指定されたパスから、メニューIDを取得します。
  const menuItemId = getMenuItemId(targetPath);

  useEffect(() => {
    // ログインユーザー情報が未取得の場合、取得APIを呼び出します。
    if ("" === loginUserInfo.mcdpId) {
      dispatch(getLoginUserInfoAsync());
    }
    if ("" !== menuItemId) {
      // 現在のメニューIDをステートに保存します。
      dispatch(setCurrnetMenuItemId(menuItemId));
    }
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

  }, [loginUserInfo]);

  // ログインユーザー情報取得まではローディング画面を描画します。
  // API制御のためAPIApiResultHandlerコンポーネントを読み込みます。
  if ("" === loginUserInfo.mcdpId) {
    return (
      <>
        <BffApiResultHandler />
        <NowLoading />
      </>
    )
  }

  // プライム契約していない場合はエラー
  if (isPrime && loginUserInfo.contractPrimeService == CONSTANTS.CONTRACT_PRIME_SERVICE.NOT_CONTRACT) {
    return (
      <NotContractPrimeService />
    )
  }

  // 利用可能フラグが利用不可の場合、403画面を描画します。
  if (isPrime && "0" === loginUserInfo.primeServiceAvailableFlg) {
    return (
      <Forbidden />
    )
  }
  if (!isPrime && "0" === loginUserInfo.partnerAvailableFlg) {
    return (
      <NoPartnerAvailableFlg />
    )
  }

  // 同意未チェックの場合、約款同意画面へ遷移します。
  if ((isPrime && "0" === loginUserInfo.primeServiceTermsAgreeFlg) ||
    (!isPrime && "0" === loginUserInfo.partnerServiceTermsAgreeFlg)) {

    return (
      <>
        <BffApiResultHandler />
        <TermsOfService title="サービス利用約款　同意" />
      </>
    )
  }

  if (isAllow(targetPath, loginUserInfo)) {

    // TOP-URLが指定された場合、指定パスへ遷移
    if ("/top" === props.path && "string" === typeof targetPath) {
      history.push(targetPath);
    }

    // APIResultを初期化します。
    dispatch(initApiResult());
    // DatePickerステートを初期化します。
    dispatch(initDateString());
    // MonthPickerステートを初期化します。
    dispatch(initMonthString());

    return (
      <>
        <BffApiResultHandler />
        <Route {...props} />
      </>
    )
  }


  // 契約や権限により表示できない場合のエラー表示
  if (isPrime) {
    if ("0" === loginUserInfo.authorityInfo.primeAttendanceApprovalRoleAuth
                && "0" === loginUserInfo.authorityInfo.primeAttendanceReffenceRoleAuth
                && "0" === loginUserInfo.authorityInfo.primeAvailableAuth) {
      return (
        <NoUsableAuthorized />
      )
    }
  } else {
    if ("0" === loginUserInfo.authorityInfo.partnerAvailableAuth) {
      return (
        <NoUsableAuthorized />
      )  
    }
  }

  // 上記の処理で判断できなかった場合、403画面を描画します。
  return <Forbidden />

}
