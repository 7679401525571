import React from 'react';
import styles from '../css/CriteriaForm.module.css';
import { Form } from 'antd';
import { FormProps, FormItemProps } from 'antd/lib/form';

const CriteriaForm = (props: FormProps) => {

    let addOnClass: string = "";
    if (typeof props.className !== "undefined") {
        addOnClass = props.className
    }

    return (
        <Form {...props} className={styles.criteriaForm + " " + addOnClass}
        />
    )
}

CriteriaForm.Item = (props: FormItemProps) => { return <Form.Item {...props}></Form.Item> }

export default CriteriaForm;