
/** 独自資格の認定状態変更API.状態変更処理コード */
export enum STATUS_CHANGE_CODE {
    COMFIRM_REQUEST = "01", // 確認依頼
    COMFIRM_REQUEST_CANCEL = "02", // 確認依頼取消
    COMFIRM = "11", // 確認
    COMFIRM_REQUEST_REMAND = "12", // 確認依頼差戻
    APPROVAL = "21", // 承認
    COMFIRM_REMAND = "22", // 確認差戻
    APPROVAL_CANCEL = "23", // 承認取消
}

interface baseInterface {
    [propertyName: string]: any
}

// 所属会社一覧行定義
export interface belongCompanyDataRow extends baseInterface {
    // 一覧KEY
    key: string
    // 所属会社ID
    belongCompanyId: string
    // 所属会社名
    belongCompanyName: string
    // 郵便番号
    zipcode: string
    // 所在地
    address: string
    // 有効独自資格保有者人数
    numberOfPeople: number
}

// 所属会社一覧行定義
export interface belongCompanyCriteria extends baseInterface {
    // 所属会社名
    belongCompanyName: string
    // 所属会社名カナ
    belongCompanyNameKana: string
    // ソート項目名
    sortItem: string
    // ソート順
    sortOrder: string
    // 開始位置
    offset: number
    // 取得件数
    limit: number
    // 有効資格保有者のみ
    onlyEnableQualification: string
}
// 独自資格保有者一覧行定義
export interface QualificationHolderDataRow extends baseInterface {
    // 一覧KEY
    key: string
    // 管理グループID
    managementGroupId: string
    // 管理グループ名
    managementGroupName: string
    // 従業員ID
    workerId: number
    // 氏名（姓）
    firstName: string
    // 氏名（名）
    lastName: string
    // 生年月日
    birthday: string
    // 元請認定番号
    primeCertificationNo: string
    // 備考
    remarks: string
    // 更新日時
    updateDatetime: string
    // 独自資格一覧
    originalQualificationList: Array<originalQualificationRow>
    // 有効独自資格数
    numberOfQualifications: number
    // 確認済独自資格数
    numberOfConfirmedQualifications: number
    // 確認依頼中独自資格数
    numberOfRequestedQualifications: number
    // 申請独自資格数
    numberOfRequestQualifications: number
}
// 独自資格一覧行定義
export interface originalQualificationRow extends baseInterface {
    // key
    key: string
    // 認定ID
    certificationId: string
    // 状態コード
    registrationStatus: string
    // 状態
    status: string
    // 独自資格ID
    originalQualificationId: string
    // 独自資格番号
    originalQualificationNo: string
    // 独自資格名
    originalQualificationName: string
    // 認定日
    certificationDate: string
    // 認定取消日
    certificationCancelDate: string
    // 有効期限有無
    deadlineExist: string
    // 有効期限開始日
    deadlineStartDate: string
    // 有効期限終了日
    deadlineEndDate: string
    // 新規フラグ
    newFlg: boolean
    // 削除フラグ
    deleteFlag: boolean
    // 更新日時
    updateDatetime: string
}

// 独自資格保有者検索項目定義
export interface QualificationHolderCriteria extends baseInterface {
    // 所属会社ID
    belongCompanyId: string
    // 管理グループID
    managementGroupId: string
    // 独自資格名
    originalQualificationName: string
    // 従業員ID
    workerId: number
    // 氏名（姓）
    firstName: string
    // 氏名（名）
    lastName: string
    // 氏名カナ（姓）
    firstNameKana: string
    // 氏名カナ（名）
    lastNameKana: string
    // 元請認定番号
    primeCertificationNo: string
    // ソート項目名
    sortItem: string
    // ソート順
    sortOrder: string
    // 開始位置
    offset: number
    // 取得件数
    limit: number
    // 有効資格保有者のみ
    onlyEnableQualification: string
}

// 管理グループ一覧行定義
export interface managementGroupRow {
    key: string
    managementGroupId: string
    managementGroupName: string
}


// state定義
export interface State {
    belongCompanyDataList: Array<belongCompanyDataRow>
    belongCompanyCriteria: belongCompanyCriteria
    belongCompanyTotal: number
    belongCompanyDataRow: belongCompanyDataRow
    dataList: Array<QualificationHolderDataRow>
    dataRow: QualificationHolderDataRow
    total: number
    criteria: QualificationHolderCriteria
    managementGroupList: Array<managementGroupRow>
    count: number
    managementModalVisibleState: boolean
    workerSelectModalVisibleState: boolean
    qualificationSelectModalVisibleState: boolean
    qualificationSelectTableKey: string
    commentVisibleState: boolean
    loadingFlg: boolean
}
/**
 * 独自資格保有者所属会社一覧取得
 */
export declare namespace getBelongCompaniesDataList {
    /** 
     * リクエスト
     */
    export interface Request {
        // 所属会社名
        belongCompanyName: string
        // 所属会社名カナ
        belongCompanyNameKana: string
        // ソート項目名
        sortItem: string
        // ソート順
        sortOrder: string
        // 開始位置
        offset: number
        // 取得件数
        limit: number
    }
    /** 
     * レスポンス
     */
    export interface Response {
        qualificationOwnerPartnerList: Array<belongCompanyDataRow>
        total: number
    }
}

/**
 * 独自資格保有者一覧取得
 */
export declare namespace getQualificationHolderDataList {
    /** 
     * リクエスト
     */
    export interface Request {
        // 所属会社ID
        belongCompanyId: string
        // 管理グループID
        managementGroupId: string
        // 独自資格名
        originalQualificationName: string
        // 従業員ID
        workerId: number
        // 氏名（姓）
        firstName: string
        // 氏名（名）
        lastName: string
        // 氏名カナ（姓）
        firstNameKana: string
        // 氏名カナ（名）
        lastNameKana: string
        // 元請認定番号
        primeCertificationNo: string
        // ソート項目名
        sortItem: string
        // ソート順
        sortOrder: string
        // 開始位置
        offset: number
        // 取得件数
        limit: number
    }
    /** 
     * レスポンス
     */
    export interface Response {
        belongCompanyName: string
        qualificationOwnerList: Array<QualificationHolderDataRow>
        managementGroupList: Array<managementGroupRow>
        total: number
    }
}

/**
 * 独自資格保有者編集
 */
export declare namespace putDataList {
    /** 
     * リクエスト
     */
    export interface Request {
        // 処理区分
        processType: string
        // 所属企業ID
        belongCompanyId: string
        // 従業員ID
        workerId: number
        // 元請認定番号
        primeCertificationNo: string
        // 管理グループID
        managementGroupId: string
        // 備考
        remarks: string
        // 資格情報リスト
        originalQualificationList: Array<originalQualification>
        // 更新日時
        updateDatetime: string
    }
    /** 
     * 独自資格
     */
    export interface originalQualification {
        // 認定ID
        certificationId: string
        // 独自資格ID
        originalQualificationId: string
        // 状態コード
        registrationStatus: string
        // 認定日
        certificationDate: string
        // 認定取消日
        certificationCancelDate: string
        // 削除フラグ
        deleteFlag: string
        // 更新日時
        updateDatetime: string
    }
}

/**
 * 独自資格保有者削除
 */
export declare namespace deleteData {
    /** 
     * リクエスト
     */
    export interface Request {
        // 所属企業ID
        belongCompanyId: string
        // 従業員ID
        workerId: number
        // 更新日時
        updateDatetime: string
        // 資格情報リスト
        originalQualificationList: Array<originalQualification>
    }
    /** 
     * 独自資格
     */
    export interface originalQualification {
        // 認定ID
        certificationId: string
        // 更新日時
        updateDatetime: string
    }
}

/**
 * 独自資格認定状態変更
 */
export declare namespace putCertificationStatusData {
    /** 
     * リクエスト
     */
    export interface Request {
        // 認定ID
        certificationId: string
        // 状態変更処理コード
        statusChangeCode: string
        // 更新日時
        updateDatetime: string
        // 所属企業ID
        belongCompanyId: string
        // 従業員ID
        workerId: number
    }
}