import * as Model from './workerSelectModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

const initialState: Model.WorkerSelectState = {
  dataRow: {
    workerId: 0
    , lastName: ""
    , firstName: ""
    , lastNameKana: ""
    , firstNameKana: ""
    , birthday: ""
  }
  , criteria: {
    belongCompanyId: ""
    , nameType: "1"
    , lastName: ""
    , firstName: ""
    , birthday: ""
  }
  , checked: false
};

export const WorkerSelectSlice = createSlice({
  name: 'workerSelect',
  initialState,
  reducers: {
    // 取得した従業員情報をステートにセットします。
    setWorker: (state, action: PayloadAction<Model.getData.Response>) => {
      const dataRow: Model.dataRow = {
        workerId: action.payload.workerId
        , lastName: action.payload.lastName
        , firstName: action.payload.firstName
        , lastNameKana: action.payload.lastNameKana
        , firstNameKana: action.payload.firstNameKana
        , birthday: action.payload.birthday
      };
      state.dataRow = dataRow;
    }
    , setCriteria: (state, action: PayloadAction<Model.criteria>) => {
      const criteria: Model.criteria = {
        belongCompanyId: action.payload.belongCompanyId
        , nameType: action.payload.nameType
        , lastName: action.payload.lastName
        , firstName: action.payload.firstName
        , birthday: action.payload.birthday
      };
      state.criteria = criteria;
    }
    , setChecked: (state, action: PayloadAction<boolean>) => {
      state.checked = action.payload;
    }
    , initState: (state) => {
      state.dataRow = initialState.dataRow;
      state.criteria = initialState.criteria;
      state.checked = initialState.checked;
    }
  }
});

export const { setWorker, setCriteria, setChecked, initState } = WorkerSelectSlice.actions;

export const getWorkerDataAsync = (param: Model.getData.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_WORKERS, { params: param });
    if(response.data.cmn.errorCode === ""){
      // 結果をstateに設定します。
      dispatch(setWorker(response.data.data));
    }
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataRow = (state: RootState) => state.workerSelect.dataRow;
export const storedCriteria = (state: RootState) => state.workerSelect.criteria;
export const storedChecked = (state: RootState) => state.workerSelect.checked;

export default WorkerSelectSlice.reducer;
