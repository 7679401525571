import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import styles from '../css/FunctionTitle.module.css';
import { isPrimeMenu } from '../../../modules/core/menuModule';

const { Title } = Typography;

// パラメータ定義
interface titleInterface {
  title: string;
}

export const FunctionTitle = (props: titleInterface) => {

  const isPrime = useSelector(isPrimeMenu);

  return (
    <Title level={4} className={isPrime ? styles.functionTitle : styles.partnerfunctionTitle}>
      {props.title}
    </Title>
  )
}
