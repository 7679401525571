import * as Model from './managementGroupModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
import { ManagementGroupChoicesListRow } from '../auth/authorityModel';
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

interface baseInterface {
  [propertyName: string]: any
}


const initialState: Model.ManagementGroupSelectState = {
  dataList: [],
  exclusionList: [],
  modalSelectValue: {
    key: '',
    managementGroupId: '',
    managementGroupName: ''
  },
  managementGroupSelectRow: {
    key: '',
    managementGroupId: '',
    managementGroupName: '',
  },
  managementGroupCriteria: {
    branchName: '',
    branchNameKana: '',
    offset: 0,
    limit: 10,
  },
  total: 0,
};

export const ManagementGroupSelectSlice = createSlice({
  name: 'managementGroupSelect',
  initialState,
  reducers: {
    prepareMoveToDetail: (state, action: PayloadAction<Model.ManagementGroupSelectRow>) => {
      state.dataList.push(action.payload);
    },
    initDataList: (state) => {
      state.dataList = initialState.dataList;
    },
    initModalSelectValue: (state) => {
      state.modalSelectValue = initialState.modalSelectValue;
    },
    reflectModalSelectValue: (state, action: PayloadAction<Model.ManagementGroupSelectRow>) => {
      // 選択値保持
      state.modalSelectValue = action.payload
    },

    setCriteria: (state, action: PayloadAction<Model.ManagementGroupCriteria>) => {
      let managementGroupCriteria: Model.ManagementGroupCriteria = {
        branchName: action.payload.branchName,
        branchNameKana: action.payload.branchNameKana,
        offset: action.payload.offset,
        limit: action.payload.limit
      }
      return Object.assign({}, state, { managementGroupCriteria: managementGroupCriteria })
    },

    initCriteria: (state) => {
      state.managementGroupCriteria = initialState.managementGroupCriteria;
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    getDataListOfManagementGroupSelect: (state, action: PayloadAction<Model.getBranchList.Response>) => {
      let managementGroupRowList: Array<Model.ManagementGroupSelectRow> = [];

      action.payload.branchList.map((branchRecord: Model.BranchRow) => {
        let managementGroupRow: Model.ManagementGroupSelectRow =
        {
          key: branchRecord.branchId,
          managementGroupId: branchRecord.branchId,
          managementGroupName: branchRecord.branchName
        };
        managementGroupRowList.push(managementGroupRow);
      });

      state.dataList = managementGroupRowList;
      state.total = action.payload.total;
    },
    setExclusionList: (state, action: PayloadAction<Array<string>>) => {
      // 選択値保持
      state.exclusionList = action.payload
    }
  },
});

export const { getDataListOfManagementGroupSelect, initDataList, prepareMoveToDetail, reflectModalSelectValue, initModalSelectValue, setCriteria, setExclusionList, initCriteria } = ManagementGroupSelectSlice.actions;

export const getDataListAsync = (param: Model.getBranchList.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_CMN_PRIME_BRANCHES, { params: param });
    // 結果をstateに設定します。
    dispatch(getDataListOfManagementGroupSelect(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.managementGroupSelect.dataList;
export const storedExclusionList = (state: RootState) => state.managementGroupSelect.exclusionList;
export const storedModalSelectValue = (state: RootState) => state.managementGroupSelect.modalSelectValue;
export const storedTotal = (state: RootState) => state.managementGroupSelect.total;
export const storedManageCriteria = (state: RootState) => state.managementGroupSelect.managementGroupCriteria;

export default ManagementGroupSelectSlice.reducer;
