import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// core
import userInfoReducer from '../modules/core/authModule';
import menuReducer from '../modules/core/menuModule';
import bffApiReducer from '../modules/core/bffApiModule';
import commonMessageReducer from '../modules/core/commonMessageModule';
import termOfServiceReducer from '../modules/core/termOfServiceModule';
import datePickerReducer from '../modules/core/datePickerModule';
import monthPickerReducer from '../modules/core/monthPickerModule';
import abolishCompanyListButtonModuleReducer from '../modules/core/abolishCompanyListButtonModule';
// 機能別
import counterReducer from '../modules/partner/counterModule';
import primeQualificationReducer from '../modules/prime/qualification/qualificationModule';
import primeQualificationHoldersReducer from '../modules/prime/qualifications-holders/qualificationHoldersModule';
import qualificationSelectReducer from '../modules/prime/qualificationSelect/qualificationSelectModule';
import primePartnerCompaniesReducer from '../modules/prime/partner-companies/partner-companiesModule';
import primeAttendanceReducer from '../modules/prime/attendance/attendanceModule'
import partnerAttendanceReducer from '../modules/partner/attendance/attendanceModule'
import partnerAttendanceMobileReducer from '../modules/partner/attendance-mobile/attendanceMobileModule'
import primeAuthorityReducer from '../modules/prime/auth/authorityModule';
import partnerAuthorityReducer from '../modules/partner/auth/partnerAuthorityModule';
import managementGroupSelectReducer from '../modules/prime/managementGroup/managementGroupModule';
import multiManagementGroupSelectReducer from '../modules/prime/managementGroup/multiManagementGroupModule';
import workerSelectReducer from '../modules/prime/workerSelect/workerSelectModule';
import projectSelectReducer from '../modules/prime/projectSelect/projectSelectModule';
import partnerCompanySelectReducer from '../modules/prime/partnerCompanySelect/partnerCompanySelectModule';
import primeReportReducer from '../modules/prime/report/reportModule';
import partnerReportReducer from '../modules/partner/report/reportModule';
import primeWorkerAttendanceReducer from '../modules/prime/workerAttendance/workerAttendanceModule';
import comment from '../modules/prime/comment/commentModule';
import primeCommonSettingReducer from '../modules/prime/commonSetting/commonSettingModule';

export const store = configureStore({
  reducer: {
    // core
    userInfo: userInfoReducer,
    menu: menuReducer,
    bffApi: bffApiReducer,
    commonMessage: commonMessageReducer,
    termOfService: termOfServiceReducer,
    datePicker: datePickerReducer,
    monthPicker: monthPickerReducer,
    abolishCompanyModal: abolishCompanyListButtonModuleReducer,
    // 機能別
    primeQualification: primeQualificationReducer,
    primeQualificationHolders: primeQualificationHoldersReducer,
    qualificationSelect: qualificationSelectReducer,
    primePartnerCompanies: primePartnerCompaniesReducer,
    counter: counterReducer,
    workRecordPrime: primeAttendanceReducer,
    workRecordPartner: partnerAttendanceReducer,
    workRecordMobilePartner: partnerAttendanceMobileReducer,
    primeAuthority: primeAuthorityReducer,
    managementGroupSelect: managementGroupSelectReducer,
    multiManagementGroupSelect: multiManagementGroupSelectReducer,
    projectSelect: projectSelectReducer,
    partnerCompanySelect: partnerCompanySelectReducer,
    partnerAuthority: partnerAuthorityReducer,
    primeReport: primeReportReducer,
    partnerReport: partnerReportReducer,
    workerSelect: workerSelectReducer,
    workerAttendancePrime: primeWorkerAttendanceReducer,
    comment: comment,
    primeCommonSetting: primeCommonSettingReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
