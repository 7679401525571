import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler, putRequest } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
import * as Model from './partnerAuthorityModel';

axios.defaults.headers.post["Content-Type"] = "application/json";

// state初期化
const initialState: Model.AuthorityState = {
  dataList: []
  , dataRow: {
    // key
    key: '',
    // MCDP-ID
    mcdpId: '',
    // ユーザ名
    userName: '',
    //元請_利用者
    partnerAvailableAuth: '',
    //元請_勤怠承認者
    partnerAttendanceSubmitAuth: '',
    //元請_システム管理者
    partnerSystemMngAuth: '',
    // 次ページトークンリスト
    nextPageTokenList: []
  }
  , total: 0
  , authorityCriteria: {
    mcdpId: '',
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    authKbn: '',
    offset: 0,
    limit: 10,
    nextPageToken: ''
  }
  , nextPageTokenList: []
  , loadingFlg: false
};

// スライサー （state更新アクションの実装）
export const authoritySlice = createSlice({
  name: 'partnerAuthority',
  initialState,
  reducers: {
    reflectAuthorityMasetrPrimeValue: (state, action: PayloadAction<Model.changeValueTransfer>) => {
      // 値をdatalist反映 reflectCheckBoxValue
      // state.dataList のaction.payload.targetDataRow.mcdpIdを持つレコードを特定する。
      state.dataList.forEach((stateRow: Model.AuthorityRow) => {
        if (stateRow.mcdpId === action.payload.targetDataRow.mcdpId) {

          action.payload.changeKeyValueArray.forEach((target: Model.keyValue) => {
            if (target.propertyName in stateRow) {
              stateRow[target.propertyName] = target.value;
            }
          })

          return;
        }
      })
    },

    transferDataRow: (state, action: PayloadAction<Model.AuthorityRow>) => {
      state.dataRow = action.payload
    },

    setCriteria: (state, action: PayloadAction<Model.AuthorityCriteria>) => {
      let authorityCriteria: Model.AuthorityCriteria = {
        mcdpId: action.payload.mcdpId,
        lastName: action.payload.lastName,
        firstName: action.payload.firstName,
        lastNameKana: action.payload.lastNameKana,
        firstNameKana: action.payload.firstNameKana,
        authKbn: action.payload.authKbn,
        limit: action.payload.limit,
        offset: action.payload.offset,
        nextPageToken: action.payload.nextPageToken,
      }
      return Object.assign({}, state, { authorityCriteria: authorityCriteria })
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    getDataListOfAuthorityMaster: (state, action: PayloadAction<Model.AuthorityApiData>) => {
      state.dataList = initialState.dataList;
      state.total = action.payload.total;

      let authorityInfoList: Array<Model.AuthorityRow> = [];
      action.payload.authorityInfoList.forEach((authorityRowRecord: Model.AuthorityRow) => {

        const rec: Model.AuthorityRow = {
          key: authorityRowRecord.mcdpId,
          mcdpId: authorityRowRecord.mcdpId,
          userName: authorityRowRecord.userName,
          partnerAvailableAuth: authorityRowRecord.partnerAvailableAuth,
          partnerAttendanceSubmitAuth: authorityRowRecord.partnerAttendanceSubmitAuth,
          partnerSystemMngAuth: authorityRowRecord.partnerSystemMngAuth,
        };
        authorityInfoList.push(rec);
      });
      state.dataList = authorityInfoList;

      state.nextPageTokenList[state.authorityCriteria.offset / state.authorityCriteria.limit + 2] = action.payload.nextPageToken;
    },

    /**
     * 検索条件の初期化
     */
    initCriteria: (state) => {
      let authorityCriteria = {
        mcdpId: '',
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        authKbn: '',
        limit: 10,
        offset: 0,
        nextPageToken: ''
      }

      return Object.assign({}, state, { authorityCriteria: authorityCriteria });
    },

    setLoadingFlg: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { loadingFlg: action.payload })
    },
  },
});

export const {
  getDataListOfAuthorityMaster,
  reflectAuthorityMasetrPrimeValue,
  transferDataRow,
  setCriteria,
  initCriteria,
  setLoadingFlg
} = authoritySlice.actions;

export const getDataListAsync = (authorityCriteriaInfo: Model.AuthorityCriteria): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_CMN_PARTNER_AUTH, { params: authorityCriteriaInfo });
    // 結果をstateに設定します。
    dispatch(getDataListOfAuthorityMaster(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};


export const putDataLsitAsync = (dataList: Array<Model.AuthorityRow>): AppThunk => async dispatch => {

  let putData: Model.putData = {
    authorityInfoList: []
  };

  dataList.forEach((data: Model.AuthorityRow) => {

    let authorityInfoListRow: Model.AuthorityInfoListRow;

    // 利用者権限がない場合は、設定に関わらずその他権限もない状態で更新する
    if (data.partnerAvailableAuth === "0") {
      authorityInfoListRow = {
        // MCDP-ID
        mcdpId: data.mcdpId
        //利用者
        , partnerAvailableAuth: data.partnerAvailableAuth
        //勤怠承認者
        , partnerAttendanceSubmitAuth: "0"
        //システム管理者
        , partnerSystemMngAuth: "0"
      }
    } else {
      authorityInfoListRow = {
        // MCDP-ID
        mcdpId: data.mcdpId
        //利用者
        , partnerAvailableAuth: data.partnerAvailableAuth
        //勤怠承認者
        , partnerAttendanceSubmitAuth: data.partnerAttendanceSubmitAuth
        //システム管理者
        , partnerSystemMngAuth: data.partnerSystemMngAuth
      }
    }

    putData.authorityInfoList.push(authorityInfoListRow);
  })

  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_CMN_PARTNER_AUTH, putData);
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_PUT));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.partnerAuthority.dataList;
export const storedDataRow = (state: RootState) => state.partnerAuthority.dataRow;
export const storedTotal = (state: RootState) => state.partnerAuthority.total;
export const storedCriteriaInfo = (state: RootState) => state.partnerAuthority.authorityCriteria;
export const storedNextPageToken = (state: RootState) => state.partnerAuthority.nextPageTokenList;
export const storedLoadingFlg = (state: RootState) => state.partnerAuthority.loadingFlg;

export default authoritySlice.reducer;
