import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router'
import { Menu as AntdMenu } from 'antd';
import styles from '../css/Menu.module.css';
import { generatePrimeMenu, generatePartnerMenu } from '../helpers/menuGenerator'
import { storedLoginUserInfo } from '../../../modules/core/authModule';
import * as Module from '../../../modules/core/menuModule';
import { initMessages } from '../../../modules/core/commonMessageModule';
import { initCriteria as initCriteriaQualification } from '../../../modules/prime/qualification/qualificationModule'
import { initCriteria as initCriteriaQualificationHolders } from '../../../modules/prime/qualifications-holders/qualificationHoldersModule'
import { initCriteria as initCriteriaAttendancePrime } from '../../../modules/prime/attendance/attendanceModule'
import { initCriteria as initCriteriaAttendancePartner } from '../../../modules/partner/attendance/attendanceModule'
import { initCriteria as initCriteriaPartnerCompanies } from '../../../modules/prime/partner-companies/partner-companiesModule'
import { initCriteria as initCriteriaAuthority } from '../../../modules/prime/auth/authorityModule'
import { initCriteria as initCriteriaPartnerAuthority } from '../../../modules/partner/auth/partnerAuthorityModule'
import { initCriteria as initCriteriaWorkerAttendancePrime } from '../../../modules/prime/workerAttendance/workerAttendanceModule'
import * as CONSTANTS from '../../../constants/constants';

export const Menu = () => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const loginUserInfo = useSelector(storedLoginUserInfo);
  const primeMenu = useSelector(Module.storedPrimeMenu);
  const partnerMenu = useSelector(Module.storedPartnerMenu);
  const currentMenuItemId = useSelector(Module.storedCurrentMenuItemId);
  const isPrime = useSelector(Module.isPrimeMenu);

  let currentMenu = partnerMenu;
  if (isPrime) {
    currentMenu = primeMenu;
  }

  useEffect(() => {
    // 元請メニューを生成してstateに設定します。
    dispatch(Module.setPrimeMenu(generatePrimeMenu(loginUserInfo)));
    // 協力会社メニューを生成してstateに設定します。
    dispatch(Module.setPartnerMenu(generatePartnerMenu(loginUserInfo)));
  }, []);

  // メニューアイテムクリック時、所定のURLのコンポーネントを描画します。
  const menuItemOnClick = (key: string) => {
    currentMenu.forEach((item: Module.IMenuItem) => {
      if (item.id === key) {
        // criteriaを初期化します。
        dispatch(initCriteriaQualification());
        dispatch(initCriteriaQualificationHolders());
        dispatch(initCriteriaAttendancePartner());
        dispatch(initCriteriaPartnerCompanies());
        dispatch(initCriteriaAttendancePrime());
        dispatch(initCriteriaAuthority());
        dispatch(initCriteriaPartnerAuthority());
        dispatch(initCriteriaWorkerAttendancePrime());

        // 表示中のページと選択したメニューのトップページが同じURLの場合、以降の処理を行いません。
        if (location.pathname === item.url) {
          return;
        }

        // カレントメニューIDを更新します。
        dispatch(Module.setCurrnetMenuItemId(item.id));

        // 共通メッセージ情報を初期化します。
        dispatch(initMessages());

        history.push(item.url);
      }
    })
  }

  // ログアウト処理
  async function logout() {
    try {
      // ログアウト処理を呼び出す
      await Module.logout();
      // ログアウト処理が正常終了したら利用終了画面へ遷移
      history.push(CONSTANTS.PATH_USE_END);
    } catch (error) {
      // ログアウト処理が失敗したらシステムエラー画面へ遷移
      history.push(CONSTANTS.PATH_SYSTEMERROR);
    }
  }

  return (
    <AntdMenu className={isPrime ? styles.HeaderMenu : styles.PartnerHeaderMenu} onClick={(event) => menuItemOnClick(event.key)} selectedKeys={[currentMenuItemId]} mode="horizontal">

      {currentMenu.map((menuItem: Module.IMenuItem) => (
        <AntdMenu.Item className={styles.HeaderMenuItem} key={menuItem.id} id={menuItem.id}>
          {menuItem.title}
        </AntdMenu.Item>
      ))}

      <AntdMenu.Item className={styles.HeaderMenuItem + ' ' + styles.HeaderMenuRightStartItem}>
        <a href='https://manual.kensetsu-site.com/skillmap/' target="_blank" rel="noopener noreferrer" className={styles.manualDownloadPageLink} >使い方</a>
      </AntdMenu.Item>
      <AntdMenu.Item className={styles.HeaderMenuItem} onClick={logout}>
        終了（閉じる）
      </AntdMenu.Item>
    </AntdMenu >
  )
}