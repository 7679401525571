import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { AppThunk, RootState } from "../../store/store";
import * as CONSTANTS from "../../constants/constants";
import { saveAs } from "file-saver";

// 設定ファイルの読み込み
require("dotenv").config();
export const a = process.env.BFF_SERVICE_ENDPOINT;
const host = window.location.host;
// フロント側のドメインに合わせたBFFのURLを特定
let baseURL;
if (host.match("localhost")) {
  baseURL = CONSTANTS.BFF_SERVICE_ENDPOINT_FOR_LOCAL;
} else if (host === 't2.skillmap-site.kensetsu-site.com') {
  baseURL = CONSTANTS.BFF_SERVICE_ENDPOINT_FOR_T2;
} else if (host === 's2.skillmap-site.kensetsu-site.com') {
  baseURL = CONSTANTS.BFF_SERVICE_ENDPOINT_FOR_S2;
} else if (host === 'skillmap-site.kensetsu-site.com') {
  baseURL = CONSTANTS.BFF_SERVICE_ENDPOINT_FOR_P2;
}
export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
});

// リクエスト送信前インターセプター
axiosInstance.interceptors.request.use((request) => {
  // csrfトークンがセッションストレージに保存されている場合、リクエストヘッダに設定します。
  const csrfToken = sessionStorage.getItem('csrfToken');
  if (csrfToken !== null) {
    request.headers['csrf-token'] = csrfToken;
  }

  return request;
});

// BFF-APIのGETリクエストを送信します。
export const getRequest = (url: string, config?: AxiosRequestConfig): any => {
  try {
    if (typeof config !== "undefined") {
      config.withCredentials = true;
      let params = Object.assign({}, config.params);
      params["_time"] = new Date().getTime();
      config.params = params;
    } else {
      config = { withCredentials: true }
      config.params = { _time: new Date().getTime() };
    }

    const response = axiosInstance.get(url, config);

    // レスポンス取得時共通処理
    response.then((res) => {
      apiResponseHandler(res);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

// BFF-APIのPOSTリクエストを送信します。
export const postRequest = (url: string, data?: any, config?: AxiosRequestConfig): any => {

  try {

    if (typeof config !== "undefined") {
      config.withCredentials = true;
    } else {
      config = { withCredentials: true }
    }

    const response = axiosInstance.post(url, data, config);

    // レスポンス取得時共通処理
    response.then((res) => {
      apiResponseHandler(res);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

// BFF-APIのPUTリクエストを送信します。
export const putRequest = (url: string, data?: any, config?: AxiosRequestConfig): any => {

  try {

    if (typeof config !== "undefined") {
      config.withCredentials = true;
    } else {
      config = { withCredentials: true }
    }

    const response = axiosInstance.put(url, data, config);

    // レスポンス取得時共通処理
    response.then((res) => {
      apiResponseHandler(res);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

// BFF-APIのDELETEリクエストを送信します。
export const deleteRequest = (url: string, config?: AxiosRequestConfig): any => {

  try {

    if (typeof config !== "undefined") {
      config.withCredentials = true;
    } else {
      config = { withCredentials: true }
    }

    const response = axiosInstance.delete(url, config);

    // レスポンス取得時共通処理
    response.then((res) => {
      apiResponseHandler(res);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

// BFF-APIのGETリクエストを送信し、ファイルをダウンロードします。
export const download = (url: string, param: any, config: AxiosRequestConfig): any => {
  try {

    let data = { _time: new Date().getTime() };
    if (typeof param !== "undefined") {
      data = Object.assign({}, param);
      data["_time"] = new Date().getTime();
    }

    if (typeof config !== "undefined") {
      config.withCredentials = true;
    } else {
      config = { withCredentials: true }
    }

    const response = axiosInstance.post(url, data, config);

    // レスポンス取得時共通処理
    response.then((response) => {
      // レスポンスヘッダからファイル名を取得します
      const fileName = getDownloadFileNameFromResHeader(response.headers);
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      if (fileName === 'untitled') {
        return response;
      }
      //ダウンロードします
      saveAs(blob, fileName);
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * レスポンスヘッダからダウンロードファイル名を取得する
 * @param headers
 * @return ダウンロードファイル名
 */
export const getDownloadFileNameFromResHeader = (headers: any): string => {
  let cd = 'untitled';
  if (headers['content-disposition']) {
    cd = headers['content-disposition'] as string;
  } else if (headers['Content-Disposition']) {
    cd = headers['Content-Disposition'] as string;
  }
  cd = cd.replace('attachment;', '').trim().replace('"', '');
  const names = cd.split(';');

  if (names.length === 1) {
    if (names[0].indexOf('filename*') >= 0) {
      return decodeURI(names[0].replace(/filename\*=UTF-8''/, '').trim());
    } else {
      return names[0].replace('filename=', '').replace('"', '').replace('"', '');
    }
  } else {
    // filename*が併記されている場合
    for (const name of names) {
      if (name.indexOf('filename*') >= 0) {
        return decodeURI(name.replace(/filename\*=UTF-8''/, '').trim());
      }
    }
  }
  return 'download';
}

// APIレスポンス取得時の共通処理を行います。
export const apiResponseHandler = (response: AxiosResponse<any>) => {
  if (response.status === CONSTANTS.API_STATUS_200) {
    // ステータス200の場合、csrfトークンをセッションストレージに保存します。
    if (response.headers["csrf-token"]) {
      sessionStorage.csrfToken = response.headers["csrf-token"];
    }
  }
};

// API成功時の共通処理を行います。
export const apiSuccessHandler = (response: AxiosResponse<IResponseBody> | any, method: string): AppThunk => (dispatch) => {
  let errorCode = response.data.cmn.errorCode;

  if (typeof response.data.data.total !== "undefined" && response.data.data.total === 0) {
    errorCode = "EA9999000";
  }
  dispatch(setApiResult({ status: response.status, errorCode: errorCode, requestMethod: method, url: response.config.url }));
};

// APIエラー時の共通処理を行います。
export const apiErrorHandler = (response: AxiosResponse<IResponseBody> | any, method: string): AppThunk => (dispatch) => {
  try {
    dispatch(setApiResult({ status: response.status, errorCode: response.data.cmn.errorCode, requestMethod: method, url: response.config.url }));
  } catch (error) {
    dispatch(setApiResult({ status: CONSTANTS.API_STATUS_500, errorCode: "", requestMethod: method, url: "" }));
  }
};

interface baseInterface {
  [propertyName: string]: any;
}

// BFF-APIのレスポンスボディ定義
export interface IResponseBody extends baseInterface {
  cmn: {
    errorCode: string;
  };
  data: any;
}

// state.cmn定義
interface bffApiResponseCmn {
  status: number;
  errorCode: string;
  requestMethod: string;
  url: string;
}

// state定義
interface bffApiState {
  cmn: bffApiResponseCmn;
}

// state初期化
const initialState: bffApiState = {
  cmn: {
    status: CONSTANTS.API_STATUS_INITIAL,
    errorCode: "",
    requestMethod: "",
    url: "",
  },
};

// スライサー （state更新アクションの実装）
export const bffApiSlice = createSlice({
  name: "bffApi",
  initialState,
  reducers: {
    initApiResult: (state) => {
      state.cmn.status = CONSTANTS.API_STATUS_INITIAL;
      state.cmn.errorCode = "";
      state.cmn.requestMethod = "";
      state.cmn.url = "";
    },
    setApiResult: (state, action: PayloadAction<bffApiResponseCmn>) => {
      state.cmn.status = action.payload.status;
      state.cmn.errorCode = action.payload.errorCode;
      state.cmn.requestMethod = action.payload.requestMethod;
      state.cmn.url = action.payload.url;
    },
  },
});

// state更新アクションを公開
export const { initApiResult, setApiResult } = bffApiSlice.actions;

// state参照
export const storedApiResult = (state: RootState) => state.bffApi.cmn;

export default bffApiSlice.reducer;
