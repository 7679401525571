import { EyeInvisibleFilled, EyeFilled, BoldOutlined } from '@ant-design/icons';
import { Form, Input, Layout, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as CONSTANTS from '../../../constants/constants';
import { setApiResult, storedApiResult, initApiResult } from '../../../modules/core/bffApiModule';
import { message, setMessages, initMessages } from '../../../modules/core/commonMessageModule';
import * as Model from '../../../modules/partner/report/reportModel';
import * as Module from '../../../modules/partner/report/reportModule';
import * as formatUtils from '../../../utils/formatUtils';
import * as messagesUtils from '../../../utils/messagesUtils';
import * as validationRules from '../../../validation/coreRules';
import { AuthButton, MonthPicker, Radio, Checkbox } from '../../core/CoreForm';
import { CommonMessage, ContentEx as Content, Footer, FooterButtonArea, FunctionTitle, Header } from '../../core/CorePageContents';
import AuthButtonStyles from '../../core/css/AuthButton.module.css';
import styles from './ReportDownload.module.css';


interface titleInterface {
  title: string;
}

export const ReportDownload = (props: titleInterface) => {
  //　hoocs
  const dispatch = useDispatch();
  const belongCompanyList_detail = useSelector(Module.storedBelongCompanyList_detail);
  const filterdBelongCompanyList_detail = belongCompanyList_detail.filter(belongCompany => belongCompany.belongCompanyId !== "");
  const primeCompanyRow_detail = useSelector(Module.storedPrimeCompanyRow_detail);
  const primeCompanyList_detail = useSelector(Module.storedPrimeCompanyList_detail);
  const filterdPrimeCompanyList_detail = primeCompanyList_detail.filter(primeCompany => primeCompany.primeCompanyId !== "");
  const belongCompanyRow_detail = useSelector(Module.storedBelongCompanyRow_detail);
  const belongCompanyList_summary = useSelector(Module.storedBelongCompanyList_summary);
  const filterdBelongCompanyList_summary = belongCompanyList_summary.filter(belongCompany => belongCompany.belongCompanyId !== "");
  const primeCompanyRow_summary = useSelector(Module.storedPrimeCompanyRow_summary);
  const primeCompanyList_summary = useSelector(Module.storedPrimeCompanyList_summary);
  const filterdPrimeCompanyList_summary = primeCompanyList_summary.filter(primeCompany => primeCompany.primeCompanyId !== "");
  const belongCompanyRow_summary = useSelector(Module.storedBelongCompanyRow_summary);
  const branchRow_detail = useSelector(Module.storedBranchRow_detail);
  const branchList_detail = useSelector(Module.storedBranchList_detail);
  const filterdBranchList_detail = branchList_detail.filter(branch => branch.branchId !== "");
  const branchRow_summary = useSelector(Module.storedBranchRow_summary);
  const branchList_summary = useSelector(Module.storedBranchList_summary);
  const filterdBranchList_summary = branchList_summary.filter(branch => branch.branchId !== "");
  const outputApprovedOnlyFlag_detail = useSelector(Module.storedOutputApprovedOnlyFlag_detail);
  const outputApprovedOnlyFlag_summary = useSelector(Module.storedOutputApprovedOnlyFlag_summary);
  const selectValue = useSelector(Module.storedSelectRadio);
  const disabled = useSelector(Module.storedDisabled);
  const apiResult = useSelector(storedApiResult);
  const [form] = Form.useForm();
  let loadingFlg = useSelector(Module.storedLoadingFlg);

  useEffect(() => {
    // 帳票検索条件取得
    let param: Model.getSearchConditions.Request = {
      belongCompanyId: ""
      , primeCompanyId: ""
    }
    dispatch(Module.getSearchConditionsAsync_allReport(param));
    dispatch(Module.setDisabled(false));
    dispatch(Module.setLoadingFlg(false));
    dispatch(Module.selectRadio(CONSTANTS.PRIME_REPORT_PAYMENT));
    dispatch(Module.setPrimeCompanyRow_detail(""));
    dispatch(Module.setBelongCompanyRow_detail(""));
    dispatch(Module.setBranchRow_detail(""));
    dispatch(Module.setOutputApprovedOnlyFlag_detail(true));
    dispatch(Module.setPrimeCompanyRow_summary(""));
    dispatch(Module.setBelongCompanyRow_summary(""));
    dispatch(Module.setBranchRow_summary(""));
    dispatch(Module.setOutputApprovedOnlyFlag_summary(true));
  },
    []
  );

  // 検索条件取得API実行後、選択肢変更
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_ATTENDANCE_PARTNER_SEARCH_CONDITIONS === apiResult.url) {
    form.setFieldsValue({ belongCompany_detail: belongCompanyRow_detail });
    form.setFieldsValue({ primeCompany_detail: primeCompanyRow_detail });
    form.setFieldsValue({ branch_detail: branchRow_detail });
    form.setFieldsValue({ outputApprovedOnlyFlag_detail: outputApprovedOnlyFlag_detail });
    form.setFieldsValue({ belongCompany_summary: belongCompanyRow_summary });
    form.setFieldsValue({ primeCompany_summary: primeCompanyRow_summary });
    form.setFieldsValue({ branch_summary: branchRow_summary });
    form.setFieldsValue({ outputApprovedOnlyFlag_summary: outputApprovedOnlyFlag_summary });
  }

  // 帳票出力API実行後、ダウンロードが完了したらボタンを活性化する。
  if (disabled && CONSTANTS.API_STATUS_INITIAL !== apiResult.status && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && (CONSTANTS.URL_REPORT_PARTNER_ATTENDANCE === apiResult.url || CONSTANTS.URL_REPORT_PARTNER_ATTENDANCE_SUMMARY === apiResult.url)) {
    if ("EA8004001" === apiResult.errorCode) {
      const setMessage: message = {
        message: messagesUtils.getMessage("EA8004001")
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setMessages([setMessage]));
    }
    dispatch(Module.setDisabled(false));
    dispatch(Module.setLoadingFlg(false));
    // ステータスコード以外を初期化します。
    dispatch(setApiResult({ status: apiResult.status, errorCode: "", requestMethod: "", url: "" }));
  }

  const onSubmit = (values: any) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    dispatch(initApiResult());
    if (selectValue === CONSTANTS.PARTNER_REPORT_ATTENDANCE_DETAIL) {
      let criteria: Model.downloadAttendanceRecord.Request = {
        belongCompanyId: values.belongCompany_detail
        , primeCompanyId: values.primeCompany_detail
        , branchId: values.branch_detail
        , attendanceYearMonth: formatUtils.formatMonthNonSlash(values.attendanceYearMonth_detail)
        , outputApprovedOnlyFlag: outputApprovedOnlyFlag_detail ? CONSTANTS.OUTPUT_REPORT_APPROVED_ONLY_FLAG.OUTPUT_ONLY_APPROVED_ATTENDANCE : CONSTANTS.OUTPUT_REPORT_APPROVED_ONLY_FLAG.OUTPUT_ALL_ATTENDANCE
        , password: values.password
      }
      dispatch(Module.downloadAttendanceRecord(criteria));
    } else {
      let criteria: Model.downloadAttendanceSummaryRecord.Request = {
        belongCompanyId: values.belongCompany_summary
        , primeCompanyId: values.primeCompany_summary
        , branchId: values.branch_summary
        , attendanceYearMonth: formatUtils.formatMonthNonSlash(values.attendanceYearMonth_summary)
        , outputApprovedOnlyFlag: outputApprovedOnlyFlag_summary ? CONSTANTS.OUTPUT_REPORT_APPROVED_ONLY_FLAG.OUTPUT_ONLY_APPROVED_ATTENDANCE : CONSTANTS.OUTPUT_REPORT_APPROVED_ONLY_FLAG.OUTPUT_ALL_ATTENDANCE
        , password: values.password
      }
      dispatch(Module.downloadAttendanceSummaryRecord(criteria));
    }
    dispatch(Module.setDisabled(true));
    dispatch(Module.setLoadingFlg(true));
  }

  // 帳票選択ラジオボタン切り替え
  const onChange = (e: any) => {
    dispatch(Module.selectRadio(e.target.value));
  }

  const disabledDetailReport = () => {
    if (selectValue !== CONSTANTS.PARTNER_REPORT_ATTENDANCE_DETAIL) {
      return true;
    }
    return false;
  }

  const disabledSummaryReport = () => {
    if (selectValue !== CONSTANTS.PARTNER_REPORT_ATTENDANCE_SUMMARY) {
      return true;
    }
    return false;
  }

  const changeBelongCompany_detail = (value: any) => {
    dispatch(Module.setBelongCompanyRow_detail(value));
    // 帳票検索条件取得
    let param: Model.getSearchConditions.Request = {
      belongCompanyId: value
      , primeCompanyId: primeCompanyRow_detail
    }
    dispatch(Module.getSearchConditionsAsync_detail(param));
  }

  const changePrimeCompany_detail = (value: any) => {
    dispatch(Module.setPrimeCompanyRow_detail(value));
    // 帳票検索条件取得
    let param: Model.getSearchConditions.Request = {
      belongCompanyId: belongCompanyRow_detail
      , primeCompanyId: value
    }
    dispatch(Module.getSearchConditionsAsync_detail(param));
  }

  const changeBranch_detail = (value: any) => {
    dispatch(Module.setBranchRow_detail(value));
  }

  const changeOutputApprovedOnlyFlag_detail = (value: any) => {
    dispatch(Module.setOutputApprovedOnlyFlag_detail(value.target.checked));
  }

  const changeBelongCompany_summary = (value: any) => {
    dispatch(Module.setBelongCompanyRow_summary(value));
    // 帳票検索条件取得
    let param: Model.getSearchConditions.Request = {
      belongCompanyId: value
      , primeCompanyId: primeCompanyRow_summary
    }
    dispatch(Module.getSearchConditionsAsync_summary(param));
  }

  const changePrimeCompany_summary = (value: any) => {
    dispatch(Module.setPrimeCompanyRow_summary(value));
    // 帳票検索条件取得
    let param: Model.getSearchConditions.Request = {
      belongCompanyId: belongCompanyRow_summary
      , primeCompanyId: value
    }
    dispatch(Module.getSearchConditionsAsync_summary(param));
  }

  const changeBranch_summary = (value: any) => {
    dispatch(Module.setBranchRow_summary(value));
  }

  const changeOutputApprovedOnlyFlag_summary = (value: any) => {
    dispatch(Module.setOutputApprovedOnlyFlag_summary(value.target.checked));
  }

  const PasswordContent = () => {
    return (
      <div className={styles.passwordArea}>
        <Form.Item
          name="password"
          id="password"
          label={"zipファイルの暗号化パスワード(1文字以上の任意の文字列)"}
          rules={[
            {
              required: true,
              message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["パスワード"]),
            },
            {
              pattern: validationRules.alphanumeric,
              message: messagesUtils.getMessage("ERROR_ALPHANUMERIC", ["パスワード"]),
            },
            {
              max: CONSTANTS.PASSWORD_MAX_LENGTH,
              message: messagesUtils.getMessage("ERROR_MAX_LENGTH", ["パスワード", CONSTANTS.PASSWORD_MAX_LENGTH.toString()]),
            }
          ]}
        >
          <Input.Password
            placeholder={"パスワード"}
            maxLength={CONSTANTS.PASSWORD_MAX_LENGTH}
            iconRender={(visible: any) => (visible ? <EyeInvisibleFilled /> : <EyeFilled />)}
          />
        </Form.Item>
      </div>
    );
  }

  const ReportDownloadContent = () => {
    return (
      <>
        <div className={styles.amountNotion}>
          <span>出力する帳票を選択してください。帳票は暗号化されたzipファイルに圧縮されて出力されます。</span><br/>
          <span>元請会社が未承認の勤怠実績が含まれている場合、帳票上に出力される手当合計金額は実際の金額と異なる可能性がありますので、ご注意ください。</span>
        </div>
        <div className={styles.content}>
          <div className={styles.radioArea}>
            <Radio value={CONSTANTS.PARTNER_REPORT_ATTENDANCE_DETAIL} onChange={onChange} checked={selectValue === CONSTANTS.PARTNER_REPORT_ATTENDANCE_DETAIL ? true : false}>
              <span className={styles.subTitle}>勤怠実績帳票</span>
            </Radio>
          </div>
          <div className={styles.selectedArea} style={{ display: `${disabledDetailReport() ? "none" : ""}` }}>
            <div className={styles.commentArea}>
              <span>勤怠実績の帳票の出力条件を選択してください(各項目は入力必須です)</span>
            </div>
            <div className={styles.criteriaArea}>
              <Form.Item
                label={"所属会社"}
                name="belongCompany_detail"
                id="belongCompany_detail"
                rules={[!disabledDetailReport() ?
                  {
                    required: true,
                    message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["所属会社"])
                  } : {}
                ]}
              >
                <Select className={styles.belongCompany} onChange={changeBelongCompany_detail} disabled={disabledDetailReport()}>
                  <Select.Option value={""}>-</Select.Option>
                  {filterdBelongCompanyList_detail.map((rec: Model.belongCompany) => (
                    <Select.Option key={rec.belongCompanyId} value={rec.belongCompanyId}>{rec.belongCompanyName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={"元請会社"}
                name="primeCompany_detail"
                id="primeCompany_detail"
                rules={[!disabledDetailReport() ?
                  {
                    required: true,
                    message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["元請会社"])
                  } : {}
                ]}
              >
                <Select className={styles.primeCompany} onChange={changePrimeCompany_detail} disabled={disabledDetailReport()}>
                  <Select.Option value={""}>-</Select.Option>
                  {filterdPrimeCompanyList_detail.map((rec: Model.primeCompany) => (
                    <Select.Option key={rec.primeCompanyId} value={rec.primeCompanyId}>{rec.primeCompanyName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={"提出先支店"}
                name="branch_detail"
                id="branch_detail"
                rules={[!disabledDetailReport() ?
                  {
                    required: true,
                    message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["提出先支店"])
                  } : {}
                ]}
              >
                <Select className={styles.branch} onChange={changeBranch_detail} disabled={disabledDetailReport()}>
                  <Select.Option value={""}>-</Select.Option>
                  <Select.Option value={"ALL"}>(すべて)</Select.Option>
                  {filterdBranchList_detail.map((rec: Model.branch) => (
                    <Select.Option key={rec.branchId} value={rec.branchId}>{rec.branchName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="attendanceYearMonth_detail"
                id="attendanceYearMonth_detail"
                label={"勤怠年月"}
                rules={[!disabledDetailReport() ?
                  {
                    required: true,
                    message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["勤怠年月"])
                  } : {
                    required: false
                  }
                ]}
              >
                <MonthPicker disabled={disabledDetailReport()} />
              </Form.Item>
              <Form.Item
                name="outputApprovedOnlyFlag_detail"
                id="outputApprovedOnlyFlag_detail"
                label={"元請会社の承認済のみ出力する"}
              >
                <Checkbox
                  className={styles.checkBox}
                  checked={outputApprovedOnlyFlag_detail}
                  onChange={changeOutputApprovedOnlyFlag_detail}
                  style={{ display: "inline" }}
                  disabled={disabledDetailReport()}
                ></Checkbox>
              </Form.Item>
            </div>
            <PasswordContent />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.radioArea}>
            <Radio value={CONSTANTS.PARTNER_REPORT_ATTENDANCE_SUMMARY} onChange={onChange} checked={selectValue === CONSTANTS.PARTNER_REPORT_ATTENDANCE_SUMMARY ? true : false}>
              <span className={styles.subTitle}>勤怠実績サマリ帳票</span>
            </Radio>
          </div>
          <div className={styles.selectedArea} style={{ display: `${disabledSummaryReport() ? "none" : ""}` }}>
            <div className={styles.commentArea}>
              <span>勤怠実績サマリ帳票の出力条件を選択してください(各項目は入力必須です)</span>
            </div>
            <div className={styles.criteriaArea}>
              <Form.Item
                label={"所属会社"}
                name="belongCompany_summary"
                id="belongCompany_summary"
                rules={[!disabledSummaryReport() ?
                  {
                    required: true,
                    message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["所属会社"])
                  } : {}
                ]}
              >
                <Select className={styles.belongCompany} onChange={changeBelongCompany_summary} disabled={disabledSummaryReport()}>
                  <Select.Option value={""}>-</Select.Option>
                  {filterdBelongCompanyList_summary.map((rec: Model.belongCompany) => (
                    <Select.Option key={rec.belongCompanyId} value={rec.belongCompanyId}>{rec.belongCompanyName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={"元請会社"}
                name="primeCompany_summary"
                id="primeCompany_summary"
                rules={[!disabledSummaryReport() ?
                  {
                    required: true,
                    message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["元請会社"])
                  } : {}
                ]}
              >
                <Select className={styles.primeCompany} onChange={changePrimeCompany_summary} disabled={disabledSummaryReport()}>
                  <Select.Option value={""}>-</Select.Option>
                  {filterdPrimeCompanyList_summary.map((rec: Model.primeCompany) => (
                    <Select.Option key={rec.primeCompanyId} value={rec.primeCompanyId}>{rec.primeCompanyName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={"提出先支店"}
                name="branch_summary"
                id="branch_summary"
                rules={[!disabledSummaryReport() ?
                  {
                    required: true,
                    message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["提出先支店"])
                  } : {}
                ]}
              >
                <Select className={styles.branch} onChange={changeBranch_summary} disabled={disabledSummaryReport()}>
                  <Select.Option value={""}>-</Select.Option>
                  <Select.Option value={"ALL"}>(すべて)</Select.Option>
                  {filterdBranchList_summary.map((rec: Model.branch) => (
                    <Select.Option key={rec.branchId} value={rec.branchId}>{rec.branchName}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="attendanceYearMonth_summary"
                id="attendanceYearMonth_summary"
                label={"勤怠年月"}
                rules={[!disabledSummaryReport() ?
                  {
                    required: true,
                    message: messagesUtils.getMessage("ERROR_REQUIRED_INPUT", ["勤怠年月"])
                  } : {
                    required: false
                  }
                ]}
              >
                <MonthPicker disabled={disabledSummaryReport()} />
              </Form.Item>
              <Form.Item
                name="outputApprovedOnlyFlag_summary"
                id="outputApprovedOnlyFlag_summary"
                label={"元請会社の承認済のみ出力する"}
              >
                <Checkbox
                  className={styles.checkBox}
                  checked={outputApprovedOnlyFlag_summary}
                  onChange={changeOutputApprovedOnlyFlag_summary}
                  style={{ display: "inline" }}
                  disabled={disabledSummaryReport()}
                ></Checkbox>
              </Form.Item>
            </div>
            <PasswordContent />
          </div>
        </div>

        <div className={styles.buttonFixed}>
          <AuthButton
            name={"帳票出力"}
            size={"large"}
            shape={"round"}
            className={AuthButtonStyles.partnerAuthButtonFixed}
            htmlType="submit"
            disabled={disabled}
          />
          <div style={{ "color": "rgba(0,0,0,0.85)" }}>
            <div>※しばらく時間がかかる場合があります。</div>
          </div>
        </div>
      </>
    );
  }


  return (
    <Layout>
      <Header />
      <Content style={{ marginBottom: "70px" }} hasapicall={loadingFlg ? "true" : "false"}>
        <FunctionTitle title={props.title} />
        <CommonMessage />
        <Form
          form={form}
          method="POST"
          layout="inline"
          onFinish={onSubmit}
          className={styles.form}
          initialValues={{
            'belongCompany_detail': ""
            , 'primeCompany_detail': ""
            , 'branch_detail': ""
            , 'attendanceYearMonth_detail': ""
            , 'outputApprovedOnlyFlag_detail': true
            , 'belongCompany_summary': ""
            , 'primeCompany_summary': ""
            , 'branch_summary': ""
            , 'attendanceYearMonth_summary': ""
            , 'outputApprovedOnlyFlag_summary': true
          }}
        >
          <ReportDownloadContent />
        </Form>
      </Content >
      <FooterButtonArea />
      <Footer />
    </Layout >
  );
}