import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { Layout, Typography, Input, Table } from 'antd';
import { CriteriaForm, AuthButton, AuthButtonAuthorities } from '../../core/CoreForm';
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from '../../core/CorePageContents';
import styles from './QualificationList.module.css';
import * as Module from '../../../modules/prime/qualification/qualificationModule';
import * as Model from '../../../modules/prime/qualification/qualificationModel';
import { initMessages } from '../../../modules/core/commonMessageModule';
import * as CONSTANTS from '../../../constants/constants';
import * as constantsUtils from '../../../utils/constantsUtils';
import * as formatUtils from '../../../utils/formatUtils';
import * as Utils from '../../../utils/utils';
import { SortOrder } from 'antd/lib/table/interface';
import { initApiResult, storedApiResult } from '../../../modules/core/bffApiModule';

// const { Title } = Typography;

interface titleInterface {
  title: string;
}

export const QualificationList = (props: titleInterface) => {
  //　hoocs
  const dispatch = useDispatch();
  const history = useHistory();
  // ステート取得
  const apiResult = useSelector(storedApiResult);
  let dataList = useSelector(Module.storedDataList);
  let dataCriteria = useSelector(Module.storedCriteria);
  const totalCnt = useSelector(Module.storedTotal);

  useEffect(() => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    dispatch(Module.getDataListAsync(dataCriteria));
  },
    [dataCriteria]
  );

  /**
   * 画面アクション
   * 
   */
  //　行選択時アクション
  const moveToDetail = (record: Model.dataRow) => {
    // 行選択情報をステートに設定します。
    dispatch(Module.prepareMoveToDetail(record));
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // 詳細画面へ遷移します。
    history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_DETAIL);
  }

  //　新規登録アクション
  const addClicked = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // 独自資格一覧行定義のstateを初期化します。
    dispatch(Module.initDataRow());
    // 登録画面へ遷移します。
    history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_ADD);
  }

  // 検索ボタン押下時イベント
  const searchDataList = (value: Store) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    criteriaDataTransfer([
      { key: "originalQualificationNo", value: value.originalQualificationNo }
      , { key: "originalQualificationName", value: value.originalQualificationName }
      , { key: "offset", value: 0 }
    ]);
  }

  // テーブル検索条件変更時イベント（ページ、表示件数、ソート）
  const handleTableChange = (pagination: any, sort: any) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    let order = "";
    if (sort.order === "ascend") {
      order = "0";
    } else if (sort.order === "descend") {
      order = "1";
    }
    let sortItem = sort.columnKey
    if (typeof sort.column === "undefined") {
      sortItem = "";
    }

    let offsetReset: boolean = false;

    if (dataCriteria.limit !== pagination.pageSize ||
      dataCriteria.sortItem !== sortItem ||
      dataCriteria.sortOrder !== order) {
      offsetReset = true;
    }

    criteriaDataTransfer([
      { key: "sortItem", value: sortItem }
      , { key: "sortOrder", value: order }
      , { key: "limit", value: pagination.pageSize }
      , { key: "offset", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
    ]);
  }

  // CriteriaをStoreに保持
  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let criteria: Model.Criteria = {
      originalQualificationNo: dataCriteria.originalQualificationNo,
      originalQualificationName: dataCriteria.originalQualificationName,
      originalQualificationId: dataCriteria.originalQualificationId,
      sortItem: dataCriteria.sortItem,
      sortOrder: dataCriteria.sortOrder,
      offset: dataCriteria.offset,
      limit: dataCriteria.limit
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      criteria[rec.key] = rec.value;
    })
    dispatch(Module.setQualificationCriteria(criteria));
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_QUALIFICATIONS_PRIME_LIST === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (dataCriteria.offset > 0 && totalCnt > 0 && dataList.length === 0) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(totalCnt, dataCriteria.limit);
      criteriaDataTransfer([
        { key: "offset", value: newOffset }
      ]);
    }
  }

  /**
   * 描画用メソッド
   * 
   */

  // 手当区分を描画します。
  const renderAllowanceTypeLabel = (value: string, row: Model.dataRow) => {
    // 手当区分ラベルを取得
    return {
      props: {
        align: 'right'
      }
      , children: (
        constantsUtils.getLabelOf(CONSTANTS.ALLOWANCE_TYPE, row.allowanceType)
      )
    }
  };

  // 有効期限を描画します。
  const renderDeadlineTerm = (value: string, row: Model.dataRow) => {
    return {
      children: (
        formatUtils.formatDuration(row.deadlineStartDate, row.deadlineEndDate)
      )
    }
  };

  // 数値型にフォーマットします。 
  const renderFormattedNumber = (value: number, row: Model.dataRow) => {
    const formattedValue = formatUtils.formatNumberComma(value);
    return {
      children: (
        <div className={styles.columnAlignRight}>{formattedValue}</div>
      )
    }
  }

  // 検索条件エリアを描画します。
  const QualificationCriteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={searchDataList}
        // 初期値はFormに設定する
        initialValues={{
          'originalQualificationNo': dataCriteria.originalQualificationNo
          , 'originalQualificationName': dataCriteria.originalQualificationName
        }}
      >
        <div className={styles.criteriaSearchField}>
          <CriteriaForm.Item name="originalQualificationNo" id="originalQualificationNo">
            <Input
              type="text"
              placeholder="独自資格番号"
              maxLength={CONSTANTS.QUALIFICATION_NO_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="originalQualificationName">
            <Input
              type="text"
              placeholder="独自資格名"
              maxLength={CONSTANTS.QUALIFICATION_NAME_MAX_LENGTH}
            />
          </CriteriaForm.Item>
          <AuthButton
            name={"検索"}
            htmlType="submit"
            shape="round"
          />
        </div>
      </CriteriaForm>
    )
  }

  // テーブル列を設定します。
  const columns = [
    {
      title: '独自資格番号'
      , dataIndex: 'originalQualificationNo'
      , key: '1'
      , width: 200
      , sortOrder: dataCriteria.sortItem !== '1' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , sorter: true
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: '独自資格名'
      , dataIndex: 'originalQualificationName'
      , key: '2'
      , width: 300
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '2' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
      , render: (value: string, row: Model.dataRow) => {
        return {
          children: (
            <div className={styles.columnAlignLeft}>{value}</div>
          )
        }
      }
    },
    {
      title: '手当区分'
      , dataIndex: 'allowanceTypeLabel'
      , key: '3'
      , width: 100
      , render: renderAllowanceTypeLabel
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '3' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: '手当金額'
      , dataIndex: 'allowanceAmount'
      , key: '4'
      , width: 100
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '4' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: '有効期限'
      , dataIndex: 'deadlineTerm'
      , key: '5'
      , width: 200
      , render: renderDeadlineTerm
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '5' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: '保有企業数'
      , dataIndex: 'numberOfCompanies'
      , key: '6'
      , width: 120
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '6' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    },
    {
      title: (
        <>
          <span>有効独自資格</span><br />
          <span> 保有人数</span>
        </>
      )
      , dataIndex: 'numberOfPeople'
      , key: '7'
      , width: 140
      , render: renderFormattedNumber
      , sorter: true
      , sortOrder: dataCriteria.sortItem !== '7' ? null : dataCriteria.sortOrder === "0" ? "ascend" : "descend" as SortOrder
      , showSorterTooltip: false
      , align: 'center' as 'center'
    }
  ];

  // ページングボタンを描画します。
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <AuthButton name='＜' size='small' />;
    }
    if (type === 'next') {
      return <AuthButton name='＞' size='small' />;
    }
    return originalElement
  }

  // フッターボタンエリアを描画します。
  const ButtonArea = () => {
    return (
      <FooterButtonArea>
        <AuthButton
          name={"登録"}
          authorities={[
            AuthButtonAuthorities.primeQualificationMngAuth
          ]}
          size={"large"}
          shape={"round"}
          onClick={addClicked}
        />
      </FooterButtonArea>
    )
  }

  return (
    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <QualificationCriteria />
        <CommonMessage searchNoDataName="独自資格" />
        {totalCnt > 0 && dataList.length > 0 ?
          <Table
            columns={columns}
            sortDirections={['ascend', 'descend']}
            size='small'
            dataSource={dataList}
            scroll={{ x: 1200 }}
            pagination={{
              total: totalCnt  //総件数
              , defaultCurrent: 1 //
              , current: dataCriteria.offset / dataCriteria.limit + 1
              , pageSize: dataCriteria.limit //ページサイズを入れる
              , pageSizeOptions: ['5', '10', '20', '50', '100']
              , showSizeChanger: true
              , showTotal: (total, range) => `${total}件中、${range[0]}件目から${range[1]}件目を表示`
              , itemRender: pageItemRender
              , locale: { items_per_page: '件/ページ' }
            }}
            onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => { moveToDetail(record) }
              };
            }}
          />
          : <></>}
      </Content>
      <ButtonArea />
      <Footer />
    </Layout >
  );
}

