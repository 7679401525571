import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router'
import { Layout, Table } from 'antd';
import { DetailForm, AuthButton, AuthButtonAuthorities } from '../../core/CoreForm';
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from '../../core/CorePageContents';
import styles from './QualificationHoldersDetail.module.css';
import * as Module from '../../../modules/prime/qualifications-holders/qualificationHoldersModule';
import * as Model from '../../../modules/prime/qualifications-holders/qualificationHoldersModel';
import * as CONSTANTS from '../../../constants/constants';
import * as messagesUtils from '../../../utils/messagesUtils';
import * as formatUtils from '../../../utils/formatUtils';
import { storedApiResult, initApiResult } from '../../../modules/core/bffApiModule';
import { storedLoginUserInfo } from '../../../modules/core/authModule'
import { initMessages, message } from '../../../modules/core/commonMessageModule';
import { setMessages } from '../../../modules/core/commonMessageModule';
import AuthButtonStyles from "../../core/css/AuthButton.module.css";

interface titleInterface {
  title: string;
}

export const QualificationHoldersDetail = (props: titleInterface) => {

  const dispatch = useDispatch();
  const dataRow = useSelector(Module.storedDataRow);
  const belongCompanyData = useSelector(Module.storedBelongCompanyDataRow);
  const apiResult = useSelector(storedApiResult);
  const loginUserInfo = useSelector(storedLoginUserInfo);
  let history = useHistory();
  let requestDataList = dataRow.originalQualificationList.filter(data => data.registrationStatus === CONSTANTS.CERTIFICATION_STATUS_CODE.REQUEST)
  let certifiedDataList = dataRow.originalQualificationList.filter(data => data.registrationStatus === CONSTANTS.CERTIFICATION_STATUS_CODE.CERTIFIED)
  let loadingFlg = useSelector(Module.storedLoadingFlg);

  useEffect(() => {
    dispatch(Module.setLoadingFlg(false));
  },
    []
  );

  useEffect(() => {
    // 所属企業が設定されていない場合、独自資格保有者協力会社一覧画面へ遷移します。
    if (belongCompanyData.belongCompanyName === "") {
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_COMPANIES);
      return;
    }
    // APIステータスが初期値の場合、詳細情報取得APIを実行します。（初期表示用の処理。loadingFlgがtrueの時は状態変更API中なのでloadingFlgの判定もする）
    if (CONSTANTS.API_STATUS_INITIAL === apiResult.status && "" === apiResult.requestMethod && !loadingFlg) {
      getDetailInfo();
    }
    // 画面更新時の詳細情報取得APIの場合、loadingFlgをfalseにする（状態変更APIでloadingFlgにtrueを設定している）
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod && CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_LIST === apiResult.url) {
      dispatch(Module.setLoadingFlg(false));
    }

    // 詳細情報取得API実行後、APIエラーメッセージが設定されていたら一覧画面へ遷移します。
    if (dataRow.key === "" && CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" !== apiResult.errorCode && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod) {
      // API結果メッセージを遷移先画面に表示するために共通メッセージに設定します。
      const setMessage: message = {
        message: messagesUtils.getMessage(apiResult.errorCode)
        , messageType: CONSTANTS.MESSAGE_TYPE_ERROR
      }
      dispatch(setMessages([setMessage]));
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_LIST);
    }

    // 詳細情報取得API実行後、APIエラーメッセージが設定されていたら一覧画面へ遷移します。
    if (dataRow.key === "" && CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod) {
      history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_COMPANIES);
    }

    // 独自資格削除API実行後、APIエラーメッセージが設定されていない場合、一覧画面に遷移して削除成功メッセージを表示します。
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_DELETE === apiResult.requestMethod) {
      const messageText = messagesUtils.getMessageTextFromSession();
      const messageType = messagesUtils.getMessageTypeFromSession() || CONSTANTS.MESSAGE_TYPE_SUCCESS;
      if (messageText !== null) {
        // 削除エラーメッセージがあるので、設定。画面遷移しない。
        const setMessage: message = {
          message: messageText
          , messageType: messageType
        }
        dispatch(setMessages([setMessage]));

        messagesUtils.clearSessionMessage();
      } else {
        // 削除成功メッセージを設定します。
        const setMessage: message = {
          message: messagesUtils.getMessage("SUCCESS_DELETE", ["独自資格保有者"])
          , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
        }
        dispatch(setMessages([setMessage]));
        history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_LIST);
      }
    }

    // 独自資格認定状態変更API実行後、詳細情報を再取得します。
    if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod && CONSTANTS.URL_QUALIFICATIONS_HOLDERS_PRIME_CERTIFICATION === apiResult.url) {
      // 独自資格認定状態変更API実行後
      
      // APIエラーメッセージが設定されている場合、エラーメッセージを表示します。
      const messageText = messagesUtils.getMessageTextFromSession();
      const messageType = messagesUtils.getMessageTypeFromSession() || CONSTANTS.MESSAGE_TYPE_SUCCESS;
      if (messageText !== null) {
        // エラーメッセージがあるので、設定
        const setMessage: message = {
          message: messageText
          , messageType: messageType
        }
        dispatch(setMessages([setMessage]));

        messagesUtils.clearSessionMessage();
      }

      // 初期表示と同じようにNow Loadingを表示しながら画面を更新
      dispatch(initApiResult());
      dispatch(Module.setLoadingFlg(true));
      getDetailInfo();
    }

    // 独自資格一覧が0行の時の処理
    viewEmptyQualificationDetailTable();

  },
    [apiResult]
  );

  // 独自資格一覧が0行の時の処理
  const viewEmptyQualificationDetailTable = () => {
    // 0行になったとき、ant designのテーブルが作成する「No Data」行を非表示にする（0行になったとき"ant-table-placeholder"が存在する）
    const tableElem: HTMLElement | null = document.getElementById("qualificationDetailTable");
    if (tableElem != null) {
      const phRow: HTMLElement | null = tableElem.querySelector(".ant-table-placeholder");
      if (phRow != null) {
        phRow.style.display = "none";
      }
    }
  }

  // 詳細情報取得APIを実行
  const getDetailInfo = () => {
    let criteria: Model.QualificationHolderCriteria = {
      belongCompanyId: belongCompanyData.belongCompanyId,
      managementGroupId: "",
      originalQualificationName: "",
      workerId: dataRow.workerId,
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
      primeCertificationNo: "",
      sortItem: "",
      sortOrder: "",
      offset: 0,
      limit: 10,
      onlyEnableQualification: "0"
    }
    dispatch(Module.getQualificationHolderDataRowAsync(criteria));
  }

  const backClicked = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_LIST);
  }

  const editClicked = () => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    history.push(CONSTANTS.PATH_PRIME_QUALIFICATIONS_HOLDERS_EDIT);
  }

  const deleteClicked = () => {
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_DELETE", ["独自資格保有者"]))) {
      return;
    }
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());

    dispatch(initApiResult());

    let originalQualificationList: Array<Model.deleteData.originalQualification> = [];
    dataRow.originalQualificationList.forEach((row: Model.originalQualificationRow) => {
      const originalQualificationRow: Model.deleteData.originalQualification = {
        certificationId: row.certificationId
        , updateDatetime: row.updateDatetime
      };
      originalQualificationList.push(originalQualificationRow);
    });
    let deleteData: Model.deleteData.Request = {
      belongCompanyId: belongCompanyData.belongCompanyId
      , workerId: dataRow.workerId
      , updateDatetime: dataRow.updateDatetime
      , originalQualificationList: originalQualificationList
    }
    dispatch(Module.deleteDataRowAsync(deleteData));
  }

  // 編集/削除ボタン表示権限有無を判定します。
  const isDisplayOperationButton = () => {
    if (loginUserInfo.authorityInfo.primeQualificationMngAuth === "0") {
      // 資格管理者権限を持っていない場合、非表示
      return false;
    }
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_ALL.code) {
      // 独自資格付与フロー設定が「資格の登録のみ行う（デフォルト）」の場合、表示
      return true;
    } else if (loginUserInfo.authorityInfo.primeCertificationFlowRequestAuth === "1") {
      // 独自資格付与フロー設定が「資格の登録のみ行う（デフォルト）」以外の場合、申請権限を持っていれば、表示
      return true;
    } else {
      return false;
    }
  }

  // 削除ボタン活性・非活性の制御
  // 無効にするかどうかなので、有効にする場合、falseを返却
  const disabledDeleteButton = () => {
    if (dataRow.originalQualificationList.length === 0) {
      // 独自資格が0件の場合は常に有効
      return false;
    }
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.NO_SKIP.code) {
      // 資格認定フロー利用設定が資格を登録する際に申請・確認・承認を必要とするの場合、独自資格の状態が全て申請の場合、有効
      return requestDataList.length !== dataRow.originalQualificationList.length;
    } else if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_CONFIRM.code) {
      // 資格認定フロー利用設定が資格を登録する際に申請・承認を必要とするの場合、独自資格の状態が全て確認済以下の場合、有効
      return certifiedDataList.length > 0;
    } else {
      // 資格認定フロー利用設定が資格の登録のみ行う（デフォルト）の場合、常に有効
      return false;
    }
  }

  // 独自資格認定状態変更ボタン押下時アクション
  const changeCertificationStatus = (row: Model.originalQualificationRow, statusChangeCode: string, buttonName: string) => {
    // メッセージを設定して確認ダイアログを表示します。
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_PROCESS", [buttonName]))) {
      return;
    }
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    dispatch(initApiResult());
    dispatch(Module.setLoadingFlg(true));

    let putData: Model.putCertificationStatusData.Request = {
      certificationId: row.certificationId
      , statusChangeCode: statusChangeCode
      , updateDatetime: row.updateDatetime
      , belongCompanyId: belongCompanyData.belongCompanyId
      , workerId: dataRow.workerId
    }
    dispatch(Module.putCertificationStatusAsync(putData));
  }

  // テーブル列を設定します。
  const columns = [
    {
      title: '状態'
      , dataIndex: 'status'
      , width: '12%'
      , align: 'center' as 'center'
    },
    {
      title: '独自資格名'
      , dataIndex: 'originalQualificationName'
      , key: '2'
      , width: '23%'
      , align: 'center' as 'center'
      , render: (value: string, row: Model.originalQualificationRow) => {
        return (
          <div className={styles.columnAlignLeft}>{row.originalQualificationName}</div>
        )
      }
    },
    {
      title: '認定日'
      , dataIndex: 'certificationDate'
      , key: '3'
      , width: '15%'
      , align: 'center' as 'center'
      , render: (value: string, row: Model.originalQualificationRow) => {
        return (
          formatUtils.formatDateSlash(row.certificationDate)
        )
      }
    },
    {
      title: '認定取消日'
      , dataIndex: 'certificationCancelDate'
      , key: '4'
      , width: '15%'
      , align: 'center' as 'center'
      , render: (value: string, row: Model.originalQualificationRow) => {
        return (
          formatUtils.formatDateSlash(row.certificationCancelDate)
        )
      }
    },
    {
      title: '有効期限'
      , dataIndex: 'numberOfQualifications'
      , key: '5'
      , width: '25%'
      , align: 'center' as 'center'
      , render: (value: string, row: Model.originalQualificationRow) => {
        if (row.deadlineExist === CONSTANTS.EXIST_DEADLINE.NONE.code) {
          return (
            "なし"
          )
        }
        return (
          formatUtils.formatDuration(row.deadlineStartDate, row.deadlineEndDate)
        )
      }
    },
    {
      title: ''
      , dataIndex: 'button'
      , key: '6'
      , width: '10%'
      , align: 'center' as 'center'
      , render: (value: string, row: Model.originalQualificationRow) => {
        if (loginUserInfo.authorityInfo.primeQualificationMngAuth === "0") {
          // 資格管理者権限を持っていない場合、全ての状態変更ボタン非表示
          return <></>;
        }

        if (row.status === CONSTANTS.CERTIFICATION_STATUS.REQUEST) {
          // 認定状態が申請
          // 確認依頼ボタンを描画
          return RequestConfirmButton(row);

        } else if (row.status === CONSTANTS.CERTIFICATION_STATUS.REQUESTED) {
          // 認定状態が確認依頼中
          // 確認依頼取消、確認依頼差戻、確認ボタンを描画
          return (
            <div>
              {CancelRequestConfirmButton(row)}
              {RemandRequestConfirmButton(row)}
              {ConfirmButton(row)}
            </div>
          )
        } else if (row.status === CONSTANTS.CERTIFICATION_STATUS.CONFIRMED) {
          // 認定状態が確認済
          // 確認差戻、承認ボタンを描画
          return (
            <div>
              {RemandConfirmButton(row)}
              {ApproveButton(row)}
            </div>
          )
        } else {
          // 認定状態が承認済み（認定済 or 有効期間外)
          // 承認取消ボタンを描画
          return CancelApproveButton(row)
        }
      }
    }
  ];

  // 確認依頼ボタンを描画します。
  const RequestConfirmButton = (row: Model.originalQualificationRow) => {
    let buttonName: string = "確認依頼";
    let className: string = styles.requestConfirmButton;
    let authorities: Array<string> | undefined = [AuthButtonAuthorities.primeCertificationFlowRequestAuth];
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_CONFIRM.code) {
      // 資格認定フロー利用設定：資格を登録する際に申請・承認を必要とする　の場合
      buttonName = "確認";
      className = styles.confirmButton;
    } else if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_ALL.code) {
      // 資格認定フロー利用設定：資格の登録のみ行う（デフォルト）　の場合
      buttonName = "承認";
      className = styles.approveButton;
      authorities = undefined; // 申請権限なしで表示
    }
    return (
      <AuthButton
        name={buttonName}
        shape={"round"}
        className={className}
        onClick={() => changeCertificationStatus(row, Model.STATUS_CHANGE_CODE.COMFIRM_REQUEST, buttonName)}
        authorities={authorities}
      />
    )
  }

  // 確認依頼取消ボタンを描画します。
  const CancelRequestConfirmButton = (row: Model.originalQualificationRow) => {
    let buttonName: string = "確認依頼取消";
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.NO_SKIP.code) {
      // 資格認定フロー利用設定：資格を登録する際に申請・確認・承認を必要とする　の場合
      return (
        <AuthButton
          name={buttonName}
          shape={"round"}
          className={styles.cancelRequestConfirmButton}
          onClick={() => changeCertificationStatus(row, Model.STATUS_CHANGE_CODE.COMFIRM_REQUEST_CANCEL, buttonName)}
          authorities={[AuthButtonAuthorities.primeCertificationFlowRequestAuth]}
        />
      )
    } else {
      // 資格認定フロー利用設定：資格を登録する際に申請・承認を必要とする　の場合
      // 資格認定フロー利用設定：資格の登録のみ行う（デフォルト）　の場合
      return <></>;
    }
  }

  // 確認依頼差戻ボタンを描画します。
  const RemandRequestConfirmButton = (row: Model.originalQualificationRow) => {
    let buttonName: string = "確認依頼差戻";
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.NO_SKIP.code) {
      // 資格認定フロー利用設定：資格を登録する際に申請・確認・承認を必要とする　の場合
      return (
        <AuthButton
          name={buttonName}
          shape={"round"}
          className={styles.remandRequestConfirmButton}
          onClick={() => changeCertificationStatus(row, Model.STATUS_CHANGE_CODE.COMFIRM_REQUEST_REMAND, buttonName)}
          authorities={[AuthButtonAuthorities.primeCertificationFlowConfirmAuth]}
        />
      )
    } else {
      // 資格認定フロー利用設定：資格を登録する際に申請・承認を必要とする　の場合
      // 資格認定フロー利用設定：資格の登録のみ行う（デフォルト）　の場合
      return <></>;
    }
  }

  // 確認ボタンを描画します。
  const ConfirmButton = (row: Model.originalQualificationRow) => {
    let buttonName: string = "確認";
    let className: string = styles.confirmButton;
    let authorities: Array<string> | undefined = [AuthButtonAuthorities.primeCertificationFlowConfirmAuth];
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_ALL.code) {
      // 資格認定フロー利用設定：資格の登録のみ行う（デフォルト）　の場合
      buttonName = "承認";
      className = styles.approveButton;
      authorities = undefined; // 確認権限なしで表示
    }
    return (
      <AuthButton
        name={buttonName}
        shape={"round"}
        className={className}
        onClick={() => changeCertificationStatus(row, Model.STATUS_CHANGE_CODE.COMFIRM, buttonName)}
        authorities={authorities}
      />
    )
  }

  // 確認差戻ボタンを描画します。
  const RemandConfirmButton = (row: Model.originalQualificationRow) => {
    let buttonName: string = "確認差戻";
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.NO_SKIP.code) {
      // 資格認定フロー利用設定：資格を登録する際に申請・確認・承認を必要とする　の場合
      return (
        <AuthButton
          name={buttonName}
          shape={"round"}
          className={styles.remandConfirmButton}
          onClick={() => changeCertificationStatus(row, Model.STATUS_CHANGE_CODE.COMFIRM_REMAND, buttonName)}
          authorities={[AuthButtonAuthorities.primeCertificationFlowApproveAuth]}
        />
      )
    } else {
      // 資格認定フロー利用設定：資格を登録する際に申請・承認を必要とする　の場合
      // 資格認定フロー利用設定：資格の登録のみ行う（デフォルト）　の場合
      return <></>;
    }
  }

  // 承認ボタンを描画します。
  const ApproveButton = (row: Model.originalQualificationRow) => {
    let buttonName: string = "承認";
    let authorities: Array<string> | undefined = [AuthButtonAuthorities.primeCertificationFlowApproveAuth];
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_ALL.code) {
      // 資格認定フロー利用設定：資格の登録のみ行う（デフォルト）　の場合
      authorities = undefined; // 承認権限なしで表示
    }
    return (
      <AuthButton
        name={buttonName}
        shape={"round"}
        className={styles.approveButton}
        onClick={() => changeCertificationStatus(row, Model.STATUS_CHANGE_CODE.APPROVAL, buttonName)}
        authorities={authorities}
      />
    )
  }

  // 承認取消ボタンを描画します。
  const CancelApproveButton = (row: Model.originalQualificationRow) => {
    let buttonName: string = "承認取消";
    if (loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.NO_SKIP.code
      || loginUserInfo.certificateFlowType === CONSTANTS.CERTIFICATE_FLOW_TYPE.SKIP_CONFIRM.code) {
      // 資格認定フロー利用設定：資格を登録する際に申請・確認・承認を必要とする　の場合
      // 資格認定フロー利用設定：資格を登録する際に申請・承認を必要とする　の場合
      return (
        <AuthButton
          name={buttonName}
          shape={"round"}
          className={styles.cancelApproveButton}
          onClick={() => changeCertificationStatus(row, Model.STATUS_CHANGE_CODE.APPROVAL_CANCEL, buttonName)}
          authorities={[AuthButtonAuthorities.primeCertificationFlowApproveAuth]}
        />
      )
    } else {
      // 資格認定フロー利用設定：資格の登録のみ行う（デフォルト）　の場合
      return <></>;
    }
  }


  // 独自資格詳細Formを描画します。
  const DetailFormArea = () => {
    return (
      <div className={styles.contentArea} >
        <div className={styles.headline}>
          <span>基本情報</span>
        </div>
        <DetailForm>
          <DetailForm.Item label="元請認定番号">
            <span>{dataRow.primeCertificationNo}</span>
          </DetailForm.Item>

          <DetailForm.Item label="管理グループ">
            <span>{dataRow.managementGroupName}</span>
          </DetailForm.Item>

          <DetailForm.Item label="氏名">
            <span>{dataRow.lastName + " " + dataRow.firstName}</span>
          </DetailForm.Item>

          <DetailForm.Item label="生年月日">
            <span>{formatUtils.formatDateSlash(dataRow.birthday)}</span>
          </DetailForm.Item>

          <DetailForm.Item label="備考">
            <span className={styles.remarks}>{dataRow.remarks}</span>
          </DetailForm.Item>
        </DetailForm>
      </div>
    )
  }

  // 独自資格詳細Formを描画します。
  const DetailTableArea = () => {
    return (
      <div className={styles.contentArea} >
        <div className={styles.headline}>
          <span>独自資格</span>
        </div>
        <Table
          id="qualificationDetailTable"
          columns={columns}
          className="hoverNotBackground"
          rowClassName={styles.qualificationRow}
          sortDirections={['ascend', 'descend']}
          size='small'
          dataSource={dataRow.originalQualificationList}
          pagination={false}
          scroll={{ x: 900 }}
          style={{ marginLeft: "20px", marginRight: "20px" }}
        />
      </div>
    )
  }

  // 所属企業を描画します。
  const BelongCompany = () => {
    return (
      <div className={styles.belongCompany}>
        <span>{"所属会社：" + belongCompanyData.belongCompanyName}</span>
      </div>
    )
  }

  // フッターボタンエリアを描画します。
  const ButtonArea = () => {
    return (
      <FooterButtonArea>
        <AuthButton
          name={"戻る"}
          size={"large"}
          shape={"round"}
          className={AuthButtonStyles.backButtonFixed}
          onClick={backClicked}
        />
        {isDisplayOperationButton() ?
          <>
            <AuthButton
              name={"編集"}
              size={"large"}
              shape={"round"}
              onClick={editClicked}
            />
            <AuthButton
              name={"削除"}
              className={styles.customButtonColor}
              size={"large"}
              shape={"round"}
              onClick={deleteClicked}
              disabled={disabledDeleteButton()}
            />
          </>
          : <></>
        }
      </FooterButtonArea>
    )
  }

  return (

    <Layout>
      <Header />
      <Content>
        <FunctionTitle title={props.title} />
        <CommonMessage />
        <BelongCompany />
        <DetailFormArea />
        <DetailTableArea />
      </Content>
      <ButtonArea />
      <Footer />
    </Layout>
  );
}

