import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

interface baseInterface {
  [propertyName: string]: any
}

export interface IdateString extends baseInterface {
  id: string
  dateString: string
}

// state定義
interface MonthPickerState {
  dateFocus: boolean
  , dateStrings: Array<IdateString>
}

// state初期化
const initialState: MonthPickerState = {
  dateFocus: false
  , dateStrings: []
};

// スライサー （state更新アクションの実装）
export const monthPickerSlice = createSlice({
  name: 'monthPicker',
  initialState,
  reducers: {
    toggleDateFocus: (state, action: PayloadAction<boolean>) => {
      state.dateFocus = action.payload;
    }
    , initDateString: (state) => {
      state.dateStrings = [];
    }
    , setDateString: (state, action: PayloadAction<IdateString>) => {

      let editDateStrings: Array<IdateString> = [];
      state.dateStrings.forEach((stateRow: IdateString) => {
        if (stateRow.id !== action.payload.id) {
          editDateStrings.push({ id: stateRow.id, dateString: stateRow.dateString });
        }
      })
      editDateStrings.push(action.payload);
      state.dateStrings = editDateStrings;
    }
  },
});

export const { toggleDateFocus, setDateString, initDateString } = monthPickerSlice.actions;

export const dateFocus = (state: RootState) => state.monthPicker.dateFocus;
export const dateStrings = (state: RootState) => state.monthPicker.dateStrings;

export default monthPickerSlice.reducer;
