import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Store } from 'antd/lib/form/interface';
import { Layout, Table, Input, Steps } from 'antd';
import { CriteriaForm, AuthButton, Radio } from '../../core/CoreForm';
import { ContentEx as Content, FunctionTitle, CommonMessage } from '../../core/CorePageContents';
import styles from './PartnerCompanySelect.module.css';
import { initMessages } from '../../../modules/core/commonMessageModule';
import * as Model from '../../../modules/prime/partnerCompanySelect/partnerCompanySelectModel';
import * as Module from '../../../modules/prime/partnerCompanySelect/partnerCompanySelectModule';
import * as CONSTANTS from '../../../constants/constants';
import * as constantsUtils from '../../../utils/constantsUtils';
import { initApiResult, storedApiResult } from '../../../modules/core/bffApiModule';

interface titleInterface {
  title: string;
}

export const PartnerCompanySelect = (props: titleInterface) => {

  const dispatch = useDispatch();
  const apiResult = useSelector(storedApiResult);
  let dataList = useSelector(Module.storedDataList);
  let dataCriteria = useSelector(Module.storedDataCriteria);
  let totalCnt = useSelector(Module.storedDataTotal);
  let currentPage = useSelector(Module.storedDataCurrentPage);
  let nextPageTokenList = useSelector(Module.storedNextPageTokenList);
  let selectValue = useSelector(Module.storedSelectValue);
  const { Step } = Steps;


  useEffect(() => {
    // API結果情報を初期化します。
    dispatch(initApiResult());
    dispatch(Module.getDataListAsync(dataCriteria));
  },
    [dataCriteria]
  );

  // 検索ボタン押下時イベント
  const searchDataList = (value: Store) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    let criteria: Model.Criteria = {
      projectId: dataCriteria.projectId,
      companyName: value.companyName,
      nextPageToken: "",
      limit: dataCriteria.limit
    }
    dispatch(Module.setCurrentPage(1));
    dispatch(Module.setCriteria(criteria));
    dispatch(Module.reflectModalSelectValue(""));
  }

  //　ページング選択時イベント
  const handleTableChange = (pagination: any) => {
    // 選択値を削除
    dispatch(Module.reflectModalSelectValue(""));
    let criteria: Model.Criteria = {
      projectId: dataCriteria.projectId,
      companyName: dataCriteria.companyName,
      nextPageToken: typeof nextPageTokenList[pagination.current] === "undefined" ? "" : nextPageTokenList[pagination.current],
      limit: pagination.pageSize
    }
    dispatch(Module.setCurrentPage(pagination.current));
    dispatch(Module.setCriteria(criteria));
    // 選択値をクリア
    dispatch(Module.reflectModalSelectValue(""));
  }

  // 検索が終了した時の処理（次ページが取得できなかった場合、総件数0件で返却されるので対応）
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_CMN_PRIME_PARTNER_COMPANIES === apiResult.url) {

    // 2ページ目以降を表示していて、表示するデータが空の場合、再取得する
    if (currentPage > 1 && dataList.length === 0) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 一つ前のページを取得する
      let toPage = currentPage - 1;

      // 共通メッセージ情報を初期化します。
      dispatch(initMessages());
      let criteria: Model.Criteria = {
        projectId: dataCriteria.projectId,
        companyName: dataCriteria.companyName,
        nextPageToken: typeof nextPageTokenList[toPage] === "undefined" ? "" : nextPageTokenList[toPage],
        limit: dataCriteria.limit
      }
      dispatch(Module.setCurrentPage(toPage));
      dispatch(Module.setCriteria(criteria));
    }
  }

  const reflectSelectValue = (value: string) => {
    // 選択値を保持
    dispatch(Module.reflectModalSelectValue(value));
  }

  // 協力会社検索モーダル検索フォーム
  const SearchProjectCriteria = () => {
    return (
      <CriteriaForm layout="inline" onFinish={searchDataList} initialValues={{ "companyName": dataCriteria.companyName }}>
        <div className={styles.criteriaSearchField}>
          <CriteriaForm.Item name="companyName">
            <Input type="text" placeholder="協力会社名" maxLength={CONSTANTS.COMPANY_NAME_MAX_LENGTH} />
          </CriteriaForm.Item>
          <AuthButton name={"検索"} htmlType="submit" shape="round" />
        </div>
      </CriteriaForm>
    )
  }

  // 企業区分からラベルを取得します。 
  const renderFormattedCompanyType = (value: string, row: Model.DataRow) => {
    let formattedValue: string = "";
    if (CONSTANTS.COMPANY_TYPE.ACTING_COMPANY.code == value) {
      formattedValue = "代行企業";
    } else {
      formattedValue = "依頼企業";
    }

    return formattedValue;
  }

  // ラジオボタンのフォーマット（カスタムラジオボタンを使用） 
  const radioFormatter = (value: string, row: Model.DataRow) => {
    return (
      <Radio
        key={row.companyId}
        value={row.companyId}
        onChange={() => reflectSelectValue(row.companyId)}
        checked={selectValue === row.companyId ? true : false}
      />
    )
  }

  const columns = [
    {
      title: '選択'
      , width: 60
      , align: 'center' as 'center'
      , render: radioFormatter
    },
    {
      title: '依頼/代行区分'
      , dataIndex: 'companyType'
      , width: 120
      , align: 'center' as 'center'
      , render: renderFormattedCompanyType
    },
    {
      title: '協力会社名'
      , dataIndex: 'companyName'
      , width: 150
      , align: 'center' as 'center'
      , render: (value: string) => {
        return {
          children: (
            <div className={styles.columnAlignLeft}>{value}</div>
          )
        }
      }
    },
    {
      title: '郵便番号'
      , dataIndex: 'zipcode'
      , width: 100
      , align: 'center' as 'center'
    },
    {
      title: '住所'
      , dataIndex: 'address'
      , width: 300
      , align: 'center' as 'center'
      , render: (value: string) => {
        return {
          children: (
            <div className={styles.columnAlignLeft}>{value}</div>
          )
        }
      }
    },
  ]

  // 協力会社検索モーダル検索フォームのステップを描画します。
  const SearchPartnerCompanySteps = () => {
    return (
      <Steps size="small" current={1} className={styles.step}>
        <Step title="プロジェクト選択" />
        <Step title="協力会社選択" />
      </Steps>
    )
  }

  // ページングボタンを描画します。
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <AuthButton name='＜' size='small' />;
    }
    if (type === 'next') {
      return <AuthButton name='＞' size='small' />;
    }
    return originalElement
  }

  return (
    <Layout className={styles.layout}>
      <Content>
        <FunctionTitle title={props.title} />
        <SearchProjectCriteria />
        <SearchPartnerCompanySteps />
        <CommonMessage />
        {totalCnt > 0 ?
          <Table
            columns={columns}
            size='small'
            dataSource={dataList}
            scroll={{ x: 500, y: 200 }}
            pagination={{
              total: totalCnt  //総件数
              , defaultCurrent: 1 //
              , current: currentPage
              , pageSize: dataCriteria.limit //ページサイズを入れる
              , pageSizeOptions: ['5', '10', '20', '50', '100']
              , showSizeChanger: true
              , showTotal: (total, range) => `${total}件中、${range[0]}件目から${range[1]}件目を表示`
              , itemRender: pageItemRender
              , locale: { items_per_page: '件/ページ' }
            }}
            onChange={(pagination) => handleTableChange(pagination)}
          /> : <></>
        }
      </Content>
    </Layout >
  );
}