import * as Model from './authorityModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from "axios";
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler, putRequest } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
axios.defaults.headers.post["Content-Type"] = "application/json";

// state初期化
const initialState: Model.AuthorityState = {
  dataList: []
  , dataRow: {
    // key
    key: '',
    // MCDP-ID
    mcdpId: '',
    // ユーザ名
    userName: '',
    //元請_利用者
    primeAvailableAuth: '',
    //元請_勤怠承認者
    primeAttendanceApprovalAuth: '',
    //元請_資格管理者
    primeQualificationMngAuth: '',
    //元請_システム管理者
    primeSystemMngAuth: '',
    // 元請_資格承認フロー_申請権限
    certificateFlowRequestAuth: '',
    // 元請_資格承認フロー_確認権限
    certificateFlowConfirmAuth: '',
    // 元請_資格承認フロー_承認権限
    certificateFlowApproveAuth: '',
    // 元請_参照範囲
    referenceRange: '',
    // 管理グループ一覧
    managementGroupList: [],
  }
  , managementGroupChoicesList: []
  , total: 0
  , modalVisibleState: false
  , authorityCriteria: {
    mcdpId: '',
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    authKbn: '',
    managementGroupId: '',
    offset: 0,
    limit: 10,
    nextPageToken: ''
  }
  , nextPageTokenList: []
  , loadingFlg: false
};

// スライサー （state更新アクションの実装）
export const authoritySlice = createSlice({
  name: 'primeAuthority',
  initialState,
  reducers: {
    reflectAuthorityMasetrPrimeValue: (state, action: PayloadAction<Model.changeValueTransfer>) => {
      // 値をdatalist反映 reflectCheckBoxValue
      // state.dataList のaction.payload.targetDataRow.mcdpIdを持つレコードを特定する。
      state.dataList.forEach((stateRow: Model.AuthorityRow) => {
        if (stateRow.mcdpId === action.payload.targetDataRow.mcdpId) {

          action.payload.changeKeyValueArray.forEach((target: Model.keyValue) => {
            if (target.propertyName in stateRow) {
              stateRow[target.propertyName] = target.value;
            }
          })

          return;
        }
      })
    },
    deleteManagementGroupListValue: (state, action: PayloadAction<Model.deleteValueTransfer>) => {
      // 対象のユーザを特定
      state.dataList.forEach((stateRow: Model.AuthorityRow) => {
        if (stateRow.mcdpId === action.payload.targetDataRow.mcdpId) {
          // 削除対象の管理グループを特定し、削除
          stateRow.managementGroupList.some(function (v, i) {
            if (v.managementGroupId === action.payload.managementGroup.managementGroupId) {
              stateRow.managementGroupList.splice(i, 1);
            }
          });
        }
      });
    },

    reflectModalState: (state, action: PayloadAction<boolean>) => {
      // モーダル表示,非表示
      state.modalVisibleState = action.payload
    },
    transferDataRow: (state, action: PayloadAction<Model.AuthorityRow>) => {
      state.dataRow = action.payload
    },
    reflectManagementGroup: (state, action: PayloadAction<Model.ManagementGroupListRow>) => {
      state.dataList.forEach((stateRow: Model.AuthorityRow) => {
        if (stateRow.mcdpId === state.dataRow.mcdpId) {
          if ("" !== action.payload.managementGroupId && -1 === stateRow.managementGroupList.map((o) => o.managementGroupId).indexOf(action.payload.managementGroupId)) {
            stateRow.managementGroupList.push(action.payload)
          }
        }
      })
    },

    // Use the PayloadAction type to declare the contents of `action.payload`
    getDataListOfAuthorityMaster: (state, action: PayloadAction<Model.AuthorityApiData>) => {
      state.dataList = initialState.dataList;
      state.total = action.payload.total;
      state.nextPageTokenList[state.authorityCriteria.offset / state.authorityCriteria.limit + 2] = action.payload.nextPageToken;


      let authorityInfoList: Array<Model.AuthorityRow> = [];
      action.payload.authorityInfoList.forEach((authorityRowRecord: Model.AuthorityRow) => {

        const rec: Model.AuthorityRow = {
          key: authorityRowRecord.mcdpId,
          mcdpId: authorityRowRecord.mcdpId,
          userName: authorityRowRecord.userName,
          primeAvailableAuth: authorityRowRecord.primeAvailableAuth,
          primeAttendanceApprovalAuth: authorityRowRecord.primeAttendanceApprovalAuth,
          primeQualificationMngAuth: authorityRowRecord.primeQualificationMngAuth,
          primeSystemMngAuth: authorityRowRecord.primeSystemMngAuth,
          certificateFlowRequestAuth: authorityRowRecord.certificateFlowRequestAuth,
          certificateFlowConfirmAuth: authorityRowRecord.certificateFlowConfirmAuth,
          certificateFlowApproveAuth: authorityRowRecord.certificateFlowApproveAuth,
          referenceRange: authorityRowRecord.referenceRange,
          managementGroupList: authorityRowRecord.managementGroupList,
        };
        authorityInfoList.push(rec);
      });
      state.dataList = authorityInfoList;
      state.managementGroupChoicesList = action.payload.managementGroupChoicesList
    },

    /**
     * 検索条件の初期化
     */
    initCriteria: (state) => {
      let authorityCriteria = {
        mcdpId: '',
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        authKbn: '',
        managementGroupId: '',
        limit: 10,
        offset: 0,
        nextPageToken: ''
      }

      return Object.assign({}, state, { authorityCriteria: authorityCriteria });
    },

    /**
     * 検索条件のstate更新
     */
    setCriteria: (state, action: PayloadAction<Model.AuthorityCriteria>) => {
      let authorityCriteria: Model.AuthorityCriteria = {
        mcdpId: action.payload.mcdpId,
        lastName: action.payload.lastName,
        firstName: action.payload.firstName,
        lastNameKana: action.payload.lastNameKana,
        firstNameKana: action.payload.firstNameKana,
        authKbn: action.payload.authKbn,
        managementGroupId: action.payload.managementGroupId,
        offset: action.payload.offset,
        limit: action.payload.limit,
        nextPageToken: action.payload.nextPageToken,
      }
      return Object.assign({}, state, { authorityCriteria: authorityCriteria })
    },

    setLoadingFlg: (state, action: PayloadAction<boolean>) => {
      // モーダル表示,非表示
      state.loadingFlg = action.payload
    },
  },
});

export const {
  getDataListOfAuthorityMaster,
  reflectAuthorityMasetrPrimeValue,
  deleteManagementGroupListValue,
  reflectModalState,
  transferDataRow,
  reflectManagementGroup,
  initCriteria,
  setCriteria,
  setLoadingFlg
} = authoritySlice.actions;

export const getDataListAsync = (authorityCriteriaInfo: Model.AuthorityCriteria): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_CMN_PRIME_AUTH, { params: authorityCriteriaInfo });
    // 結果をstateに設定します。
    dispatch(getDataListOfAuthorityMaster(response.data.data));
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};


export const putDataLsitAsync = (dataList: Array<Model.AuthorityRow>): AppThunk => async dispatch => {

  let putData: Model.putData = {
    authorityInfoList: []
  };

  dataList.forEach((data: Model.AuthorityRow) => {

    let authorityInfoListRow: Model.AuthorityInfoListRow;

    // 利用者権限がない場合は、設定に関わらずその他権限もない状態で更新する
    if (data.primeAvailableAuth === "0") {
      authorityInfoListRow = {
        // MCDP-ID
        mcdpId: data.mcdpId
        //利用者
        , primeAvailableAuth: data.primeAvailableAuth
        //勤怠承認者
        , primeAttendanceApprovalAuth: "0"
        //資格管理者
        , primeQualificationMngAuth: "0"
        //システム管理者
        , primeSystemMngAuth: "0"
        // 資格承認フロー_申請権限
        ,certificateFlowRequestAuth: "0"
        // 資格承認フロー_確認権限
        ,certificateFlowConfirmAuth: "0"
        // 資格承認フロー_承認権限
        ,certificateFlowApproveAuth: "0"
        // 参照範囲
        , referenceRange: "0"
        // 管理グループ一覧
        , managementGroupList: []
      }
    } else {
      authorityInfoListRow = {
        // MCDP-ID
        mcdpId: data.mcdpId
        //利用者
        , primeAvailableAuth: data.primeAvailableAuth
        //勤怠承認者
        , primeAttendanceApprovalAuth: data.primeAttendanceApprovalAuth
        //資格管理者
        , primeQualificationMngAuth: data.primeQualificationMngAuth
        //システム管理者
        , primeSystemMngAuth: data.primeSystemMngAuth
        // 資格承認フロー_申請権限
        ,certificateFlowRequestAuth: data.certificateFlowRequestAuth
        // 資格承認フロー_確認権限
        ,certificateFlowConfirmAuth: data.certificateFlowConfirmAuth
        // 資格承認フロー_承認権限
        ,certificateFlowApproveAuth: data.certificateFlowApproveAuth
        // 参照範囲
        , referenceRange: data.referenceRange
        // 管理グループ一覧
        , managementGroupList: []
      }
      // 参照範囲が"グループ指定"のとき、管理グループを反映
      if (CONSTANTS.REFERENCE_RANGE_POINT === data.referenceRange) {
        data.managementGroupList.forEach((managementGroup: Model.AuthorityInfoManagementGroupRow) => {
          authorityInfoListRow.managementGroupList.push({ managementGroupId: managementGroup.managementGroupId });
        })
      }
    }
    putData.authorityInfoList.push(authorityInfoListRow);
  })

  try {
    // PUT-APIを呼び出します。
    const response = await putRequest(CONSTANTS.URL_CMN_PRIME_AUTH, putData);
    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_PUT));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_PUT));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedDataList = (state: RootState) => state.primeAuthority.dataList;
export const storedModalVisibleState = (state: RootState) => state.primeAuthority.modalVisibleState;
export const storedManagementGroupChoicesList = (state: RootState) => state.primeAuthority.managementGroupChoicesList;
export const storedDataRow = (state: RootState) => state.primeAuthority.dataRow;
export const storedTotal = (state: RootState) => state.primeAuthority.total;
export const storedCriteriaInfo = (state: RootState) => state.primeAuthority.authorityCriteria;
export const storedNextPageTokenList = (state: RootState) => state.primeAuthority.nextPageTokenList;
export const storedLoadingFlg = (state: RootState) => state.primeAuthority.loadingFlg;

export default authoritySlice.reducer;
