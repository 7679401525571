import React from 'react';
import * as CONSTANTS from '../../../constants/constants';
import styles from "../css/MergeCompanyAnnotations.module.css"

interface MergeCompanyAnnotationsProps {
  attendanceList?: any[];
  mergeCompanyAttendanceFlgName: string
}

export const MergeCompanyAnnotations = (props: MergeCompanyAnnotationsProps) => {

  let isExistMergeCompanyAttendance: boolean = false;
  if (props.attendanceList != null) {
    for (let itm of props.attendanceList) {
      const flg: string | null = itm[props.mergeCompanyAttendanceFlgName];
      if (flg != null && flg == CONSTANTS.ABOLISH_COMPANY_FLAG_ON) {
        isExistMergeCompanyAttendance = true;
        break;
      }
    }
  }

  if (isExistMergeCompanyAttendance) {
    return (
      <div className={styles.mergeCompanyAnnotetion}>
        所属会社が変更になった場合に、変更前の勤怠について◎を表示しています。<br />
        ◎をクリックすることで所属の変更履歴が確認できます。
      </div>
    )
  } else {
    return (
      <></>
    )
  }
}