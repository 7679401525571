import React from 'react';
import { useSelector } from 'react-redux';
import styles from '../css/ContentEx.module.css';
import { Layout, Spin } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import { storedApiResult } from '../../../modules/core/bffApiModule';
import { storedLoginUserInfo } from '../../../modules/core/authModule'
import { isPrimeMenu } from '../../../modules/core/menuModule'
import * as CONSTANTS from '../../../constants/constants';

const { Content } = Layout;
const TRUE = "true";

// 指定可能なパラメータ定義（拡張）
interface ContentExProps extends BasicProps {
    hasapicall?: string
}
// antd Contentの拡張コンポーネント
// 各画面初期描画時にAPI呼び出し完了までコンテンツが中途半端に表示されるのを防止する。
export const ContentEx = (props: ContentExProps) => {
    const apiResult = useSelector(storedApiResult);
    const loginUserInfo = useSelector(storedLoginUserInfo);
    const isPrime = useSelector(isPrimeMenu);

    // 約款に同意している場合に鍵って「表示データなし」と記載する（約款未同意の場合は約款文章を表示するので
    if ((isPrime && loginUserInfo.primeServiceTermsAgreeFlg !== "0")
    || (!isPrime && loginUserInfo.partnerServiceTermsAgreeFlg !== "0")) {

        // 利用者権限を保持していない場合、表示可能なデータがないことを通知する。
        if (window.location.pathname === CONSTANTS.PATH_PRIME_ATTENDANCE_SUMMARY || window.location.pathname === CONSTANTS.PATH_PRIME_ATTENDANCE_DETAIL) {
            if (isPrime
                && "0" === loginUserInfo.authorityInfo.primeAttendanceApprovalRoleAuth
                && "0" === loginUserInfo.authorityInfo.primeAttendanceReffenceRoleAuth
                && "0" === loginUserInfo.authorityInfo.primeAvailableAuth) {
                return (
                    <div className={styles.loadingMessage}>
                        表示可能なデータがありません。
                    </div>
                )
            }
        } else {
            if ((isPrime && "0" === loginUserInfo.authorityInfo.primeAvailableAuth)
                || (!isPrime && "0" === loginUserInfo.authorityInfo.partnerAvailableAuth)) {

                if (window.location.pathname !== CONSTANTS.PATH_TOP) {
                    return (
                        <div className={styles.loadingMessage}>
                            表示可能なデータがありません。
                        </div>
                    )
                }
            }
        }
    }

    let className = "";
    if (isPrime) {
        className = styles.loadingPrimeMessage;
    } else {
        className = styles.loadingPartnerMessage;
    }

    // 初期表示時にAPIコールを含む画面で APIステータスが初期値の場合、Now Loadingメッセージを表示します。
    if ((typeof props.hasapicall === "undefined" || props.hasapicall === TRUE) && CONSTANTS.API_STATUS_INITIAL === apiResult.status) {
       
        return (
            <>
                <div className={styles.wrapperLoading}>
                    <Spin tip="Now Loading..." className={className} wrapperClassName={styles.loading}></Spin>
                </div>
                <Content {...props} ></Content>
            </>
        )
    }

    return (
        <Content {...props} >
        </Content>
    )
}
