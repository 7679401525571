import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler } from '../core/bffApiModule'
import * as CONSTANTS from '../../constants/constants';


/**
 * 廃止企業取得用API用のインタフェース定義
 */
export declare namespace getAbolishCompanyList {
  /**
   * リクエスト
   */
  export interface Request {
    /** 勤怠実績ID */
    workRecordSubmissionHistoryId: string
  }

  /**
   * レスポンス
   */
  export interface Response {
    abolishCompanyList: Array<AbolishCompany>
  }

  export interface AbolishCompany {
    /** 勤怠処理年月 */
    companyMergedDate: string
    /** 廃止企業名 */
    abolishedCompanyName: string
  }
}





export interface AbolishCompanyModalState {
  /** 廃止企業のダイアログを表示するかどうか */
  abolishCompanyModalVisibleState: boolean;
  abolishCompanyList: Array<AbolishCompanyListItem>;
}

export interface AbolishCompanyListItem {
  /** 勤怠処理年月 */
  companyMergedDate: string
  /** 廃止企業名 */
  abolishedCompanyName: string
}

const initialState: AbolishCompanyModalState = {
  abolishCompanyModalVisibleState: false
  , abolishCompanyList: []
}



export const abolishCompanyInfoSlice = createSlice({
  name: 'abolishCompanyInfo',
  initialState,
  reducers: {
    initAbolishCompanyModal:  (state) => {
      state.abolishCompanyModalVisibleState = false;
      state.abolishCompanyList = [];
    },


    /**
     * 廃止企業一覧モーダルの状態を反映させる
     * @param state 
     * @param action 
     */
    reflectAbolishCompanyModal: (state, action: PayloadAction<AbolishCompanyModalState>) => {
      return Object.assign({}, state, {
        abolishCompanyModalVisibleState: action.payload.abolishCompanyModalVisibleState
        , abolishCompanyList: action.payload.abolishCompanyList
      });
    },



    /**
     * 廃止企業一覧モーダルの表示/非表示切り替え
     * @param state 
     * @param action 
     */
    reflectAbolishCompanyModalState: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { abolishCompanyModalVisibleState: action.payload });
    }
  }
});

export const {
  reflectAbolishCompanyModalState
  , reflectAbolishCompanyModal
} = abolishCompanyInfoSlice.actions;



export const showAbolishCompanyAsync = (historyId: string): AppThunk => async dispatch => {

  const apiParam: getAbolishCompanyList.Request = {
    workRecordSubmissionHistoryId: historyId
  };

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_CMN_ABOLISH_COMPANY_LIST, {
      params: apiParam
    });

    // 取得結果を整形する。
    let abolishCompanyList: Array<AbolishCompanyListItem> = [];
    response.data.data.abolishCompanyList.forEach((abolishCompany: getAbolishCompanyList.AbolishCompany) => {
      abolishCompanyList.push({
        companyMergedDate: abolishCompany.companyMergedDate
        , abolishedCompanyName: abolishCompany.abolishedCompanyName
      })
    });

    // 結果をstateに設定します。
    dispatch(reflectAbolishCompanyModal({
      abolishCompanyModalVisibleState: true
      ,abolishCompanyList : abolishCompanyList
    }));

    // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error: any) {
    // 共通エラー時処理を呼び出します。
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
}

export const storedAbolishCompanyModalVisibleState = (state: RootState) => state.abolishCompanyModal.abolishCompanyModalVisibleState;
export const storeAbolishCompanyList = (state: RootState) => state.abolishCompanyModal.abolishCompanyList;

export default abolishCompanyInfoSlice.reducer;