/**
 * 指定された文字が空かどうかチェック
 * @param value 
 */
export const isNullOrEmpty = (value: string) => {
    if (typeof value === "undefined") {
        return true;
    } else if (value === null || value === '') {
        return true;
    } else {
        return false;
    }
}

/**
 * 入退場時間が逆転しているか確認する
 * @param firstInTime 
 * @param lastOutTime 
 * @returns 
 */
export const isInversionInOutTime = (firstInTime: string, lastOutTime: string) => {
    return !isNullOrEmpty(firstInTime)
        && !isNullOrEmpty(lastOutTime)
        && firstInTime > lastOutTime;
}

/**
 * 最後のページの開始位置を計算
 * @param total 
 * @param pageSize 
 * @returns 
 */
export const calcLastPageOffset = (total: number, pageSize: number) => {
    return Math.trunc((total - 1) / pageSize) * pageSize;
}