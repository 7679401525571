import React from 'react';
import { useHistory } from 'react-router'
import styles from './css/SystemError.module.css';
import { Row, Col, Button, Layout, Typography, Menu as AntdMenu } from 'antd';
import * as CONSTANTS from '../../constants/constants';

const { Title } = Typography;
const { Content } = Layout;

export const SystemError = () => {

  let history = useHistory();

  const onClickPrevBack = () => {
    // window.location.assign(`${window.location.protocol + "//" + window.location.host}/hoge`);
    history.push(CONSTANTS.PATH_TOP);
  }

  // 環境によってサービス利用時間の表記を変える
  const getServiceAvailableTime = () => {
    if (window.location.hostname.startsWith('t2') || window.location.hostname.startsWith('s2')) {
      return "月 ～ 金　　9:00 ～ 20:00";
    } else {
      return "月 ～ 土　　7:00 ～ 20:00";
    }
  }

  return (
    <Layout>
      <Row className={styles.HeaderArea}>
        <Col span={6} className={styles.HeaderlogoArea}>
          <img
            className={styles.LogoStyle}
            src={process.env.PUBLIC_URL + '/header_logo_green.png'}
            alt="スキルマップサイト" />
        </Col>
      </Row>


      <AntdMenu className={styles.HeaderMenu}>
      </AntdMenu>

      <Content>
        <div className={styles.mainMessage} >ただいまメンテナンス中です。</div>
        <div className={styles.mainMessage} >サービス利用可能時間は下記のとおりです。</div>
        <br />
        <div className={styles.mainMessage} >{getServiceAvailableTime()}</div>
        <br />
        <br />
        <br />
        <div className={styles.mainMessage} >上記の時間内にこの画面が表示された場合は、</div>
        <div className={styles.mainMessage} >再度下記のボタンからシステムにアクセスしてください。</div>
        <br />
        <div className={styles.mainMessage} >繰り返しこの画面が表示される場合は、</div>
        <div className={styles.mainMessage} >システム管理者へお問い合わせください。</div>
        <br />
        <br />
        <Button
          className={styles.sysButton}
          onClick={onClickPrevBack}
        >
          トップへ
        </Button>
      </Content>
    </Layout >
  );
}
