import React from 'react';
import { useSelector } from 'react-redux';
import { storedLoginUserInfo, IUserInfo } from '../../../modules/core/authModule';
import { isPrimeMenu } from '../../../modules/core/menuModule';
import styles from '../css/AuthButton.module.css';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button'

/**
 * 指定可能なパラメータ定義（拡張）
 * name: ボタンの表示ラベル
 * authorities: このボタンを表示する条件（ユーザが持つ権限）指定された権限を持っていれば画面表示する。無指定なら常に表示
 */
interface AuthbuttonProps extends ButtonProps {
    name?: string
    authorities?: Array<string>
}

// パラメータauthoritiesに指定可能な権限定義
export const AuthButtonAuthorities = {
    // 元請アクセス
    primeAccessAuth: "primeAccessAuth"
    // 元請利用者
    , primeAvailableAuth: "primeAvailableAuth"
    // 元請勤怠参照者
    , primeAttendanceReffenceRoleAuth: "primeAttendanceReffenceRoleAuth"
    // 元請勤怠承認者
    , primeAttendanceApprovalAuth: "primeAttendanceApprovalAuth"
    // 元請勤怠承認役割保持者
    , primeAttendanceApprovalRoleAuth: "primeAttendanceApprovalRoleAuth"
    // 元請資格管理者
    , primeQualificationMngAuth: "primeQualificationMngAuth"
    // 元請システム管理者
    , primeSystemMngAuth: "primeSystemMngAuth"
    // 元請_資格承認フロー_申請権限
    , primeCertificationFlowRequestAuth: "primeCertificationFlowRequestAuth"
    // 元請_資格承認フロー_確認権限
    , primeCertificationFlowConfirmAuth: "primeCertificationFlowConfirmAuth"
    // 元請_資格承認フロー_承認権限
    , primeCertificationFlowApproveAuth: "primeCertificationFlowApproveAuth"
    // 協力アクセス
    , partnerAccessAuth: "partnerAccessAuth"
    // 協力利用者
    , partnerAvailableAuth: "partnerAvailableAuth"
    // 協力勤怠提出者
    , partnerAttendanceSubmitAuth: "partnerAttendanceSubmitAuth"
    // 協力システム管理者
    , partnerSystemMngAuth: "partnerSystemMngAuth"
}

/**
 * ユーザが持つ権限によって表示、非表示を切り替えるボタン（antDesignのButtonコンポーネントの拡張）
 * ・nameプロパティ指定でボタン名を設定可能とした。
 * ・authoritiesプロパティ指定で押下可能判断を可能とした。
 * ・他動作は原則Buttonコンポーネントと同じ
 * @param props 
 * @returns 
 */
export const AuthButton = (props: AuthbuttonProps) => {
    const loginUserInfo = useSelector(storedLoginUserInfo);
    const isPrime = useSelector(isPrimeMenu);

    // prime/partner用CSSを切り替えます。
    const classNameProperty = () => {
        if (typeof props.className === "undefined") {
            return isPrime ? styles.authButton : styles.partnerAuthButton;
        }
        return props.className;
    }

    // パラメータ権限指定がある場合、権限による押下可否判断を行い、認可時のみ押下可能ボタンを描画します。
    // パラメータ権限指定がない場合は押下可能ボタンを描画します。
    if (typeof props.authorities === "undefined" || isAllowed(props.authorities, loginUserInfo)) {
        return (
            <Button {...props}
                className={classNameProperty()} >
                {props.name}
            </Button>
        )
    }

    // 認可されていない場合、ボタンを表示しません。
    return (
        <Button {...props}
            className={classNameProperty() + " " + styles.displayNone} >
            {props.name}
        </Button>
    )
}

// 権限による押下認可判定を行います。
const isAllowed = (authorities: Array<string>, loginUserInfo: IUserInfo) => {
    let ret: boolean = false;

    // 権限指定がある場合、ログインユーザ情報を参照し、指定権限が権限有りなら許可する
    authorities.forEach((authority: string) => {
        if (loginUserInfo.authorityInfo[authority] === "1") {
            ret = true;
        }
    })
    // 権限指定がある場合、いずれの権限でも許可されなければ不許可
    return ret;
}
