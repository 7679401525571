import { IUserInfo } from "../../../modules/core/authModule";
import { IMenuItem } from '../../../modules/core/menuModule';
import {
    IMenuItemPermissionSettings
    , primeMenuPermissionSettings
    , partnerMenuPermissionSettings
} from '../../../settings/menuPermissionSettings'
import * as CONSTANTS from '../../../constants/constants';

// 元請メニューを構成します。
export const generatePrimeMenu = (loginUserInfo: IUserInfo) => {
    let primeMenu: Array<IMenuItem> = [];

    primeMenuPermissionSettings.forEach((menuItemPS: IMenuItemPermissionSettings) => {

        if (isAllowedAdding(menuItemPS, loginUserInfo)) {

            let menuItem = {
                id: menuItemPS.id
                , title: menuItemPS.title
                , url: menuItemPS.url
            }
            primeMenu.push(menuItem);
        }
    })

    return primeMenu;
}

// 協力会社メニューを構成します。
export const generatePartnerMenu = (loginUserInfo: IUserInfo) => {
    let partnerMenu: Array<IMenuItem> = [];

    partnerMenuPermissionSettings.forEach((menuItemPS: IMenuItemPermissionSettings) => {

        if (isAllowedAdding(menuItemPS, loginUserInfo)) {

            let menuItem = {
                id: menuItemPS.id
                , title: menuItemPS.title
                , url: menuItemPS.url
            }
            partnerMenu.push(menuItem);
        }
    })

    return partnerMenu;
}

// 指定メニューが追加可能かどうか判定します。
const isAllowedAdding = (menuItemPS: IMenuItemPermissionSettings, loginUserInfo: IUserInfo) => {

    // 元請メニューの場合、契約していなければ強制的にメニュー表示不可とする
    if (menuItemPS.id.indexOf("prime") !== -1 &&
        loginUserInfo.contractPrimeService == CONSTANTS.CONTRACT_PRIME_SERVICE.NOT_CONTRACT ) {
        return false;
    }

    // メニューIDに"prime"が含まれているかつ、勤怠参照者権限または勤怠承認者権限があるかつ、元請アクセス権限：有かつ、元請利用者権限：無の場合、勤怠実績管理（プロジェクト）のみ表示
    // 目的：「現場に参加しており勤怠の確認や承認が必要な人（例えば所長とか）」はスキルマップの権限関係なく勤怠承認のための画面にはアクセスできる必要があるので例外的に許可している
    if (menuItemPS.id.indexOf("prime") !== -1 &&
        loginUserInfo.authorityInfo.primeAccessAuth === "1" &&
        loginUserInfo.authorityInfo.primeAvailableAuth === "0" &&
        (loginUserInfo.authorityInfo.primeAttendanceApprovalRoleAuth === "1" ||
            loginUserInfo.authorityInfo.primeAttendanceReffenceRoleAuth === "1")) {
        if (menuItemPS.id === "primeWorkRecord") {
            return true;
        }
        return false;
    }


    // 元請アクセス権限 認可チェック 
    if (menuItemPS.primeAccessAuth &&
        loginUserInfo.authorityInfo.primeAccessAuth === "1") {
        return true;
    }
    // 元請利用者権限 認可チェック 
    if (menuItemPS.primeAvailableAuth &&
        loginUserInfo.authorityInfo.primeAvailableAuth === "1") {
        return true;
    }
    // 元請勤怠参照役割保持者 認可チェック
    if (menuItemPS.primeAttendanceReffenceRoleAuth &&
        loginUserInfo.authorityInfo.primeAttendanceReffenceRoleAuth === "1") {
        return true;
    }
    // 元請勤怠承認者権限 認可チェック
    if (menuItemPS.primeAttendanceApprovalAuth &&
        loginUserInfo.authorityInfo.primeAttendanceApprovalAuth === "1") {
        return true;
    }
    // 元請勤怠承認役割保持者 認可チェック
    if (menuItemPS.primeAttendanceApprovalRoleAuth &&
        loginUserInfo.authorityInfo.primeAttendanceApprovalRoleAuth === "1") {
        return true;
    }
    // 元請資格管理者権限 認可チェック
    if (menuItemPS.primeQualificationMngAuth &&
        loginUserInfo.authorityInfo.primeQualificationMngAuth === "1") {
        return true;
    }
    // 元請システム管理者権限 認可チェック
    if (menuItemPS.primeSystemMngAuth &&
        loginUserInfo.authorityInfo.primeSystemMngAuth === "1") {
        return true;
    }
    // 協力アクセス権限 認可チェック 
    if (menuItemPS.partnerAccessAuth &&
        loginUserInfo.authorityInfo.partnerAccessAuth === "1") {
        return true;
    }
    // 協力利用者権限 認可チェック
    if (menuItemPS.partnerAvailableAuth &&
        loginUserInfo.authorityInfo.partnerAvailableAuth === "1") {
        return true;
    }
    // 協力勤怠提出者 認可チェック
    if (menuItemPS.partnerAttendanceSubmitAuth &&
        loginUserInfo.authorityInfo.partnerAttendanceSubmitAuth === "1") {
        return true;
    }
    // 協力システム管理者 認可チェック
    if (menuItemPS.partnerSystemMngAuth &&
        loginUserInfo.authorityInfo.partnerSystemMngAuth === "1") {
        return true;
    }

    return false;
}
