import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../store/store';
import { getRequest, apiSuccessHandler, apiErrorHandler, setApiResult, download, getDownloadFileNameFromResHeader } from '../../core/bffApiModule'
import * as CONSTANTS from '../../../constants/constants';
import * as Model from './reportModel';


// state初期化
const initialState: Model.State = {
  belongCompanyList_detail: []
  , belongCompanyRow_detail: ""
  , primeCompanyList_detail: []
  , primeCompanyRow_detail: ""
  , belongCompanyList_summary: []
  , belongCompanyRow_summary: ""
  , primeCompanyList_summary: []
  , primeCompanyRow_summary: ""
  , branchList_detail: []
  , branchRow_detail: ""
  , branchList_summary: []
  , branchRow_summary: ""
  , outputApprovedOnlyFlag_detail: true
  , outputApprovedOnlyFlag_summary: true
  , selectRadio: "1"
  , disabled: false
  , loadingFlg: false
};

// スライサー （state更新アクションの実装）
export const reportSlice = createSlice({
  name: 'partnerReport',
  initialState,
  reducers: {
    setPrimeCompanyRow_detail: (state, action: PayloadAction<string>) => {
      state.primeCompanyRow_detail = action.payload;
    },
    setBelongCompanyRow_detail: (state, action: PayloadAction<string>) => {
      state.belongCompanyRow_detail = action.payload;
    },
    setBranchRow_detail: (state, action: PayloadAction<string>) => {
      state.branchRow_detail = action.payload;
    },
    setOutputApprovedOnlyFlag_detail: (state, action: PayloadAction<boolean>) => {
      state.outputApprovedOnlyFlag_detail = action.payload;
    },
    setPrimeCompanyList_detail: (state, action: PayloadAction<Model.getSearchConditions.Response>) => {

      let primeCompanyList: Array<Model.primeCompany> = [];
      let selected = false;
      action.payload.primeCompanyList.forEach((apiDataRow: Model.primeCompany) => {
        const primeCompany: Model.primeCompany = {
          primeCompanyId: apiDataRow.primeCompanyId
          , primeCompanyName: apiDataRow.primeCompanyName
        };
        primeCompanyList.push(primeCompany);
        if (apiDataRow.primeCompanyId === state.primeCompanyRow_detail) {
          // 選択しているものが含まれる場合
          selected = true;
        }
      });
      if (!selected) {
        state.primeCompanyRow_detail = "";
      }
      state.primeCompanyList_detail = primeCompanyList;
    },
    setBelongCompanyList_detail: (state, action: PayloadAction<Model.getSearchConditions.Response>) => {

      let belongCompanyList: Array<Model.belongCompany> = [];
      let selected = false;
      action.payload.belongCompanyList.forEach((apiDataRow: Model.belongCompany) => {
        const belongCompany: Model.belongCompany = {
          belongCompanyId: apiDataRow.belongCompanyId
          , belongCompanyName: apiDataRow.belongCompanyName
        };
        belongCompanyList.push(belongCompany);
        if (apiDataRow.belongCompanyId === state.belongCompanyRow_detail) {
          // 選択しているものが含まれる場合
          selected = true;
        }
      });
      if (!selected) {
        state.belongCompanyRow_detail = "";
      }
      state.belongCompanyList_detail = belongCompanyList;
    },
    setBranchList_detail: (state, action: PayloadAction<Model.getSearchConditions.Response>) => {

      let branchList: Array<Model.branch> = [];
      let selected = false;
      action.payload.branchList.forEach((apiDataRow: Model.branch) => {
        const branch: Model.branch = {
          branchId: apiDataRow.branchId
          , branchName: apiDataRow.branchName
        };
        branchList.push(branch);
        if (apiDataRow.branchId === state.branchRow_detail) {
          // 選択しているものが含まれる場合
          selected = true;
        }
      });
      if (!selected) {
        state.branchRow_detail = "";
      }
      state.branchList_detail = branchList;
    },
    setPrimeCompanyRow_summary: (state, action: PayloadAction<string>) => {
      state.primeCompanyRow_summary = action.payload;
    },
    setBelongCompanyRow_summary: (state, action: PayloadAction<string>) => {
      state.belongCompanyRow_summary = action.payload;
    },
    setBranchRow_summary: (state, action: PayloadAction<string>) => {
      state.branchRow_summary = action.payload;
    },
    setOutputApprovedOnlyFlag_summary: (state, action: PayloadAction<boolean>) => {
      state.outputApprovedOnlyFlag_summary = action.payload;
    },
    setPrimeCompanyList_summary: (state, action: PayloadAction<Model.getSearchConditions.Response>) => {

      let primeCompanyList: Array<Model.primeCompany> = [];
      let selected = false;
      action.payload.primeCompanyList.forEach((apiDataRow: Model.primeCompany) => {
        const primeCompany: Model.primeCompany = {
          primeCompanyId: apiDataRow.primeCompanyId
          , primeCompanyName: apiDataRow.primeCompanyName
        };
        primeCompanyList.push(primeCompany);
        if (apiDataRow.primeCompanyId === state.primeCompanyRow_summary) {
          // 選択しているものが含まれる場合
          selected = true;
        }
      });
      if (!selected) {
        state.primeCompanyRow_summary = "";
      }
      state.primeCompanyList_summary = primeCompanyList;
    },
    setBelongCompanyList_summary: (state, action: PayloadAction<Model.getSearchConditions.Response>) => {

      let belongCompanyList: Array<Model.belongCompany> = [];
      let selected = false;
      action.payload.belongCompanyList.forEach((apiDataRow: Model.belongCompany) => {
        const belongCompany: Model.belongCompany = {
          belongCompanyId: apiDataRow.belongCompanyId
          , belongCompanyName: apiDataRow.belongCompanyName
        };
        belongCompanyList.push(belongCompany);
        if (apiDataRow.belongCompanyId === state.belongCompanyRow_summary) {
          // 選択しているものが含まれる場合
          selected = true;
        }
      });
      if (!selected) {
        state.belongCompanyRow_summary = "";
      }
      state.belongCompanyList_summary = belongCompanyList;
    },
    setBranchList_summary: (state, action: PayloadAction<Model.getSearchConditions.Response>) => {

      let branchList: Array<Model.branch> = [];
      let selected = false;
      action.payload.branchList.forEach((apiDataRow: Model.branch) => {
        const branch: Model.branch = {
          branchId: apiDataRow.branchId
          , branchName: apiDataRow.branchName
        };
        branchList.push(branch);
        if (apiDataRow.branchId === state.branchRow_summary) {
          // 選択しているものが含まれる場合
          selected = true;
        }
      });
      if (!selected) {
        state.branchRow_summary = "";
      }
      state.branchList_summary = branchList;
    },
    selectRadio: (state, action: PayloadAction<string>) => {
      state.selectRadio = action.payload;
    },
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.disabled = action.payload;
    },
    setLoadingFlg: (state, action: PayloadAction<boolean>) => {
      return Object.assign({}, state, { loadingFlg: action.payload })
    },
  }
});

export const {
  setPrimeCompanyRow_detail
  , setBelongCompanyRow_detail
  , setBranchRow_detail
  , setOutputApprovedOnlyFlag_detail
  , setPrimeCompanyList_detail
  , setBelongCompanyList_detail
  , setBranchList_detail
  , setPrimeCompanyRow_summary
  , setBelongCompanyRow_summary
  , setBranchRow_summary
  , setOutputApprovedOnlyFlag_summary
  , setPrimeCompanyList_summary
  , setBelongCompanyList_summary
  , setBranchList_summary
  , selectRadio
  , setDisabled
  , setLoadingFlg
} = reportSlice.actions;


//　勤怠検索条件取得（勤怠実績）
export const getSearchConditionsAsync_detail = (param: Model.getSearchConditions.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_PARTNER_SEARCH_CONDITIONS, {
      params: param
    });
    // 結果をstateに設定します。
    if (param.belongCompanyId === "" && param.primeCompanyId === "") {
      // 初期表示
      dispatch(setBelongCompanyList_detail(response.data.data));
      dispatch(setPrimeCompanyList_detail(response.data.data));
      dispatch(setBranchList_detail(response.data.data));
    } else if (param.belongCompanyId !== "" && param.primeCompanyId !== "") {
      // 初期表示
      dispatch(setBranchList_detail(response.data.data));
    } else {
      if (param.belongCompanyId !== "") {
        // 所属会社変更
        dispatch(setPrimeCompanyList_detail(response.data.data));
        // 支店
        dispatch(setBranchList_detail(response.data.data));
      }
      if (param.primeCompanyId !== "") {
        // 元請会社変更
        dispatch(setBelongCompanyList_detail(response.data.data));
        // 支店
        dispatch(setBranchList_detail(response.data.data));
      }
    }
    // // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　勤怠検索条件取得（勤怠実績サマリ）
export const getSearchConditionsAsync_summary = (param: Model.getSearchConditions.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_PARTNER_SEARCH_CONDITIONS, {
      params: param
    });
    // 結果をstateに設定します。
    if (param.belongCompanyId === "" && param.primeCompanyId === "") {
      // 初期表示
      dispatch(setBelongCompanyList_summary(response.data.data));
      dispatch(setPrimeCompanyList_summary(response.data.data));
      dispatch(setBranchList_summary(response.data.data));
    } else if (param.belongCompanyId !== "" && param.primeCompanyId !== "") {
      // 初期表示
      dispatch(setBranchList_summary(response.data.data));
    } else {
      if (param.belongCompanyId !== "") {
        // 所属会社変更
        dispatch(setPrimeCompanyList_summary(response.data.data));
        // 支店
        dispatch(setBranchList_summary(response.data.data));
      }
      if (param.primeCompanyId !== "") {
        // 元請会社変更
        dispatch(setBelongCompanyList_summary(response.data.data));
        // 支店
        dispatch(setBranchList_summary(response.data.data));
      }
    }
    // // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

// 勤怠検索条件取得（勤怠実績、勤怠実績サマリの両方）
// 画面初期表示時のような両方の帳票で同じ条件であると分かりきっている場合に利用
export const getSearchConditionsAsync_allReport = (param: Model.getSearchConditions.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    const response = await getRequest(CONSTANTS.URL_ATTENDANCE_PARTNER_SEARCH_CONDITIONS, {
      params: param
    });
    // 結果をstateに設定します。
    if (param.belongCompanyId === "" && param.primeCompanyId === "") {
      // 初期表示（勤怠実績）
      dispatch(setBelongCompanyList_detail(response.data.data));
      dispatch(setPrimeCompanyList_detail(response.data.data));
      dispatch(setBranchList_detail(response.data.data));
      // 初期表示（勤怠実績サマリ）
      dispatch(setBelongCompanyList_summary(response.data.data));
      dispatch(setPrimeCompanyList_summary(response.data.data));
      dispatch(setBranchList_summary(response.data.data));
     
    } else if (param.belongCompanyId !== "" && param.primeCompanyId !== "") {
      // 初期表示（勤怠実績）
      dispatch(setBranchList_detail(response.data.data));
      // 初期表示（勤怠実績サマリ）
      dispatch(setBranchList_summary(response.data.data));
    } else {
      if (param.belongCompanyId !== "") {
        // 所属会社変更、支店（勤怠実績）
        dispatch(setPrimeCompanyList_detail(response.data.data));
        dispatch(setBranchList_detail(response.data.data));
        // 所属会社変更、支店（勤怠実績サマリ）
        dispatch(setPrimeCompanyList_summary(response.data.data));
        dispatch(setBranchList_summary(response.data.data));
      }
      if (param.primeCompanyId !== "") {
        // 元請会社変更、支店
        dispatch(setBelongCompanyList_detail(response.data.data));
        dispatch(setBranchList_detail(response.data.data));
        // 元請会社変更、支店
        dispatch(setBelongCompanyList_summary(response.data.data));
        dispatch(setBranchList_summary(response.data.data));
      }
    }
    // // 共通成功時処理を呼び出します。
    dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
}



//　勤怠実績帳票ダウンロード
export const downloadAttendanceRecord = (request: Model.downloadAttendanceRecord.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    // ダウンロードします
    const response = await download(CONSTANTS.URL_REPORT_PARTNER_ATTENDANCE,
      request,
      { responseType: 'blob' }
    );
    if (getDownloadFileNameFromResHeader(response.headers) === 'untitled') {
      dispatch(setApiResult({ status: response.status, errorCode: "EA8004001", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
      return;
    }
    // 共通成功時処理を呼び出します。
    // dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
    if (response.status === CONSTANTS.API_STATUS_200) {
      dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
    }
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};

//　勤怠実績サマリ帳票ダウンロード
export const downloadAttendanceSummaryRecord = (request: Model.downloadAttendanceSummaryRecord.Request): AppThunk => async dispatch => {

  try {
    // GET-APIを呼び出します。
    // ダウンロードします
    const response = await download(CONSTANTS.URL_REPORT_PARTNER_ATTENDANCE_SUMMARY,
      request,
      { responseType: 'blob' }
    );
    if (getDownloadFileNameFromResHeader(response.headers) === 'untitled') {
      dispatch(setApiResult({ status: response.status, errorCode: "EA8004001", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
      return;
    }
    // 共通成功時処理を呼び出します。
    // dispatch(apiSuccessHandler(response, CONSTANTS.REQUEST_METHOD_GET));
    if (response.status === CONSTANTS.API_STATUS_200) {
      dispatch(setApiResult({ status: response.status, errorCode: "", requestMethod: CONSTANTS.REQUEST_METHOD_GET, url: response.config.url }));
    }
  } catch (error :any) {
    // 共通エラー時処理を呼び出します。
    console.log(error);
    dispatch(apiErrorHandler(error.response, CONSTANTS.REQUEST_METHOD_GET));
  }
};


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const storedBelongCompanyList_detail = (state: RootState) => state.partnerReport.belongCompanyList_detail;
export const storedBelongCompanyRow_detail = (state: RootState) => state.partnerReport.belongCompanyRow_detail;
export const storedPrimeCompanyList_detail = (state: RootState) => state.partnerReport.primeCompanyList_detail;
export const storedPrimeCompanyRow_detail = (state: RootState) => state.partnerReport.primeCompanyRow_detail;
export const storedOutputApprovedOnlyFlag_detail = (state: RootState) => state.partnerReport.outputApprovedOnlyFlag_detail;
export const storedBelongCompanyList_summary = (state: RootState) => state.partnerReport.belongCompanyList_summary;
export const storedBelongCompanyRow_summary = (state: RootState) => state.partnerReport.belongCompanyRow_summary;
export const storedPrimeCompanyList_summary = (state: RootState) => state.partnerReport.primeCompanyList_summary;
export const storedPrimeCompanyRow_summary = (state: RootState) => state.partnerReport.primeCompanyRow_summary;
export const storedBranchList_detail = (state: RootState) => state.partnerReport.branchList_detail;
export const storedBranchRow_detail = (state: RootState) => state.partnerReport.branchRow_detail;
export const storedBranchList_summary = (state: RootState) => state.partnerReport.branchList_summary;
export const storedBranchRow_summary = (state: RootState) => state.partnerReport.branchRow_summary;
export const storedOutputApprovedOnlyFlag_summary = (state: RootState) => state.partnerReport.outputApprovedOnlyFlag_summary;
export const storedSelectRadio = (state: RootState) => state.partnerReport.selectRadio;
export const storedDisabled = (state: RootState) => state.partnerReport.disabled;
export const storedLoadingFlg = (state: RootState) => state.partnerReport.loadingFlg;

export default reportSlice.reducer;
