import React from 'react';
import { useSelector } from 'react-redux';
import { isPrimeMenu } from '../../../modules/core/menuModule';
import styles from '../css/Checkbox.module.css';
import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox'

export const Checkbox = (props: CheckboxProps) => {
    const isPrime = useSelector(isPrimeMenu);

    // prime/partner用CSSを切り替えます。
    const classNameProperty = () => {
        if (typeof props.className === "undefined") {
            return isPrime ? styles.primeCheckbox : styles.partnerCheckbox;
        }
        return props.className;
    }

    // 認可されていない場合、ボタンを表示しません。※レイアウトが崩れないようvisible:noneボタンを描画します。
    return (
        <AntCheckbox {...props}
            className={classNameProperty()} >
        </AntCheckbox>
    )
}

