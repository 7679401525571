import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Table } from "antd";
import { Checkbox, AuthButton } from "../../core/CoreForm"
import { Header, ContentEx as Content, FooterButtonArea, Footer, FunctionTitle, CommonMessage } from "../../core/CorePageContents"
import "../../core/css/core.css";
import styles from "./PartnerAuthorityList.module.css";
import { useHistory } from "react-router"
import * as Module from "../../../modules/partner/auth/partnerAuthorityModule";
import * as Model from "../../../modules/partner/auth/partnerAuthorityModel";
import * as CONSTANTS from "../../../constants/constants";
import { storedLoginUserInfo, getLoginUserInfoAsync } from "../../../modules/core/authModule";
import AuthButtonStyles from "../../core/css/AuthButton.module.css";
import { storedApiResult, initApiResult } from "../../../modules/core/bffApiModule";
import { setMessages, message } from "../../../modules/core/commonMessageModule";
import * as messagesUtils from "../../../utils/messagesUtils";

// チェックボックス
const CHECKBOX_CHECK = "1";
const CHECKBOX_NONE = "0";

interface titleInterface {
  title: string;
}

export const PartnerAuthorityListEdit = (props: titleInterface) => {

  const dispatch = useDispatch();
  let dataList = useSelector(Module.storedDataList);
  let loadingFlg = useSelector(Module.storedLoadingFlg);
  let history = useHistory();
  const loginUserInfo = useSelector(storedLoginUserInfo);
  const apiResult = useSelector(storedApiResult);

  // dataListがなくなったら元の一覧画面に戻る
  if (dataList.length === 0) {
    history.push(CONSTANTS.PATH_PARTNER_AUTH_LIST);
  }

  // 戻るボタン押下時アクション
  const backClicked = () => {
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_BACK"))) {
      return;
    }
    history.push(CONSTANTS.PATH_PARTNER_AUTH_LIST);
  }

  // 確定ボタン押下時アクション
  const confirmClicked = () => {
    // メッセージを設定して確認ダイアログを表示します。
    if (!window.confirm(messagesUtils.getMessage("CONFIRM_UPDATE", ["権限"]))) {
      return;
    }

    // API結果情報を初期化します。
    dispatch(initApiResult());

    dispatch(Module.setLoadingFlg(true));
    dispatch(Module.putDataLsitAsync(dataList));
  }

  // 更新API実行後、APIエラーメッセージが設定されていない場合、一覧画面に遷移して更新成功メッセージを表示します。
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" === apiResult.errorCode && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod) {
    // 成功メッセージを設定します。
    const setMessage: message = {
      message: messagesUtils.getMessage("SUCCESS_UPDATE", ["権限"])
      , messageType: CONSTANTS.MESSAGE_TYPE_SUCCESS
    }
    dispatch(setMessages([setMessage]));
    dispatch(Module.setLoadingFlg(false));
    // API処理結果を初期化します。
    dispatch(initApiResult());
    // ログインユーザ情報を再取得します。
    dispatch(getLoginUserInfoAsync());
    history.push(CONSTANTS.PATH_PARTNER_AUTH_LIST);

    // メッセージはセッションにも保持
    messagesUtils.setMessageInSession(setMessage.message, setMessage.messageType);
  }

  // 更新API実行後、APIエラーメッセージが設定されている場合
  else if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status && "" !== apiResult.errorCode && CONSTANTS.REQUEST_METHOD_PUT === apiResult.requestMethod) {
    dispatch(Module.setLoadingFlg(false));
  }

  // チェックボックス切替時アクション
  const onChangeCheckbox = (e: any, record: Model.AuthorityRow, dataIndex: string) => {
    const keyValueArray = [];
    keyValueArray.push({ propertyName: dataIndex, value: (e.target.checked) ? CHECKBOX_CHECK : CHECKBOX_NONE });
    changeValueTransfer(keyValueArray, record);
  }

  // 変更値をstateに反映する
  const changeValueTransfer = (keyValueArray: any, record: Model.AuthorityRow) => {
    let transfer: Model.changeValueTransfer = {
      targetDataRow: record,
      changeKeyValueArray: keyValueArray
    }
    dispatch(Module.reflectAuthorityMasetrPrimeValue(transfer));
  }

  // 利用者以外のチェックボックス活性・非活性の制御
  const disabledCheckBox = (record: Model.AuthorityRow) => {
    if (CHECKBOX_CHECK === record.partnerAvailableAuth) {
      return false;
    } else {
      return true;
    }
  }

  // 利用者本人のデータの場合は非活性にする
  const myDataAuthorityDisabled = (record: Model.AuthorityRow) => {
    if (loginUserInfo.mcdpId === record.mcdpId) {
      return true;
    } else {
      return false;
    }
  }

  // テーブル列を設定します。
  const columns = [
    {
      title: "MCDP-ID",
      dataIndex: "mcdpId",
      align: "center" as "center",
      width: 150,
    },
    {
      title: "ユーザ名",
      dataIndex: "userName",
      align: "center" as "center",
      width: 150,
    },
    {
      title: "利用者",
      dataIndex: "partnerAvailableAuth",
      align: "center" as "center",
      width: 100,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          onChange={(e) => onChangeCheckbox(e, record, "partnerAvailableAuth")}
          disabled={myDataAuthorityDisabled(record)}
          className={styles.partnerCheckbox}
        >
        </Checkbox >
    },
    {
      title: "勤怠提出者",
      dataIndex: "partnerAttendanceSubmitAuth",
      align: "center" as "center",
      width: 100,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          onChange={(e) => onChangeCheckbox(e, record, "partnerAttendanceSubmitAuth")}
          disabled={disabledCheckBox(record)}
          className={styles.partnerCheckbox}
        >
        </Checkbox>
    },
    {
      title: "システム管理者",
      dataIndex: "partnerSystemMngAuth",
      align: "center" as "center",
      width: 100,
      render: (text: string, record: Model.AuthorityRow) =>
        <Checkbox
          defaultChecked={CHECKBOX_CHECK === text ? true : false}
          onChange={(e) => onChangeCheckbox(e, record, "partnerSystemMngAuth")}
          disabled={disabledCheckBox(record) || myDataAuthorityDisabled(record)}
          className={styles.partnerCheckbox}
        >
        </Checkbox>
    },
  ];

  // フッターボタンエリアを描画します。
  const ButtonArea = () => {
    return (
      <FooterButtonArea hasapicall={loadingFlg ? "true" : "false"}>
        <AuthButton
          name="戻る"
          size={"large"}
          shape={"round"}
          onClick={backClicked}
          className={AuthButtonStyles.backButtonFixed}
          style={{ margin: "10px" }}
        />
        <AuthButton
          name="確定"
          size={"large"}
          shape={"round"}
          onClick={confirmClicked}
          className={AuthButtonStyles.authButtonFixed}
          style={{ margin: "10px" }}

        />
      </FooterButtonArea>
    )
  }


  return (
    <Layout>
      <Header />
      <Content hasapicall={loadingFlg ? "true" : "false"}>
        <FunctionTitle title={props.title} />
        <CommonMessage />
        <Table
          columns={columns}
          size={"small"}
          scroll={{ x: 1000 }}
          dataSource={dataList}
          rowClassName={styles.tableRow}
          pagination={false}
        />
      </Content>
      <ButtonArea />
      <Footer />
    </Layout>
  );
}