import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Typography, Table, Input } from "antd";
import { CriteriaForm, AuthButton, Radio } from "../../core/CoreForm"
import { ContentEx as Content, CommonMessage } from "../../core/CorePageContents"
import styles from "./ManagementGroupSelect.module.css";
import * as ManageModule from "../../../modules/prime/managementGroup/managementGroupModule";
import * as ManageModel from "../../../modules/prime/managementGroup/managementGroupModel";
import { Store } from "antd/lib/form/interface";
import * as CONSTANTS from "../../../constants/constants";
import { initMessages } from "../../../modules/core/commonMessageModule";
import { initApiResult, storedApiResult } from "../../../modules/core/bffApiModule";
import * as Utils from '../../../utils/utils';

const { Title } = Typography;

interface titleInterface {
  title: string;
}

export const ManagementGroupSelect = (props: titleInterface) => {

  const dispatch = useDispatch();
  const apiResult = useSelector(storedApiResult);
  let dataList = useSelector(ManageModule.storedDataList);
  let exclusionList = useSelector(ManageModule.storedExclusionList);
  let totalCnt = useSelector(ManageModule.storedTotal);
  let criteria = useSelector(ManageModule.storedManageCriteria)
  let modalSelectValue = useSelector(ManageModule.storedModalSelectValue);

  useEffect(() => {

    // API結果情報を初期化します。
    dispatch(initApiResult());
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    let request: ManageModel.getBranchList.Request =
    {
      branchName: criteria.branchName,
      branchNameKana: criteria.branchNameKana,
      offset: criteria.offset,
      limit: criteria.limit,
      excludeBranchIdList: exclusionList.map((o) => o).toString()
    }
    dispatch(ManageModule.getDataListAsync(request));
  },
    [criteria]
  );

  const reflectSelectValue = (record: ManageModel.ManagementGroupSelectRow) => {
    // 選択値を保持
    dispatch(ManageModule.reflectModalSelectValue(record))
  }

  // ラジオボタンのフォーマット（カスタムラジオボタンを使用） 
  const radioFormatter = (value: string, row: ManageModel.ManagementGroupSelectRow) => {
    return (
      <Radio checked={row === modalSelectValue ? true : false} value={row.managementGroupId} />
    )
  }

  const columns = [
    {
      title: "選択",
      align: "center" as "center",
      width: 100,
      render: radioFormatter,
    },
    {
      title: "管理グループ名",
      dataIndex: "managementGroupName",
      align: "center" as "center",
      render: (text: string, row: ManageModel.ManagementGroupSelectRow) => {
        return (
          <>
            <div className={styles.manageName}>{text}</div>
          </>
        )
      },
    },
  ]

  // 検索ボタン押下時アクション
  const pushSerchButton = (value: Store) => {

    criteriaDataTransfer([
      { key: "branchName", value: value.branchName }
      , { key: "branchNameKana", value: value.branchNameKana }
      , { key: "offset", value: 0 }
    ])
  }

  const criteriaDataTransfer = (keyValueList: Array<{ key: string, value: any }>) => {
    let newCriteria: ManageModel.ManagementGroupCriteria = {
      branchName: criteria.branchName,
      branchNameKana: criteria.branchNameKana,
      offset: 0,
      limit: criteria.limit,
    }
    keyValueList.forEach((rec: { key: string, value: any }) => {
      newCriteria[rec.key] = rec.value;
    })
    dispatch(ManageModule.setCriteria(newCriteria));
    dispatch(ManageModule.initModalSelectValue());
  }

  // 検索が正常終了した時の処理
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && "" === apiResult.errorCode
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_CMN_PRIME_BRANCHES === apiResult.url) {

    // 2ページ目以降を表示していて、総件数はあるが、表示するデータが空の場合、再取得する
    if (criteria.offset > 0 && totalCnt > 0 && dataList.length === 0) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 最終ページを取得する（基本的には一つ前のページ）
      const newOffset = Utils.calcLastPageOffset(totalCnt, criteria.limit);
      criteriaDataTransfer([
        { key: "offset", value: newOffset }
      ]);
    }
  }

  // ページサイズ変更時アクション
  const handleTableChange = (pagination: any) => {

    let managementGroupSelectRow: ManageModel.ManagementGroupSelectRow = {
      key: "",
      managementGroupId: "",
      managementGroupName: ""
    }

    // 選択値を削除
    dispatch(ManageModule.reflectModalSelectValue(managementGroupSelectRow));

    let offsetReset: boolean = false;

    if (criteria.limit !== pagination.pageSize) {
      offsetReset = true;
    }

    criteriaDataTransfer([
      { key: "limit", value: pagination.pageSize }
      , { key: "offset", value: offsetReset ? 0 : (pagination.current - 1) * pagination.pageSize }
    ])
  }

  // ページングボタンを描画します。
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === "prev") {
      return <AuthButton name="＜" size="small" />;
    }
    if (type === "next") {
      return <AuthButton name="＞" size="small" />;
    }
    return originalElement
  }


  // 検索フォーム
  const AttendanceCriteria = () => {
    return (
      <CriteriaForm
        layout="inline"
        onFinish={pushSerchButton}
        initialValues={{
          "branchName": criteria.branchName,
          "branchNameKana": criteria.branchNameKana,
        }}
      >
        <div className={styles.criteriaSearchField}>
          <CriteriaForm.Item name="branchName" className={styles.formItem}>
            <Input type="text"
              placeholder="管理グループ名"
              maxLength={CONSTANTS.MANAGE_BRANCH_NAME_MAX_LENGTH} />
          </CriteriaForm.Item>
          <CriteriaForm.Item name="branchNameKana" className={styles.formItem}>
            <Input
              placeholder="管理グループ名カナ"
              maxLength={CONSTANTS.MANAGE_BRANCH_NAME_MAX_LENGTH}
              style={{ width: "130px" }} />
          </CriteriaForm.Item>
          <AuthButton name={"検索"}
            shape={"round"}
            htmlType="submit" />
        </div>
      </CriteriaForm>
    )
  }

  return (

    <Layout className={styles.layout}>
      <Content>
        <Title level={4} className={styles.functionTitle}>
          {props.title}
        </Title>
        <AttendanceCriteria />
        <CommonMessage />
        {totalCnt > 0 && dataList.length > 0 ?
          <Table
            columns={columns}
            size="small"
            rowKey="managementGroupId"
            scroll={{ x: 500, y: 230 }}
            dataSource={dataList}
            pagination={{
              total: totalCnt  //総件数
              , defaultCurrent: 1 //
              , current: criteria.offset / criteria.limit + 1
              , pageSize: criteria.limit //ページサイズを入れる
              , pageSizeOptions: ["5", "10", "20", "50", "100"]
              , showSizeChanger: true
              , showTotal: (total, range) => `${total}件中、${range[0]}件目から${range[1]}件目を表示`
              , itemRender: pageItemRender
              , locale: { items_per_page: "件/ページ" }
            }}
            onChange={(pagination, filters) => handleTableChange(pagination)}
            onRow={(record) => {
              return {
                onChange: event => { reflectSelectValue(record) }
              };
            }}
          />
          : <></>}
      </Content>
    </Layout>
  );
}

